import React, { useEffect, useState, useRef, useCallback } from 'react';
import universityDetails from './university_details_updated'; // Import the university details JSON
import courseData from './courses_updated'; // Import the courses JSON
import { useParams } from 'react-router-dom';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { toast } from 'react-hot-toast';
const PreviewUniversity = () => {
  const { id } = useParams(); // Extract the university id from the URL
  const [university, setUniversity] = useState(null);
  const [universityCourses, setUniversityCourses] = useState([]);
  const [activeTab, setActiveTab] = useState('info');
  const [courseType, setCourseType] = useState('Undergraduate'); // Default course type to Undergraduate
  const [activeCourseFilter, setActiveCourseFilter] = useState('Full-time'); // Default to full-time courses
  const [loadedCourses, setLoadedCourses] = useState([]);
  const [currentCoursePage, setCurrentCoursePage] = useState(1);
  const coursesPerPage = 6; // Number of courses to load at a time
  const courseLoader = useRef(null); // Reference to loader for infinite scrolling
  const [hasMoreCourses, setHasMoreCourses] = useState(true); // Whether there are more courses to load
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    // Find the university by id from universityDetails
    const foundUniversity = universityDetails.find((uni) => uni.id === id);
    setUniversity(foundUniversity);

    // Find the university courses by id from courseData
    const filteredCourses = courseData.find((course) => course.id === id);

    if (filteredCourses) {
      setUniversityCourses(filteredCourses);
    } else {
      setUniversityCourses(null);
    }

    // Load courses after university and course data are fetched
    setLoadedCourses([]);  // Reset loaded courses on first load
    setCurrentCoursePage(1);  // Reset the pagination
    setHasMoreCourses(true);  // Enable infinite scrolling
  }, [id]);

  // Load more courses for infinite scroll
  const loadCourses = useCallback(() => {
    if (!universityCourses) return;
    const filtered = universityCourses[courseType]?.[activeCourseFilter] || [];

    // Paginate courses
    const start = (currentCoursePage - 1) * coursesPerPage;
    const end = start + coursesPerPage;
    const coursesToLoad = filtered.slice(start, end);

    if (coursesToLoad.length === 0) {
      setHasMoreCourses(false); // No more courses to load
    } else {
      setLoadedCourses((prevCourses) => [...prevCourses, ...coursesToLoad]);
    }
  }, [courseType, activeCourseFilter, currentCoursePage, universityCourses]);

  useEffect(() => {
    loadCourses(); // Load courses on filter change or page change
  }, [courseType, activeCourseFilter, currentCoursePage, loadCourses]);

  // Infinite Scroll Observer for Courses
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMoreCourses) {
          setCurrentCoursePage((prevPage) => prevPage + 1); // Load next page of courses
        }
      },
      { threshold: 1 }
    );

    if (courseLoader.current) {
      observer.observe(courseLoader.current);
    }

    return () => {
      if (courseLoader.current) observer.unobserve(courseLoader.current);
    };
  }, [hasMoreCourses]);

  if (!university) return <div className="text-center text-white">University not found</div>;

  return (
    <div className="text-gray-200 bg-gray-900 min-h-screen font-sans">
      {/* Hero Section */}
      <div
        className="hero-section h-80 bg-cover bg-center flex items-center justify-center relative"
        style={{ backgroundImage: `url(${university.background_image})` }} // Use background_image field
      >
      <div className="hero-content text-center bg-gray-800 bg-opacity-60 p-6 rounded-md shadow-lg">
  <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-white tracking-wide">
    {university.university_name}
  </h1>
</div>

        <div className='flex h-24 w-24 items-center bg-white bottom-4 left-4  content-center absolute rounded-lg'>
          <img
            src={university.logo_url}
            alt=""
            className="max-w-full h-auto "
          />
        </div>
      </div>

      {/* Sub Navigation */}
      <div className="subnav flex justify-center space-x-6 py-4 border-b border-gray-700 mt-4">
        <button
          onClick={() => setActiveTab('info')}
          className={`px-6 py-2 text-lg font-semibold rounded transition-colors ${activeTab === 'info' ? 'bg-blue-600 text-white' : 'bg-gray-800 hover:bg-gray-700'
            }`}
        >
          Info
        </button>
        <button
          onClick={() => setActiveTab('courses')}
          className={`px-6 py-2 text-lg font-semibold rounded transition-colors ${activeTab === 'courses' ? 'bg-blue-600 text-white' : 'bg-gray-800 hover:bg-gray-700'
            }`}
        >
          Courses
        </button>
      </div>

      {/* Content Section */}
      <div className="content mt-8 px-6 md:px-12 lg:px-24">
        {activeTab === 'info' && (
          <div className="info-section text-gray-300">
          {/* About Us Section with Read More */}
          <div className="text-center mb-6">
            <p className={`text-lg leading-relaxed ${isExpanded ? '' : 'line-clamp-4'}`}>
              {university.about_us}
            </p>
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="text-blue-500 hover:text-blue-700 mt-2"
            >
              {isExpanded ? 'Read Less' : 'Read More'}
            </button>
          </div>
    
          {/* University Details */}
          <div className="mt-6 space-y-4">
            <h3 className="text-lg md:text-xl font-semibold mb-4 text-white text-center">University Details</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-center md:text-left">
              <p><strong>Location:</strong> {university.location}</p>
              <p><strong>Contact Email:</strong> {university.contact_email}</p>
              <p><strong>Contact Phone:</strong> {university.contact_phone}</p>
            </div>
    
            {/* Course Locations */}
            <h4 className="mt-8 text-lg md:text-2xl font-bold text-gray-200 text-center">Course Locations</h4>
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {university.course_locations.map((location, index) => (
                <div key={index} className="bg-gray-700 p-4 rounded-lg shadow-md flex items-center">
                  <div className="mr-4">
                    <FaMapMarkerAlt className="text-orange-500 h-6 w-6" />
                  </div>
                  <div>
                    <h5 className="text-white text-lg font-bold">{location.location_name}</h5>
                    <p className="text-sm text-white">{location.location_address}</p>
                  </div>
                </div>
              ))}
            </div>
    
            {/* Useful Links */}
            <h4 className="mt-8 text-lg md:text-xl font-semibold text-white text-center">Useful Links</h4>
            <div className="mt-2 flex flex-col space-y-2">
              <a
                href={university.official_website}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-700 text-center"
              >
                Official Website
              </a>
            </div>
          </div>
        </div>
        )}

        {activeTab === 'courses' && (
          <div className="courses-section">
            <h2 className="text-2xl font-bold text-white mb-6">Available Courses</h2>

            {/* Course Type and Study Mode Filters */}
            <div className="mb-6">
              <div className="flex space-x-4 mb-4">
                <button
                  onClick={() => {
                    setCourseType('Undergraduate');
                    setLoadedCourses([]); // Clear previous courses
                    setCurrentCoursePage(1); // Reset pagination
                    setHasMoreCourses(true); // Enable infinite scrolling again
                  }}
                  className={`px-4 py-2 rounded-lg ${courseType === 'Undergraduate' ? 'bg-blue-600 text-white' : 'bg-gray-800 text-gray-300'
                    }`}
                >
                  Undergraduate
                </button>
                <button
                  onClick={() => {
                    setCourseType('Postgraduate');
                    setLoadedCourses([]); // Clear previous courses
                    setCurrentCoursePage(1); // Reset pagination
                    setHasMoreCourses(true); // Enable infinite scrolling again
                  }}
                  className={`px-4 py-2 rounded-lg ${courseType === 'Postgraduate' ? 'bg-blue-600 text-white' : 'bg-gray-800 text-gray-300'
                    }`}
                >
                  Postgraduate
                </button>
              </div>

              <div className="flex space-x-4">
                <button
                  onClick={() => {
                    setActiveCourseFilter('Full-time');
                    setLoadedCourses([]); // Clear previous courses
                    setCurrentCoursePage(1); // Reset pagination
                    setHasMoreCourses(true); // Enable infinite scrolling again
                  }}
                  className={`px-4 py-2 rounded-lg ${activeCourseFilter === 'Full-time' ? 'bg-blue-600 text-white' : 'bg-gray-800 text-gray-300'
                    }`}
                >
                  Full-time
                </button>
                <button
                  onClick={() => {
                    setActiveCourseFilter('Part-time');
                    setLoadedCourses([]); // Clear previous courses
                    setCurrentCoursePage(1); // Reset pagination
                    setHasMoreCourses(true); // Enable infinite scrolling again
                  }}
                  className={`px-4 py-2 rounded-lg ${activeCourseFilter === 'Part-time' ? 'bg-blue-600 text-white' : 'bg-gray-800 text-gray-300'
                    }`}
                >
                  Part-time
                </button>
              </div>
            </div>

            {/* Display Filtered Courses */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {loadedCourses.length > 0 ? (
                loadedCourses.map((course, index) => (
                  <div
                  key={index}
                  className="bg-gray-800 p-6 rounded-lg shadow-md hover:bg-gray-700 transition-all cursor-pointer"
                  onClick={() => toast(
                    <span >
                      Please contact <a href="mailto:info@worldlynk.co.uk" style={{ color: 'blue' }}>info@worldlynk.co.uk</a> for more assistance.
                    </span>
                  )}
                >
                  <h3 className="text-lg font-medium text-gray-100">{course.course_name}</h3>
                  <p className="text-gray-400">{course.study_mode} - {course.duration}</p>
                  <p className="text-gray-400">Location: {course.location}</p>
                  <p className="text-gray-400">Qualification: {course.qualification}</p>
                  <p className="text-gray-400">UCAS Points: {course.ucas_points}</p>
                </div>
                
            ))
            ) : (
<div className="min-h-screen flex justify-center items-center">
  <div className="loader text-center py-6 text-white">
    Loading courses...
  </div>
</div>              )}
          </div>

            {/* Infinite Scroll Loader */}
        <div ref={courseLoader} className="loader text-center py-6 text-white">
          {hasMoreCourses ? 'Loading more courses...' : 'No more courses to load'}
        </div>
      </div>
        )}
    </div>
    </div >
  );
};

export default PreviewUniversity;
