const university_details_updated = [
    {
        "id": "b8694e01676bbdbd6b0154d5552b316e",
        "university_name": "ACM (The Academy of Contemporary Music)",
        "location": "The Rodboro Building, Bridge Street,, Guildford, Surrey, GU1 4SB",
        "official_website": "https://www.acm.ac.uk",
        "contact_email": "enquiries@acm.ac.uk",
        "contact_phone": "01483 500800",
        "course_locations": [
            {
                "location_name": "ACM Guildford",
                "location_address": "The Rodboro Buildings, Bridge Street, Guildford, GU1 4SB"
            },
            {
                "location_name": "ACM Online",
                "location_address": "The Rodboro Buildings, Bridge Street, Guildford, GU1 4SB"
            },
            {
                "location_name": "ACM London",
                "location_address": "16 Bromell's Road, London, SW4 0BG"
            },
            {
                "location_name": "ACM Birmingham",
                "location_address": "3 Lionel St, Birmingham, B3 1AG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3d3a3bb5-b96a-e00c-2682-29151c9bde11",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_hdr.jpg",
        "about_us": "ACM is a specialist provider, delivering degrees, diplomas and postgraduate provision across creative industries programmes in subjects including, music performance, games development, sound engineering, visual arts and business.Our students collaborate in interdisciplinary teams on industry-set projects throughout their programme. This is done in our state-of-the-art facilities, supported by industry mentors and an academic staff team who are also practicing professionals. ACM is a microcosm of the creative industries, giving our students an impactful space to start their journey into their chosen field whilst still on programme."
    },
    {
        "id": "7135f22a4f0b450a8878458ff1bca343",
        "university_name": "Arts University Bournemouth",
        "location": "Wallisdown, Poole, Poole, Dorset, BH12 5HH",
        "official_website": "https://www.aub.ac.uk",
        "contact_email": "admissions@aub.ac.uk",
        "contact_phone": "01202 363225",
        "course_locations": [
            {
                "location_name": "Main Site - Arts University Bournemouth",
                "location_address": "Wallisdown, Poole, BH12 5HH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/4e978513-90d5-434a-8616-ff7bdb3f7439",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/banner_image_-_aub.jpg",
        "about_us": "We\u2019ve been a specialist university focusing on art, design, media and performance since 1883. With just over 3,000 students based on our single campus, we\u2019re a close-knit community of creative and collaborative innovators, who all share a common commitment to the arts. We invest heavily in our teaching facilities to provide students with everything needed to fulfil their creative potential and thrive as graduates.You\u2019ll be taught by our course teams who are experts in their fields, bringing years of industry experience into a unique and creative academic environment.Courses are delivered through a range of methods, including studio practice; lectures; screenings; seminars (staff and student-led); group presentations and critiques; and external visits to galleries, theatres and exhibitions. "
    },
    {
        "id": "a80493401e6d7f9fec44c1f81cab199c",
        "university_name": "Associated Studios Performing Arts Academy",
        "location": "204 Latimer Road, London, W10 6QY",
        "official_website": "https://www.associatedstudios.co.uk",
        "contact_email": "info@associatedstudios.co.uk",
        "contact_phone": "02073852038",
        "course_locations": [
            {
                "location_name": "Associated Studios",
                "location_address": "204 Latimer Road, London, W10 6QY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3824407c-8811-4802-9272-49e879e3649c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/sa_banner.jpeg",
        "about_us": "Associated Studios is a leading British Drama school, committed to the training and continued professional development of performers in Musical Theatre.Associated Studios offers full-time and part-time training in Musical Theatre. In addition to the courses, professional development workshops in all disciplines run throughout the year with leading industry professionals.As a central part of all courses, students work with leading industry professionals including directors, vocal coaches, musical directors, repetiteurs, conductors, agents, casting directors, producers, choreographers and well-known performers. The full-time Musical Theatre courses offer a West End showcase for UK agents, casting directors and industry professionals as well as a full-scale public production in a professional theatre."
    },
    {
        "id": "c877da2fbea6072c8836d1b1b640719e",
        "university_name": "University College Birmingham",
        "location": "Summer Row, Birmingham, West Midlands, B3 1JB",
        "official_website": "https://www.ucb.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "0121 6041000",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Summer Row, Birmingham, B3 1JB"
            },
            {
                "location_name": "University College Birmingham",
                "location_address": "Summer Row, Birmingham, B3 1JB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b7599006-84ef-0c0f-74a8-14ec9237b6ec",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/university_college_birmingham_-_ucas_hero0.jpg",
        "about_us": "With award-winning support, a \u00a3160 million investment into our five campuses and facilities and with many degrees accredited by the University of Warwick, ranked 8th in the UK in The Guardian 2023 league table, we are a university dedicated to ensuring students excel.Voted the highest-ranking university in the region at this year\u2019s Whatuni Student Choice Awards, as voted by students, our diverse and inclusive institution in the heart of one of England\u2019s biggest, youngest and most dynamic cities specialises in a wide range of undergraduate and postgraduate degrees developed with and supported by industry.Subject areas include nursing, physiotherapy and psychology, computer science and cyber security, finance and accounting, sport, education, tourism, hospitality and creative industries. We have also expanded into STEM subjects in the fields of sustainable engineering and construction, supported by the likes of the Greater Birmingham and Solihull Institute of Technology."
    },
    {
        "id": "804bcaeaea1c9d2f6f6f3760aad60a14",
        "university_name": "Bloomsbury Institute London",
        "location": "7 Bedford Square, Bloomsbury, London, WC1B 3RA",
        "official_website": "http://www.bil.ac.uk/",
        "contact_email": "reception@bil.ac.uk",
        "contact_phone": "020 7078 8840",
        "course_locations": [
            {
                "location_name": "Bloomsbury Campus",
                "location_address": "7 Bedford Square, London, WC1B 3RA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/898276b4-f392-a093-9339-cf6270d30aa8",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/6d5a9679.jpg",
        "about_us": "Bloomsbury Institute is a close-knit learning community at the heart of the UK's capital. World-class learning within a uniquely supportive environment \u2013 that\u2019s what we\u2019re about at Bloomsbury Institute. We offer practical, career-focused degrees in Accounting, Business and Law taught by experts with extensive experience, both in practice and academia. All of our programmes are designed to equip you with the knowledge and skills needed to thrive in your chosen profession and all of our undergraduates receive accreditations from relevant professional bodies alongside their degrees."
    },
    {
        "id": "1e2f8a56122abfd4dcc8e11838eace13",
        "university_name": "University of Bradford",
        "location": "Richmond Road, Bradford, West Yorkshire, BD7 1DP",
        "official_website": "https://www.bradford.ac.uk/undergraduate/?utm_source=ucas&utm_medium=referral_paid&utm_campaign=undergraduate-2024&utm_content=ec_brand-ads",
        "contact_email": "N/A",
        "contact_phone": "01274 233081",
        "course_locations": [
            {
                "location_name": "University of Bradford Middle East Regional Office",
                "location_address": "Dubai Knowledge Village, Block 13, G39,, UAE, P.O. Box 501723, Dubai, 501723"
            },
            {
                "location_name": "Main Site",
                "location_address": "Richmond Road, Bradford, BD7 1DP"
            },
            {
                "location_name": "Main Site",
                "location_address": "Richmond Road, Bradford, BD7 1DP"
            },
            {
                "location_name": "Dewsbury and District Hospital",
                "location_address": "Dewsbury and District Hospital, Halifax Road, Dewsbury, WF13 4HS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/627021f2-a3e9-a197-dca4-29e7d70bc6ca",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas-search-banner-uob.jpg",
        "about_us": "This is where your journey begins. At Bradford, our degrees are more than just a qualification. It's all about giving you the opportunities to become a confident, skilled and employable graduate. You'll get practical training and learn the skills you need to make your mark on the world.We offer over 100 undergraduate courses across our four faculties. Whichever you choose, we'll be there to support you every step of the way. We'll make sure you reach your full potential, both academically and personally, so that when you graduate, you'll be ready to take on anything.Here at Bradford, you get a real-world education. Our courses are designed using input from our industry partners, so you'll develop the skills employers need. You'll put theory into practice in the lad, in the field, and on placement. We ensure as many students as possible undertake placements and sandwich years, as well as having exposure to industry-standard facilities on campus."
    },
    {
        "id": "dc1cf1edfa8e466a41d07c89dd9e54b9",
        "university_name": "University of Brighton",
        "location": "Mithras House, Lewes Road, Brighton, East Sussex, BN2 4AT",
        "official_website": "https://www.brighton.ac.uk/index.aspx",
        "contact_email": "N/A",
        "contact_phone": "01273 644644",
        "course_locations": [
            {
                "location_name": "Brighton",
                "location_address": "Mithras House, Lewes Road, Brighton, BN2 4AT"
            },
            {
                "location_name": "Moulsecoomb Campus",
                "location_address": "Lewes Road, Brighton, BN2 4GA"
            },
            {
                "location_name": "Falmer, Brighton",
                "location_address": "University of Brighton, Brighton, BN1 9PH"
            },
            {
                "location_name": "Grand Parade",
                "location_address": "68 Grand Parade, Lewes Road, Brighton, BN2 0JY"
            },
            {
                "location_name": "Falmer Campus",
                "location_address": "Village Way, Falmer, Brighton, BN1 9PH"
            },
            {
                "location_name": "Main Site",
                "location_address": "Mithras House, Lewes Road, Brighton, BN2 4AT"
            },
            {
                "location_name": "Main site",
                "location_address": "Watts, Lewes Road, Brighton, BN2 4GJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/27ea792a-79c2-71e4-f4d1-80b08d62b00a",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/brighton_student_hub_-_banner_crop_0.jpg",
        "about_us": "At the University of Brighton, you\u2019ll study in the independent and inclusive beachside city of Brighton &amp; Hove, and gain more than just a degree. We offer over 170 undergraduate courses, all designed with your future in mind.Through work-related learning opportunities and placements, we\u2019ll help you to develop the knowledge, skills and experiences to smoothly transition you to university life and beyond.Our graduates leave ready to respond to the world; we\u2019re ranked in the top 50 universities in England for the number of our graduates who are in highly skilled employment or further study (Complete University Guide 2025).Studying at Brighton is accessible and inclusive, and grades are never the whole picture. We value your potential, creativity, resilience and determination. Brighton is a community where you'll belong. Throughout, you\u2019ll be supported by experts so you can feel confident and empowered to take the next step (whatever that may be!)\u00a0"
    },
    {
        "id": "97084b4de217983160c0b43149dcdadb",
        "university_name": "Bristol, University of the West of England",
        "location": "University of the West of England, Frenchay Campus, Coldharbour Lane,, Bristol, Gloucestershire, BS16 1QY",
        "official_website": "https://www.uwe.ac.uk",
        "contact_email": "admissions@uwe.ac.uk",
        "contact_phone": "+44(0)117 32 83333",
        "course_locations": [
            {
                "location_name": "City of Bristol College",
                "location_address": "Ashley Down, Bristol, BS7 9BU"
            },
            {
                "location_name": "Glenside Campus",
                "location_address": "Glenside, Bristol, BS16 1DD"
            },
            {
                "location_name": "City Campus",
                "location_address": "Kennel Lodge Road, Bristol, BS3 2JT"
            },
            {
                "location_name": "Bristol Zoo Gardens",
                "location_address": "Clifton, Bristol, BS8 3HA"
            },
            {
                "location_name": "Arnolfini",
                "location_address": "Bush House, Floors 3, 4 &amp; 5, Bristol, BS1 4QD"
            },
            {
                "location_name": "Frenchay Campus",
                "location_address": "Coldharbour Lane, Bristol, BS16 1QY"
            },
            {
                "location_name": "Gloucestershire College",
                "location_address": "Cheltenham Campus, Princess Elizabeth Way, Cheltenham, GL51 7SJ"
            },
            {
                "location_name": "Main Site",
                "location_address": "Coldharbour Lane, Frenchay, Bristol, BS16 1QY"
            },
            {
                "location_name": "Bristol City Football Club, Ashton Gate",
                "location_address": "Ashton Gate Stadium, Ashton Road, Bristol, BS3 2EJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b79a4cbd-f530-3251-06b9-99ec0d90d1fb",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/campus_photo_-_frenchay.jpg",
        "about_us": "Choose to do university rightAt UWE Bristol, we empower our students to make a choice. To choose a university that pushes itself as much as it pushes you. To choose a community that\u2019s breaking boundaries and leading the way. To choose a passion that excites, delights and gives you purpose.\u00a0Excellent employability91% of our graduates are in work or further study 15 months after graduation, placing UWE Bristol in the top 50% of UK universities for positive graduate employment prospects (Graduate Outcome Survey results from 2020/21, published 2023).\u00a0Support servicesWe know that heading to university is a big deal. It\u2019s our aim as a university to give students all the tools they need to succeed. Services and resources available include academic personal tutors, School support staff, Peer Assisted Learning (student-to-student support scheme), student support advisers and a 24/7 wellbeing service."
    },
    {
        "id": "8ba0c6d7b65f9efa7d8dfa67a788081c",
        "university_name": "Brunel University London",
        "location": "Kingston Lane, Uxbridge, Middlesex, UB8 3PH",
        "official_website": "https://www.brunel.ac.uk/?utm_source=ucas&utm_medium=institution-profile&utm_campaign=ug-awareness",
        "contact_email": "enquiries@brunel.ac.uk",
        "contact_phone": "=44 (0)1895 274000 ",
        "course_locations": [
            {
                "location_name": "Brunel University London",
                "location_address": "Kingston Lane, Uxbridge, Middlesex, Uxbridge, UB8 3PH"
            },
            {
                "location_name": "Main Site",
                "location_address": "Kingston Lane, Uxbridge, UB8 3PH"
            },
            {
                "location_name": "Carol",
                "location_address": "N/A"
            },
            {
                "location_name": "Anna",
                "location_address": "N/A"
            },
            {
                "location_name": "Amberin",
                "location_address": "N/A"
            },
            {
                "location_name": "Faiyaz",
                "location_address": "N/A"
            },
            {
                "location_name": "Jasmine",
                "location_address": "N/A"
            },
            {
                "location_name": "Fardhin",
                "location_address": "N/A"
            },
            {
                "location_name": "Antony",
                "location_address": "N/A"
            },
            {
                "location_name": "Anna",
                "location_address": "N/A"
            },
            {
                "location_name": "Deepshikha",
                "location_address": "N/A"
            },
            {
                "location_name": "Alice",
                "location_address": "N/A"
            },
            {
                "location_name": "Jemimah",
                "location_address": "N/A"
            },
            {
                "location_name": "Carolyn",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/42121682-7b82-3e70-6acf-9c726942a1e4",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/eastern_gateway_building_1_30.jpg",
        "about_us": "Founded in 1966, Brunel University of London is a leading technology university renowned for its education and research and its spirit of innovation and entrepreneurship. It is based on a single leafy campus with outstanding sports facilities, easy access to central London and a friendly, vibrant and strikingly international community. Brunel provides its students with the academic knowledge and personal skills they need to forge successful and rewarding careers. Students will learn from academics who are leaders in their field and they can combine rigorous academic study with opportunities to apply their knowledge to real\u2011world issues. All students are encouraged to undertake a work placement and to explore areas of special interest through project work. Brunel aims to provide an exciting, challenging, inspiring and rewarding experience that will help to shape the future careers of our graduates. "
    },
    {
        "id": "5aee588e3da407453c955f56949e7d6a",
        "university_name": "Coventry University",
        "location": "Priory Street, Coventry, West Midlands, CV1 5FB",
        "official_website": "https://www.coventry.ac.uk",
        "contact_email": "ukadmissions@coventry.ac.uk",
        "contact_phone": "02476 887688",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Priory Street, Coventry, CV1 5FB"
            },
            {
                "location_name": "CU Coventry",
                "location_address": "8 Mile Lane, Coventry, CV1 2TU"
            },
            {
                "location_name": "CU London (Dagenham)",
                "location_address": "Dagenham Civic Centre, Rainham Road North, London, RM10 7BN"
            },
            {
                "location_name": "Hudson Building",
                "location_address": "The Hudson Building, 350 Kennington Lane, London, SE11 5AD"
            },
            {
                "location_name": "Coventry University London",
                "location_address": "University House, 109-117 Middlesex Street, London, E1 7JF"
            },
            {
                "location_name": "CU London (Greenwich)",
                "location_address": "6 Mitre Passage, Greenwich, SE10 0ER"
            },
            {
                "location_name": "CU Scarborough",
                "location_address": "Ashburn Road, Scarborough, YO11 2JW"
            },
            {
                "location_name": "Salisbury Campus, Wiltshire College and University Centre",
                "location_address": "Southampton Road, Wiltshire, Salisbury, SP1 2LW"
            },
            {
                "location_name": "Tanisha",
                "location_address": "N/A"
            },
            {
                "location_name": "Rebecca",
                "location_address": "N/A"
            },
            {
                "location_name": "Ance",
                "location_address": "N/A"
            },
            {
                "location_name": "Sadiyah ",
                "location_address": "N/A"
            },
            {
                "location_name": "Jennifer",
                "location_address": "N/A"
            },
            {
                "location_name": "Matthew",
                "location_address": "N/A"
            },
            {
                "location_name": "Toluwani ",
                "location_address": "N/A"
            },
            {
                "location_name": "Oluwatosin",
                "location_address": "N/A"
            },
            {
                "location_name": "Morgan",
                "location_address": "N/A"
            },
            {
                "location_name": "Praneeth",
                "location_address": "N/A"
            },
            {
                "location_name": "Ashley",
                "location_address": "N/A"
            },
            {
                "location_name": "Kristy (Ming Yan)",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/dfed9a6a-89c6-d0c1-e3b9-ccc3ab651bc5",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/covuni_ucas_header_image_update_v2.jpg",
        "about_us": "Coventry University is a place where you can achieve your ambitions. We get you ready for the real world with a focus on employability, practical learning, and personalised support throughout your time at university. When you study at Coventry, you get the knowledge and skills you need for your chosen career, but you also get our support to develop confidence in yourself and your abilities which will be invaluable when you go on to pursue your ambitions. We were awarded a Gold rating in the 2023 Teaching Excellence Framework, showing we deliver excellence in what students care most about \u2013 teaching, learning and achieving positive outcomes from their studies."
    },
    {
        "id": "bde62806d7c84f8b539c23c0bb1e48fa",
        "university_name": "University for the Creative Arts",
        "location": "UCA Farnham, Falkner Road, Farnham, Surrey, GU9 7DS",
        "official_website": "https://www.uca.ac.uk?utm_source=ucas&utm_medium=profile&utm_campaign=ug_annual_awareness&utm_content=uca_gb_b_brand",
        "contact_email": "enquiries@uca.ac.uk",
        "contact_phone": "01252 722 441",
        "course_locations": [
            {
                "location_name": "Farnham",
                "location_address": "Falkner Road, Farnham, GU9 7DS"
            },
            {
                "location_name": "Maidstone TV Studios",
                "location_address": "Maidstone Studios, New Cut Road, Maidstone, ME14 5NZ"
            },
            {
                "location_name": "Canterbury",
                "location_address": "New Dover Road, Canterbury, CT1 3AN"
            },
            {
                "location_name": "Main Site",
                "location_address": "Falkner Road, Farnham, GU9 7DS"
            },
            {
                "location_name": "Epsom",
                "location_address": "Ashley Road, Epsom, KT18 5BE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/1de3729b-81ac-5471-66cd-580a448cf823",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_2025_header_0.jpg",
        "about_us": "Dream. Discover. Disrupt.At the University for the Creative Arts, you can be as unique as the work you create. We help creatives take their specialist skills further, providing the tools and experiences needed for a rewarding career in the creative industries.Offering a range of Undergraduate and Postgraduate courses across the arts, business, and technology, with us you\u2019ll be supported to find your creative voice and specialise in the area that excites you the most.We have vibrant campuses located across the South East of England in Canterbury, Epsom, Farnham, and Maidstone. Each campus is home to a unique set of dedicated creative spaces and workshops, as well as a community of like-minded creatives ready to meet you.Why not find out for yourself? Visit our website to register for the next UCA Open Day."
    },
    {
        "id": "cc6244bbd685ed511f450d1426629527",
        "university_name": "University of Derby",
        "location": "Kedleston Road, Derby, Derbyshire, DE22 1GB",
        "official_website": "http://www.derby.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "08701 202330",
        "course_locations": [
            {
                "location_name": "Kedleston Road",
                "location_address": "Kedleston Road, Derby, DE22 1GB"
            },
            {
                "location_name": "Chesterfield - St Helena",
                "location_address": "St. Helena, Sheffield Road, Chesterfield, S41 7LU"
            },
            {
                "location_name": "Main Site",
                "location_address": "Kedleston Road, Derby, DE22 1GB"
            },
            {
                "location_name": "Markeaton Street",
                "location_address": "Markeaton Street, Derby, DE22 3AW"
            },
            {
                "location_name": "Britannia Mill",
                "location_address": "Mackworth Road, Derby, DE22 3BL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/008ac8a8-f655-e436-a163-a5e3d16adab7",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/july-open-day-0194-13002-promo.jpg",
        "about_us": "Located in the heart of England, we are the only university in Derbyshire. We combine teaching excellence, research innovation and industry engagement to create an inspiring learning experience, and were proudly awarded Gold in the\u00a0prestigious\u00a0Teaching Excellence Framework\u00a0(TEF)\u00a02023, making us one of the top\u00a0universities in the country\u00a0recognised\u00a0for outstanding student experience and student outcomes.Ranked 4th for employability, 5th for course quality and 15th overall in the Uni Compare Annual Student Review Rankings 2025, we are focused on preparing students for a successful future. 96% of our UK graduates are in employment or further study (HESA Graduate Outcomes 2021/22).Derby has a long-standing reputation for extraordinary innovation which we aim to build upon, using our knowledge to support the prosperity of our region, nation and the world. We partner for impact, meaning we are committed to creating a collaborative environment where graduate talent can flourish."
    },
    {
        "id": "c03a67ccf7729250b17aa81a730feb91",
        "university_name": "Edge Hill University",
        "location": "St Helens Road, Ormskirk, Lancashire, L39 4QP",
        "official_website": "https://www.edgehill.ac.uk/discover/?utm_source=ucas&utm_medium=profile&utm_campaign=always_on_ug",
        "contact_email": "study@edgehill.ac.uk",
        "contact_phone": "01695 657000",
        "course_locations": [
            {
                "location_name": "St James\u2019 Manchester",
                "location_address": "St James', 79 Oxford Street, Manchester, M1 6EG"
            },
            {
                "location_name": "Wrightington Hospital",
                "location_address": "Hall Lane, Appley Bridge, Wigan, WN6 9EP"
            },
            {
                "location_name": "Holy Cross College",
                "location_address": "Manchester Road, Bury, BL9 9BB"
            },
            {
                "location_name": "Ormskirk (Main Campus)",
                "location_address": "St Helens Road, Ormskirk, L39 4QP"
            },
            {
                "location_name": "Main Site",
                "location_address": "St Helens Road, Ormskirk, L39 4QP"
            },
            {
                "location_name": "Liverpool Heart and Chest Hospital",
                "location_address": "Thomas Drive, Liverpool, L14 3PE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/44f558c0-37c7-2d78-eace-fa532d9add4c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ehu-18.jpg",
        "about_us": "At Edge Hill University, we\u2019ve been inspiring minds and changing futures since 1885. We help students embark on inspirational journeys, expanding their knowledge and creating lifelong friendships. Whatever you study, we invest in your future and provide you with the tools you need to succeed.Located in Lancashire, close to Liverpool and Manchester, we\u2019ve created a diverse environment where our students can live, learn, and belong\u2014recognised in the Complete University Guide as a Top 4 University in the North West (2025).Lecture halls are equipped with everything you need, and our high-quality teaching brings subjects to life. If you're moving away for the first time, our on-campus accommodation provides a home away from home within easy reach of study and social spaces - ranked 1st in the UK by students (Uni Compare, 2025).Recently rated an Ofsted 'Outstanding' provider, you'll be taught by research-active staff who are experts in their field, ensuring you reach your full potential."
    },
    {
        "id": "3d0d4bdd433dc64954f4f6dfe22cdc8b",
        "university_name": "University of Glasgow",
        "location": "University Avenue, Glasgow, G12 8QQ",
        "official_website": "http://www.gla.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "0141 330 2000",
        "course_locations": [
            {
                "location_name": "Gilmorehill (Main) Campus",
                "location_address": "University Of Glasgow, University Avenue, Glasgow, G12 8QQ"
            },
            {
                "location_name": "Dumfries Campus",
                "location_address": "Rutherford and McCowan Buildings, Bankend Road, Dumfries, DG1 4ZL"
            },
            {
                "location_name": "Main Site",
                "location_address": "The Fraser Building, 65 Hillhead Street, Glasgow, G12 8QQ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/e4c66810-05c8-fd1c-4e9e-a8072e3189e2",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/students_on_campus_382_1_0.jpg",
        "about_us": "The University of Glasgow began changing the world in\u00a01451 and\u00a0has been inspiring others to do so for the last 571 years\u2026 and counting. That\u2019s why we\u2019re a World Top 100 University and a member of the Russell Group, and a first-choice destination for today\u2019s students and tomorrow\u2019s talent.\u00a0\u00a0Wherever you are in education, and in life \u2013 and wherever you want to go \u2013 University of Glasgow shares and supports your ambitions. World changers are welcome here, in Scotland\u2019s vibrant,\u00a0multicultural\u00a0and forward-thinking city.\u00a0\u00a0\u00a0\u00a0\u00a0"
    },
    {
        "id": "dded9d35ad4fa8bb318a9852754a1380",
        "university_name": "University of Hertfordshire",
        "location": "University of Hertfordshire, College Lane, Hatfield, Hertfordshire, AL10 9AB",
        "official_website": "https://www.herts.ac.uk/campaign/undergraduate?utm_campaign=ug_alwayson&utm_medium=onlineprofile&utm_source=ucas&utm_id=brand",
        "contact_email": "ask@herts.ac.uk",
        "contact_phone": "01707 284800",
        "course_locations": [
            {
                "location_name": "West Herts College",
                "location_address": "Hempstead Road, Watford, WD17 3EZ"
            },
            {
                "location_name": "Barnet and Southgate College",
                "location_address": "Barnet &amp; Southgate College, Grahame Park Way, London, NW9 5RA"
            },
            {
                "location_name": "Hertford Regional College",
                "location_address": "Scotts Road, Ware, SG12 9JF"
            },
            {
                "location_name": "Main Site",
                "location_address": "College Lane, Hatfield, AL10 9AB"
            },
            {
                "location_name": "College Lane Campus",
                "location_address": "College Lane, Hatfield, AL10 9AB"
            },
            {
                "location_name": "Oaklands College",
                "location_address": "St. Albans Campus, Hatfield Road, Oaklands, AL4 0JA"
            },
            {
                "location_name": "North Hertfordshire College",
                "location_address": "Monkswood Way, Stevenage, SG1 1LA"
            },
            {
                "location_name": "Main Site",
                "location_address": "University Admissions Service, College Lane, Hatfield, AL10 9AB"
            },
            {
                "location_name": "De Havilland Campus",
                "location_address": "University of Hertfordshire, Hatfield, AL10 9EU"
            },
            {
                "location_name": "Sarah",
                "location_address": "N/A"
            },
            {
                "location_name": "Maddison",
                "location_address": "N/A"
            },
            {
                "location_name": "Hannah ",
                "location_address": "N/A"
            },
            {
                "location_name": "Laura",
                "location_address": "N/A"
            },
            {
                "location_name": "Aaliyah",
                "location_address": "N/A"
            },
            {
                "location_name": "Moyra",
                "location_address": "N/A"
            },
            {
                "location_name": "Nuzhat Tasnim",
                "location_address": "N/A"
            },
            {
                "location_name": "Cleona",
                "location_address": "N/A"
            },
            {
                "location_name": "Sarah",
                "location_address": "N/A"
            },
            {
                "location_name": "Courtney",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/e2cc6194-be8e-763e-fb4b-931c35a169b0",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/9777-145-outdoors-campus.jpg",
        "about_us": "The University of Hertfordshire is a thriving, diverse community of more than 30,000 students, studying 550-degree options at our vibrant, inclusive, campus-based institution.\u00a0\u00a0We create skilled graduates ready for the modern workplace by delivering career-focused degrees. We consult with employers when designing courses and our facilities provide you with up-to-date, real-world experience, mirroring the careers and industries you will in work in after graduation. Just 25 minutes north of London, you will find inspiring and engaging teaching, fantastic extra-curricular activities, and the tools to prepare you for the career of your dreams. Our main focus is on providing a learning community where you can make lifelong friendships, draw on the experiences and expertise of our skilled academic staff and find extra help whenever you need it."
    },
    {
        "id": "b3b0e279674c01688d10fddbf73a19c1",
        "university_name": "University of the Highlands and Islands (UHI)",
        "location": "UHI House, Old Perth Road, Highlands and Islands, Moray and Perth, Highland, IV2 3JH",
        "official_website": "https://www.uhi.ac.uk/en/",
        "contact_email": "courses@uhi.ac.uk",
        "contact_phone": "01463 279190",
        "course_locations": [
            {
                "location_name": "Argyll",
                "location_address": "UHI Argyll, Glenshellach Business Park, Oban, PA34 4RY"
            },
            {
                "location_name": "Department of Nursing and Midwifery Inverness",
                "location_address": "UHI House, Old Perth Road, Inverness, IV2 3JH"
            },
            {
                "location_name": "Inverness Scottish Borders Campus",
                "location_address": "Langlee Community Centre, Marigold Drive, Galashiels, TD1 2LP"
            },
            {
                "location_name": "UHI House",
                "location_address": "UHI House, Old Perth Road, Inverness, IV2 3JH"
            },
            {
                "location_name": "Sabhal Mor Ostaig",
                "location_address": "An Teanga, Sleite, IV44 8RQ"
            },
            {
                "location_name": "Inverness",
                "location_address": "UHI Inverness, 1 Inverness Campus, Inverness, IV2 5NA"
            },
            {
                "location_name": "Moray Scottish Borders Campus",
                "location_address": "Langlee Community Centre, Marigold Drive, Galashields, TD1 2LP"
            },
            {
                "location_name": "UHI Centre for History",
                "location_address": "UHI Centre for History, Burghfield House, Cnoc-an-Lobht, Dornoch, IV25 3HN"
            },
            {
                "location_name": "HTC",
                "location_address": "19 High Street, Dingwall, IV15 9HA"
            },
            {
                "location_name": "Perth",
                "location_address": "UHI Perth, Crieff Road, Perth, PH1 2NX"
            },
            {
                "location_name": "North, West and Hebrides",
                "location_address": "UHI North West and Hebrides, Ormlie Road, Thurso, KW14 7EE"
            },
            {
                "location_name": "Department of Nursing and Midwifery Stornoway",
                "location_address": "UHI Outer Hebrides, Stornoway, Isle of Lewis, HS2 0XR"
            },
            {
                "location_name": "HTC Paisley Education Centre",
                "location_address": "6 School Wynd, Paisley, PA1 2DB"
            },
            {
                "location_name": "Inverness Aberdeen Campus",
                "location_address": "Aberdeen City Council, Marischal College, Broad Street, Aberdeen, AB10 1AQ"
            },
            {
                "location_name": "Scottish Association for Marine Science",
                "location_address": "Scottish Association for Marine Science, PO Box 3, Oban, PA37 1QA"
            },
            {
                "location_name": "Shetland",
                "location_address": "UHI Shetland, Gremista, Lerwick, ZE1 0PX"
            },
            {
                "location_name": "Orkney",
                "location_address": "UHI Orkney, East Road, Kirkwall, KW15 1LX"
            },
            {
                "location_name": "Moray",
                "location_address": "UHI Moray, Moray Street, Elgin, IV30 1JJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/c1c7b933-c38e-c3ac-747c-41e70e9bb3d6",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_3.png",
        "about_us": "The University of the Highlands and Islands (UHI) is an integrated university that offers a broader range of options than any single college or university can.UHI are part of a new breed of tertiary institutions, the only one in Scotland and one of only a few in Europe that offers flexible and supported learning from access level to PhD, which suits more people at more levels for more reasons.We have 31,000 students at the heart of what we do and our greatest strength is the diversity and flexibility of our partnership, which empowers us to deliver more. We are proud of our unique place and deep roots in our communities and this makes our teaching and research more connected to their needs.You\u2019ll find us throughout the Highlands and Islands, Moray and Perthshire and online from wherever you want to study. UHI - where learning means more."
    },
    {
        "id": "7f22ef008a9f5f3875b39bec7f28b49b",
        "university_name": "Hult International Business School",
        "location": "Hult International Business School, 35 Commercial Rd, London, E1 1LD",
        "official_website": "https://www.hult.edu/undergraduate/",
        "contact_email": "N/A",
        "contact_phone": "020 7636 5667",
        "course_locations": [
            {
                "location_name": "Hult Campus: London",
                "location_address": "35 Commercial Road, Camden, London, E1 1LD"
            },
            {
                "location_name": "Hult Campus: San Francisco",
                "location_address": "1355 Sansome Street, San Francisco, CA 94111"
            },
            {
                "location_name": "Hult Campus: Boston",
                "location_address": "1 Education Street, Boston, MA 02141"
            },
            {
                "location_name": "Hult Campus: Dubai",
                "location_address": "Dubai Internet City, Dubai, PO Box 502988"
            },
            {
                "location_name": "Main Site",
                "location_address": "Hult London Campus, 35 Commercial Road, London, E1 1LD"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/31d5869a-9ee1-f5c6-937a-d1373bb118bd",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/london_24_banner.jpg",
        "about_us": "Hult International Business School is a dynamic, global community dedicated to supporting, encouraging, and uniting the next generation of innovative business leaders. We transform education by emphasizing hands-on learning experiences, equipping our students to excel in the business world.As a world-leading business school, Hult holds triple accreditation from AACSB, EQUIS, and AMBA. We are proud of our high rankings in the Financial Times, The Economist, Forbes, and Bloomberg Businessweek. Our practical programs for undergraduates, graduates, and executives are offered at our campuses in Boston, San Francisco, London, and Dubai."
    },
    {
        "id": "f34d2b3a7d7c415ba539f9994360ca3d",
        "university_name": "Imperial College London",
        "location": "Kensington, London, SW7 2AZ",
        "official_website": "https://www.imperial.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "020 7589 5111",
        "course_locations": [
            {
                "location_name": "South Kensington Campus",
                "location_address": "Exhibition Road, Kensington and Chelsea, SW7 2AZ"
            },
            {
                "location_name": "Main Site",
                "location_address": "South Kensington Campus, Kensington and Chelsea, SW7 2AZ"
            },
            {
                "location_name": "St Mary's Campus",
                "location_address": "Norfolk Place, Westminster, W2 1NY"
            },
            {
                "location_name": "Imperial College Business School",
                "location_address": "Imperial College London, South Kensington Campus, London, SW72AZ"
            },
            {
                "location_name": "Chelsea and Westminster Campus",
                "location_address": "369 Fulham Road, Westminster, SW10 9NH"
            },
            {
                "location_name": "Main Site",
                "location_address": "Imperial College, Prince Consort Road, London, SW7 2AZ"
            },
            {
                "location_name": "Hammersmith Campus",
                "location_address": "The Commonwealth Building, The Hammersmith Hospital, Du Cane Road, Hammersmith and Fulham, W12 0NN"
            },
            {
                "location_name": "White City Campus",
                "location_address": "80 Wood Lane, London, W12 7TA"
            },
            {
                "location_name": "Royal Brompton Campus",
                "location_address": "Dovehouse Street, Islington, SW3 6LY"
            },
            {
                "location_name": "Silwood Park Campus",
                "location_address": "Buckhurst Road, Ascot, SL5 7PY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/52087408-312c-b1ee-f982-bc0eaf81d35f",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/south-kensington-panorama.jpg",
        "about_us": "We\u2019re Imperial College London, a world-leading university for science, technology, engineering, medicine and business.Our scientific community studies the forces that are shaping the future. And the research and innovations we produce are helping to navigate some of the world\u2019s toughest challenges in global health, climate change, AI and more.Imperial is a place to ask questions and get closer to the answers, together. You\u2019ll get hands-on research experience, plus opportunities for research at Imperial and our global partners. Every year, our Enterprise Lab supports more than 2,000 students with mentoring, access to experts and workshops to turn your ideas into tangible, real-world impact.We combine exceptional teaching, world-class facilities and collaboration to unlock scientific imagination in everyone.Our work impacts the world, and our community reflects it. Here, you'll find a welcoming home for everyone who believes in the power of science to build, heal and lead."
    },
    {
        "id": "4472ce84e35dc75fa284d53b9e1b33ec",
        "university_name": "The University of Law",
        "location": "2 Bunhill Row,, London, EC1Y 8HQ",
        "official_website": "https://ad.doubleclick.net/ddm/clk/555647360;364801996;w",
        "contact_email": "study@law.ac.uk",
        "contact_phone": "0800 289 997",
        "course_locations": [
            {
                "location_name": "Distance Learning",
                "location_address": "2 Bunhil Row, London, EC1Y 8HQ"
            },
            {
                "location_name": "Bristol",
                "location_address": "Temple Circus, Bristol, BS1 6HG"
            },
            {
                "location_name": "Sheffield",
                "location_address": "The University of Sheffield, Western Bank, Sheffield, S10 2TN"
            },
            {
                "location_name": "Liverpool",
                "location_address": "Rendall Building, The University of Liverpool, Bedford Street South, Liverpool, L69 7ZQ"
            },
            {
                "location_name": "Southampton",
                "location_address": "University of Southampton, University Road, Highfield, Southampton, SO17 1BJ"
            },
            {
                "location_name": "Norwich",
                "location_address": "University of East Anglia, Norwich Research Park, Norwich, NR4 7TJ"
            },
            {
                "location_name": "Manchester",
                "location_address": "2 New York Street, Manchester, M1 4HJ"
            },
            {
                "location_name": "London Bloomsbury",
                "location_address": "14 Store Street, London, WC1E 7DE"
            },
            {
                "location_name": "Leeds",
                "location_address": "15-16 Park Road, Leeds, LS1 5HD"
            },
            {
                "location_name": "Nottingham",
                "location_address": "One Royal Standard Place, Nottingham, NG1 6FS"
            },
            {
                "location_name": "Main Site",
                "location_address": "2 Bunhil Row, London, EC1Y 8HQ"
            },
            {
                "location_name": "Newcastle",
                "location_address": "Newcastle University, 42-44 Great North Road/Windsor Court, Newcastle Upon Tyne, NE2 4HE"
            },
            {
                "location_name": "Chester",
                "location_address": "Christleton Hall, Pepper Street, Christleton, Chester, CH3 7AB"
            },
            {
                "location_name": "Reading",
                "location_address": "London Road Campus, Reading, RG1 5EX"
            },
            {
                "location_name": "Birmingham",
                "location_address": "133 Great Hampton Street, Birmingham, B18 6AQ"
            },
            {
                "location_name": "Exeter",
                "location_address": "Amory Building, Rennes Drive, Exeter, EX4 4RJ"
            },
            {
                "location_name": "Online",
                "location_address": "Online, Online, Online"
            },
            {
                "location_name": "London Moorgate",
                "location_address": "2 Bunhill Row, Moorgate, City of London, EC1Y 8HQ"
            },
            {
                "location_name": "Hadia ",
                "location_address": "N/A"
            },
            {
                "location_name": "Amelia",
                "location_address": "N/A"
            },
            {
                "location_name": "Emma",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6cadf6e5-74e3-7e17-ef02-3bf5d0c018b6",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/nottingham_campus_exterior_rb_32-1920x420px.jpg",
        "about_us": "The University of Law is one of the longest-established specialist providers of legal education in the UK, alongside offering a wide range of professional courses including law, business, policing, criminology, psychology and computer science.With campuses in London, Birmingham, Bristol, Leeds, Manchester and Nottingham*, as well as online, our goal is to prepare you for a professional career with flexible study options, including accelerated programmes and placement years.Our degree courses balance academic rigour and practical skills, so you\u2019re working towards achieving your career ambitions right from the start.We hold Open Days, both on-campus and virtually, throughout the year to give you the chance to discover more about studying with us.*Campus locations available vary by course and start date."
    },
    {
        "id": "c488588d044694ea1d0bdf8920717147",
        "university_name": "University of Leicester",
        "location": "University Road, Leicester, Leicestershire, LE1 7RH",
        "official_website": "https://le.ac.uk/study/undergraduates?utm_source=ucas&utm_medium=paid&utm_campaign=affiliate-sites&utm_content=button&utm_term=visit-website",
        "contact_email": "study@le.ac.uk",
        "contact_phone": "0116 252 5281",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "University Road, Leicester, LE1 7RH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/edaad69a-3b51-1473-8b7d-49f70afcfb32",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/uol-ucas.jpg",
        "about_us": "Powered by world-class research, the University of Leicester delivers inspirational education across the Sciences, Humanities, Law, Medicine, the Arts and Business, with staff and students collaborating to create, share and apply knowledge to change the world. We are a world-leading, diverse and inclusive university based in the UK\u2019s most multicultural city.The University of Leicester is a top 30 UK university (Times Higher Education World Rankings 2024), recently been rated Gold overall in the Teaching Excellence Framework 2023, and a top 20 UK university for career prospects (Whatuni Student Choice Awards 2023).Our students are Citizens of Change, unconfined by academic boundaries. The innovative approach you will take towards your studies brings together the sciences and the arts to inform the latest conversations and provide solutions to the most complex of issues. You will be given the space and support in which to be creative and develop both personally and professionally."
    },
    {
        "id": "eb229da0a551cd54b9a5812279508f49",
        "university_name": "LIBF",
        "location": "8th Floor, Peninsular House, 36 Monument Street, London, EC3R 8LJ",
        "official_website": "https://libf.prf.hn/click/camref:1100l3DeUj/creativeref:1100l128689/adref:profile",
        "contact_email": "onlinedegree@libf.ac.uk",
        "contact_phone": "01227818609",
        "course_locations": [
            {
                "location_name": "LIBF",
                "location_address": "8th Floor, Peninsular House, 36 Monument Street, City of London, EC3R 8LJ"
            },
            {
                "location_name": "Main Site",
                "location_address": "8th Floor, Peninsular House, 36 Monument Street, City of London, EC3R 8LJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/96faf64f-9c17-28dc-38e8-d8ce64cd10a7",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/1-banner-image-1920x420px-libf-ucas.png",
        "about_us": "We've been providing education for over 140 years, and we\u2019re highly regarded throughout the sector. Throughout our history we\u2019ve focused on helping our students, members, and alumni, to succeed and progress \u2013 no matter where they are in their career.If you study with us, you\u2019ll automatically become a member of our professional body. You\u2019ll also have the opportunity to study a professional qualification while working for your degree and be on your way to achieving chartered status \u2013 the highest level of professional recognition! "
    },
    {
        "id": "33807419d74d1e3b62d1f3ae5320290b",
        "university_name": "University of Lincoln",
        "location": "University of Lincoln, Brayford Pool, Lincoln, Lincolnshire, LN6 7TS",
        "official_website": "https://www.lincoln.ac.uk/",
        "contact_email": "enquiries@lincoln.ac.uk",
        "contact_phone": "01522 886644",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Brayford Pool, Lincoln, LN6 7TS"
            },
            {
                "location_name": "Lincoln (Main Site)",
                "location_address": "Brayford Pool, Lincoln, LN6 7TS"
            },
            {
                "location_name": "Lincoln",
                "location_address": "Admissions, Lincoln, LN6 7TS"
            },
            {
                "location_name": "Darcie ",
                "location_address": "N/A"
            },
            {
                "location_name": "Edward",
                "location_address": "N/A"
            },
            {
                "location_name": "Abdul",
                "location_address": "N/A"
            },
            {
                "location_name": "Elizabeth ",
                "location_address": "N/A"
            },
            {
                "location_name": "Benjamin",
                "location_address": "N/A"
            },
            {
                "location_name": "Amber",
                "location_address": "N/A"
            },
            {
                "location_name": "Ellie",
                "location_address": "N/A"
            },
            {
                "location_name": "Ella",
                "location_address": "N/A"
            },
            {
                "location_name": "Amelia",
                "location_address": "N/A"
            },
            {
                "location_name": "Chloe",
                "location_address": "N/A"
            },
            {
                "location_name": "Christina",
                "location_address": "N/A"
            },
            {
                "location_name": "Abigail",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/970c6a07-9cd0-a0f1-ec3e-19c7f2529f2b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/brayford_pool_campus_night_2000x1080.jpg",
        "about_us": "With Inspirational teaching, specialist facilities, and great student support we aim to help you achieve your aspirations.Voted for by students at the WhatUni Student Choice Awards 2024, the University of Lincoln is ranked 1st\u00a0in the UK for\u00a0Student Accommodation,\u00a03rd for University of the Year,\u00a03rd for\u00a0Student Life,\u00a03rd\u00a0Facilities, 4th for Student Support, 2nd\u00a0for International, and 2nd for Postgraduate.We hold Triple Gold Status\u00a0for the 2023 Teaching Excellence Framework\u00a0(TEF)\u00a0\u2013 achieving the top Gold rating overall and in both aspect ratings for\u00a0student experience\u00a0and\u00a0student outcomes.We were awarded the Queen\u2019s Anniversary Prize through innovations in research, education and technology.We rank in the\u00a0UK's top 10 modern universities\u00a0in The Times and Sunday Times Good University Guide 2024 and in the top 50 UK universities overall in The Complete University Guide 2024."
    },
    {
        "id": "22da2a25abc6e2302df0ce5f0606cd1f",
        "university_name": "London Interdisciplinary School (LIS)",
        "location": "The London Interdisciplinary School, 20-30 Whitechapel Road, London, E1 1EW",
        "official_website": "https://www.lis.ac.uk/?utm_source=UCAS&utm_medium=Homepage&utm_campaign=homepage&utm_id=UCAS",
        "contact_email": "admissions@lis.ac.uk",
        "contact_phone": "02034091912",
        "course_locations": [
            {
                "location_name": "The London Interdisciplinary School (LIS)",
                "location_address": "20-30 Whitechapel Road, London, E1 1EW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3e00a545-6710-4886-b89b-a2cd5f7beef8",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_3_0.png",
        "about_us": "We\u2019re building a new London university with a unique interdisciplinary Bachelor of Arts and Sciences (BASc) degree.In a world faced by ever more complex problems, it\u2019s never been more important to find new ways of thinking and doing. Today\u2019s graduates need to be more versatile, more entrepreneurial, and more resilient than ever before. They need to be comfortable with uncertainty and able to navigate complex and fast-changing environments.Our degree, Interdisciplinary Problems and Methods, is the first of its kind in the UK. Multifaceted real-world challenges \u2014 challenges like sustainability, global health, and the ethics of AI \u2014 don\u2019t respect the traditional subject boundaries; so why should your degree?"
    },
    {
        "id": "f2220ad02d7e77b2a460e46e9f46479f",
        "university_name": "McTimoney College of Chiropractic",
        "location": "1 Kimber Road, Abingdon, Oxfordshire, OX14 1BZ",
        "official_website": "https://mctimoney-college.ac.uk/",
        "contact_email": "admissions@mctimoney-college.ac.uk",
        "contact_phone": "01235 468569",
        "course_locations": [
            {
                "location_name": "Abingdon",
                "location_address": "McTimoney House, 1 Kimber Road, Abingdon, OX14 1BZ"
            },
            {
                "location_name": "Manchester",
                "location_address": "2 New York St, Manchester, M1 4HJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/bc261a05-99cf-4356-a0ee-6d2ccdca1688",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/mctimoney_college_of_chiropractic.jpg",
        "about_us": "At McTimoney College of Chiropractic, we accommodate students who are fresh out of school as well as mature individuals with life experience and an understanding of potential patient needs. Whether your passion is in chiropractic care for people or animals, we provide a diverse range of chiropractic courses in both areas.As part of the College of Health, McTimoney College will prepare you for your career as a chiropractor by providing support at each stage of your educational journey. By using a whole-body approach, with a sound understanding of the medical and scientific implications, chiropractic ensures a healthy balance in all aspects of the body\u2019s daily functions, providing a route to better health.Regardless of your chosen course, you will graduate with sound understanding of the subject and an official chiropractic degree, ensuring a high-quality learning experience."
    },
    {
        "id": "a3ecdc68db408f0762158fe278597b7d",
        "university_name": "Middlesex University",
        "location": "Hendon Campus, The Burroughs, London, London, NW4 4BT",
        "official_website": "https://www.mdx.ac.uk/?utm_source=ucas&utm_medium=profile_paid&utm_campaign=ec_undergraduate_apply&utm_term=brand_prospecting",
        "contact_email": "enquiries@mdx.ac.uk",
        "contact_phone": "020 8411 5555",
        "course_locations": [
            {
                "location_name": "Hendon Campus",
                "location_address": "The Burroughs, London, NW4 4BT"
            },
            {
                "location_name": "College of Animal Welfare - Leeds",
                "location_address": "The College Of Animal Welfare, Topcliffe Close, Tingley, Leeds, WF3 1DR"
            },
            {
                "location_name": "College of Animal Welfare - Huntington",
                "location_address": "Headland House, London Road, Godmanchester, Huntingdon, PE29 2BQ"
            },
            {
                "location_name": "Main Site",
                "location_address": "Hendon Campus, The Burroughs, Barnet, NW4 4BT"
            },
            {
                "location_name": "Helicentre Aviation",
                "location_address": "Leicester Airport, Gartree Road, Leicester, LE2 2FG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5692045c-9274-b50e-8467-fb52ce4ef728",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/banner_image_0.jpg",
        "about_us": "Discover a future that\u2019s yours at Middlesex University London, a top 6 UK university (THE Young Universities, 2024). We pride ourselves on our specialist, practice-based courses led by expert industry leaders. You\u2019ll gain real-world skills, experience and award-winning support to boost your employability with students working with organisations such as Google, NHS Trusts, and Deloitte.On campus, you\u2019ll learn in state-of-the-art facilities, like our augmented reality equipment for nursing and midwifery and our Hollywood-grade motion capture suite. You\u2019ll also be able to work flexibly off-campus using specialist software and the latest communication tools.We\u2019re confident you\u2019ll find your place in our friendly and diverse global community with over 75 nationalities. You\u2019ll enjoy opportunities to connect globally across our campuses in London, Dubai and Mauritius to make your future at Middlesex."
    },
    {
        "id": "f4899d9078aa32115b24918c316d2823",
        "university_name": "Newcastle University",
        "location": "Newcastle University, Newcastle upon Tyne, Tyne And Wear, NE1 7RU",
        "official_website": "https://www.ncl.ac.uk/?utm_source=ucas&utm_medium=listing&utm_campaign=undergrad&utm_term=body-link&utm_content=visit-website",
        "contact_email": "Contact us: apps.ncl.ac.uk/contact-us/general-enquiry",
        "contact_phone": "+44 (0)191 208 6000 ",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "King\u2019s Gate, Newcastle upon Tyne, NE1 7RU"
            },
            {
                "location_name": "Main Site (Newcastle)",
                "location_address": "King's Gate, Newcastle upon Tyne, NE1 7RU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/9528a5b4-845b-84f5-974e-92d025a75d1f",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/students_in_front_of_arches.jpg",
        "about_us": "Since 1834, Newcastle University has been pushing the boundaries of knowledge. A Global Top 110 university (QS World University Rankings 2024), we\u2019re a founding member of the Russell Group, pioneering solutions to some of the biggest challenges facing the world today.Committed to environmental and social justice, we\u2019re ranked 4th in the UK for sustainable development (Times Higher Education Impact rankings 2023). Our student body \u2013 over 27,000 strong, drawn from 145 countries \u2013 is vibrant and inclusive and we\u2019re proud to be a University of Sanctuary.At Newcastle, we educate for life. Degrees are underpinned by our world-leading research and global connections to create a quality academic experience. Learning comes alive among our state-of-the-art facilities.With us, you\u2019ll be challenged and supported to think independently; to innovate and make an impact. Employability and opportunity will be built into your experience, preparing you for life after your degree."
    },
    {
        "id": "ecea16b880ffa312f84bf54cf83ba492",
        "university_name": "University of Northampton",
        "location": "Waterside Campus, University Drive, Northampton, Northamptonshire, NN1 5PH",
        "official_website": "http://www.northampton.ac.uk/",
        "contact_email": "study@northampton.ac.uk",
        "contact_phone": "0300 3032772",
        "course_locations": [
            {
                "location_name": "Waterside Campus",
                "location_address": "Waterside Campus, University Drive, Northampton, NN1 5PH"
            },
            {
                "location_name": "Main Site",
                "location_address": "The University of Northampton, Waterside Campus, University Drive, Northampton, NN1 5PH"
            },
            {
                "location_name": "Waterside",
                "location_address": "Waterside Campus, University Drive, Northampton, NN1 5PH"
            },
            {
                "location_name": "Jacqui",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/1f6ba907-0961-cb7c-54be-14d6a47439b0",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/university-of-northampton-waterside-campus.jpg",
        "about_us": "Come to the University of Northampton and you will see that we do things a bit differently.We are one of the youngest universities in the UK but we are already leading the way in adding value to society, which we call social impact. We have won multiple awards for our work in this area, among others, but what matters the most to us is ensuring that our students and graduates have the opportunity to make their mark on the world too.We want to break the mould of what Higher Education can be. This has led us to build a whole new University from the ground up that is designed to reflect the way that you actually learn rather than the way you are expected to learn. Waterside Campus opened in September 2018.We want to ensure that your experience studying with us enables you to transform your life and those of others too, no matter how great or small this may be."
    },
    {
        "id": "4bb813a3f020aa58b8a58eade4024e32",
        "university_name": "Nottingham Trent University",
        "location": "Nottingham Trent University, 50 Shakespeare Street, Nottingham, Nottinghamshire, NG1 4FQ",
        "official_website": "http://www.ntu.ac.uk/?utm_campaign=IM-Profiles&utm_medium=Profile&utm_source=UCAS&utm_content=ProvidersPage&utm_term=IM",
        "contact_email": "www.ntu.ac.uk/askntu",
        "contact_phone": "0115 8484200",
        "course_locations": [
            {
                "location_name": "Clifton Campus",
                "location_address": "Nottingham Trent University, Clifton Lane, Nottingham, NG11 8NS"
            },
            {
                "location_name": "Brackenhurst Campus",
                "location_address": "Nottingham Trent University, Brackenhurst Lane, Southwell, NG25 0QF"
            },
            {
                "location_name": "Confetti - Nottingham",
                "location_address": "Nottingham Trent University, 6-10 Convent Street, Nottingham, NG1 3LL"
            },
            {
                "location_name": "City Campus",
                "location_address": "Nottingham Trent University, 50 Shakespeare Street, Nottingham, NG1 4FQ"
            },
            {
                "location_name": "Confetti - London",
                "location_address": "Nottingham Trent University, Commercial Road, London, E1 1LA"
            },
            {
                "location_name": "Mansfield",
                "location_address": "Nottingham Trent University, Vision West Nottinghamshire College, Derby Road, Mansfield, NG18 5BH"
            },
            {
                "location_name": "Main Site",
                "location_address": "50 Shakespeare Street, Nottingham, NG1 4FQ"
            },
            {
                "location_name": "Bisma",
                "location_address": "N/A"
            },
            {
                "location_name": "Mary",
                "location_address": "N/A"
            },
            {
                "location_name": "Lucie",
                "location_address": "N/A"
            },
            {
                "location_name": "Issy",
                "location_address": "N/A"
            },
            {
                "location_name": "Daanish",
                "location_address": "N/A"
            },
            {
                "location_name": "Arashdeep",
                "location_address": "N/A"
            },
            {
                "location_name": "Amira",
                "location_address": "N/A"
            },
            {
                "location_name": "MITU MD",
                "location_address": "N/A"
            },
            {
                "location_name": "Donovan",
                "location_address": "N/A"
            },
            {
                "location_name": "Heidi",
                "location_address": "N/A"
            },
            {
                "location_name": "Harry",
                "location_address": "N/A"
            },
            {
                "location_name": "Izzi",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/9698b0cc-2162-611f-edae-2e26d58ea92f",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ntu-main-ucas.jpg",
        "about_us": "Nottingham Trent University (NTU) is an awarding-winning University like no other. Committed to driving and delivering change, we have been named University of the Year five times in six years \u2013 and most recently our students voted us the best university in the UK (Uni Compare 2025). We\u2019ve earned our worldwide reputation for excellence by daring to do things a bit differently.\u00a0\u00a0Ranked 1st in the UK for employability (Uni Compare 2025), we\u2019re one of the UK's most employment-focused universities and home to a supportive community that will help you thrive in your ambitions. Our courses have been developed with careers in mind, so whatever you choose to study, you\u2019ll have access to the work experience opportunities to help you succeed.\u00a0\u00a0With incredible facilities, worldwide industry links and gold-rated teaching (TEF 2023) \u2013 we provide a student experience like no other."
    },
    {
        "id": "a4708b09d57ee1b955432fdae5074d91",
        "university_name": "University of Nottingham",
        "location": "University Park, Nottingham, Nottinghamshire, NG7 2RD",
        "official_website": "https://www.nottingham.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01159 515151",
        "course_locations": [
            {
                "location_name": "Lincoln",
                "location_address": "Lincoln, Nottingham, NG7 2HA"
            },
            {
                "location_name": "Malaysia Campus",
                "location_address": "Jalan Broga, 43500 Semenyih, Selangor Darul Ehsan, Kuala Lumpur, Malaysia"
            },
            {
                "location_name": "University Park Campus",
                "location_address": "University of Nottingham, Nottingham, NG7 2RJ"
            },
            {
                "location_name": "Queen's Medical Centre",
                "location_address": "Queen's Medical Centre, Nottingham, NG7 2HA"
            },
            {
                "location_name": "Main Site",
                "location_address": "University Park, Nottingham, NG7 2RD"
            },
            {
                "location_name": "Medical School",
                "location_address": "Devonshire House, London Road, Derby, DE1 2QY"
            },
            {
                "location_name": "Derby",
                "location_address": "Derby, Nottingham, NG7 2HA"
            },
            {
                "location_name": "Jubilee Campus",
                "location_address": "Wollaton Road, Nottingham, NG8 1BB"
            },
            {
                "location_name": "University Park Campus",
                "location_address": "University Park, Nottingham, NG7 2RD"
            },
            {
                "location_name": "Nottingham",
                "location_address": "Nottingham, Nottingham, NG7 2RD"
            },
            {
                "location_name": "Sutton Bonington Campus",
                "location_address": "Sutton Bonington, Leicestershire, Loughborough, LE12 5RD"
            },
            {
                "location_name": "City Hospital Campus",
                "location_address": "Hucknall Road, Nottingham, NG5 1PB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/79f42328-0271-1ace-1aec-05359cc16dd9",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/dsc_4186_ucasresize.jpg",
        "about_us": "Join the University of Nottingham and you\u2019ll see why we\u2019re ranked one of the UK\u2019s top 20 universities (QS World University Rankings 2025).You\u2019ll be taught by academics who are leaders in their field, while enjoying a student experience that\u2019s among the best in the country (top 5 university for student life, UniCompare 2025).As a UoN graduate you\u2019ll be highly prized by leading companies. We\u2019re the 3rd most targeted UK university by top employers (Graduate Market in 2024 report, High Fliers) and have ranked in the top 5 for 3 years running.We\u2019re also Sports University of the Year (The Times and The Sunday Times Good University Guide 2024 and Daily Mail University Guide 2024), so you can pursue your passion in state-of-the-art facilities \u2013 whether you\u2019re an elite athlete or beginner.Meanwhile our award-winning campuses \u2013 home to students from over 150 countries \u2013 provide the perfect setting for forging lifelong friendships, with the vibrant city of Nottingham on your doorstep."
    },
    {
        "id": "afc7e1661fd697bf6e02232afd5380a5",
        "university_name": "Plymouth Marjon University",
        "location": "Derriford Road, Plymouth, Plymouth, Devon, PL6 8BH",
        "official_website": "http://www.marjon.ac.uk/",
        "contact_email": "visitus@marjon.ac.uk",
        "contact_phone": "01752 636700",
        "course_locations": [
            {
                "location_name": "Bristol School of Acting",
                "location_address": "Bristol School of Acting, Silver Street,, Bristol, BS1 2AG"
            },
            {
                "location_name": "M.I.T Skills",
                "location_address": "M.I.T Skills, 12-13, Camphill Industrial Estate, West Byfleet, KT14 6EW"
            },
            {
                "location_name": "News Associates London",
                "location_address": "Ampersand House, 49-53 York Street, London, TW1 3LP"
            },
            {
                "location_name": "Main Site",
                "location_address": "Plymouth Marjon University, Derriford Road, Plymouth, PL6 8BH"
            },
            {
                "location_name": "Make Happen Institute (Bristol)",
                "location_address": "St Vincent's Works, Silverthorne Lane, Bristol, BS2 0QD"
            },
            {
                "location_name": "Plymouth Marjon University",
                "location_address": "Derriford Road, Plymouth, PL6 8BH"
            },
            {
                "location_name": "Main Site",
                "location_address": "Derriford Road, Plymouth, PL6 8BH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a3bf2ac2-f379-5b8b-0411-bc11fa9bf92f",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_image_1920x420_c.jpg",
        "about_us": "Plymouth Marjon University sits on a green and spacious campus, with state-of-the-art facilities in a spectacular part of the world. But the thing that most makes us stand out is our students. They are the inspiration and motivation for all we do.\u00a0Here, students build strong working relationships with a network of supporters, from their Personal Development Tutors to their lecturers to the Student Union, and therefore achieve more than they ever thought possible. They work side-by-side with us to improve and develop the University to make their experience as rich and rewarding as possible.\u00a0All our programmes involve work placements of some sort, and all students are trained in critical graduate-level skills such as presenting with impact, applying for jobs, managing interviews, and influencing others. We work very closely with key employers on all programmes, linking our students to a network of contacts to help them in their future careers."
    },
    {
        "id": "4cca771f84df31e8f94bb700aa9487dc",
        "university_name": "University of Plymouth",
        "location": "University of Plymouth, Drake Circus, Plymouth, Devon, PL4 8AA",
        "official_website": "http://www.plymouth.ac.uk/",
        "contact_email": "admissions@plymouth.ac.uk",
        "contact_phone": "01752 585858",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Drake Circus, Plymouth, PL4 8AA"
            },
            {
                "location_name": "Truro, Cornwall",
                "location_address": "Knowledge Spa, Royal Cornwall Hospital, Truro, TR1 3HD"
            },
            {
                "location_name": "Plymouth Site",
                "location_address": "Drake Circus, Plymouth, PL4 8AA"
            },
            {
                "location_name": "Exeter",
                "location_address": "WESC Foundation, Topsham Road, Countess Wear, Exeter, EX2 6HA"
            },
            {
                "location_name": "Albert",
                "location_address": "N/A"
            },
            {
                "location_name": "James",
                "location_address": "N/A"
            },
            {
                "location_name": "Gifty",
                "location_address": "N/A"
            },
            {
                "location_name": "Elena",
                "location_address": "N/A"
            },
            {
                "location_name": "Chloe",
                "location_address": "N/A"
            },
            {
                "location_name": "Ishita",
                "location_address": "N/A"
            },
            {
                "location_name": "April",
                "location_address": "N/A"
            },
            {
                "location_name": "Brigitte",
                "location_address": "N/A"
            },
            {
                "location_name": "Annabel",
                "location_address": "N/A"
            },
            {
                "location_name": "Ed",
                "location_address": "N/A"
            },
            {
                "location_name": "Feiyu",
                "location_address": "N/A"
            },
            {
                "location_name": "Diksha ",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/250ab332-bd0a-3163-e03a-2a1f16466346",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_profile_2024_-_banner_image_jan_update.jpg",
        "about_us": "At the University of Plymouth, we are proud to be one of only a select number of UK universities to achieve triple gold \u2013 gold overall and gold for both underpinning aspects: student experience and student outcomes (Teaching Excellent Framework, 2023).\u00a0\u00a0Set in the middle of beautiful countryside and stunning sandy beaches, we also have a unique and special location to match your gold-rated education. The city has been found to be 48% cheaper than London in terms of day-to-day living costs (livingcost.org), which means our students can make the most of their student experience and the opportunities university offers.\u00a0At the University of Plymouth, it doesn\u2019t matter where you\u2019re from but what\u2019s important, is where you want to go \u2013 for us it is all about making your journey personal. We are welcoming and inclusive, and proud to be members of our unique community where every individual counts.\u00a0\u00a0"
    },
    {
        "id": "19ba9e0af1475016c391d01454ac5a24",
        "university_name": "pointblank Music School",
        "location": "23-28 Penn Street, London, N1 5DL",
        "official_website": "https://www.pointblankmusicschool.com/courses/london/",
        "contact_email": "admissions@pointblankmusicschool.com",
        "contact_phone": "020 7729 4884",
        "course_locations": [
            {
                "location_name": "Main Campus",
                "location_address": "26 Orsman Road, London, N1 5QJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ee9a8e04-fe4f-ef42-bf78-65f0bd5a2fa4",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_hero_0.png",
        "about_us": "pointblank Music School\u00a0was voted\u00a0Best Music or Specialist Institution\u00a0by WhatUni in 2023, so if you\u2019re looking to study music production, sound engineering, DJing, vocal performance, songwriting, music business, music systems engineering, or radio/podcasting, then Point Blank is where you need to be.\u00a0We\u2019ve been teaching the art of music production and related arts for almost 30 years, and we pride ourselves on the industry relationships we\u2019ve built which make pointblank stand out from the crowd. Our students are among the most satisfied in the country, giving us an 86% Teaching score in the 2023 National Student Survey (NSS). We're also very proud to receive\u00a0TEF Gold\u00a0status for 2023, the\u00a0only\u00a0Music Production school to do so.\u00a0We collaborate with Middlesex University to run foundation, three-year, and two-year (accelerated) university-level degree programmes designed and\u00a0taught by industry experts\u00a0who will guide you throughout your journey and help you\u00a0step inside the music industry."
    },
    {
        "id": "8a625cf416bd657ff607b787b8ee6604",
        "university_name": "Richmond American University London",
        "location": "Building 12, Chiswick Park, 566 Chiswick High Road, London, W4 5AN",
        "official_website": "https://www.richmond.ac.uk/?utm_source=UCAS&utm_medium=Profile&utm_campaign=UCAS_ProviderProfilePage&utm_content=Visit_Website_CTA",
        "contact_email": "enquiries@richmond.ac.uk",
        "contact_phone": "44 0208 332 8298",
        "course_locations": [
            {
                "location_name": "Chiswick Park Campus",
                "location_address": "Building 12, Chiswick Park, 566 Chiswick High Road, London, W4 5YA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/712655f4-90f8-8795-cf48-5ea990207f3b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/richmond_american_university_london_building.jpg",
        "about_us": "Richmond American University London, established in 1972, is a unique institution with a mission to educate and inform future generations by providing them with the knowledge and support to think critically, the freedom to challenge assumptions and the skills to work with others. We award our students both a UK and a US degree on the basis of an applied liberal arts curriculum in the American tradition..Our undergraduate students gain comprehensive knowledge across a range of disciplines and develop this through specialisation in a major with the option to study further areas as minors.A diverse community of around 1000 students benefits from award-winning student support, small class sizes, high contact hours, integrated internships and service-learning opportunities, and dedicated highly qualified academic faculty."
    },
    {
        "id": "32a5d3da3b9566c31acd147808c78699",
        "university_name": "University of Roehampton",
        "location": "Roehampton Lane,, Roehampton Lane, London, SW15 5PU",
        "official_website": "https://www.roehampton.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "0208 392 3779",
        "course_locations": [
            {
                "location_name": "University Centre Croydon",
                "location_address": "University Centre Croydon, College Rd, Croydon, CR9 1DX"
            },
            {
                "location_name": "Whitelands College",
                "location_address": "Holybourne Avenue, London, SW15 4JD"
            },
            {
                "location_name": "Froebel College",
                "location_address": "Grove House, Roehampton Lane, London, SW15 5PJ"
            },
            {
                "location_name": "Main Site",
                "location_address": "Erasmus House, Roehampton Lane, Wandsworth, SW15 5PU"
            },
            {
                "location_name": "Main Site",
                "location_address": "Erasmus House, Roehampton Lane, London, SW15 5PU"
            },
            {
                "location_name": "Digby Stuart College",
                "location_address": "Erasmus House, Roehampton Lane, London, SW15 5PU"
            },
            {
                "location_name": "Southlands College",
                "location_address": "80 Roehampton Lane, London, SW15 5SL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/895f238c-14a8-283a-a1d6-067e959dd858",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/roehampton_-_ucas_1.jpg",
        "about_us": "At Roehampton, you will be challenged to engage with vital global issues. Here, we don\u2019t think anything should stand in your way. Here, you set the foundation for the future you want.Roehampton will give you the skills you need to stay ahead. Our industry-relevant courses and placements are built with your future in mind. Our teaching is designed around you, from study support personalised to you, to teaching patterns designed to recognise your wider commitments.As a campus university, you\u2019ll fit right into our welcoming community in southwest London. We are:#8 in England for undergraduate student satisfaction (National Student Survey 2024)Top 3 modern university in London (Complete University Guide 2025)One of the most affordable London universities for overall living costs (Which? Student Budget Calculator)"
    },
    {
        "id": "31d00ab3ffc41f901c77420df0b5a404",
        "university_name": "Royal Veterinary College, University of London",
        "location": "Royal College Street, London, London, Greater London, NW1 0TU",
        "official_website": "http://www.rvc.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "020-7468-5148",
        "course_locations": [
            {
                "location_name": "Camden Campus",
                "location_address": "Royal College Street, Camden, NW1 0TU"
            },
            {
                "location_name": "Main Site",
                "location_address": "Royal College Street, Westminster, NW1 0TU"
            },
            {
                "location_name": "main site",
                "location_address": "Royal College Street, London, NW1 0TU"
            },
            {
                "location_name": "Aberystwyth University (Aber)",
                "location_address": "Aberystwyth University, Penglais, Aberystwyth, SY23 3FL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/f2b98edd-bd3f-2d36-a0e3-f4e70b878c16",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/04_1920x420.jpg",
        "about_us": "The Royal Veterinary College (RVC), University of London is one of the world\u2019s leading veterinary and biological science institutions, renowned for pioneering approaches to teaching, research and cutting edge clinical provision. The RVC is currently ranked as the #1 Veterinary School in the World (QS 2023).Founded in 1791, the RVC\u00a0was the first veterinary institution in the English speaking world and the driving force behind the establishment of the profession in the UK. Now based over two campuses, its Camden Campus (London) offers the buzz of being based in the heart of the nation\u2019s capital; while in contrast, its Hawkshead Campus (Hertfordshire) offers a more rural experience on a 575 acre site in the English countryside. Both campuses offer its 2,500 students a friendly, supportive environment with state-of-the-art facilities. The RVC offers a range of renowned undergraduate, postgraduate and research degrees."
    },
    {
        "id": "f48332d0c83d76f954c6846abd67166f",
        "university_name": "University of South Wales",
        "location": "University of South Wales, Cardiff I Newport I Pontypridd, CF37 1DL",
        "official_website": "http://www.southwales.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "03455 76 01 01",
        "course_locations": [
            {
                "location_name": "Coleg Gwent (Cross Keys)",
                "location_address": "Coleg Gwent (Cross Keys), Risca Road, Cross Keys, NP11 7ZA"
            },
            {
                "location_name": "Cardiff (Atrium) Campus",
                "location_address": "University of South Wales, Pontypridd, Cardiff, CF37 1DL"
            },
            {
                "location_name": "Main Site",
                "location_address": "Pontypridd, Pontypridd, CF37 1DL"
            },
            {
                "location_name": "Coleg Gwent (Ebbw Vale)",
                "location_address": "Coleg Gwent (Ebbw Vale), Waun-Y-Pound Road, Ebbw Vale, NP23 6GT"
            },
            {
                "location_name": "Coleg y Cymoedd (Rhondda College)",
                "location_address": "Coleg Morgannwg (Rhondda College), Rhondda, Tonypandy, CF40 2TQ"
            },
            {
                "location_name": "Pontypridd",
                "location_address": "University of South Wales, Pontypridd, CF37 1DL"
            },
            {
                "location_name": "Cardiff and Vale College (Cardiff)",
                "location_address": "Cardiff And Vale College (Cardiff), Trowbridge Road, Cardiff, CF3 1XZ"
            },
            {
                "location_name": "Newport",
                "location_address": "Newport City Campus, Usk Way, Newport, NP20 2BP"
            },
            {
                "location_name": "Glyntaff Campus",
                "location_address": "Glyntaff, Pontypridd, CF37 4BD"
            },
            {
                "location_name": "Merthyr Tydfil College",
                "location_address": "Merthyr Tydfil College, Ynysfach, Merthyr Tydfil, CF48 1AR"
            },
            {
                "location_name": "Gower College Swansea",
                "location_address": "Tycoch Road, Sketty, Swansea, SA2 9EB"
            },
            {
                "location_name": "Cardiff",
                "location_address": "University Of South Wales, Adam Street, Cardiff, CF24 2FN"
            },
            {
                "location_name": "Coleg Gwent (Usk)",
                "location_address": "Coleg Gwent (Usk), Llanbadoc, Llanbadoc, Usk, NP15 1XJ"
            },
            {
                "location_name": "Torfaen Learning Zone",
                "location_address": "Torfaen Learning Zone, St Davids Road, Cwmbran, NP44 1DF"
            },
            {
                "location_name": "Coleg Gwent (Newport)",
                "location_address": "Coleg Gwent (Newport), Nash Road, Newport, NP19 4TS"
            },
            {
                "location_name": "City Campus, Newport",
                "location_address": "Usk Way, Newport (Wales), NP20 2BP"
            },
            {
                "location_name": "Coleg y Cymoedd (Aberdare)",
                "location_address": "Coleg Morgannwg (Aberdare), Cwmdare Road, Aberdare, CF44 8ST"
            },
            {
                "location_name": "Main Site",
                "location_address": "Llantwit Road, Treforest, Pontypridd, CF37 1DL"
            },
            {
                "location_name": "Coleg y Cymoedd (Nantgarw)",
                "location_address": "Parc Natgarw, Heol y Coleg, Cardiff, CF15 7QX"
            },
            {
                "location_name": "Rebecca",
                "location_address": "N/A"
            },
            {
                "location_name": "Natalia",
                "location_address": "N/A"
            },
            {
                "location_name": "AshaJyothi",
                "location_address": "N/A"
            },
            {
                "location_name": "Jaycie",
                "location_address": "N/A"
            },
            {
                "location_name": "Kaitlyn",
                "location_address": "N/A"
            },
            {
                "location_name": "Kat",
                "location_address": "N/A"
            },
            {
                "location_name": "Catherine",
                "location_address": "N/A"
            },
            {
                "location_name": "Diya",
                "location_address": "N/A"
            },
            {
                "location_name": "Chloe",
                "location_address": "N/A"
            },
            {
                "location_name": "Hayley",
                "location_address": "N/A"
            },
            {
                "location_name": "Jess",
                "location_address": "N/A"
            },
            {
                "location_name": "Gabby",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a33ecb69-a317-4e2c-6143-e665fdb20b14",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/dezire_banner_ucas.png",
        "about_us": "At USW we ensure your degree is 100% focused on what employers need in the future, by developing and delivering our courses in exclusive partnerships with employers, leaders of industry, the public sector and national bodies, ensuring USW graduates are prepared for tomorrow, ready to build their careers and make a difference.We have three locations, but we are one University. Every campus is well connected and open to you as a USW student.\u00a0Whichever campus hosts your course, you get the best of South Wales to explore.There\u2019s a network of people waiting to help you get the most from your time at USW, from study skills to disability services, counselling and wellbeing. We pride ourselves on the support we give our students, ensuring they have everything they need to thrive."
    },
    {
        "id": "59f504dbb1ea816386ae373364fd74b9",
        "university_name": "University of Southampton",
        "location": "University Road, Southampton, Hampshire, SO17 1BJ",
        "official_website": "https://www.southampton.ac.uk?utm_source=central-general-ucas-provider&utm_medium=advertising&utm_content=weblink&utm_campaign=ug2025",
        "contact_email": "enquiries@southampton.ac.uk",
        "contact_phone": "+44 (0)23 8059 5000",
        "course_locations": [
            {
                "location_name": "Avenue Campus",
                "location_address": "University of Southampton, Avenue Campus, Highfield Road, Southampton, SO17 1BF"
            },
            {
                "location_name": "Winchester School of Art",
                "location_address": "Park Avenue, Winchester, SO23 8DL"
            },
            {
                "location_name": "National Oceanography Centre, Southampton (NOCS)",
                "location_address": "University of Southampton, Waterfront Campus, European Way, Southampton, SO14 3ZH"
            },
            {
                "location_name": "Boldrewood Campus",
                "location_address": "Burgess Road, Southampton, SO16 7QF"
            },
            {
                "location_name": "Main Site - Highfield Campus",
                "location_address": "Highfield, Southampton, SO17 1BJ"
            },
            {
                "location_name": "University Hospital Southampton",
                "location_address": "University of Southampton, Tremona Road, Southampton, SO16 6YD"
            },
            {
                "location_name": "Main Site",
                "location_address": "Highfield, Southampton, SO17 1BJ"
            },
            {
                "location_name": "Highfield Campus and Boldrewood Campus",
                "location_address": "University of Southampton, Southampton, SO17 1BJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/0f5d882f-2ebe-1b13-fc9d-98363e55d1d4",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/header_resized.png",
        "about_us": "Imagine studying somewhere where the lecturer in front of you is making discoveries, pushing boundaries and working at the edge of human knowledge. At the University of Southampton, you\u2019ll be immersed in an environment enriched by that research, those experts and our global connections.\u00a0We\u2019re a founding member of the Russell Group, ranked in the top 100 universities worldwide* and in the top 20 in the UK**. Our research shapes your education. You won\u2019t just hear about it; you\u2019ll have the chance to get involved and experience it.\u00a0You\u2019ll develop the skills and knowledge to stand out in a changing global jobs market; not just because of your degree, but because of the incredible opportunities you\u2019ll have. We\u2019re a top 10 UK university targeted by the largest number of top employers***, with 97% of graduates in employment or further study****.\u00a0*QS World University Rankings 2024\u00a0**Complete University Guide 2024\u00a0***The Graduate Market 2023 High Fliers Research\u00a0****HESA Graduate Outcomes 2023\u00a0"
    },
    {
        "id": "e04f049fd4d50b9178bd6d69299949d9",
        "university_name": "SP Jain London School of Management",
        "location": "2 Harbour Exchange Square, London, E14 9GE",
        "official_website": "https://www.spjain.ac.uk/?utm_source=ucas&utm_medium=search&utm_campaign=SPJ-London",
        "contact_email": "enquiries@spjain.ac.uk",
        "contact_phone": "+44 020 3011 0960",
        "course_locations": [
            {
                "location_name": "SP Jain London",
                "location_address": "2 Harbour Exchange Square, London, E14 9GE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/f1f9a1ea-2646-44ca-8512-ea909252545b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/banner_opt_21.jpg",
        "about_us": "One of the pioneers in global business education, the SP Jain Group, opens the doors to a new London business school, SP Jain London School of Management.Our exchange partner, SP Jain School of Global Management, is a registered Australian business school recognised as the youngest business school to be ranked for our exceptional 1-year MBA program. Most recently, Forbes ranked us 12th in the world for the Best International 1-year MBSs from 2019 to 2021."
    },
    {
        "id": "4f1dce2bf3464500d5f0cbbde1f1937b",
        "university_name": "University of Suffolk",
        "location": "Waterfront Building, Neptune Quay, Neptune Quay, Ipswich, Suffolk, IP4 1QJ",
        "official_website": "https://www.uos.ac.uk",
        "contact_email": "studentlife@uos.ac.uk",
        "contact_phone": "01473 338000",
        "course_locations": [
            {
                "location_name": "University of Suffolk at East Coast College (Great Yarmouth)",
                "location_address": "Southtown, Great Yarmouth, NR31 0ED"
            },
            {
                "location_name": "Ipswich Hospital",
                "location_address": "Heath Road, Ipswich, IP4 5PD"
            },
            {
                "location_name": "Norfolk and Norwich University Hospital",
                "location_address": "Colney Lane, Norwich, NR4 7UY"
            },
            {
                "location_name": "University of Suffolk",
                "location_address": "Waterfront Building, Neptune Quay, Ipswich, IP4 1QJ"
            },
            {
                "location_name": "University of Suffolk at East Coast College (Lowestoft)",
                "location_address": "Lowestoft\u00a0College, St Peter's Street, Lowestoft, NR32 2NB"
            },
            {
                "location_name": "James Paget Hospital, Great Yarmouth",
                "location_address": "Lowestoft Road, Gorleston, Great Yarmouth, NR31 6LA"
            },
            {
                "location_name": "Ipswich - Distance Learning",
                "location_address": "Online Virtual Venue, Distance Learning, Online"
            },
            {
                "location_name": "Elliot",
                "location_address": "N/A"
            },
            {
                "location_name": "Joshua ",
                "location_address": "N/A"
            },
            {
                "location_name": "Mabel",
                "location_address": "N/A"
            },
            {
                "location_name": "Oliwia",
                "location_address": "N/A"
            },
            {
                "location_name": "Grace",
                "location_address": "N/A"
            },
            {
                "location_name": "Phoebe",
                "location_address": "N/A"
            },
            {
                "location_name": "Rhianna",
                "location_address": "N/A"
            },
            {
                "location_name": "Harri",
                "location_address": "N/A"
            },
            {
                "location_name": "Morgan",
                "location_address": "N/A"
            },
            {
                "location_name": "Milena",
                "location_address": "N/A"
            },
            {
                "location_name": "Jess",
                "location_address": "N/A"
            },
            {
                "location_name": "Madison",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/cf271b96-f9f1-4eef-e308-bb33fe18a91a",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/clearing_cover.jpg",
        "about_us": "Established in 2007, the University of Suffolk began as University Campus Suffolk (UCS), and was awarded university status in 2016.The University of Suffolk is all about transformation \u2013 transforming individuals, communities and beyond.The University of Suffolk main campus is based on the Ipswich Waterfront, with their courses also being delivered at their partner colleges; Ipswich\u2019s Suffolk New College and East Coast College based in Lowestoft and Great Yarmouth, as well as across a number of other national and international partnerships.The University is proud to be home to a vibrant and dynamic research community with a range of specialist academic expertise. The current research priorities focus on the Universities areas of distinctiveness; Health and Wellbeing, Sustainability, Digital Futures, Crime and Social Justice, Culture and Heritage, Excellence in Education."
    },
    {
        "id": "86ed5c81464bc88eb18be6081c5e2ea6",
        "university_name": "University of Sussex",
        "location": "Undergraduate Admissions, Sussex House, Falmer, Sussex House, Falmer, Brighton, East Sussex, BN1 9RH",
        "official_website": "https://www.sussex.ac.uk/?utm_source=ucas&utm_medium=organic_referral&utm_campaign=ao-ug-brand&utm_content=provider-page",
        "contact_email": "ug.enquiries@sussex.ac.uk",
        "contact_phone": "01273 606755",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Sussex House, Undergraduate Admissions, Falmer, Brighton, BN1 9RH"
            },
            {
                "location_name": "Sussex House",
                "location_address": "Sussex House, Brighton, BN1 9RH"
            },
            {
                "location_name": "Main Site",
                "location_address": "Sussex House, Brighton, BN1 9RH"
            },
            {
                "location_name": "Hanna",
                "location_address": "N/A"
            },
            {
                "location_name": "Accalia",
                "location_address": "N/A"
            },
            {
                "location_name": "Dana",
                "location_address": "N/A"
            },
            {
                "location_name": "Izzy",
                "location_address": "N/A"
            },
            {
                "location_name": "Caitlin",
                "location_address": "N/A"
            },
            {
                "location_name": "Gabriel",
                "location_address": "N/A"
            },
            {
                "location_name": "Holly",
                "location_address": "N/A"
            },
            {
                "location_name": "Gianna",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6e856d76-a6f1-2fb0-93d8-94415e6bfa0e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_profile_banner_1920x420.png",
        "about_us": "If you want a university experience that excites and challenges you in your ambition, and gives you the support to get there, we\u2019re your place.Inspired by our talented research and teaching staff, our graduates can take their learning and passion into the world and use it for good \u2013 from helping communities devastated by conflicts, to creating technologies that save lives, to bringing positive change through new ways of thinking.Sustainability is one of our top priorities, as reflected in our curriculum and research. Across the globe, our academics are engaged in projects to restore and protect our natural landscapes. At home, we\u2019re committed to making our environment as green as possible.We\u2019re lucky to be situated in the South Downs. Our sunny, safe campus \u2013 with stunning architecture \u2013 is surrounded by a national park, and yet close to the ever-vibrant city of Brighton &amp; Hove and miles of glorious coastline.It\u2019s a winning combination."
    },
    {
        "id": "6dfa83666abb473e63d2352ad098f149",
        "university_name": "Swansea University",
        "location": "Swansea University, Singleton Park, Swansea, SA2 8PP",
        "official_website": "http://www.swansea.ac.uk/?utm_source=ucas&utm_medium=onlineprofile&utm_campaign=ucasproject&utm_content=hubprofile",
        "contact_email": "study@swansea.ac.uk",
        "contact_phone": "01792 205678",
        "course_locations": [
            {
                "location_name": "Singleton Park Campus",
                "location_address": "Singleton Park, Swansea, SA2 8PP"
            },
            {
                "location_name": "Bay Campus, Swansea",
                "location_address": "Fabian Way, Swansea, SA1 8EN"
            },
            {
                "location_name": "St David's Park, Carmarthen",
                "location_address": "Jobswell Road, Carmarthen, SA31 3HB"
            },
            {
                "location_name": "Coleg Cambria - Northop",
                "location_address": "Holywell Road, Northop, CH7 6AA"
            },
            {
                "location_name": "Bay Campus",
                "location_address": "Fabian Way, Swansea, SA1 8EN"
            },
            {
                "location_name": "Singleton Park, Swansea",
                "location_address": "Singleton Park, Swansea, SA2 8PP"
            },
            {
                "location_name": "Main Site",
                "location_address": "Singleton Park, Swansea, SA2 8PP"
            },
            {
                "location_name": "Eleri ",
                "location_address": "N/A"
            },
            {
                "location_name": "Charles",
                "location_address": "N/A"
            },
            {
                "location_name": "Emmanuela",
                "location_address": "N/A"
            },
            {
                "location_name": "Jess",
                "location_address": "N/A"
            },
            {
                "location_name": "Imogen",
                "location_address": "N/A"
            },
            {
                "location_name": "Alex",
                "location_address": "N/A"
            },
            {
                "location_name": "Hope",
                "location_address": "N/A"
            },
            {
                "location_name": "Athul ",
                "location_address": "N/A"
            },
            {
                "location_name": "Ellis",
                "location_address": "N/A"
            },
            {
                "location_name": "Chris Jo",
                "location_address": "N/A"
            },
            {
                "location_name": "Abby",
                "location_address": "N/A"
            },
            {
                "location_name": "Gwellaouen",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/4b70e003-9056-dea2-e186-1dc3ad3316c9",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_1_0.jpg",
        "about_us": "Swansea University is known for cultivating a supportive and inclusive learning environment, with excellent tuition and expert staff, all based at its unique beachfront location.Swansea University prides itself on being flexible, by looking at each applicant as an individual, and making offers based on the suitability not only to the course, but also the environment and community at Swansea University."
    },
    {
        "id": "3939b9542026cfc4f9f895842ae21243",
        "university_name": "University of Worcester",
        "location": "St John's Campus, Henwick Grove, Worcester, Worcestershire, WR2 6AJ",
        "official_website": "http://www.worcester.ac.uk/",
        "contact_email": "study@worc.ac.uk",
        "contact_phone": "01905 855000",
        "course_locations": [
            {
                "location_name": "Riverside Centre",
                "location_address": "113 Hylton Road, Worcester, WR2 5JN"
            },
            {
                "location_name": "City Campus",
                "location_address": "Castle Street, Worcester, WR1 3AS"
            },
            {
                "location_name": "Millennium Performing Arts",
                "location_address": "Rudolf Steiner House, 35 Park Road, London, NW1 6XT"
            },
            {
                "location_name": "Halesowen College",
                "location_address": "Whittingham Road, Halesowen, B63 3NA"
            },
            {
                "location_name": "Birmingham Metropolitan College (Matthew Boulton College)",
                "location_address": "4 Jennens Road, Birmingham, B4 7PS"
            },
            {
                "location_name": "Birmingham Metropolitan College (Sutton Coldfield College)",
                "location_address": "Jennens Road, Birmingham, B4 7PS"
            },
            {
                "location_name": "St John's Campus",
                "location_address": "Henwick Grove, Worcester, WR2 6AJ"
            },
            {
                "location_name": "Dudley College of Technology (The Broadway)",
                "location_address": "The Broadway, Dudley, DY1 4AS"
            },
            {
                "location_name": "Herefordshire, Ludlow and North Shropshire College",
                "location_address": "Folly Lane, Hereford, HR1 1LS"
            },
            {
                "location_name": "Somerset Centre for Integrated Learning",
                "location_address": "The Holway Centre, Taunton, TA1 2JB"
            },
            {
                "location_name": "Dudley College of Technology (The Black Country and Marches Institute of Technology)",
                "location_address": "Zoological Drive, Dudley, DY1 4AE"
            },
            {
                "location_name": "Birmingham Metropolitan College (James Watt College)",
                "location_address": "Aldridge Road, Great Barr, Birmingham, B44 8NE"
            },
            {
                "location_name": "Iron Mill College (Exeter Campus or Poole Campus)",
                "location_address": "7 Barnfield Crescent, Exeter, EX1 1QT"
            },
            {
                "location_name": "Main Site",
                "location_address": "Henwick Grove, Worcester, WR2 6AJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/62431d8f-c60b-58bb-0df6-e80b0dc05c48",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_course_page_banner_worcester.jpg",
        "about_us": "The University of Worcester is a close-knit and high-achieving community where students are supported to succeed at every level.The University has been ranked in the top\u00a05 in the UK for Quality Education in all years of the Times Higher Education\u2019s University Impact Rankings, which assess how universities globally are meeting the UN\u2019s Sustainable Development Goals. The University has also been ranked in the top 5 in the UK for Gender Equality in all years of the rankings.Worcester has been shortlisted three times as the Times Higher Education\u2019s University of the Year \u2013 in 2016, 2019 and 2020 - and twice for University of the Year in the UK Social Mobility Awards \u2013 in 2019 and 2020."
    },
    {
        "id": "fd3ddef373e40ea002f4bc7e2d8e7f6b",
        "university_name": "University of Aberdeen",
        "location": "King's College, Aberdeen, AB24 3FX",
        "official_website": "https://www.abdn.ac.uk/study?utm_source=ucas&utm_medium=referral&utm_campaign=ucasprovider22-23",
        "contact_email": "study@abdn.ac.uk",
        "contact_phone": "01224 272090",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Directorate of External Relations, King's College, Aberdeen, AB24 3FX"
            },
            {
                "location_name": "Foresterhill Campus",
                "location_address": "Ashgrove Road West, Aberdeen, AB25 2ZN"
            },
            {
                "location_name": "Main Site",
                "location_address": "University Office, Regent Walk, King's College, Aberdeen, AB24 3FX"
            },
            {
                "location_name": "King's Campus",
                "location_address": "King's College, Aberdeen, AB24 3FX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3a023828-8d77-ecfa-dc21-2708f721b788",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/kings-college-exterior06_v3.jpg",
        "about_us": "Founded in 1495, the University of Aberdeen is a top 20 UK university and 4th in the UK for overall student satisfaction. A community of over 130 nationalities and 15,000 students, we teach over 350 undergraduate degrees. We provide the quality and depth of a traditional Scottish degree, but with choice, flexibility and support to produce graduates for the 21st Century."
    },
    {
        "id": "80deeb96d19a0fe1a947f952f1839e76",
        "university_name": "Abertay University",
        "location": "Kydd Building, Bell Street, Dundee, Tayside, DD1 1HG",
        "official_website": "https://www.abertay.ac.uk",
        "contact_email": "sro@abertay.ac.uk",
        "contact_phone": "01382 308000",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Bell Street, Dundee, DD1 1HG"
            },
            {
                "location_name": "Abertay Campus",
                "location_address": "Bell Street, Dundee, DD1 1HG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/1558119a-fa4d-ca42-ddbd-0694a4e9bdc8",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/building-1_1.jpg",
        "about_us": "Abertay University is based in the heart of Dundee\u2014dubbed Britain's coolest little city by GQ Magazine\u2014on Scotland\u2019s stunning east coast.Home to approximately 4,500 students, our exciting range of undergraduate courses are designed to prepare you to meet the demands of the modern workplace and make your mark\u00a0in a variety of industries. Many of our degrees have work placements and industry experience built in, which ensures you hit the ground running.\u00a0Our supportive community will help you thrive, with personalised support, including peer mentoring, mental health counselling and academic advice on hand to make sure you get the most out of your time studying here.\u00a0We're passionate about quality teaching, which was recognised by The Times and Sunday Times Good University Guide, who ranked Abertay's teaching quality top in the UK in 2021 and in the UK top 20 in 2022.\u00a0Wherever your interests lie, you'll find your place at Abertay University. "
    },
    {
        "id": "ccece12394930de41dca2155ffa392d4",
        "university_name": "Aberystwyth University",
        "location": "Penglais Campus, Penglais Campus, Aberystwyth, Ceredigion, SY23 3FB",
        "official_website": "https://www.aber.ac.uk",
        "contact_email": "admissions@aber.ac.uk",
        "contact_phone": "+44 (0)1970 622021",
        "course_locations": [
            {
                "location_name": "Main Site (Aberystwyth)",
                "location_address": "Penglais Campus, Penglais Road, Aberystwyth, SY23 3DD"
            },
            {
                "location_name": "Main Site",
                "location_address": "Penglais, Aberystwyth, Ceredigion, Wales, SY23 3FL"
            },
            {
                "location_name": "School of Art",
                "location_address": "Buarth Mawr, Aberystwyth, SY23 1NG"
            },
            {
                "location_name": "Coleg Cambria Deeside Campus",
                "location_address": "Coleg Cambria Deeside Campus, Kelsterton Road, Connah's Quay, CH5 4BR"
            },
            {
                "location_name": "Penglais Campus",
                "location_address": "Penglais Road, Ceredigion, Aberystwyth, SY23 3AR"
            },
            {
                "location_name": "Llanbadarn Campus",
                "location_address": "Llanbadarn Fawr, Ceredigion, Aberystwyth, SY23 3RJ"
            },
            {
                "location_name": "Coleg Gwent Usk Campus",
                "location_address": "Coleg Gwent Usk Campus, Usk, NP15 1XJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/168d2cd8-ad19-7681-d665-453cfb2e55f0",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucashero.jpg",
        "about_us": "Aberystwyth University offers an excellent student experience and is consistently ranked amongst the best universities in the UK. Aberystwyth is Top in Wales for Student Satisfaction (NSS 2024) and was placed among the Top 3 UK Universities for Teaching Quality and Student Experience (GUG 2024).The University is committed to delivering outstanding teaching in an environment that is welcoming, supportive and inclusive. Aberystwyth\u2019s teaching quality is amongst the best in the UK and 98% of our research is of an \u201cinternationally recognised standard or higher\u201d (Research Excellence Framework 2021). This means that students on undergraduate programmes are taught by leading experts in their fields, within departments at the forefront of emerging research.The University places emphasis on supporting our students, including in offering a comprehensive range of\u202fScholarships and Bursaries to help with study and living costs.\u00a0"
    },
    {
        "id": "9cdb3ddad5f9cf5619d09ffd5527e49b",
        "university_name": "Academy of Live Technology",
        "location": "Unit 53 Langthwaite Business Park, South Kirby, South Kirby, Wakefield, West Yorkshire, WF9 3NR",
        "official_website": "https://www.academyoflivetechnology.co.uk/",
        "contact_email": "admissions@academyoflivetechnology.co.uk",
        "contact_phone": "01977 659880",
        "course_locations": [
            {
                "location_name": "Academy of Live Technology",
                "location_address": "Unit 53/55 Lidgate Crescent - Langthwaite Grange Ind. Est., South Kirkby, WF9 3NR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a04f3f5e-b295-4733-9cf9-3dafd99c12a4",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/1655_alt_social_twitter_header_01.png",
        "about_us": "We\u2019re the Academy of Live Technology, a specialist education provider focusing on live events, experience and entertainment production.\u00a0We offer undergraduate degrees in three core areas: Live Events Production, Stage and Production Management and Live Visual Design and Production. Each course has been developed with careers in mind, so whatever you choose to study, you\u2019ll have access to work experience opportunities and award-winning partnerships to help you prepare for the world of work ahead of you.\u00a0When you study at the Academy of Live Technology, you\u2019ll learn in a unique creative environment where some of the world\u2019s greatest productions are put together. You\u2019ll be taught by experts who bring their years of industry experience to your learning. You\u2019ll see theory come to life as you get hands-on while you study.\u00a0"
    },
    {
        "id": "0808df817c98d55d814f013eaec3281a",
        "university_name": "Activate Learning",
        "location": "Oxpens Road, Oxford, Oxfordshire, OX1 1SA",
        "official_website": "https://www.activatelearning.ac.uk",
        "contact_email": "he@activatelearning.ac.uk",
        "contact_phone": "he@activatelearning.ac.uk",
        "course_locations": [
            {
                "location_name": "Online",
                "location_address": "Online Learning, Oxford, OX1 1SA"
            },
            {
                "location_name": "Blackbird Leys Campus",
                "location_address": "Cuddesdon Way, Oxford, OX4 6HN"
            },
            {
                "location_name": "Merrist Wood College",
                "location_address": "Holly Lane, Worplesdon, Guildford, GU3 3PE"
            },
            {
                "location_name": "Reading College",
                "location_address": "Kings Road, Reading, RG1 4HJ"
            },
            {
                "location_name": "Guildford College",
                "location_address": "Stoke Rd, Guildford, GU1 1EZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/1630c359-4a88-1162-6be9-75a9bb8257e3",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/activate-learning-2019_banner.jpg",
        "about_us": "At Activate Learning we offer a range of undergraduate learning options from HNCs and HNDs to Foundation Degrees and top-ups for BA and BSc Degrees. Each course is recognised for its focus on employability by the Quality Assurance Agency and designed to build the very skills you need to have a successful career.Undergraduate study options are validated by partner universities, including Oxford Brookes, University of Reading, Middlesex University, Kingston University and Pearson."
    },
    {
        "id": "ef30296fad0948181d22fdf23426660f",
        "university_name": "Amity University [IN] London",
        "location": "24 Bedford Square,, London, London, WC1B 3HN",
        "official_website": "https://www.amity.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "0207 6310 190",
        "course_locations": [
            {
                "location_name": "Birkbeck, University of London",
                "location_address": "Malet St, Bloomsbury, London, WC1E 7HX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/57f3e439-84c0-4e07-8e3e-92673bc266b8",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "db0cc6792e9ed891fff443bcdf7815f3",
        "university_name": "Anglia Ruskin University",
        "location": "Anglia Ruskin University, Cambridge, Chelmsford, Peterborough and Writtle, CB1 1PT / CM1 1SQ",
        "official_website": "https://aru.ac.uk",
        "contact_email": "answers@aru.ac.uk",
        "contact_phone": "01245 686868",
        "course_locations": [
            {
                "location_name": "Chelmsford Campus",
                "location_address": "Bishop Hall Lane, Chelmsford, CM1 1SQ"
            },
            {
                "location_name": "Writtle Campus",
                "location_address": "Lordship Road, Writtle, CM1 3RR"
            },
            {
                "location_name": "Main Site",
                "location_address": "East Road, Cambridge, CB1 1PT"
            },
            {
                "location_name": "Cambridge Theological Federation",
                "location_address": "Wesley House, Jesus Lane, Cambridge, CB5 8BQ"
            },
            {
                "location_name": "Renew Counselling",
                "location_address": "Sadlers House, 2 Legg Street, Chelmsford, CM1 1AH"
            },
            {
                "location_name": "Cambridge Campus",
                "location_address": "East Road, Cambridge, CB1 1PT"
            },
            {
                "location_name": "ARU Peterborough",
                "location_address": "University House, Bishops Road, Peterborough, PE1 5BW"
            },
            {
                "location_name": "The Newtown Centre, Huntingdon",
                "location_address": "The Newtown Centre, Nursery Road,, Huntingdon, Huntingdon, PE29 3RJ"
            },
            {
                "location_name": "London School of Osteopathy",
                "location_address": "The Grange, 12 Grange Road, London, SE1 3BE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d890f3a3-4c8e-f241-747b-fa2f1b5f87db",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/aru_chelmsford_3_1-2_3.jpg",
        "about_us": "ARU gives you the opportunity to explore who you are and discover your strengths. We provide an active, applied education that will empower you to amplify your purpose and gain the knowledge, skills and experience that employers demand.\u00a0ARU offer undergraduate and postgraduate courses with a range of September and January start dates, part-time options, Degree Apprenticeships and accelerated (2 year) courses, to help find the right degree for you. We measure our success by the impact we have on our students and on the communities we serve. We are proud that our students rate their educational experience highly.You\u2019ll find our campuses in the heart\u00a0Cambridge, Chelmsford, Peterborough and Writtle, each with their own unique style and charm. Our partnership with\u00a0ARU London\u00a0also gives you the opportunity to study for an ARU-validated degree in the capital city.\u00a0Our ARU Writtle campus, close to Chelmsford, was formally Writtle University College prior to the merge in March 2024. \u00a0\u00a0\u00a0"
    },
    {
        "id": "e265ccc553cc66c6d64fa6dd2dc1a9d9",
        "university_name": "Architectural Association School of Architecture",
        "location": "36 Bedford Square, Camden, WC1B 3ES",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "School of Architecture",
                "location_address": "36 Bedford Square, Camden, WC1B 3ES"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/15f49bd0-a344-5a36-46c9-077ebb5e35c3",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/dscf4082_rev_copy.jpg",
        "about_us": "N/A"
    },
    {
        "id": "99c3caf8993111f132e22cd2b0006cbc",
        "university_name": "Arden University",
        "location": "Arden House, Middlemarch Park, Coventry, CV3 4FJ",
        "official_website": "https://arden.ac.uk/",
        "contact_email": "enquiries@arden.ac.uk",
        "contact_phone": "024 7651 5700",
        "course_locations": [
            {
                "location_name": "Berlin Campus",
                "location_address": "University of Applied Sciences Europe, Dessauer Str. 3-5, Berlin, 10963"
            },
            {
                "location_name": "Distance Learning",
                "location_address": "Arden House, Middlemarch Park, Coventry, CV3 4FJ"
            },
            {
                "location_name": "Tower Hill Study Centre",
                "location_address": "Sceptre Court, 40 Tower Hill, London, EC3N 4DX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/8554563c-d2bb-4bc5-b0eb-e05c7ec741fc",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "efd3491fd7337ee79490bafe58246010",
        "university_name": "Arts Educational Schools",
        "location": "Cone Ripman House, 14 Bath Road, Chiswick, Hounslow, W4 1LY",
        "official_website": "https://artsed.co.uk",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Arts Educational Schools London",
                "location_address": "Cone Ripman House, 14 Bath Road, Chiswick, Hounslow, W4 1LY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d4a8f435-e665-ede1-c05f-3d8ab658c551",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_2.png",
        "about_us": "N/A"
    },
    {
        "id": "3ba0858ea65642553231e87ee3cb1b12",
        "university_name": "Arts University Plymouth",
        "location": "Tavistock Place, Plymouth, Devon, PL4 8AT",
        "official_website": "https://www.aup.ac.uk",
        "contact_email": "hello@aup.ac.uk",
        "contact_phone": "01752 203434",
        "course_locations": [
            {
                "location_name": "Arts University Plymouth",
                "location_address": "Tavistock Place, Plymouth, PL4 8AT"
            },
            {
                "location_name": "Main Site",
                "location_address": "Tavistock Place, Plymouth, PL4 8AT"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/fd337b6e-3aea-80dc-ebd6-48fffb665bb8",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_3.jpg",
        "about_us": "CREATIVE EDUCATION FOR A CHANGING WORLDArts University Plymouth is a new kind of Arts University for the 21st century, preparing graduates who are uniquely placed to provide creative solutions to the complex global challenges of our times.Founded in 1856 as the Plymouth Drawing School, we are proud to be the Arts University in Plymouth, Britain\u2019s Ocean City. The University offers a comprehensive spectrum of specialist undergraduate, postgraduate and pre-degree study in art, design, craft and digital media programmes to a dynamic community of around 1,500 UK and international students \u2013 combining over 165 years of creativity with contemporary thinking and cutting-edge, industry standard resources.Our university is widely regarded as a catalyst for individual, societal and ecological transformation, delivering innovative and distinctive learning, teaching and research through our interdisciplinary ecosystem of materials, processes, technologies and knowledge exchange."
    },
    {
        "id": "4c0fdb0681f83d6307b186c63d46ef45",
        "university_name": "ARU London",
        "location": "Import and Export Buildings, 2 Clove Crescent, Poplar, London, E14 2BE",
        "official_website": "https://london.aru.ac.uk/about-us/aru-london",
        "contact_email": "enquiry@london.aru.ac.uk",
        "contact_phone": "020 7400 6789",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Import and Export Buildings, 2 Clove Crescent, Poplar, London, E14 2BE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/95371765-7c69-2982-dd20-2ad94e821bab",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/website_template-2.png",
        "about_us": "Anglia Ruskin is a progressive university, breaking into the top 350 educational institutions in the world in 2017, and maintaining its place ever since.Our fantastic academics will link theory with practice in a friendly and supportive environment: just one of the reasons why our students have recorded some of the highest satisfaction rates across the university.At ARU London, we pride ourselves first and foremost on the experiences and development of our students. We try to enhance our students' experience and development through our support and teaching.Our fantastic academics will link theory with practice in a friendly and supportive environment, just one of the reasons that our students have recorded some of the highest satisfaction rates across our university. In the 2021 National Student Survey (NSS), ARU London achieved an 83.59% Overall Satisfaction rating, which is 8.18% higher than the national average."
    },
    {
        "id": "8a88409d9f0d37327c0c85dc737b31b9",
        "university_name": "ARU Writtle (formerly Writtle University College) ",
        "location": "Lordship Road, Writtle, Chelmsford, Essex, CM1 3RR",
        "official_website": "https://www.aru.ac.uk/student-life/life-on-campus/writtle-campus",
        "contact_email": "admissions@aru.ac.uk",
        "contact_phone": "01245424200",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Writtle, Writtle, Chelmsford, CM1 3RR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/7f5fefd6-14a9-99c1-31a5-5bb262ec879c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/untitled_design.jpg",
        "about_us": "\u2018Now Part of the ARU family\u2019 \u00a0We are pleased to confirm that Writtle University College and ARU (Anglia Ruskin University) have joined together. Writtle\u2019s full range of Higher and Further Education courses will continue to be delivered on site at the ARU Writtle campus, enhanced by resources available at nearby ARU Chelmsford. ARU Writtle is a small and supportive campus location, located in rural Essex, close to the city of Chelmsford. Specialising in Land based, Animal and Sport and Health undergraduate and postgraduate programmes, and offering industry-focused, innovative courses. If you are starting your course in September 2024, your degree will be awarded by ARU however you will need to apply through Institutional code: W85 \u2018ARU Writtle\u2019 \u2018Now Part of the ARU family\u2019 \u00a0"
    },
    {
        "id": "a64d3f742b90453585278ef5c72ae7ec",
        "university_name": "University Centre Askham Bryan",
        "location": "Askham Bryan,, York, North Yorkshire, YO23 3FR",
        "official_website": "https://www.askham-bryan.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01904 772277",
        "course_locations": [
            {
                "location_name": "Main Campus",
                "location_address": "University Centre Askham Bryan, Askham Bryan, York, YO23 3FR"
            },
            {
                "location_name": "Gateshead",
                "location_address": "Cameron House, Pinetree Way, Metrocentre, Gateshead, NE11 9XW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/7d51ec43-b668-d3ed-015b-3edc9a11367b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/17-4-18_askham_bryan_190.jpg",
        "about_us": "University Centre Askham Bryan is the perfect place to study if your passion lies in the outdoors and land-based sectors.\u00a0 With our fantastic range of education facilities, industry specialist lecturers and easy access to the stunning Yorkshire Countryside, your student experience will be second to none.Faced with the opportunity of sustaining and enhancing the rapidly changing environment we live in, the land-based sector is extremely dynamic and diverse.Driven by constantly changing legislative, technological and consumer demands and an overall requirement for scientific research, there is a strong industry need for well trained, higher-level skilled graduates."
    },
    {
        "id": "6ddefec3ee09d5d4ebef09c38d8a9ece",
        "university_name": "Aston University, Birmingham",
        "location": "Aston Triangle, Aston Triangle, Birmingham, West Midlands, B4 7ET",
        "official_website": "http://www.aston.ac.uk/",
        "contact_email": "ugadmissions@aston.ac.uk",
        "contact_phone": "01212043030",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Aston Triangle, Birmingham, B4 7ET"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/89c29ffe-ffc3-f10a-42b6-d3bead98a923",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/campus_exterior_001.jpg",
        "about_us": "Founded in 1895 and a university since 1966, Aston University is a long-established research-led university known for its world-class teaching quality and strong links to industry. With 18,000 students from over 120 countries our\u00a0safe and friendly campus sits in the\u00a0heart of Birmingham \u2013 one of the UK\u2019s most diverse and vibrant cities \u2013 and our teaching, facilities and commitment to an outstanding student experience are what really sets us apart. Being named The Guardian University of the Year and The Times Higher Education Outstanding Entrepreneurial University is testament to the excellent work of our staff and students. Our students work with real life projects, create start-up businesses and undertake placements and internships in industry.Our teaching has been rated excellent with the majority of staff being experienced practitioners and experts in their fields.\u00a0 Our teaching is hands-on, specialist, relevant and practical, with access to professional and technical equipment. "
    },
    {
        "id": "2ed73cae07da105918cd3347cdf7dc4b",
        "university_name": "Bangor University",
        "location": "Bangor University, College Road, Bangor, Gwynedd, LL57 2DG",
        "official_website": "https://www.bangor.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01248 351151",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Bangor University, Bangor, Gwynedd, LL57 2DG"
            },
            {
                "location_name": "Bangor Campus",
                "location_address": "Bangor Campus, Bangor (Wales), LL57 2DG"
            },
            {
                "location_name": "Wrexham",
                "location_address": "Archimedes Centre, Croesnewydd Road, Wrexham, LL13 7YP"
            },
            {
                "location_name": "Main Site",
                "location_address": "Bangor (Wales), LL57 2DG"
            },
            {
                "location_name": "Distance Learning (Bangor)",
                "location_address": "Bangor University, Bangor, LL57 2DG"
            },
            {
                "location_name": "Catty",
                "location_address": "N/A"
            },
            {
                "location_name": "Nicolas",
                "location_address": "N/A"
            },
            {
                "location_name": "Mike ",
                "location_address": "N/A"
            },
            {
                "location_name": "David",
                "location_address": "N/A"
            },
            {
                "location_name": "Emma",
                "location_address": "N/A"
            },
            {
                "location_name": "Chloe",
                "location_address": "N/A"
            },
            {
                "location_name": "Katie",
                "location_address": "N/A"
            },
            {
                "location_name": "Lewis",
                "location_address": "N/A"
            },
            {
                "location_name": "Mayu International Ambassador",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/7522cc92-d132-567e-e079-02b254dfad10",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/bangor_university.jpg",
        "about_us": "There are many reasons to study at Bangor. Offering academic excellence, a friendly study environment and stunning surroundings, Bangor has something for everyone.We offer the highest quality teaching in a supportive learning environment, and you can be assured that you will be taught by lecturers who want to see you thrive.Bangor University\u2019s research has been ranked highly in the Government\u2019s most recent Research Excellence Framework (REF2021), with 85% judged to be world-leading (4*) or internationally excellent (3*).Our courses will help to set you on the right path for a successful career. You can choose to study from courses across the sciences, arts, business, law and humanities. We offer foundation degrees in many subjects and most undergraduate courses offer the option of studying for an additional year as part of the \u2018International Year\u2019 or \u2018Placement Year\u2019 scheme.\u00a0"
    },
    {
        "id": "5588bf75d1a014b039b3b0ea3f644b37",
        "university_name": "Barnet and Southgate College",
        "location": "Southgate Campus,High Street, London, N14 6BS",
        "official_website": "https://www.barnetsouthgate.ac.uk",
        "contact_email": "tricia.curran@barnetsouthgate.ac.uk",
        "contact_phone": "020 8982 5123",
        "course_locations": [
            {
                "location_name": "Main Site: Wood Street - High Barnet Campus",
                "location_address": "Wood Street, Barnet, EN5 4AZ"
            },
            {
                "location_name": "Southgate Campus",
                "location_address": "High Street, London, N14 6BS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a24b2b81-f587-0bee-c9d4-3bbf5bbe87fb",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_woodstreet_1920x420.jpg",
        "about_us": "N/A"
    },
    {
        "id": "45b911f08065a0d944b2a33f84ed746c",
        "university_name": "Barnfield College, Luton",
        "location": "New Bedford Road, Luton, Bedfordshire, LU2 7BF",
        "official_website": "https://www.barnfield.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01582 569569",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "New Bedford Road, Luton, LU2 7BF"
            },
            {
                "location_name": "Barnfield College",
                "location_address": "New Bedford Road, Luton, LU2 7BF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5f7d6526-02f9-2a0d-befe-558796b99584",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/img_0060.jpg",
        "about_us": "N/A"
    },
    {
        "id": "664ac9646a61cbf2fdae309920f7262d",
        "university_name": "Barnsley College University Centre",
        "location": "PO Box 266, Church Street, Barnsley, South Yorkshire, S70 2YW",
        "official_website": "https://www.barnsley.ac.uk/higher-education/",
        "contact_email": "interviews@barnsley.ac.uk",
        "contact_phone": "01226 216 106",
        "course_locations": [
            {
                "location_name": "Barnsley College Higher Education Church Street Campus",
                "location_address": "Church Street, Barnsley, S70 2AN"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/4f52efe2-3dbf-e69f-11d5-a8e90fefdc9c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_1.jpg",
        "about_us": "Barnsley College University Centre is a part of Barnsley College,\u00a0one of the most successful Further Education colleges in South Yorkshire, offering a range of vocational, A Levels, apprenticeship, T Levels (Technical qualifications), part-time and Higher Education pathways to approximately 9,000 students.\u00a0Barnsley College is rated Outstanding by Ofsted and is a member of the Chartered Institute for Further Education (CIFE). It regularly receives awards for teaching and the support available to students and it ranks highly in the National Student Survey (NSS).Our town centre campuses which host our Higher Education study are equipped with state-of-the-art equipment for you to use. Our brand-new Barnsley College University Centre, incorporating the South Yorkshire Institute of Technology (IoT), is set to open in the 2024/25 academic year."
    },
    {
        "id": "feb48ed2d6c65efb5036027772356fe0",
        "university_name": "Bath College",
        "location": "Avon Street, Bath, Bath, Somerset, BA1 1UP",
        "official_website": "https://www.bathcollege.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01225 312191",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Avon Street, Bath, BA1 1UP"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/4c5e93f0-92d5-a60a-c7c9-96a5ee53a70f",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/somer_valley_campus_3.jpg",
        "about_us": "N/A"
    },
    {
        "id": "278dfbe11547ffb5aeb50f43c4131c68",
        "university_name": "Bath Spa University",
        "location": "Newton Park, Newton St Loe, Bath, Somerset, BA2 9BN",
        "official_website": "https://www.bathspa.ac.uk/",
        "contact_email": "be@bathspa.ac.uk",
        "contact_phone": "01225 875875",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Newton Park, Newton St Loe, Bath, BA2 9BN"
            },
            {
                "location_name": "City of Bristol College",
                "location_address": "Brunel Centre, Ashley Down, Bristol, BS7 9BU"
            },
            {
                "location_name": "The Scottish Institute for Theatre, Dance, Film and TV",
                "location_address": "207 Balgreen Road, Edinburgh, EH11 2RZ"
            },
            {
                "location_name": "New City College",
                "location_address": "Falkirk Street, London, N1 6HQ"
            },
            {
                "location_name": "University Centre Weston (UCW)",
                "location_address": "Knightstone Road, Weston-super-Mare, BS23 2AL"
            },
            {
                "location_name": "Circomedia",
                "location_address": "Kingswood Foundation, Britannia Road, Kingswood, Bristol, BS15 8DB"
            },
            {
                "location_name": "Main Site",
                "location_address": "Newton Park, Bath, BA2 9BN"
            },
            {
                "location_name": "Locksbrook Campus",
                "location_address": "Bath School of Art and Bath School of Design, Locksbrook Rd, Bath, BA1 3EL"
            },
            {
                "location_name": "Newton Park Campus",
                "location_address": "Newton St Loe, Bath, BA2 9BN"
            },
            {
                "location_name": "Brighton Academy",
                "location_address": "Unit 2, Hove Business Centre, Fonthill Road, Hove, BN3 6HA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/29a67da9-98b0-5cb7-294d-ffdc3e463904",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/commons_photo_ucas_1920_x_420.png",
        "about_us": "Bath Spa University (BSU) is proud to offer high-quality teaching with dedicated staff who can support you in your subject, as well as access to our cutting edge-facilities during study and after graduation. We value creativity, curiosity and confidence, and we\u2019re here to support you on your higher education journey. \u00a0Bath is the perfect student city \u2013 not too big, and not too small \u2013 with plenty of great places to explore, whether that\u2019s walks by the canal, coffee in the botanical garden or one of the city\u2019s festivals; these and more are the things our students love about studying in Bath.\u00a0Studying at BSU means discovering who you are - Our students build networks, boost their employability and develop skills ready to graduate. Most of our degrees offer a professional placement year, allowing you to experience a year in industry, and our dedicated Careers and Employability Team are ready to support you while you study and post-graduation.\u00a0"
    },
    {
        "id": "a2bc9872e97f37452120f8adac1773c8",
        "university_name": "University of Bath",
        "location": "University of Bath, Claverton Down campus, Bath, Bath &amp; North East Somerset, BA2 7AY",
        "official_website": "http://www.bath.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01225 385985",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Claverton Down, Bath, BA2 7AY"
            },
            {
                "location_name": "Online Study",
                "location_address": "Your home town or city, University of Bath, BA2 7AY"
            },
            {
                "location_name": "University of Bath",
                "location_address": "Claverton Down, Bath, Banes, Bath, BA2 7AY"
            },
            {
                "location_name": "University of Plymouth",
                "location_address": "University of Plymouth, Plymouth, PL4 8AA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/295cc6a3-2774-d00f-93ee-8a9bf8ae7d0b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/29619-0003_banner_2.jpg",
        "about_us": "At Bath, we are known for excellence in teaching and research; our welcoming community; and for providing students with outstanding preparation for the workplace.We offer undergraduate and postgraduate courses in science, engineering , humanities and social sciences, and management. Our placement scheme helps ensure our highly sought-after graduates are well equipped to meet the evolving needs of employers.\u00a0Our vibrant campus, and location right on the edge of the city of Bath, offer sporting, social and cultural opportunities. We are one of the safest campus sites in the UK - we were one of the first universities in the country to win a national, police-approved security award.\u00a0With over 180 groups run by the Students\u2019 Union, a centre of the Arts and our Sports Training Village, there is plenty to get involved with outside of your studies.\u00a0\u00a0Join our friendly, high-achieving community to gain the knowledge, skills, and experience you\u2019ll need to thrive in a world of global networks.\u00a0"
    },
    {
        "id": "f14c025da4ee2932f9edd78a437aadb6",
        "university_name": "Bedford College Group",
        "location": "Cauldwell Street, Bedford, Bedfordshire, MK42 9AH",
        "official_website": "https://bedfordcollegegroup.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "0345 658 8990",
        "course_locations": [
            {
                "location_name": "Tresham College - Kettering Campus",
                "location_address": "Windmill Avenue, Kettering, NN15 6ER"
            },
            {
                "location_name": "Shuttleworth College (part of Bedford College)",
                "location_address": "Old Warden Park, Biggleswade, SG18 9DX"
            },
            {
                "location_name": "Bedford College",
                "location_address": "Cauldwell Street, Bedford, MK42 9AH"
            },
            {
                "location_name": "Central Bedfordshire College",
                "location_address": "Main Campus, Dunstable, LU5 4HG"
            },
            {
                "location_name": "Tresham College - Corby Campus",
                "location_address": "Oakley Road, Corby, NN17 1NE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a6fa6f7f-0bbb-8040-17b6-2254211691b5",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_web_banner_1920_420.jpg",
        "about_us": "\u00a0The Bedford College Group offers a wide range of degree level courses which can be studied across our campuses in Bedfordshire, Northamptonshire and the South East Midlands. We are the largest education provider across these regions and our breadth of talented and expert tutors means you will get to benefit from only the best that each industry has to offer. \u00a0Our strong partnerships with the University of Bedfordshire, the University of Huddersfield and the University of Northampton, means you can study with us, in the knowledge that accredited qualifications will come from renowned universities. \u00a0Not only this, but those who choose to study with us benefit from great facilities, small class sizes and individual support. All this and more means the experience we can offer, puts you on an amazing footing to succeed with whatever your ambitions may be.\u00a0 "
    },
    {
        "id": "ac96c9e87de0d2a74f6246c5ede68082",
        "university_name": "University of Bedfordshire",
        "location": "University Square, Luton, Bedfordshire, LU1 3JU",
        "official_website": "https://www.beds.ac.uk",
        "contact_email": "study@beds.ac.uk",
        "contact_phone": "01234 400400",
        "course_locations": [
            {
                "location_name": "Luton Campus",
                "location_address": "Park Square, Luton, LU1 3JU"
            },
            {
                "location_name": "Aylesbury Campus",
                "location_address": "The University of Bedfordshire Aylesbury Campus, Stoke Mandeville Hospital, Mandeville Road, Aylesbury, HP21 8AL"
            },
            {
                "location_name": "Shuttleworth College",
                "location_address": "Old Warden Park, Biggleswade, SG18 9DX"
            },
            {
                "location_name": "Emil Dale Academy",
                "location_address": "60 Wilbury Way, Hitchin, SG4 0TP"
            },
            {
                "location_name": "Bedford Campus",
                "location_address": "Polhill Avenue, Bedford, MK41 9EA"
            },
            {
                "location_name": "Putteridge Bury campus",
                "location_address": "Hitchin Road, Luton, LU2 8LE"
            },
            {
                "location_name": "Bedford College",
                "location_address": "Cauldwell Street, Bedford, MK42 9AH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3261960f-0635-6329-ccde-717b18f97592",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/outside_luton_campus_1430_1.jpg",
        "about_us": "Here at the University of Bedfordshire, we\u2019re committed to your success. As an internationally recognised and award-winning institution with an education heritage going back more than 100 years, we\u2019re passionate about delivering career-powered education that acts as a springboard to the future you\u2019ve set your sights on.\u00a0Our practice-driven courses are designed with your employability in mind. They offer a variety of initiatives to prepare you for the modern workplace, including professional accreditation; internships and placements; industry-standard facilities; and hands-on experience."
    },
    {
        "id": "fd63efd598087bcff8104b44fd1a0870",
        "university_name": "Belfast Bible College",
        "location": "Glenburn Road South, Dunmurry, Belfast, BT17 9JP",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Belfast Bible College",
                "location_address": "Glenburn Road South, Dunmurry, Belfast, BT17 9JP"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a537a0df-123e-cc1e-9e09-26800c3e814e",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "5daaefea4a4c02dddede6a88900fcdff",
        "university_name": "BIMM University",
        "location": "38-42 Brunswick Street West, Hove, Hove, East Sussex, BN3 1EL",
        "official_website": "https://www.bimm.university/",
        "contact_email": "admissions@bimm.ac.uk",
        "contact_phone": "01273 840 346",
        "course_locations": [
            {
                "location_name": "MetFilm School Birmingham",
                "location_address": "Little Ann St, Deritend, Birmingham, B5 5QF"
            },
            {
                "location_name": "BIMM Music Institute Manchester",
                "location_address": "BIMM Manchester, 8 Great Marlborough Street, Manchester, M1 5NN"
            },
            {
                "location_name": "Screen and Film School Manchester",
                "location_address": "Bank Chambers, Faulkner Street, Manchester, M1 4EH"
            },
            {
                "location_name": "Performers College Essex",
                "location_address": "Southend Road, Corringham, SS17 8JT"
            },
            {
                "location_name": "MetStudios Leeds",
                "location_address": "Prime Studios, 94-96 Kirkstall Road, Leeds, LS3 1HD"
            },
            {
                "location_name": "Screen and Film School Brighton",
                "location_address": "48a Old Steine, Brighton and Hove, BN1 1NH"
            },
            {
                "location_name": "Institute for Contemporary Theatre Brighton",
                "location_address": "Vantage Point, New England Street, Brighton, BN1 4GW"
            },
            {
                "location_name": "MetFilm School London",
                "location_address": "Ealing Studios, London, W5 5EP"
            },
            {
                "location_name": "Performers College Brighton",
                "location_address": "55 North Street, Portslade, Brighton, BN41 1DH"
            },
            {
                "location_name": "MetFilm School Manchester",
                "location_address": "Unit 6 and 8, Left Bank, New Quay Street, Manchester, M3 3AN"
            },
            {
                "location_name": "MetStudios Manchester",
                "location_address": "Unit 6 and 8, Left Bank, New Quay Street, Manchester, M3 3AN"
            },
            {
                "location_name": "BIMM Music Institute Bristol",
                "location_address": "St James House, Moon Street, St Pauls, Bristol, BS2 8QY"
            },
            {
                "location_name": "Screen and Film School Birmingham",
                "location_address": "93-96 Floodgate Street, Birmingham, B5 5SR"
            },
            {
                "location_name": "BIMM Music Institute London",
                "location_address": "Barclays House, Effie Road, Fulham, London, SW6 1EN"
            },
            {
                "location_name": "BIMM Music Institute Brighton",
                "location_address": "38-42 Brunswick Street West, Brighton and Hove, BN3 1EL"
            },
            {
                "location_name": "Performers College Birmingham",
                "location_address": "Little Ann Street, Birmingham, B5 5SR"
            },
            {
                "location_name": "MetStudios Bristol",
                "location_address": "St James House, Moon Street, St Pauls, Bristol, BS2 8QY"
            },
            {
                "location_name": "MetStudios London",
                "location_address": "Ealing Studios, London, W5 5EP"
            },
            {
                "location_name": "MetFilm School Brighton",
                "location_address": "48A Old Steine, Brighton and Hove, BN1 1NH"
            },
            {
                "location_name": "Institute for Contemporary Theatre Manchester",
                "location_address": "The Dancehouse, 10 Oxford Road, Manchester, M1 5QA"
            },
            {
                "location_name": "MetFilm School Leeds",
                "location_address": "Prime Studios, 94-96 Kirkstall Road, Leeds, LS3 1HD"
            },
            {
                "location_name": "Performers College Manchester",
                "location_address": "10 Oxford Road, Manchester, M1 5QA"
            },
            {
                "location_name": "MetStudios Brighton",
                "location_address": "48A Old Steine, Brighton and Hove, BN1 1NH"
            },
            {
                "location_name": "BIMM University Berlin",
                "location_address": "BIMM Institute Berlin, House of Music, Revaler Str. 99, Berlin, 10245"
            },
            {
                "location_name": "BIMM Music Institute Birmingham",
                "location_address": "93-96 Floodgate Street, Birmingham, B5 5SR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5871db48-f67d-c4c7-42e5-b76cc723169a",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/bimm_university_film_students.jpg",
        "about_us": "At BIMM University, we provide an extensive range of courses in modern music, performing arts, filmmaking, and creative technology to over 10,000 students across nine campuses in\u00a0the UK, Ireland, and Germany. We have a long-standing commitment\u00a0to providing the highest quality in creative industries education, allowing\u00a0students to maximise their career potential.BIMM University comprises four academic schools: BIMM Music Institute, Performers College, MetFilm School, and MetStudios."
    },
    {
        "id": "6c5b46488a301a964270ae053eb3f708",
        "university_name": "Birkbeck, University of London",
        "location": "Malet Street, Bloomsbury, London, WC1E 7HX",
        "official_website": "https://www.bbk.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "020 3907 0700",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Malet Street, Bloomsbury, London, WC1E 7HX"
            },
            {
                "location_name": "Main Site",
                "location_address": "Malet Street, London, WC1E 7HX"
            },
            {
                "location_name": "Le Cordon Bleu",
                "location_address": "15 Bloomsbury Square, London, WC1A 2LS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a5e9ffc8-b187-f126-52ed-3d89a146b445",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/new_ucas_pic-_students_outside.jpg",
        "about_us": "A part of the prestigious University of London, Birkbeck is the only UK campus-based university that specialises in learning that fits around our students\u2019 busy lives. By teaching in the evening; during the day; and online, it enables its students to work and build their career or pursue other interests whilst they study.\u00a0When you finish your studies with us, you will gain a prestigious University of London degree.\u00a0A world class teaching and research institution with a strong and vibrant learning community, Birkbeck is located in the heart of the capital, a few minutes\u2019 walk from the West End; Bloomsbury is a lively, student-centred area. Everything London has to offer is on our doorstep. For those who choose to study in the evening, you have your days free to work, intern, volunteer or study, which can help you get ahead of other graduates in a competitive job market.\u00a0\u00a0\u00a0"
    },
    {
        "id": "9906bf9d2f983a9532bd6094b5f342d2",
        "university_name": "Birmingham City University",
        "location": "University House, 15 Bartholomew Row, Birmingham, B5 5JU",
        "official_website": "https://www.bcu.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "0121 331 6295",
        "course_locations": [
            {
                "location_name": "Vittoria Street",
                "location_address": "82-86 Vittoria Street, Birmingham, B1 3PA"
            },
            {
                "location_name": "Birmingham School of Art, Margaret Street",
                "location_address": "Margaret Street, Birmingham, B3 3BX"
            },
            {
                "location_name": "Main Site",
                "location_address": "University House, 15 Bartholomew Row, Birmingham, B5 5JU"
            },
            {
                "location_name": "School of Acting, Bournville",
                "location_address": "Ruskin Hall, Linden Road, Bournville, Birmingham, B30 1JX"
            },
            {
                "location_name": "School of Jewellery",
                "location_address": "Vittoria Street, Birmingham, B1 3PA"
            },
            {
                "location_name": "Millennium Point Campus",
                "location_address": "1 Curzon Street, Birmingham, B4 7XG"
            },
            {
                "location_name": "University House / City South Campus",
                "location_address": "15 Bartholomew Row, Birmingham, B5 5JU"
            },
            {
                "location_name": "University House",
                "location_address": "15 Bartholomew Row, Birmingham, B5 5JU"
            },
            {
                "location_name": "City South Campus, Edgbaston",
                "location_address": "Westbourne Road, Birmingham, B15 3TN"
            },
            {
                "location_name": "Margaret Street",
                "location_address": "Margaret Street, Birmingham, B3 3BX"
            },
            {
                "location_name": "National Institute of Conductive Ed",
                "location_address": "Cannon Hill House, 14 Russell Road, Birmingham, B13 8RD"
            },
            {
                "location_name": "South and City College Birmingham",
                "location_address": "High Street, Deritend, Deritend, Birmingham, B5 5SU"
            },
            {
                "location_name": "BMET (James Watt)",
                "location_address": "James Watt Campus, Aldridge Road, Birmingham, B44 8NE"
            },
            {
                "location_name": "Aston Villa Foundation",
                "location_address": "Aston Villa Foundation, Villa Park, Birmingham, B6 6HE"
            },
            {
                "location_name": "BMet (Matt Boulton)",
                "location_address": "Matthew Boulton College, Jennens Road, Birmingham, B4 7PS"
            },
            {
                "location_name": "Curzon Building Campus",
                "location_address": "Cardigan Street, Birmingham, B4 7BD"
            },
            {
                "location_name": "City Centre Campuses",
                "location_address": "1 Curzon Street, Birmingham, B4 7XG"
            },
            {
                "location_name": "Parkside Building Campus",
                "location_address": "Parkside Building, Cardigan Street, Birmingham, B4 7BD"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/24d90a06-9489-417d-0d89-7474079c1477",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/curzon_and_parkside_buildings_-_small.jpg",
        "about_us": "Birmingham City University is dedicated to transforming the prospects of our learners, inspiring them to become makers and doers. We are an integral part of the city in which we are based, with courses shaped by the needs of industry and partnerships with some of the country's leading employers. The University focuses on practice-led, knowledge-based learning, providing access to cutting-edge facilities and real-world experience. We benefit from around 50 professional accreditations and have a well-established reputation for delivering in-company training and development programmes.Our teaching staff come from and maintain their links with industry, ensuring you are given an insight into the latest thinking. We are constantly looking for new ways to enhance the learning experience, with inspiring visiting lecturers and learning environments that are designed to replicate the workplaces you aim to enter.\u00a0"
    },
    {
        "id": "fe9f199735db0fff63e5ebc7f3149790",
        "university_name": "BMet (Birmingham Metropolitan College)",
        "location": "Jennens Road, Birmingham City Centre, Birmingham, West Midlands, B4 7PS",
        "official_website": "https://www.bmetc.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "0121 4464545",
        "course_locations": [
            {
                "location_name": "James Watt College",
                "location_address": "Aldridge Road, Great Barr, Birmingham, B44 8NE"
            },
            {
                "location_name": "Matthew Boulton College",
                "location_address": "Jennens Road, Birmingham, B74 2NW"
            },
            {
                "location_name": "Sutton Coldfield College",
                "location_address": "Lichfield Road, Sutton Coldfield, B74 2NW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/fa8f4026-3a70-e872-ac01-17e74ad46f33",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/image_-_bmet_college_07-07-20.jpg",
        "about_us": "N/A"
    },
    {
        "id": "883a7f5657b0c4a885a848d46f8e0207",
        "university_name": "Birmingham Newman University",
        "location": "Genners Lane, Bartley Green, Birmingham, West Midlands, B32 3NT",
        "official_website": "http://www.newman.ac.uk/",
        "contact_email": "admissions@newman.ac.uk",
        "contact_phone": "0121 387 4506",
        "course_locations": [
            {
                "location_name": "Birmingham Newman University",
                "location_address": "Genners Lane, Bartley Green, Birmingham, B32 3NT"
            },
            {
                "location_name": "Main Site",
                "location_address": "Genners Lane, Bartley Green, Birmingham, B32 3NT"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/11ac4ef6-cdf2-0e8f-fa19-6f8ad173178e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/newman_birmingham_new_logo_1.png",
        "about_us": "We\u2019re located in a quiet residential area only a few miles from Birmingham City Centre, which creates a tranquil and focused learning environment for students. Birmingham City Centre is just a short drive away and on excellent bus routes.Being founded as recently as 1968, the buildings are modern and purpose-built. The campus is arranged around a series of inner quadrangles of lawns and trees. Halls of residence provide bedrooms for over 200 students, conveniently adjacent to the teaching areas and well-stocked library. The Students\u2019 Union provides a warm welcome at the heart of the campus.In February 2023, the University officially opened its new \u00a33 million School of Nursing and Allied Health. It includes state-of-the art facilities including a clinical simulation suite and an immersive lab to recreate the experience of working in a busy health care environment.\u00a0"
    },
    {
        "id": "f0f1eb9404c9134f63ca5a010aa41aa6",
        "university_name": "University of Birmingham",
        "location": "Edgbaston, Birmingham, B15 2TT",
        "official_website": "https://www.birmingham.ac.uk/study",
        "contact_email": "N/A",
        "contact_phone": "0121 4143344",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Academic and Student Division, Birmingham, B15 2TT"
            },
            {
                "location_name": "Main Site",
                "location_address": "Edgbaston, Birmingham, B15 2TT"
            },
            {
                "location_name": "Singapore Institute of Management",
                "location_address": "461 Clementi Road, Singapore, 599491"
            },
            {
                "location_name": "Edgbaston Campus",
                "location_address": "Edgbaston, Birmingham, B15 2TU"
            },
            {
                "location_name": "Shakespeare Institute",
                "location_address": "Mason Croft, Church Street, Stratford-upon-Avon, CV37 6HP"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/1d61dc76-ba6e-c6af-6cc0-879c71b5db5c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/bham_3_lk.jpg",
        "about_us": "Forge your future at our green and spacious campus university just minutes from the hustle and bustle of the UK's lively and diverse second city."
    },
    {
        "id": "e99800e4eb24364cbcd70bd8a829bba8",
        "university_name": "University Centre Bishop Burton",
        "location": "University Centre Bishop Burton, York Road, Bishop Burton, Beverley, East Yorkshire, HU17 8QG",
        "official_website": "https://www.bishopburton.ac.uk",
        "contact_email": "enquiries@bishopburton.ac.uk",
        "contact_phone": "01964 553 028",
        "course_locations": [
            {
                "location_name": "Bishop Burton",
                "location_address": "York Rd, Bishop Burton, Beverley, HU17 8QG"
            },
            {
                "location_name": "Riseholme",
                "location_address": "Riseholme College, Showground Campus, Horncastle Lane, North Carlton, LN1 2ZR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/2519869b-191a-7c05-ff70-779273e95aa6",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_0.jpg",
        "about_us": "University Centre Bishop Burton (UCBB) provides specialist, technical\u00a0education to ensure that you succeed within your chosen career.UCBB started life offering agricultural\u00a0education in 1954. Since then, it has grown\u00a0to include a wider range of specialist\u00a0land-based subjects, including Animal\u00a0Sciences and Equine, plus complementary\u00a0subjects such as Sport and Floristry.Each of our programmes taps into the\u00a0wonderful resources that our land offers\u00a0us, as well as utilising outstanding\u00a0purpose-built facilities like our Centre for\u00a0Precision Agriculture and our Centre for\u00a0Sport and Exercise Science."
    },
    {
        "id": "eb75b86a755de8b2b0793ef3d1830fbe",
        "university_name": "Bishop Grosseteste University",
        "location": "Longdales Road, Lincoln, Lincolnshire, LN1 3DY",
        "official_website": "https://www.bgu.ac.uk",
        "contact_email": "courseenquiries@bishopg.ac.uk",
        "contact_phone": "01522 583658",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Bishop Grosseteste University, Longdales Road, Lincoln, LN1 3DY"
            },
            {
                "location_name": "Grantham College",
                "location_address": "Grantham College, Stonebridge Road, Grantham, NG31 9AP"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a734fa1a-84f9-abee-dbb0-a7d494132ee5",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/bgu_photos_129.jpg",
        "about_us": "Widely recognised for its consistently high employability and student satisfaction rates, Bishop Grosseteste University aims to deliver transformative education, professional experiences and partnerships that build the knowledge, ability and confidence needed to make a meaningful difference.All this, paired with a rich heritage of education stretching back over 160 years, has earned BGU many accolades and an extremely positive reputation. In fact, students give BGU top marks nationally for support, resources, accommodation, feedback, opportunities, and campus facilities. Bishop Grosseteste University's purpose is to support individuals to realise their ambitions and create positive change in their communities and beyond."
    },
    {
        "id": "86a0ced829e53c448c37b2668588921b",
        "university_name": "Blackburn College",
        "location": "Feilden St, Blackburn, Lancashire, BB2 1LH",
        "official_website": "http://www.blackburn.ac.uk/",
        "contact_email": "admissions@blackburn.ac.uk",
        "contact_phone": "01254 292929",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Feilden Street, Blackburn, BB2 1LH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/c2fe0703-f6ce-c3ae-40b8-426490977ef0",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_graduation_cover_photo.jpg",
        "about_us": "Study a degree from Lancaster University, the University of Central Lancashire or the University of South Wales, all here in Blackburn. Discover your path to success with our flexible range of courses and degrees.\u00a0Staying local has many benefits including saving you thousands of pounds and leaving you to graduate with less debt.There are direct access to bus routes and a train station within a 10 minute walk of our University Centre. There's also a bus stop on campus making it an easy and accessible commute for your studying.\u00a0We know life has its challenges. That's why we have a range of services to support you. Including:- Academic coaches- Careers Advise- Disability Advice Service\u00a0"
    },
    {
        "id": "a23b38f8e2f1b3c8f2444a7814c14446",
        "university_name": "Blackpool and the Fylde College",
        "location": "Bispham Campus, Ashfield Road, Bispham, Blackpool, FY2 0HB",
        "official_website": "https://www.blackpool.ac.uk",
        "contact_email": "admissions@blackpool.ac.uk",
        "contact_phone": "01253 504322",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Ashfield Road, Blackpool, FY2 0HB"
            },
            {
                "location_name": "University Centre",
                "location_address": "Park Road, Blackpool, FY1 4ES"
            },
            {
                "location_name": "Nautical Campus",
                "location_address": "Broadwater, Fleetwood, FY7 8JZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/15d7c39f-a92b-6a33-670e-36d0ed79bc43",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/banner2_0.jpg",
        "about_us": "At Blackpool and The Fylde College, we offer degree programmes awarded by Lancaster University, a UK top 15 university. With 9 out of 10 B&amp;FC graduates in work or further study within one year[1][1]\u00a0and 40% of our graduates in highly skilled employment earning \u00a333k or more, you can be sure that B&amp;FC will get you where you want to be.\u00a0As a student at B&amp;FC, you will benefit from a wealth of industry experience via our industry specialist tutors, first-class industry-standard facilities and a strong support network that allows you to personalise your learning journey.\u00a0At a time of skills shortages in the UK, we work closely with industry to co-create technical and professional education and training, which develop the skills, knowledge, and attributes that employers need to drive their businesses forward and provides you with rich employment opportunities. There has never been a better time to progress your career with B&amp;FC.\u00a0[2][1]\u00a0Graduate Outcomes (LEO) 2018/19\u00a0"
    },
    {
        "id": "66b92c85a339116d379834c4b5e4c750",
        "university_name": "University of Bolton",
        "location": "Deane Road, Bolton, Greater Manchester, BL3 5AB",
        "official_website": "http://www.bolton.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01204 528851",
        "course_locations": [
            {
                "location_name": "Shockout Arts, Manchester",
                "location_address": "Valo Building, Brian Statham Way, Old Trafford, Manchester, M16 0PU"
            },
            {
                "location_name": "The Growth Company, Manchester",
                "location_address": "Lee House, 90 Great Bridgewater Street, Manchester, M1 5JW"
            },
            {
                "location_name": "Main Site",
                "location_address": "Deane Road, Bolton, BL3 5AB"
            },
            {
                "location_name": "Petroc College",
                "location_address": "Old Sticklepath Hill, Barnstaple, EX31 2BQ"
            },
            {
                "location_name": "University of Bolton Main Site, Greater Manchester",
                "location_address": "Deane Road, Bolton, BL3 5AB"
            },
            {
                "location_name": "Bolton College, Greater Manchester",
                "location_address": "Deane Road, Bolton, BL3 5BG"
            },
            {
                "location_name": "Bradford College",
                "location_address": "Great Horton Road, Bradford, BD7 1AY"
            },
            {
                "location_name": "University of Bolton Academic Centre London",
                "location_address": "153 Great Titchfield Street, Fitzrovia, London, W1W 5BD"
            },
            {
                "location_name": "Salford City College, Greater Manchester",
                "location_address": "Eccles Sixth Form Centre, Chatsworth Road, Eccles, M30 9BP"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/88e1a042-03f5-c63b-5bf8-d78622277c4e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/university_of_bolton_senate_house.jpg",
        "about_us": "An award-winning university that puts you firstThe University of Bolton is a dynamic, vibrant and friendly university that offers a student experience like no other. Our goal is to help you reach your potential, while developing important life skills in a positive and supportive environment.We have been voted No.1 in the North West for Student Satisfaction since 2019 (Complete University Guide, 2019-2023).\u00a0"
    },
    {
        "id": "5a31c4b28f16d7c4cb33249edeb1fa91",
        "university_name": "Boomsatsuma",
        "location": "Tobacco Factory, Southville, Bristol, BS3 1FT",
        "official_website": "https://www.boomsatsuma.com/",
        "contact_email": "education@boomsatsuma.com",
        "contact_phone": "0117 942 8429",
        "course_locations": [
            {
                "location_name": "Bristol - Tobacco Factory",
                "location_address": "Raleigh Rd, Southville, Bristol, BS3 1TF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/96f866ad-6b50-45b2-a353-f957dcb8659c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas-banner_0.jpg",
        "about_us": "boomsatsuma is a proudly independent educator delivering bespoke, industry-connected education for students aged 16 and up. Our graduates leave with hands-on experience in the creative industries, gained through compassionate, real-world learning within Bristol's vibrant creative hub\u2014empowering them to seize real-world opportunities."
    },
    {
        "id": "6135198481df03cd9a5d868ffeb0570a",
        "university_name": "Boston College",
        "location": "Skirbeck Road, Boston, Lincolnshire, PE21 6JF",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Boston Campus (Rochford)",
                "location_address": "Skirbeck Road, Boston, Lincolnshire, PE21 6JF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/61cf12e4-e628-4007-befb-622e57734608",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/bc23-169_1.jpg",
        "about_us": "N/A"
    },
    {
        "id": "3146e8ba3147130f6a0727912e6c629e",
        "university_name": "Bournemouth and Poole College",
        "location": "North Road, Poole, Dorset, BH14 0LS",
        "official_website": "https://www.thecollege.co.uk/",
        "contact_email": "highereducation@bpc.ac.uk",
        "contact_phone": "01202205205",
        "course_locations": [
            {
                "location_name": "Poole Campus",
                "location_address": "North Road, Poole, BH14 0LS"
            },
            {
                "location_name": "Bournemouth Campus",
                "location_address": "Meyrick Road, The Lansdowne, Bournemouth, BH1 3JJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d4b5280f-309b-af20-eecd-95b4009adf4c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_1.jpg",
        "about_us": "N/A"
    },
    {
        "id": "dcd375a55d029e5bd145d91c141a5b9a",
        "university_name": "Bournemouth University",
        "location": "Talbot Campus, Fern Barrow, Fern Barrow, Poole, Dorset, BH12 5BB",
        "official_website": "http://www.bournemouth.ac.uk/",
        "contact_email": "futurestudents@bournemouth.ac.uk",
        "contact_phone": "+44 (0)1202 961916",
        "course_locations": [
            {
                "location_name": "Yeovil Campus",
                "location_address": "91 Preston Road, Yeovil, BA20 2DN"
            },
            {
                "location_name": "Wiltshire College - Salisbury",
                "location_address": "Southampton Road, Salisbury, SP1 2LW"
            },
            {
                "location_name": "Main Site",
                "location_address": "Talbot Campus, Fern Barrow, Poole, BH12 5BB"
            },
            {
                "location_name": "Lansdowne Campus",
                "location_address": "Lansdowne, Christchurch Road, Bournemouth, BH1 3JJ"
            },
            {
                "location_name": "Portsmouth: Lakeside North Harbour Campus",
                "location_address": "Unit 103 - 1000 Lakeside North Harbour, Western Rd, Cosham, Portsmouth, PO6 3EN"
            },
            {
                "location_name": "GTA University Centre",
                "location_address": "St Peter Port House, Guernsey, GY1 2PT"
            },
            {
                "location_name": "Talbot Campus",
                "location_address": "Talbot Campus, Fern Barrow, Poole, BH12 5BB"
            },
            {
                "location_name": "Hannah",
                "location_address": "N/A"
            },
            {
                "location_name": "Anna",
                "location_address": "N/A"
            },
            {
                "location_name": "Andrew",
                "location_address": "N/A"
            },
            {
                "location_name": "Alexia",
                "location_address": "N/A"
            },
            {
                "location_name": "Abigail",
                "location_address": "N/A"
            },
            {
                "location_name": "Harvey",
                "location_address": "N/A"
            },
            {
                "location_name": "Folakemi",
                "location_address": "N/A"
            },
            {
                "location_name": "Emily ",
                "location_address": "N/A"
            },
            {
                "location_name": "Charlotte",
                "location_address": "N/A"
            },
            {
                "location_name": "Ella",
                "location_address": "N/A"
            },
            {
                "location_name": "Amelia",
                "location_address": "N/A"
            },
            {
                "location_name": "Faith",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/22e93c90-ca5f-983e-8c2a-f215d4e544d3",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner.jpg",
        "about_us": "At Bournemouth University, our courses are crafted alongside leading industry experts, passionate about their subjects and your learning. Our approach of integrating our globally recognised research and industry practice means our degrees are designed to give you the skills and knowledge you need to pursue your passion.Our motto is \u2018To learn is to be changed.\u2019 We are renowned for our research and teaching excellence in a range of subjects, including, forensic archaeology, business, health and as a Centre for Excellence in Media Practice. We recognise your potential and will support you and give you the opportunity to gain real-world experience through placements, volunteering, and collaborative projects. And with over 100 clubs, societies and sports \u2013 and the opportunity to be part of our global BU community studying by the sea \u2013 that\u2019s the difference of a degree from Bournemouth University. Discover your degree of difference."
    },
    {
        "id": "bdb2c51442f5c6dd8be673424d86aa68",
        "university_name": "BPP University",
        "location": "BPP House, Aldine Place, 142-144 Uxbridge Road, London, W12 8AA",
        "official_website": "http://www.bpp.com/",
        "contact_email": "N/A",
        "contact_phone": "03300 603 100",
        "course_locations": [
            {
                "location_name": "Rotherham Doncaster and South Humber NHS Foundation Trust",
                "location_address": "Almond Tree Court, Tickhill Road Hospital, Tickhill Road, Doncaster, DN4 8QN"
            },
            {
                "location_name": "London West (Shepherd's Bush)",
                "location_address": "Aldine Place, 142-144 Uxbridge Road, London, W12 8AW"
            },
            {
                "location_name": "Distance Learning",
                "location_address": "Distance Learning, Distance Learning (HQ is Coventry)"
            },
            {
                "location_name": "Bristol",
                "location_address": "BPP House, Bristol, BS1 4QY"
            },
            {
                "location_name": "Leeds",
                "location_address": "Whitehall 2, Leeds, LS1 4HG"
            },
            {
                "location_name": "London Central (Holborn)",
                "location_address": "68-70 Red Lion Street, London, WC1R 4NY"
            },
            {
                "location_name": "London South (Waterloo)",
                "location_address": "137 Stamford Street, London, SE1 9NN"
            },
            {
                "location_name": "London East (Portsoken)",
                "location_address": "1 Portsoken Street, London, E1 8PH"
            },
            {
                "location_name": "Birmingham",
                "location_address": "32-34 Colmore Circus, Birmingham, B4 6BN"
            },
            {
                "location_name": "Cambridge",
                "location_address": "Lion House, Cambridge, CB2 3NA"
            },
            {
                "location_name": "Manchester",
                "location_address": "St James'S Building, 79 Oxford Street, Manchester, M1 6FQ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/95d4242b-2a05-e68c-6358-eb576b8912c8",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/bpp_around_aldgate_img_2722.jpg",
        "about_us": "At BPP University, preparing you for your future career is our number one priority. Our teaching focuses on developing the skills and knowledge that employers look for, which is why we are the preferred choice for leading global businesses.As a private university, we are not listed in academic lead tables. Instead, we measure our employability credentials, providing a high standard of academic teaching whilst developing the professional skills and behaviours you need.\u00a0This approach works: BPP University ranks second against the 24 leading Russell Group universities in a Graduates Outcomes survey for the number of UK postgraduate students in highly skilled employment. In fact, 98%* of BPP University graduates in employment were in highly skilled occupations 15 months after graduating.*Based on The Graduate Outcomes survey of 2019/20. Highly Skilled Employment is defined by the Standard Occupational Classification (SOC) which is maintained by the Office for National Statistics"
    },
    {
        "id": "af081b7131d6492417fb52992caf08ec",
        "university_name": "Bradford College",
        "location": "Great Horton Road, Bradford, West Yorkshire, BD7 1AY",
        "official_website": "https://www.bradfordcollege.ac.uk",
        "contact_email": "hello@bradfordcollege.ac.uk",
        "contact_phone": "01274 088 088",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Great Horton Road, Bradford, BD7 1AY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/90e9d147-3c58-679c-424d-369f3414728e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/readycampaign_videoscreenshots-3.png",
        "about_us": "At Bradford College we work together to transform lives and help you reach your potential. We support our learners, helping them aim high and achieve their goals. We offer a wide range of courses including undergraduate, postgraduate and professional qualifications, meaning we have something for everyone.\u00a0We personalise learning for each student by matching your qualifications, experience, and enthusiasm to the right course for your career aspirations. Talk to us about your aspirations and we will help turn your passion into a profession.\u00a0You can benefit from state of the art facilities and resources, as well as teachers who are experts in their fields.\u00a0"
    },
    {
        "id": "80331f97b102253b81adeca33ede8bfc",
        "university_name": "Bridgend College",
        "location": "Cowbridge Road,, Bridgend, Mid Glamorgan, CF31 3DF",
        "official_website": "https://www.bridgend.ac.uk",
        "contact_email": "hello@bridgend.ac.uk",
        "contact_phone": "01656 302251",
        "course_locations": [
            {
                "location_name": "Main site",
                "location_address": "Cowbridge Road, Bridgend, CF31 3DF"
            },
            {
                "location_name": "Pencoed Campus",
                "location_address": "Pencoed, Bridgend, CF35 5LG"
            },
            {
                "location_name": "Queens Road",
                "location_address": "Queens Road South, Bridgend, CF31 3UT"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d849a737-e408-9564-a8da-1e5352d03cae",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas-webpage-banner.png",
        "about_us": "N/A"
    },
    {
        "id": "25e2e8fae71c3a285cf7f4c75be5a94b",
        "university_name": "University Centre Somerset - Bridgwater &amp; Taunton College",
        "location": "Taunton Campus, Wellington Road, Taunton, Somerset, TA1 5AX",
        "official_website": "https://www.somerset.ac.uk",
        "contact_email": "UCSAdmissions@btc.ac.uk",
        "contact_phone": "01823 366366",
        "course_locations": [
            {
                "location_name": "Cannington Campus",
                "location_address": "Rodway Hill, Cannington, Bridgwater, TA5 2LS"
            },
            {
                "location_name": "Taunton Campus",
                "location_address": "Wellington Road, Taunton, TA1 5AX"
            },
            {
                "location_name": "Bridgwater Campus",
                "location_address": "Bath Road, Bridgwater, TA6 4PZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/93e3e3b3-7ec7-a435-a3f3-cbd1e429b5dc",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/graduation_1920x420pxhr_262_21oct2022_moment_photography.jpg",
        "about_us": "At University Centre Somerset, our mission is to transform the lives of our students and our communities by delivering outstanding and aspirational teaching, learning and skills development.We specialise in vocational degree-level qualifications, grounded in the real world and combined with academic rigour so that our students can achieve the highest standards set for their chosen industry sector.\u00a0 Our aim is for our\u00a0students to begin their careers with confidence, knowing that they have been trained in the most advanced business methods and technology.We offer small class sizes and tutors with a real dedication to the student experience. As a result, you\u2019ll have loads of contact with teaching staff, and ample opportunity for discussion and deliberation with your peers. What\u2019s more, our inclusive and welcoming atmosphere gives everyone an opportunity to flourish in a supportive environment where diversity is celebrated."
    },
    {
        "id": "c1f1462ba5452e27e94c0b2dd5beacbc",
        "university_name": "Brighton and Sussex Medical School",
        "location": "University Of Brighton, The Watson Building, Brighton, East Sussex, BN1 9PH",
        "official_website": "https://www.bsms.ac.uk/index.aspx",
        "contact_email": "outreach@bsms.ac.uk",
        "contact_phone": "01273 606 755",
        "course_locations": [
            {
                "location_name": "Brighton and Sussex Med Sch",
                "location_address": "Watson Building, University of Brighton, Falmer, BN1 9PH"
            },
            {
                "location_name": "Main Site",
                "location_address": "Medical Teaching Building, University of Sussex, Falmer, BN1 9PX"
            },
            {
                "location_name": "Main Site",
                "location_address": "University of Brighton, Falmer, Brighton, BN1 9PH"
            },
            {
                "location_name": "Medical Teaching Building",
                "location_address": "University of Sussex, Falmer, BN1 9PX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/0bdd99ec-94bd-bc58-47d4-b550b55aa1b2",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ce632933-2126-4690-a0e9-5e94ce76d2fa_1_105_c.jpeg",
        "about_us": "Brighton and Sussex Medical School (BSMS) is a modern, inclusive and innovative medical school located on the south coast between the sea and the South Downs. A partnership between the two universities, the medical school is based across two campuses and benefits from its partnership with local NHS providers through extended clinical and learning facilities. BSMS has a close-knit community and consistently ranks among the top 5 medical schools in the National Student Survey. BSMS\u2019s system-based curriculum offers a range of teaching and learning methods."
    },
    {
        "id": "d17cd50f2afa8698d7ddf4081d4b9b27",
        "university_name": "City of Bristol College",
        "location": "C/O Reception, College Green Centre,St George's Road, St George's Road, Bristol, Bristol, BS1 5UA",
        "official_website": "https://www.cityofbristol.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "0117 9072731",
        "course_locations": [
            {
                "location_name": "College Green",
                "location_address": "College Green Centre, St. George's Road, Bristol, BS1 5UA"
            },
            {
                "location_name": "Main Site",
                "location_address": "College Green Centre, St George's Road, Bristol, BS1 5UA"
            },
            {
                "location_name": "Ashley Down Centre",
                "location_address": "Ashley Down Road, Bristol, BS7 9BU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/692982bf-7a6f-b43b-700c-fddef19fa23f",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/1920x420_graduation_photo_2019.jpg",
        "about_us": "N/A"
    },
    {
        "id": "f0ac0b208f5299bde0f36ac15263fbb7",
        "university_name": "Trinity College, Bristol",
        "location": "Stoke Hill, Stoke Bishop, Bristol, BS9 1JP",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Trinity College, Bristol",
                "location_address": "Stoke Hill, Bristol, BS9 1JP"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/da03c49a-4779-83a1-884c-18a67d3aa8de",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas-banner-1920x420-a.jpg",
        "about_us": "N/A"
    },
    {
        "id": "09830cdc2650df2d0a316f672edc4d3f",
        "university_name": "University of Bristol",
        "location": "Beacon House, Queens Road, Bristol, Gloucestershire, BS8 1QU",
        "official_website": "https://www.bristol.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "0117 928 9000/7678",
        "course_locations": [
            {
                "location_name": "Clifton Campus",
                "location_address": "Beacon House, Bristol, BS8 1QU"
            },
            {
                "location_name": "Langford Campus",
                "location_address": "Langford House, Langford, Bristol, BS40 5DU"
            },
            {
                "location_name": "Main Site",
                "location_address": "Beacon House, Queens Road, Bristol, BS8 1QU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/69690f14-21d5-d03f-ece0-3148e62e461e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/university-of-bristol-banner-image-for-ucas.jpg",
        "about_us": "Your story starts here.\u00a0Founded in 1876, the University of Bristol has a global reputation for high-quality education and research. We are one of the UK\u2019s top 10 universities and rank 54th in the world (QS World University Rankings, 2025).\u00a0A creative, inspiring place where everyone is welcome, Bristol has the vibrant energy of a big city, but its friendly, relaxed atmosphere will quickly help you feel at home. Our campus is at the heart of the city, so you can enjoy everything Bristol has to offer.\u00a0As a member of the Russell Group, we are one of the UK\u2019s leading research-intensive universities, with 94% of our research being assessed as world-leading or internationally excellent (REF 2021).\u00a0Join over 25,000 students from more than 150 countries and create your own unique, amazing story.\u00a0\u00a0"
    },
    {
        "id": "1947afa2c08caa9389632a3ff4a386c4",
        "university_name": "British Academy of Jewellery",
        "location": "Morley House, 26-30 Holborn Viaduct, Holborn, EC1A 2AT",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Chalk Farm",
                "location_address": "81-84 Chalk Farm Road, London, NW1 8AR"
            },
            {
                "location_name": "Sheffield",
                "location_address": "Atlas House, Attercliffe Road, Sheffield, S4 7WZ"
            },
            {
                "location_name": "Leicester",
                "location_address": "Humberstone House, 83 Humberstone Gate, Leicester, LE1 1WB"
            },
            {
                "location_name": "Birmingham",
                "location_address": "Mill Wharf, Mill Street, Birmingham, B6 4BS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/4cefe4e9-63f1-458d-b70d-50485868190b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas-banner_0.jpeg",
        "about_us": "N/A"
    },
    {
        "id": "5acbb807c4fe2f0370ec0aca2b57049e",
        "university_name": "BCOM &amp; ESO - part of BCNO Group (London &amp; Kent)",
        "location": "6 Netherhall Gardens, London, NW3 5RR",
        "official_website": "https://bcnogroup.ac.uk/",
        "contact_email": "admissions.bcom@bcnogroup.ac.uk (BCOM) admissions.eso@bcnogroup.ac.uk (ESO)",
        "contact_phone": "020 7435 6464 (BCOM) or  01622 671 558 (Kent)",
        "course_locations": [
            {
                "location_name": "European School of Osteopathy - Kent campus",
                "location_address": "104 Tonbridge Road, Maidstone, Kent, ME16 8SL"
            },
            {
                "location_name": "British College of Osteopathic Medicine - London campus",
                "location_address": "6 Netherhall Gardens, London, NW3 5RR"
            },
            {
                "location_name": "British College of Osteopathic Medicine - London",
                "location_address": "6 Netherhall Gardens, London, NW3 5RR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/98e33cb7-9fc3-6a50-f761-d2277d853a92",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/_dsc7298.jpg",
        "about_us": "The British College of Osteopathic Medicien (BCOM London) and the European School of Osteopathy (ESO Kent) are part of the BCNO Group and together they are a specialist higher education osteopathic institution with an international reputation for academic and research excellence in Osteopathy. We inspire our students to become confident professionals in this exciting and rewarding career. BCOM London and ESO Kent are the perfect places to train to be an Osteopath, with their on-site teaching clinics and fantastic locations, making student life an inspiring, rewarding and incredible experience. You can choose to study Osteopathy at either BCOM London or ESO Kent from September 2025.\u00a0"
    },
    {
        "id": "69407c97c657af528ee56c7707298fdb",
        "university_name": "University of Buckingham",
        "location": "Yeomanry House, Hunter Street, Hunter Street, Buckingham, Buckinghamshire, MK18 1EG",
        "official_website": "https://www.buckingham.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01280 814080",
        "course_locations": [
            {
                "location_name": "Crewe",
                "location_address": "Crewe Green Road, Crewe, CW1 5DU"
            },
            {
                "location_name": "London",
                "location_address": "London, London, MK18 1EG"
            },
            {
                "location_name": "School of Education",
                "location_address": "Vinson Building,, Hunter Street,, Buckingham, MK18 1EG"
            },
            {
                "location_name": "Main Site",
                "location_address": "Hunter Street, Buckingham, MK18 1EG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/1386af38-654b-f1d0-0fe3-3d30dd76442e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/jks03581_1-largecrop.jpg",
        "about_us": "Want to know why we\u2019re shortlisted for University of the Year in the Sunday Times Good University Guide 2024?We pride ourselves in delivering more than just an education; we provide a launchpad for your career. Our commitment to career-focused courses has seen us ranked 2nd for Graduate Prospects (on track) in the Complete University Guide 2023.We rank highly for teaching quality, and we think you'll love our small class sizes that mean you won\u2019t get lost in the crowd, and have all the tools needed for success. Our support services are great too \u2013 we're ranked 5th for Mental Health Wellbeing Services in the NSS, 2023.But don\u2019t just take our word for it; our students rank us in the Top 10 for Student Satisfaction (CUG, 2023). Nothing is more important to us than happy students.Discover what sets us apart from other universities; from innovative accelerated degree options - to a commitment to saving you you time and money. Your journey toward success starts here."
    },
    {
        "id": "2424148d668aa94a5add7601699048c1",
        "university_name": "Buckinghamshire New University",
        "location": "Queen Alexandra Road, High Wycombe, Buckinghamshire, HP11 2JZ",
        "official_website": "https://www.bnu.ac.uk",
        "contact_email": "advice@bnu.ac.uk",
        "contact_phone": "01494 522141",
        "course_locations": [
            {
                "location_name": "Uxbridge Campus",
                "location_address": "106 Oxford Road, Uxbridge, UB8 1NA"
            },
            {
                "location_name": "David Game College",
                "location_address": "31 Jewry Street, London, EC3N 2ET"
            },
            {
                "location_name": "Dancebox Studios and Theatre Works",
                "location_address": "216-218 Regency Court, Upper Fifth Street, Milton Keynes, MK9 2HR"
            },
            {
                "location_name": "LSST Elephant and Castle",
                "location_address": "London School of Science and Technology, Lancaster House, 70 Newington Causeway, London, SE1 6DF"
            },
            {
                "location_name": "LSST Luton",
                "location_address": "London School of Science and Technology, 1 Hastings Street, Luton, Bedfordshire, LU1 5XL"
            },
            {
                "location_name": "RHCast",
                "location_address": "Northgate St, Bury Saint Edmunds IP33 1HP, Bury Saint Edmunds, IP33 1HP"
            },
            {
                "location_name": "Buckinghamshire New University",
                "location_address": "Queen Alexandra Road, High Wycombe, HP11 2JZ"
            },
            {
                "location_name": "High Wycombe Campus",
                "location_address": "Queen Alexandra Road, High Wycombe, HP11 2JZ"
            },
            {
                "location_name": "Mont Rose College",
                "location_address": "Mont Rose College, 267 Cranbrook Road, London, IG1 4TG"
            },
            {
                "location_name": "Aylesbury Campus",
                "location_address": "Walton Street, Aylesbury, HP21 7QG"
            },
            {
                "location_name": "LSST Birmingham",
                "location_address": "London School of Science and Technology Crystal Court,, Aston Cross Business Village,, 50 Rocky Lane, Birmingham, B6 5RQ"
            },
            {
                "location_name": "LSST London Alperton",
                "location_address": "London School of Science and Technology, 401- 403 High Road, Wembley, London, HA9 7AB"
            },
            {
                "location_name": "Wycombe Wanderers Football Club",
                "location_address": "Wycombe Wanderers Football Club Ltd, Adams Park, Hillbottom Road, High Wycombe, HP12 4HJ"
            },
            {
                "location_name": "Court Theatre Training Company (Central London)",
                "location_address": "The Courtyard Theatre, Bowling Green Walk, 40 Pitfield Street, London, N1 6EU"
            },
            {
                "location_name": "Main Site",
                "location_address": "Queen Alexandra Road, High Wycombe, HP11 2JZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/592fda96-d70c-6b24-bde0-051047b0d6f7",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/gateway_for_ucas.jpg",
        "about_us": "Whether you\u2019re considering a degree that will give you a fantastic grounding for your future career, looking to take the next step with postgraduate study, or want to develop your skills further with professional development training, Buckinghamshire New University can help you kick-start your career.\u00a0With a 96% employability rate*, we'll help you develop the skills employers are looking for. Our diverse programmes provides the perfect blend of professional, practical and academic teaching. Our industry connections, accreditations, placements, and guest speakers will help you to stand out in the job market. This strong focus on employability flows through all our courses, and the sense of community stays with our alumni long after graduation.\u00a0We're also proud to be the no 1 uni in the UK for student satisfaction* and Uni of the Year for student support* and can help you achieve your career goals.*Visit bnu.ac.uk for full details.\u00a0"
    },
    {
        "id": "7f08c545ca75b85f9d3c5aa5289ac309",
        "university_name": "Burnley College",
        "location": "Princess Way, Burnley, Lancashire, BB12 0AN",
        "official_website": "https://www.burnley.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01282 733373",
        "course_locations": [
            {
                "location_name": "Burnley College",
                "location_address": "Princess Way, Burnley, BB12 0AN"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b0175104-a1e0-241a-2b97-65e79cbf113f",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/richard_tymon_-_26_11_18_-campus_6_crop.jpg",
        "about_us": "N/A"
    },
    {
        "id": "960e3bf8f787db9dd768a9d08f7aeac5",
        "university_name": "Bury College",
        "location": "Market St, Bury, BL9 0BG",
        "official_website": "http://www.burycollege.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01612 808280",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Enterprise Centre, Market Street, Bury, BL9 0BG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/74de5b4c-f1a8-6510-6874-902f07378009",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/bury-grad2019-131-131_cmyk.jpg",
        "about_us": "N/A"
    },
    {
        "id": "1cd968c3c7d2447580865e14df18e23f",
        "university_name": "Cambridge Marketing College",
        "location": "Sycamore Studios, New Road, Over, Cambridge, Cambridgeshire, CB245JP",
        "official_website": "https://www.marketingcollege.com/",
        "contact_email": "Info@marketingcollege.com",
        "contact_phone": "+44 (0)1954 234940 ",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Cygnus Business Park, Middlewatch, Swavesey, Cambridge, CB24 4AA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/27dbeccf-636f-5448-f3ef-915e2e980c31",
        "background_image": "",
        "about_us": "For over 30 years, we have worked with marketing and PR professionals across all sectors including private, public and not-for-profit organisations to develop their skills and capabilities."
    },
    {
        "id": "80bdd44825867ecad238fc94069403fd",
        "university_name": "Cambridge Regional College",
        "location": "Kings Hedges Road,, Cambridge, Cambridgeshire, CB4 2QT",
        "official_website": "https://www.camre.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01223 418200",
        "course_locations": [
            {
                "location_name": "Cambridge Campus",
                "location_address": "Kings Hedges Road, Cambridge, CB4 2QT"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3c88a10a-1164-87f7-3cb8-3dacb17b0afc",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/campus.png",
        "about_us": "At Cambridge Regional College we believe that Higher Education should be accessible for any stage of your life. Whether you are looking at what\u2019s next after your Level 3 or A Levels or you are a mature student and looking at changing or developing your career, our Higher Education courses can get you where you want to be.Our BTEC Higher Nationals are highly valued by employers looking for skilled and motivated employees. We work closely with local employers to ensure that their involvement will add to your skills base and thereby enhance your future employment opportunities.You will have access to excellent teaching and outstanding facilities, and as part of your course you will work in industry or carry out practical work-based assignments, giving you the real job skills valued by employers. We have established close links with local and regional universities, which allows our HE students to progress to university and extend their qualifications to BA and BSc.\u00a0"
    },
    {
        "id": "7b08e772bd3d45aedf3fa7526f0ed902",
        "university_name": "Cambridge School of Visual and Performing Arts",
        "location": "13-14 Round Church Street, Cambridge, Cambridgeshire, CB5 8AD",
        "official_website": "https://www.csvpa.com/",
        "contact_email": "N/A",
        "contact_phone": "0207 8411580",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "14 Round Church Street, Cambridge, CB5 8AD"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/83b36cda-f9f8-8fe5-2c8a-f88a8c0eb65e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_portal_banner.png",
        "about_us": "N/A"
    },
    {
        "id": "70eebd9d2aa4d630159d6aea790cf6eb",
        "university_name": "University of Cambridge",
        "location": "Student Services Centre, New Museums Site, Cambridge, Cambridgeshire, CB2 3PT",
        "official_website": "http://www.cam.ac.uk/",
        "contact_email": "admissions@cam.ac.uk",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Murray Edwards",
                "location_address": "Huntingdon Road, Cambridge, CB3 0DF"
            },
            {
                "location_name": "Clare",
                "location_address": "Trinity Lane, Cambridge, CB2 1TL"
            },
            {
                "location_name": "Newnham",
                "location_address": "Sidgwick Road, Cambridge, CB3 9DF"
            },
            {
                "location_name": "Wolfson",
                "location_address": "Barton Road, Cambridge, CB3 9BB"
            },
            {
                "location_name": "Peterhouse",
                "location_address": "Trumpington Street, Cambridge, CB2 1RD"
            },
            {
                "location_name": "Corpus Christi",
                "location_address": "Trumpington Street, Cambridge, CB2 1RH"
            },
            {
                "location_name": "Emmanuel",
                "location_address": "St Andrew's Street, Cambridge, CB2 3AP"
            },
            {
                "location_name": "Churchill",
                "location_address": "Storey's Way, Cambridge, CB3 0DS"
            },
            {
                "location_name": "Homerton",
                "location_address": "Hills Road, Cambridge, CB2 8PH"
            },
            {
                "location_name": "Fitzwilliam",
                "location_address": "Storey's Way, Cambridge, CB3 0DG"
            },
            {
                "location_name": "Trinity",
                "location_address": "Trinty Street, Cambridge, CB2 1TQ"
            },
            {
                "location_name": "King's",
                "location_address": "King's Parade, Cambridge, CB2 1ST"
            },
            {
                "location_name": "Cambridge University",
                "location_address": "The Old Schools, Trinity Lane, Cambridge, CB2 1TN"
            },
            {
                "location_name": "Main Site",
                "location_address": "32 Trumpington Street, Cambridge, CB2 1QY"
            },
            {
                "location_name": "St Catharine's",
                "location_address": "Trumpington Street, Cambridge, CB2 1RL"
            },
            {
                "location_name": "Open application",
                "location_address": "Cambridge Admissions Office, Cambridge, CB2 1QY"
            },
            {
                "location_name": "Queens'",
                "location_address": "Silver Street, Cambridge, CB3 9ET"
            },
            {
                "location_name": "St Edmund's",
                "location_address": "Mount Pleasant, Cambridge, CB3 0BN"
            },
            {
                "location_name": "Jesus",
                "location_address": "Jesus Lane, Cambridge, CB5 8BL"
            },
            {
                "location_name": "Sidney Sussex",
                "location_address": "Sidney Street, Cambridge, CB2 3HU"
            },
            {
                "location_name": "Downing",
                "location_address": "Regent Street, Cambridge, CB2 1DQ"
            },
            {
                "location_name": "Selwyn",
                "location_address": "Grange Road, Cambridge, CB3 9DQ"
            },
            {
                "location_name": "Robinson",
                "location_address": "Grange Road, Cambridge, CB3 9AN"
            },
            {
                "location_name": "Lucy Cavendish",
                "location_address": "Lady Margaret Road, Cambridge, CB3 0BU"
            },
            {
                "location_name": "Trinity Hall",
                "location_address": "Trinity Lane, Cambridge, CB2 1TJ"
            },
            {
                "location_name": "Christ's",
                "location_address": "St Andrew's Street, Cambridge, CB2 3BU"
            },
            {
                "location_name": "Girton",
                "location_address": "Huntingdon Road, Cambridge, CB3 0JG"
            },
            {
                "location_name": "Gonville &amp; Caius",
                "location_address": "Trinity Street, Cambridge, CB2 1TA"
            },
            {
                "location_name": "Magdalene",
                "location_address": "Magdalene Street, Cambridge, CB3 0AG"
            },
            {
                "location_name": "Pembroke",
                "location_address": "Trumpington Street, Cambridge, CB2 1RF"
            },
            {
                "location_name": "Main Site",
                "location_address": "The Old Schools, Trinity Lane, Cambridge, CB2 1TN"
            },
            {
                "location_name": "Hughes Hall",
                "location_address": "Wollaston Road, Cambridge, CB1 2EW"
            },
            {
                "location_name": "St John's",
                "location_address": "St John's Street, Cambridge, CB2 1TP"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/badc3603-5bb0-5a9d-d24f-4333c7658a0e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/cambridge_film_still.jpg",
        "about_us": "The University of Cambridge is one of the best universities in the world. We're known and respected across the globe for the excellence of our teaching and research, and the quality of our graduates. Home to over 24,000 students from more than 140 countries, we're looking for the most academically able students with a passion for their subject and the potential to thrive at the University. If this sounds like you, we hope you\u2019ll apply\u00a0to join us at Cambridge."
    },
    {
        "id": "8822b118ce6d849db75e95fba96fdce2",
        "university_name": "Canterbury Christ Church University",
        "location": "North Holmes Road, Canterbury, Kent, CT1 1QU",
        "official_website": "http://www.canterbury.ac.uk",
        "contact_email": "courses@canterbury.ac.uk",
        "contact_phone": "01227 928000",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "North Holmes Road, Canterbury, CT1 1QU"
            },
            {
                "location_name": "South Thames Colleges Group (Kingston College)",
                "location_address": "Kingston College, Kingston Hall Road, Kingston upon Thames, KT2 5AQ"
            },
            {
                "location_name": "Medway Campus",
                "location_address": "Rowan Williams Court, 30 Pembroke Court, Chatham, ME4 4AW"
            },
            {
                "location_name": "Canterbury College",
                "location_address": "New Dover Road, Canterbury, CT1 3AJ"
            },
            {
                "location_name": "ABDO College",
                "location_address": "Godmersham Park House, Godmersham Park, Canterbury, CT4 7DT"
            },
            {
                "location_name": "Canterbury Campus",
                "location_address": "North Holmes Road, Canterbury, CT1 1QU"
            },
            {
                "location_name": "London South East Colleges (Orpington Campus)",
                "location_address": "The Walnuts, Orpington High Street, Orpington London, BR6 0TE"
            },
            {
                "location_name": "Salomons Centre, Tunbridge Wells",
                "location_address": "1 Meadow Road, Royal Tunbridge Wells, TN1 2YG"
            },
            {
                "location_name": "Medway Campus",
                "location_address": "Rowan Williams Court, 30 Pembroke Court, Chatham, ME4 4UF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/24bec0e2-0d56-c578-e599-c2a9c9d0bbd2",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/canterbury-christ-church-university-ucas.jpg",
        "about_us": "At Canterbury Christ Church University, our courses are designed to give you the higher education you want, and the skills you need to make your own contribution to the world around you.\u00a0\u00a0Study in a great location\u00a0You\u2019ll be taught on one of two campuses, and each has its own distinctive character. Less than an hour from London, Canterbury is a lively student city with historic streets, ancient city walls and a vibrant atmosphere. The Medway Campus is in an area known as Chatham Maritime, a student-friendly quarter.\u00a0Meet amazing people and make friends for life\u00a0The warm and welcoming atmosphere you\u2019ll find on campus is one of the things that truly sets us apart. You\u2019ll be part of an extremely diverse, global and dynamic community, one which places a high value on individuality, equality and brings together students from over 80 countries worldwide.\u00a0\u00a0\u00a0"
    },
    {
        "id": "09f2063ba0aa026be885a507c3beacba",
        "university_name": "Cardiff and Vale College",
        "location": "City Centre Campus, Dumballs Road, Dumballs Road, Cardiff, CF10 5FE",
        "official_website": "https://www.cavc.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "02920 250250",
        "course_locations": [
            {
                "location_name": "Barry Campus",
                "location_address": "Colcot Road, Barry, CF62 8YJ"
            },
            {
                "location_name": "Cardiff Construction Training Centre",
                "location_address": "Dumballs Road, Cardiff, CF10 5NT"
            },
            {
                "location_name": "International Centre for Aerospace Training (ICAT)",
                "location_address": "Cardiff Airport Business Park, Rhoose, CF62 3DP"
            },
            {
                "location_name": "City Centre Campus",
                "location_address": "Dumballs Road, Cardiff, CF10 5FE"
            },
            {
                "location_name": "Creative Arts Academy",
                "location_address": "45a Trade Street, Cardiff, CF10 5DT"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6a3d68d5-6347-4681-9490-3bf86a5dd4d4",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/cavc-ucas-header-photo.jpg",
        "about_us": "N/A"
    },
    {
        "id": "7482a717e48f6d357fbf548c31ca5b68",
        "university_name": "Cardiff Metropolitan University",
        "location": "Admissions Unit, Llandaff Campus, Llandaff Campus, Study in Cardiff, Wales, CF5 2YB",
        "official_website": "http://www.cardiffmet.ac.uk",
        "contact_email": "askadmissions@cardiffmet.ac.uk",
        "contact_phone": "029 2041 6070",
        "course_locations": [
            {
                "location_name": "Cardiff Met - Llandaff",
                "location_address": "Cardiff Metropolitan University, Western Avenue, Cardiff, CF5 2YB"
            },
            {
                "location_name": "Black Mountains College",
                "location_address": "Unit 3 Great House Barn, New Street, Talgarth, Brecon, LD3 0AH"
            },
            {
                "location_name": "Main Site",
                "location_address": "Student Recruitment &amp; Admissions, Western Avenue, Cardiff, CF5 2YB"
            },
            {
                "location_name": "Cardiff Met - Cyncoed",
                "location_address": "Cardiff Metropolitan University, Cyncoed Road, Cardiff, CF23 6XD"
            },
            {
                "location_name": "Cyncoed Campus (Postgraduate)",
                "location_address": "Cyncoed Road, Cyncoed, Cardiff, CF23 6XD"
            },
            {
                "location_name": "Coleg Gwent",
                "location_address": "Crosskeys Campus, Risca Road, Crosskeys, Caerphilly, NP11 7ZA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/777de2ea-20ca-30db-9e05-6e3d2dcd7d69",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas-provider-cover-2.jpg",
        "about_us": "Cardiff Metropolitan University delivers professionally recognised degrees, together with impactful research and innovation, in art and design, business and management, education and public services, sport and health sciences, and technologies and engineering. We\u2019re committed to providing you with an exceptional student experience, supporting and empowering you to reach your full potential.\u202f We work in partnership with you to instil the Cardiff Met EDGE - a series of Ethical, Digital, Global and Entrepreneurial skills to develop your confidence, resilience and experiences that will prepare you for life after university and employment.We are proud to have been ranked 6th in the UK and the best in Wales for Sustainability in People and Planet\u2019s University League 2023/24."
    },
    {
        "id": "57dc066c9ecc8fbdc21faec9617f9f84",
        "university_name": "Cardiff University",
        "location": "Main Building, Park Place, Cardiff, CF10 3AT",
        "official_website": "http://www.cardiff.ac.uk/",
        "contact_email": "enquiry@cardiff.ac.uk",
        "contact_phone": "02920 879999",
        "course_locations": [
            {
                "location_name": "Main Site - Cardiff",
                "location_address": "30-36 Newport Road, Cardiff, CF24 0DE"
            },
            {
                "location_name": "Cathays Park Campus",
                "location_address": "Park Place, Cardiff, CF10 3AT"
            },
            {
                "location_name": "Main Site",
                "location_address": "PO Box 921, Cardiff, CF10 3XQ"
            },
            {
                "location_name": "Heath Park Campus",
                "location_address": "Heath Park, Cardiff, CF14 4XN"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/e1c1ad69-f707-d0f7-35d7-baf04342e1ca",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/cardiff_university_main_building_image_larger_pixels.png",
        "about_us": "Cardiff University was established in 1883 and holds an international reputation for academic excellence and is Wales\u2019s only Russell Group university. We were ranked 11th for impact in the 2021 Research Excellence Framework with our research boasting real-world benefits for society and the academic environment.With over 350 undergraduate courses to choose from, our degrees provide you with large amounts of flexibility and choice. We were ranked 13th in the UK in the Times Higher Education Global University Employability Ranking 2022.Our attractive Cathays Park Campus, located in the heart of Cardiff city centre, is home to most of our academic schools and close to a wide range of student accommodation and the Students\u2019 Union. The Heath Park Campus hosts our schools of Medicine, Dentistry and Healthcare, sharing its site with the University Hospital of Wales.The university has links with more than 300 top-ranked institutions, which gives students an opportunity to study abroad."
    },
    {
        "id": "9acc1472784872b4999ccdbd63a59824",
        "university_name": "Central Film School London",
        "location": "72 Landor Rd, London, SW9 9PH",
        "official_website": "http://www.centralfilmschool.com/",
        "contact_email": "N/A",
        "contact_phone": "020 7377 6060",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Avondale, 72 Landor Road, London, SW9 9PH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/016d384d-9c41-3e08-8df5-de53eb5a0254",
        "background_image": "",
        "about_us": "Central Film School is a film school based in South West London, running undergraduate and postgraduate programmes as well as a portfolio of non-accredited courses. Our mission is to give independent voices the skills and knowledge needed to tell their stories through film. "
    },
    {
        "id": "e271ae9663821b70e526c7c717c33886",
        "university_name": "University of Central Lancashire London",
        "location": "2 Clove Crescent, London, E14 2BE",
        "official_website": "https://london.uclan.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "+44 (0)203 948 4440",
        "course_locations": [
            {
                "location_name": "UCLan London",
                "location_address": "6th Floor, Import Building, 2 Clove Crescent, East India Dock Road, London, E14 2BE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/14062c40-eebc-446f-a04c-253c5818a7ec",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/uclanl_4.jpg",
        "about_us": "Study at the University of Central Lancashire London campus and be in the heart of one of the most vibrant, creative and cosmopolitan cities in the world.We\u2019re innovative by nature, offering more choices and creating more possibilities. Combining academic excellence and real-world teaching, we give our people the skills and experience industry needs. That\u2019s why our talented graduates are in such high demand across the world.Second-to-none supportWe understand that life can be complicated and the modern student needs an approach to education that fits seamlessly into their busy lives, and we are here to provide just that.\u00a0Our London campus offers a flexible timetable and a second-to-none supportive environment, allowing you to achieve your educational goals without sacrificing your personal and professional commitments.\u00a0Our missionOur mission is to help people seize every opportunity to flourish in education, at work and for life.\u00a0Whatever your goal, together we can make it happen."
    },
    {
        "id": "bafea6542991135590efc791b4071555",
        "university_name": "University of Central Lancashire",
        "location": "Admissions Office, Preston, Lancashire, PR1 2HE",
        "official_website": "http://www.uclan.ac.uk/",
        "contact_email": "cenquiries@uclan.ac.uk",
        "contact_phone": "01772-892400",
        "course_locations": [
            {
                "location_name": "Lancashire Teaching Hospital",
                "location_address": "Fulwood Campus, St Vincents Road, Preston, PR2 8UR"
            },
            {
                "location_name": "L3 Harris",
                "location_address": "L3 Harris London Training Centre, 2-3 Gatwick Road, Crawley, RH10 9BG"
            },
            {
                "location_name": "Burnley Campus",
                "location_address": "Princess Way, Burnley, BB12 0AN"
            },
            {
                "location_name": "Spirit Studios",
                "location_address": "65-69 Downing Street, Manchester, M1 7JE"
            },
            {
                "location_name": "Westlakes Campus",
                "location_address": "Westlakes Science and Technology Park, Samuel Lindow Building, Whitehaven, Carlisle, CA24 3JY"
            },
            {
                "location_name": "Main Site",
                "location_address": "Preston, PR1 2HE"
            },
            {
                "location_name": "Main Site",
                "location_address": "Admissions Office, Corporation Street, Preston, PR1 2HE"
            },
            {
                "location_name": "Preston Campus",
                "location_address": "Preston Campus, Preston, PR1 2HE"
            },
            {
                "location_name": "UCLan Cyprus",
                "location_address": "University Avenue, 12-14 Pyla, Pyla, Larnaka, 7080"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6533bebc-ace6-ea95-2c03-43a2395834dd",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/uclan_university_square_2.jpg",
        "about_us": "The University of Central Lancashire is an international, multi-campus University tracing its roots back to 1828 and leading the way in modern learning today.\u00a0 Our main campus is based in Preston in the north west of England, with others in Burnley, Westlakes (Cumbria) and Cyprus.We\u2019ve spent \u00a3200 million on transforming our Preston Campus to give it a world-class makeover. This includes a stunning brand-new Student Centre and University Square at the heart of campus, social spaces and a whole lot more.Our new \u00a360m Student Centre brings all the advice and support teams under one roof, providing second-to-none support. It\u2019s a stunning space designed to support every step of the student journey. The University Square will become an exciting hub of activity, a focal point for University and community events and an iconic gateway linking together the city and the University.Whoever you are, wherever you are in life, there\u2019s always a way."
    },
    {
        "id": "32f33347c65da8c590139201d45cdde5",
        "university_name": "Centre for Alternative Technology",
        "location": "Machynlleth, Machynlleth, SY20 9AZ",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Centre for Alternative Technology",
                "location_address": "Machynlleth, Machynlleth, SY20 9AZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/81f409fb-0cd4-3c4d-0861-a46bd2dc52c0",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/award_winning_wise_building_at_cat_-_be_kind.jpg",
        "about_us": "N/A"
    },
    {
        "id": "0eb69ed297e71b8c3d409aa2a6292aaa",
        "university_name": "University of Chester",
        "location": "Parkgate Road, Chester, Cheshire, CH1 4BJ",
        "official_website": "http://www.chester.ac.uk/",
        "contact_email": "enquiries@chester.ac.uk",
        "contact_phone": "01244 511000",
        "course_locations": [
            {
                "location_name": "Chester",
                "location_address": "Parkgate Road, Chester, CH1 4BJ"
            },
            {
                "location_name": "Exton Park",
                "location_address": "Parkgate Road, Chester, CH1 4BJ"
            },
            {
                "location_name": "Queen's Park",
                "location_address": "Queens Park Road, Chester, CH4 7AD"
            },
            {
                "location_name": "University Centre Warrington",
                "location_address": "Sarah Parker Redmond House, Barbauld Street, Warrington, WA1 1WA"
            },
            {
                "location_name": "Creative Campus, Kingsway",
                "location_address": "Creative Campus, Kingsway, Chester, CH2 2LB"
            },
            {
                "location_name": "University Centre Reaseheath",
                "location_address": "Reaseheath College, Worleston, Nantwich, CW5 6DF"
            },
            {
                "location_name": "Wheeler",
                "location_address": "Wheeler Building, Castle Drive, Chester, CH1 1SF"
            },
            {
                "location_name": "Main Site",
                "location_address": "Parkgate Road, Chester, CH1 4BJ"
            },
            {
                "location_name": "University Centre Shrewsbury",
                "location_address": "Guildhall, Frankwell Quay, Shrewsbury, SY3 8HQ"
            },
            {
                "location_name": "University Centre Birkenhead",
                "location_address": "Marriss House, Hamilton Street, Birkenhead, Wirral, CH41 5AL"
            },
            {
                "location_name": "The Hammond",
                "location_address": "Hoole Bank, Mannings Lane, Chester, CH2 4ES"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/09d21dac-cccb-5d5e-1c6e-28598e8cbbe8",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/banneruoc1_1.jpg",
        "about_us": "With more than 180 years of educational heritage, the University of Chester offers a wide range of highly regarded single and combined honours undergraduate and postgraduate courses, across a selection of specialist sites.\u00a0At this University, our students are not only here to get a degree. They will learn how to apply it in the wider world and engage with local, national and global conversations in an inspiring and challenging environment.\u00a0The University provides a welcoming and inclusive educational setting and celebrates the diverse life experiences each student brings to the learning community. Through excellent support, a wealth of opportunities and a genuine focus on the development of every person, the University has built a proactive community of future-focused students."
    },
    {
        "id": "feafae748cb896ec73faa7d920e15ac9",
        "university_name": "Chesterfield College",
        "location": "Infirmary Road, Chesterfield, Derbyshire, S41 7NG",
        "official_website": "https://www.chesterfield.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01246 500 531",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Infirmary Road, Chesterfield, S41 7NG"
            },
            {
                "location_name": "Chesterfield College",
                "location_address": "Infirmary Road, Chesterfield, S41 7NG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/02675d55-50c9-b7ec-29a9-66504af0a635",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/he_samples_3_of_2.jpg",
        "about_us": "N/A"
    },
    {
        "id": "35f548e45f93a92ea657891548995ea5",
        "university_name": "Chichester College Group (inc Brinsbury, Chichester, Crawley, Northbrook &amp; Worthing Colleges)",
        "location": "Westgate Fields, Chichester, West Sussex, PO19 1SB",
        "official_website": "https://www.chigroup.ac.uk/services/higher-education",
        "contact_email": "N/A",
        "contact_phone": "01243 786321",
        "course_locations": [
            {
                "location_name": "Northbrook College",
                "location_address": "Northbrook College, Littlehampton Road, Worthing, West Sussex, BN12 6NU"
            },
            {
                "location_name": "Chichester College",
                "location_address": "Westgate Fields, Chichester, PO19 1SB"
            },
            {
                "location_name": "Worthing College",
                "location_address": "Worthing College, 1 Sanditon Way, Worthing, BN14 9FD"
            },
            {
                "location_name": "Brighton MET College",
                "location_address": "Brighton Metropolitan College, Pelham Street, Brighton, BN1 4FA"
            },
            {
                "location_name": "Crawley College",
                "location_address": "College Road, Crawley, RH10 1NR"
            },
            {
                "location_name": "Brinsbury College",
                "location_address": "North Heath, Pulborough, RH20 1DL"
            },
            {
                "location_name": "Shoreham Campus",
                "location_address": "Northbrook College, Cecil Pashley Way, Shoreham-by-Sea West Sussex, BN43 5FF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/52535f33-8d08-9b7d-f741-91e006d8e24c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ccg303_he_ucascoursecards8.jpg",
        "about_us": "The Chichester College Group is proud to offer a variety of university level courses across six of our colleges:Brighton MET CollegeBrinsbury CollegeChichester College\u00a0Crawley CollegeNorthbrook College\u00a0Worthing CollegeWhether you're interested in pursuing a career in the creative industries, construction management, business, engineering, health and social care, or sport, you can study a level 4, 5 or 6 course across a broad range of subjects with us.A university level qualification can make a huge difference to your career prospects. So, whether you're at the start of your journey, looking to progress in your career or want to change direction you will find what you're looking for with the Chichester College Group."
    },
    {
        "id": "4f0e4db956e48cf57e2619e0ea9bfeea",
        "university_name": "University of Chichester",
        "location": "Bishop Otter Campus, College Lane, College Lane, Chichester, West Sussex, PO19 6PE",
        "official_website": "https://www.chi.ac.uk",
        "contact_email": "admissions@chi.ac.uk",
        "contact_phone": "01243 816002",
        "course_locations": [
            {
                "location_name": "Bognor Regis Campus, University of Chichester",
                "location_address": "Upper Bognor Road, Bognor Regis, PO21 1HR"
            },
            {
                "location_name": "Bishop Otter Campus",
                "location_address": "Bishop Otter Campus, Chichester, PO19 6PE"
            },
            {
                "location_name": "Main Site",
                "location_address": "Bishop Otter Campus, College Lane, Chichester, PO19 6PE"
            },
            {
                "location_name": "Italia Conti",
                "location_address": "2 Henry Plaza, Victoria Way,, Woking, GU21 6BU"
            },
            {
                "location_name": "Bishop Otter Campus, Chichester",
                "location_address": "Bishop Otter Campus, College Lane, Chichester, PO19 6PE"
            },
            {
                "location_name": "Platform One",
                "location_address": "Dodnor Lane, Newport (Isle of Wight), PO30 5XD"
            },
            {
                "location_name": "Associated Studios Performing Arts Academy",
                "location_address": "204 Latimer Road, London, W10 6QY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b1f140f3-5bf6-f56d-b5e3-f94d95b11daf",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/22-west-wittering-beach-students-6-1920x640.jpg",
        "about_us": "The University of Chichester is known as a thriving higher education institution with a rich history tracing back to 1839. We are based on the sunny south coast, just over an hour from London. We have two campuses surrounded by stunning Blue Flag beaches, rolling countryside and a historic city. Here are a few facts and figures to help you get to know us a bit better.Top 30 UK university - 26th in the UK, out of 122 universities. Guardian University Guide 2025TEF Gold - Awarded the Gold rating in the 2023 Teaching Excellence Framework (TEF).Rated Outstanding - Our teacher training programmes are rated outstanding by Ofsted.Quality Teaching - One of the top for quality teaching according to our students in the 2024 NSS report, rating us 21st for course teaching quality, and 24th for assessment and feedback in the UK."
    },
    {
        "id": "6843d942794a2b79282d88830528c1ed",
        "university_name": "City and Guilds of London Art School",
        "location": "124 Kennington Park Road, London, SE11 4DJ",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "City and Guilds of London Art School",
                "location_address": "124 Kennington Park Road, London, SE11 4DJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/bb82986c-1cac-9370-bd25-041881277658",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/01_20150312_dsd1165_city-guilds_small.jpg",
        "about_us": "N/A"
    },
    {
        "id": "de7161cc3dd36ff384f1e37e5349eb16",
        "university_name": "City College Norwich (incorporating Easton College)",
        "location": "Ipswich Road, Norwich, Norfolk, NR2 2LJ",
        "official_website": "https://www.ccn.ac.uk",
        "contact_email": "he_office@ccn.ac.uk",
        "contact_phone": "01603 773012",
        "course_locations": [
            {
                "location_name": "International Aviation Academy Norwich",
                "location_address": "Hangar 5, Anson Road, Norwich, NR6 6ED"
            },
            {
                "location_name": "Easton College",
                "location_address": "Bawburgh Road, Easton, Norwich, NR9 5GA"
            },
            {
                "location_name": "Main Campus (Ipswich Road)",
                "location_address": "Ipswich Road, Norwich, NR2 2LJ"
            },
            {
                "location_name": "Norfolk House",
                "location_address": "Exchange Street, Norwich, NR2 1DD"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/e0a6cb6c-e8d7-4fce-b69e-40b8260f9e4a",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/norwichcitycollegenorfolkhousemar2019hr_127_1.jpg",
        "about_us": "Welcome to City College Norwich.We are a long-established provider of higher and further education, based in the East of England, in the beautiful city of Norwich.We pride ourselves on our student-centred approach to learning, treating every student as an individual and working with you to help you achieve your academic and professional aspirations.Our students\u2019 success is backed by superb facilities which are aligned to those in the industry or sector you will go on to.You will benefit from studying trusted, high-quality qualifications, that have been endorsed by respected partners and shaped by our strong relationships with employers."
    },
    {
        "id": "5dffed58deed820cdf61c36ccedf34d1",
        "university_name": "City, University of London",
        "location": "Northampton Square, London, EC1V 0HB",
        "official_website": "http://www.city.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "02074 778000",
        "course_locations": [
            {
                "location_name": "City, University of London",
                "location_address": "Northampton Square, London, EC1V 0HB"
            },
            {
                "location_name": "The City Law School",
                "location_address": "City, University of London, Northampton Square, London, EC1V 0HB"
            },
            {
                "location_name": "Main Site",
                "location_address": "The Admissions Office, Northampton Square, London, EC1V 0HB"
            },
            {
                "location_name": "Main Site",
                "location_address": "Northampton Square, City of London, EC1V 0HB"
            },
            {
                "location_name": "School of Health and Psychological Sciences",
                "location_address": "City, University of London, Northampton Square, London, EC1V 0HB"
            },
            {
                "location_name": "Urdang",
                "location_address": "The Old Finsbury Town Hall, Rosebery Ave, London, EC1R 4RP"
            },
            {
                "location_name": "Northampton Square",
                "location_address": "Finsbury, Islington, EC1V 0HB"
            },
            {
                "location_name": "Bayes Business School (formerly Cass)",
                "location_address": "106 Bunhill Row, Islington, EC1Y 8TZ"
            },
            {
                "location_name": "Haneen ",
                "location_address": "N/A"
            },
            {
                "location_name": "Chloe",
                "location_address": "N/A"
            },
            {
                "location_name": "Allany",
                "location_address": "N/A"
            },
            {
                "location_name": "Abigail",
                "location_address": "N/A"
            },
            {
                "location_name": "btoel",
                "location_address": "N/A"
            },
            {
                "location_name": "Gabrielle",
                "location_address": "N/A"
            },
            {
                "location_name": "Allyesha",
                "location_address": "N/A"
            },
            {
                "location_name": "Charlotte",
                "location_address": "N/A"
            },
            {
                "location_name": "Aayan",
                "location_address": "N/A"
            },
            {
                "location_name": "Hajar",
                "location_address": "N/A"
            },
            {
                "location_name": "Filsan",
                "location_address": "N/A"
            },
            {
                "location_name": "Andzhela",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/0e86a0cf-a483-a7c3-63a7-e62d825349f7",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/pavilion_1920x420_px.jpg",
        "about_us": "City prides itself on being the University of business, practice and the professions and aims to provide the relevant skills, attributes and approaches that enable graduates to succeed in their future careers and life pathways."
    },
    {
        "id": "fed2239a53e84ae5576a2f291efe341a",
        "university_name": "Cliff College",
        "location": "Calver, Hope Valley, Derbyshire, S32 3XG",
        "official_website": "https://www.cliffcollege.ac.uk/",
        "contact_email": "enquiries@cliffcollege.ac.uk",
        "contact_phone": "01246 584229",
        "course_locations": [
            {
                "location_name": "Online learning",
                "location_address": "Hope Valley, Calver, S32 3XG"
            },
            {
                "location_name": "Main Site",
                "location_address": "Cliff Lane, Calver, S32 3XG"
            },
            {
                "location_name": "Main Site",
                "location_address": "Hope Valley, Calver, S32 3XG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/8ff764e4-3f1b-bfa6-e6d8-87029bf66f0c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_header_1.jpg",
        "about_us": "N/A"
    },
    {
        "id": "0f2bf493f06e197ca7dfb3f735388a91",
        "university_name": "University Centre Colchester at Colchester Institute",
        "location": "Sheepen Road, Colchester, Essex, CO3 3LL",
        "official_website": "https://www.colchester.ac.uk/ucc/",
        "contact_email": "uccadmissions@colchester.ac.uk",
        "contact_phone": "01206 712000",
        "course_locations": [
            {
                "location_name": "Colchester Site",
                "location_address": "Sheepen Road, Colchester, CO3 3LL"
            },
            {
                "location_name": "Colchester Institute",
                "location_address": "Sheepen Road, Colchester, CO3 3LL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/c58b1306-60af-6423-6ede-0e1f7c1eac75",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/untitled-3.jpg",
        "about_us": "University Centre Colchester at Colchester Institute\u2019s huge strength is in delivering professional and technical degree programmes which genuinely prepare students for exciting jobs and careers.For over fifty years we have equipped thousands of students with the wide range of skills they have needed to realise their aspirations. We\u2019re proud to have helped students of all backgrounds to fulfil and exceed their potential. While studying with us you will embark on a journey of self-discovery; at the same time you can expect the highest levels of academic, pastoral and employability support, giving you the best chance of success, now and in the future."
    },
    {
        "id": "bf50d270420dffde239f3176016cd82c",
        "university_name": "Grwp Llandrillo Menai",
        "location": "Llandudno Rd, Rhos on Sea, Colwyn Bay, Conwy, LL28 4HZ",
        "official_website": "https://www.gllm.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01492 546666",
        "course_locations": [
            {
                "location_name": "Coleg Menai - Llangefni",
                "location_address": "Penmynydd Road, Llangefni, LL77 7HY"
            },
            {
                "location_name": "Coleg Menai - Bangor",
                "location_address": "Ffriddoedd Road, Bangor, LL57 2TP"
            },
            {
                "location_name": "Coleg Llandrillo - Rhos on Sea",
                "location_address": "Llandudno Road, Rhos on Sea, Colwyn Bay, LL28 4HZ"
            },
            {
                "location_name": "Coleg Meirion-Dwyfor - Dolgellau",
                "location_address": "Ffordd Ty'n y Coed, Dolgellau, LL40 2SW"
            },
            {
                "location_name": "Coleg Menai - Parc Menai",
                "location_address": "Parc Menai, Bangor, LL57 4BN"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/12a66ed8-c9de-9660-c5e0-fcc8dd42ab6d",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/llandrillograduation_photo_4mb.jpg",
        "about_us": "N/A"
    },
    {
        "id": "715d444656ef3013d6ee8dc631a05d7b",
        "university_name": "Collective Acting Studio",
        "location": "Collective Acting Studio, Hornsey Road Baths, 2 Tiltman Place, Islington,, London, N7 7EE",
        "official_website": "https://collectiveactingstudio.co.uk",
        "contact_email": "admissions@collectiveactingstudio.co.uk",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Collective Acting Studio",
                "location_address": "Hornsey Road Baths, 2 Tiltman Place, off Hornsey Road, Finsbury Park, London, N7 7EE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/1729a34a-8739-4045-ae7b-065737943c6e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/picture_3-2.png",
        "about_us": "We believe our society is structurally unfair. Some groups are routinely advantaged over others, and that needs to change.\u00a0At Collective, we champion actors, storytellers, creatives and artistic leaders from underrepresented groups, particularly in terms of ethnicity, disability and class. By amplifying fresh new voices, we want to help rebalance our society \u2013 as actors like you have the power to change the world!"
    },
    {
        "id": "227398120abdaa76b53804fd5fd58bb5",
        "university_name": "College of Agriculture, Food and Rural Enterprise",
        "location": "Greenmount Campus,, Muckamore, Antrim, Co Antrim, BT41 4PU",
        "official_website": "http://www.cafre.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "028 9442 6601",
        "course_locations": [
            {
                "location_name": "Enniskillen Campus",
                "location_address": "2 Mullaghmeen Road, Levaghy, Enniskillen, BT74 4GF"
            },
            {
                "location_name": "Loughry Campus",
                "location_address": "76 Dungannon Road, Cookstown, BT80 9AA"
            },
            {
                "location_name": "Greenmount Campus",
                "location_address": "45 Tirgracey Road, Muckamore, Antrim, BT41 4PS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/eaafa9fa-ef49-f02f-29a8-33cccc778fd0",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/food_technology_centre.jpg",
        "about_us": "N/A"
    },
    {
        "id": "71b31ba850848c82fe0a634e3cab512f",
        "university_name": "College of Esports",
        "location": "Lee Valley VeloPark, Abercombie Road, Stratford, London, E20 3AB",
        "official_website": "https://collegeofesports.ac.uk/",
        "contact_email": "info@collegeofesports.ac.uk",
        "contact_phone": "020 8154 9529",
        "course_locations": [
            {
                "location_name": "College of Esports",
                "location_address": "Lee Valley VeloPark, Abercrombie Road, Queen Elizabeth Olympic Park, London, E20 3AB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/878ef093-5bb5-456c-ab63-3d23cf939ae5",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/velodrome-1.png",
        "about_us": "The College of Esports (CoEs) has created a Higher Education (HE) institution focusing on the business of Esports. We offer significant learning, teaching, research and career opportunities on our range of esports courses involving a breadth of culturally diverse, inclusive, enriching, stimulating and rewarding experiences.\u00a0We aim to equip our students with skills and\u00a0capabilities\u00a0to pursue long, and\u00a0successful\u00a0professional career in the industry."
    },
    {
        "id": "55467d6da8d28911763ba5a24ddb0c5a",
        "university_name": "Cond\u00e9 Nast College of Fashion &amp; Design",
        "location": "31 Bedford Square, London, London, WC1B 3EE",
        "official_website": "https://www.condenastcollege.ac.uk",
        "contact_email": "admissions@condenastcollege.ac.uk",
        "contact_phone": "02071523435",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "30-31 Bedford Square, London, WC1B 3EE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a435d80e-3ea2-484d-b84d-ec99a90a83bb",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/bloomsbury_campus.jpg",
        "about_us": "NAME CHANGE NOTICE: Cond\u00e9 Nast College of Fashion &amp; Design is changing its name to Vogue College of Fashion on 30th September 2024.\u00a0The College provides students with a world-class education in the heart of London. In preparing students for a career in the fashion, media, and luxury lifestyle industries, the College opens the doors to a unique world of excellence and creativity.\u00a0The courses fuse exciting and intensive academic learning with unrivaled access to the best in the industry, including the teams at Vogue, Glamour, GQ, and a host of other Cond\u00e9 Nast titles. The College delivers creative courses combined with a solid foundation in the business and strategic sides of the fashion, media, and luxury lifestyle industries. The courses prepare students for careers in styling, journalism, buying, marketing, branding, graphic design, PR, digital strategy, business, e-commerce, and myriad other careers within these exciting industries.\u00a0"
    },
    {
        "id": "f6f8d4a37e9bda92fd1089fb69825544",
        "university_name": "Cornwall College",
        "location": "Cornwall College Camborne, Trevenson Road, Cornwall, Cornwall, TR15 3GF",
        "official_website": "https://www.cornwall.ac.uk/university/",
        "contact_email": "enquiries@cornwall.ac.uk",
        "contact_phone": "0330 123 2523",
        "course_locations": [
            {
                "location_name": "Duchy College Stoke Climsland",
                "location_address": "Callington, Cornwall, PL17 8PB"
            },
            {
                "location_name": "Newquay University Centre Cornwall College",
                "location_address": "Wildflower Lane, Trenance Gardens, Newquay, TR7 2LZ"
            },
            {
                "location_name": "Falmouth Marine School",
                "location_address": "Killigrew Street, Falmouth, TR11 3QS"
            },
            {
                "location_name": "Lindfield",
                "location_address": "Lindfield Golf Club, East Mascalls Lane, Lindfield, RH16 2QN"
            },
            {
                "location_name": "Bicton College",
                "location_address": "East Budleigh, Budleigh Salterton, EX9 7BY"
            },
            {
                "location_name": "Cornwall College Camborne",
                "location_address": "Trevenson Road, Pool, Redruth, TR15 3RD"
            },
            {
                "location_name": "Cornwall College University Centre at the Eden Project",
                "location_address": "Bodelva, Cornwall, St Austell, PL24 2SG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6fc7541d-f148-c346-b2d6-6daf9d7f845e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/cc-ucas-banner2.jpg",
        "about_us": "We offer a unique university-level experience and it\u2019s the differences that make us stand out.\u00a0Our bespoke qualifications place the development of real, vocationally-relevant skills that are valued by employers, at the heart of our offer.This is all made possible through close working relationships with our industry partners. It\u2019s these all-important real-world experiences that guide our graduates to rewarding careers.Smaller class sizes, combined with wraparound pastoral care and dedicated tutorial support, allow each individual to stand out and become truly engaged in the learning experience. \u00a0\u00a0"
    },
    {
        "id": "1b47cd34ce1f90dd950dba126ca3773e",
        "university_name": "Courtauld Institute of Art, University of London",
        "location": "The Courtauld\r\nVernon Square\r\nPenton Rise, Somerset House, Strand, London, London, WC1X 9EW",
        "official_website": "https://www.courtauld.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "020 7848 2645",
        "course_locations": [
            {
                "location_name": "Courtauld Institute of Art",
                "location_address": "Somerset House, London, WC2R 0RN"
            },
            {
                "location_name": "Courtauld Institute of Art",
                "location_address": "Vernon Square, Penton Rise, London, WC1X 9EW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6ec9c892-374c-0710-77c7-190b76d28233",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "9ff55416635b657a42a6fad95604f435",
        "university_name": "Coventry College",
        "location": "50 Swanswell Street,, 50 Swanswell Street, Coventry, West Midlands, CV1 5DG",
        "official_website": "http://www.covcollege.ac.uk/Pages/default.aspx",
        "contact_email": "hello@coventrycollege.ac.uk",
        "contact_phone": "02476 932932",
        "course_locations": [
            {
                "location_name": "City Campus",
                "location_address": "50 Swanswell Street, Coventry, CV1 5DG"
            },
            {
                "location_name": "Midland Academy of Dance and Drama (MADD)",
                "location_address": "Building B, 428 Carlton Hill, Nottingham, NG4 1QA"
            },
            {
                "location_name": "Studio 79",
                "location_address": "St John Street, Leicester, LE1 3WL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/e86a79e3-8338-a05d-b6f6-ea37bcf8bcb7",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/img_9693.jpg",
        "about_us": "N/A"
    },
    {
        "id": "7b25ffb27388afdf28e1b5bd7b6fd4c0",
        "university_name": "Cranfield University",
        "location": "Cranfield, Bedford, MK43 0AL",
        "official_website": "https://www.cranfield.ac.uk/",
        "contact_email": "studysom@cranfield.ac.uk",
        "contact_phone": "+441234758081",
        "course_locations": [
            {
                "location_name": "Shrivenham",
                "location_address": "Cranfield University, Shrivenham, Swindon, Swindon, SN6 8LA"
            },
            {
                "location_name": "Main Site",
                "location_address": "Cranfield, Bedford, MK43 0AL"
            },
            {
                "location_name": "Cranfield Campus",
                "location_address": "Cranfield, Bedfordshire, Milton Keynes, MK43 0AL"
            },
            {
                "location_name": "Cranfield School of Management",
                "location_address": "Cranfield, Bedfordshire, Milton Keynes, MK43 0AL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/2a9fba46-7669-0b57-0353-203ec4474217",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/cranfield_uni_building_banner3.jpg",
        "about_us": "Cranfield is a specialist postgraduate university that is a global leader for education and transformational research in technology and management.We have many world-class, large-scale facilities, including our own global research airport, which offers a unique environment for transformational education and research, living laboratory for testing transformative technologies and new approaches to deliver enhanced social, economic and environmental outcomes.\u00a0Cranfield School of Management is one of the oldest business schools in Europe. As part of Cranfield University, we are uniquely placed to connect technology and leadership.\u00a0In 2021 the University opened a \u00a37.2 million centre of excellence with the latest forensic equipment, a mortuary, expert academic staff and innovative courses in the fields of crime scene investigation, digital forensics investigation and forensic materials analysis.\u00a0Our results are recognised in our Rankings and awards and the success of our alumni.\u00a0"
    },
    {
        "id": "714a6f652fef437068586aea0bfe62fd",
        "university_name": "Craven College",
        "location": "Aireville Campus, Gargrave Road, Skipton, Skipton, North Yorkshire, BD23 1US",
        "official_website": "https://www.craven-college.ac.uk",
        "contact_email": "customerservices@craven-college.ac.uk",
        "contact_phone": "01756 791 411",
        "course_locations": [
            {
                "location_name": "Auction Mart Campus",
                "location_address": "Craven Auction Mart, Skipton, BD23 1UX"
            },
            {
                "location_name": "The Aviation Academy",
                "location_address": "Southside Aviation Centre, Leeds Bradford Airport, Leeds, LS19 1GU"
            },
            {
                "location_name": "Aireville Campus",
                "location_address": "Aireville Campus, Gargrave Road, Skipton, BD23 1US"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/aecda516-2b36-278d-a1d8-f8c7b78b4330",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas.jpg",
        "about_us": "Craven College, Skipton is a great place to study \u2013 in the 23-24 National Student Survey\u00a0 90% of Craven students agreed that the teaching was good and\u00a0 93% \u00a0that the teaching staff made the subject engaging and that their course had developed the skills and knowledge that they needed for their future.We offer HNC\u2019s, Foundation Degrees with an option to convert them into a full honours degree at Craven College or another college/university. To ensure all of our courses are industry-relevant, we write them in consultation with employers and also add key employment skills such as time-management, ICT and numeracy into your programme."
    },
    {
        "id": "ba2a47771f7031c61b0be8ee4952cced",
        "university_name": "Croydon University Centre",
        "location": "College Road,\r\nCroydon, London, CR9 1DX",
        "official_website": "https://www.croydon.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "020 8686 5700",
        "course_locations": [
            {
                "location_name": "University Centre Croydon",
                "location_address": "College Road, Croydon, CR9 1DX"
            },
            {
                "location_name": "Main Site",
                "location_address": "College Road, London, CR9 1DX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ca2a0ff9-645c-8902-d0a2-9417bcb63a37",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_1.png",
        "about_us": "N/A"
    },
    {
        "id": "a13193b05ffe59a5de378849324cf37f",
        "university_name": "University of Cumbria",
        "location": "University of Cumbria, Fusehill Street, Carlisle, Cumbria, CA1 2HH",
        "official_website": "https://www.cumbria.ac.uk/",
        "contact_email": "enquirycentre@cumbria.ac.uk",
        "contact_phone": "01228 588 588",
        "course_locations": [
            {
                "location_name": "Carlisle - Fusehill Street",
                "location_address": "Fusehill Street, Carlisle, CA1 2HH"
            },
            {
                "location_name": "School Direct",
                "location_address": "University of Cumbria in Lancaster, Bowerham Road, Lancaster, LA1 3JD"
            },
            {
                "location_name": "Ambleside",
                "location_address": "Rydal Road, Ambleside, LA22 9BB"
            },
            {
                "location_name": "Main Site",
                "location_address": "Registered Office, Fusehill Street, Carlisle, CA1 2HH"
            },
            {
                "location_name": "London - Tower Hamlets",
                "location_address": "Foundation Court, 58 East India Dock Road, London, E14 6JE"
            },
            {
                "location_name": "Lancaster",
                "location_address": "Bowerham Road, Lancaster, LA1 3JD"
            },
            {
                "location_name": "Carlisle - Brampton Road",
                "location_address": "Brampton Road, Carlisle, CA3 9AY"
            },
            {
                "location_name": "Barrow-in-Furness",
                "location_address": "University of Cumbria at Furness College, Channelside, Barrow-in-Furness, LA14 2PJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ee20f1e0-76fd-86be-4f36-ffa8d1a1505a",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/uoc-summer-graduation-carlisle-007.jpg",
        "about_us": "We are Cumbria. We are passionate about our people, our place, and enriching our world and community. With us, you'll get more than just a degree; you'll get life-changing experiences and amazing memories.\u00a0We are a growing multi-campus university that is home to over 14,000 students. Encompassing Ambleside, Barrow (from 2025), Carlisle, Lancaster and London, our historic campuses are surrounded by natural beauty, vibrant student communities and endless career opportunities. We're also the only UK university with a campus in a World UNESCO Heritage Site! \u00a0 \u00a0 \u00a0It is our mission to provide you with skills you need to pursue your passions, optimise your true potential and go far in your chosen career. To help you achieve this, we offer a wide range of internationally-recognised degrees in health, sports, social work, teaching, business, law, science, conservation, outdoor studies, arts, creativity, tourism, policing, advanced manufacturing engineering, computer science and more."
    },
    {
        "id": "0d0592d9003bcc406c9ba0b1a9dd4bf7",
        "university_name": "David Game College - Higher Education Centre",
        "location": "32-35 Jewry Street, London, London, EC3N 2ET",
        "official_website": "https://www.dghe.ac.uk",
        "contact_email": "info@dghe.ac.uk",
        "contact_phone": "02032200347",
        "course_locations": [
            {
                "location_name": "David Game Higher Education",
                "location_address": "32-35 Jewry Street, London, EC3N 2ET"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/e859e326-6038-1ddb-11b6-acca57d8ea88",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_-_dghe_banner.png",
        "about_us": "David Game Higher Education (DGHE) is an independent college based in the Centre of London offering BTEC HND, Top-Up, Foundation and Degree courses in the areas of business and marketing, health and social care, public services and criminology, and art and design. DGHE offers a vibrant, practical and inclusive learning environment that aims to support students to thrive and reach their full potential and advance their careers. We welcome students from all backgrounds and employ lecturers who offer both industry experience and exceptional teaching talent."
    },
    {
        "id": "ae68ae2b139325653831d2b59f3f0ff6",
        "university_name": "dBs Institute",
        "location": "2 Mitchell Lane, Bristol, BS1 6BU",
        "official_website": "https://www.dbsinstitute.ac.uk",
        "contact_email": "admissions@dbsinstitute.ac.uk",
        "contact_phone": "0161 552 6467",
        "course_locations": [
            {
                "location_name": "DBS Institute Plymouth",
                "location_address": "6 Elizabeth Court, Higher Lane, Plymouth, PL1 2AN"
            },
            {
                "location_name": "DBS Institute Manchester",
                "location_address": "New Century, Basement Level, 34 Hanover Street, Manchester, M4 4AH"
            },
            {
                "location_name": "DBS Institute Bristol",
                "location_address": "2 Mitchell Lane, Bristol, BS1 2BU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/11b2cb24-db24-4d5a-9275-714518b4fa0e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas-header-x2.jpg",
        "about_us": "Located across 3 campuses in the lively cities of Bristol, Manchester and Plymouth, we specialise in music technology, game design and the creative industries and\u00a0are home to over 500 students.\u00a0Whether you envision yourself as a game developer, game artist, DJ, producer, sound engineer or a film and TV composer, we provide the specialised skills and knowledge to turn your aspirations into reality.\u00a0dBs Institute provides an unparalleled experience for pursuing your undergraduate degree. It's not just a place to study - it's a launchpad for your creative career.\u00a0Be inspired by our state-of-the-art facilities, industry-standard equipment and collaborative spaces built for breaking boundaries. Our campuses have been meticulously designed to mirror the industry; giving you the ultimate environment to hone your skills.\u00a0Ready to immerse yourself in the creative industries? Join dBs Institute and be part of a community that's driving the future of the creative industries.\u00a0"
    },
    {
        "id": "989e38ec6373ed680b55940abedc839e",
        "university_name": "De Montfort University",
        "location": "The Gateway,, Leicester, Leicestershire, LE1 9BH",
        "official_website": "https://www.dmu.ac.uk/home.aspx?utm_campaign=homepage_202223&utm_content=ucas_homepage&utm_medium=ucas_profile_202223&utm_source=ucas",
        "contact_email": "enquiry@dmu.ac.uk",
        "contact_phone": "0116 2577300",
        "course_locations": [
            {
                "location_name": "SCL Education Group",
                "location_address": "SCL Tomlinscote Vocational Centre, Tomlinscote Way, Frimley, Surrey, GU16 8PY"
            },
            {
                "location_name": "Partner College DMUIC",
                "location_address": "DMU International Pathway College, Leicester, LE1 9BH"
            },
            {
                "location_name": "Main Site",
                "location_address": "The Gateway, Leicester, LE1 9BH"
            },
            {
                "location_name": "Leicester College",
                "location_address": "Abbey Park Campus, Painter Street, Leicester, LE1 3WA"
            },
            {
                "location_name": "Clephan Building",
                "location_address": "Oxford Street, Leicester, LE1 5XY"
            },
            {
                "location_name": "Leicester Campus",
                "location_address": "The Gateway, Leicester, LE1 9BH"
            },
            {
                "location_name": "Performance Preparation Academy (Guildford)",
                "location_address": "Guildford, Guildford, GU1 4LH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6d06ed34-78f3-acef-c9b9-24ec161e7639",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/hero-2_rgb_added-sky-04edit_landscape_ucas2.jpg",
        "about_us": "De Montfort University Leicester (DMU) is a diverse UK university with a global outlook and a focus on launching successful graduate careers with the support of its award-winning careers service. University is your new beginning and at DMU you can achieve things you never thought you could. Having educated and trained for more than 150 years, we are creating opportunities to empower students, staff and the wider community to create a fairer and more sustainable society that transforms lives. Students will benefit from a curriculum that's informed by active research and industry demand, while being taught in a way that ensures they get the experience they need to graduate with real prospects.\u00a0Students value their experience at DMU, with the university placed 14th nationwide in the \u2018University of the Year\u2019 category at the 2022 WhatUni Student Choice Awards.\u00a0"
    },
    {
        "id": "eb4e0754b62c17ecae3cd96ca125695f",
        "university_name": "Derby College",
        "location": "The Roundhouse, Roundhouse Road, Derby, Derbyshire, DE24 8JE",
        "official_website": "https://www.derby-college.ac.uk",
        "contact_email": "enquiries@derby-college.ac.uk",
        "contact_phone": "01332 387400",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Roundhouse Road, Derby, DE24 8JE"
            },
            {
                "location_name": "Broomfield Hall",
                "location_address": "Morley, Ilkeston, DE7 6DN"
            },
            {
                "location_name": "Joseph Wright Centre",
                "location_address": "Cathedral Road, Derby, DE1 3PA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/1fd8988e-1782-4930-75cc-b23337bb5761",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/dcg_the_roundhouse_-_pano.jpg",
        "about_us": "The vision of Derby College Group (DCG) is to create world-class, accessible education opportunities that enrich lives and make bright futures a reality for all. DCG is committed to providing educational excellence, championing social mobility and driving economic prosperity."
    },
    {
        "id": "46ab8e4d97d28ae9f3fe1ec43f726352",
        "university_name": "DN Colleges Group",
        "location": "Doncaster College (Registered office), The Hub, Chappell Drive, Doncaster, South Yorkshire, DN1 2RF",
        "official_website": "https://www.dncolleges.ac.uk/",
        "contact_email": "info@dncolleges.ac.uk",
        "contact_phone": "01302553553",
        "course_locations": [
            {
                "location_name": "University Campus North Lincolnshire",
                "location_address": "Ashby Road, Scunthorpe, DN16 1BU"
            },
            {
                "location_name": "University Campus Doncaster",
                "location_address": "The Hub, Chappell Drive, Doncaster, DN1 2RF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a3e4c2d4-7f29-5ae2-71d0-30b67eb83bfc",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas-banner.png",
        "about_us": "N/A"
    },
    {
        "id": "ad456172e48c249052e93601b42b2241",
        "university_name": "Dudley College",
        "location": "The Broadway,, Dudley, West Midlands, DY1 4AS",
        "official_website": "https://www.dudleycol.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01384  363000",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "The Broadway, Dudley, DY1 4AS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/0299fcf2-c765-c412-9d51-a18a32b39f0c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/broadway_front_7.jpg",
        "about_us": "N/A"
    },
    {
        "id": "0192718951494a15a04353b3cf1d402e",
        "university_name": "University of Dundee",
        "location": "Nethergate, Dundee, DD1 4HN",
        "official_website": "https://www.dundee.ac.uk/",
        "contact_email": "contactus@dundee.ac.uk",
        "contact_phone": "01382 383838",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Nethergate, Dundee, DD1 4HN"
            },
            {
                "location_name": "Dundee and Angus College",
                "location_address": "Old Glamis Road, Dundee, DD3 8LE"
            },
            {
                "location_name": "Fife Campus (Kirkcaldy)",
                "location_address": "Forth Avenue, Kirkcaldy, KY2 5YS"
            },
            {
                "location_name": "Ninewells Hospital and Medical School",
                "location_address": "Ninewells, Dundee, DD1 9SY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/bfcb0d31-ce4c-f762-62a2-aaa148d556bd",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/dundee_towercafeview-2_-_1920x420_002.jpg",
        "about_us": "Discover your future and open your mind to the exciting opportunities that await you at one of the UK\u2019s most dynamic and vibrant universities. Located on the east coast of Scotland, the University of Dundee\u2019s self-contained main campus, in the West End of Dundee, is your home away from home in a compact, friendly and diverse city. Dundee\u2019s West End was named the best place to live in Scotland (The Sunday Times 2024).\u00a0\u00a0"
    },
    {
        "id": "43ef12ecd34ff4f4cfd23a62011676b2",
        "university_name": "Durham University",
        "location": "Palatine Centre, Stockton Road,, Durham, County Durham, DH1 3LE",
        "official_website": "https://www.durham.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "0191 334 1000",
        "course_locations": [
            {
                "location_name": "Durham University",
                "location_address": "South Road, Durham, DH1 3LG"
            },
            {
                "location_name": "Durham City",
                "location_address": "Palatine Centre, Durham, DH1 3HP"
            },
            {
                "location_name": "Cranmer Hall",
                "location_address": "St John's College, Durham University, Durham, DH1 3RJ"
            },
            {
                "location_name": "Georgia",
                "location_address": "N/A"
            },
            {
                "location_name": "Keshav",
                "location_address": "N/A"
            },
            {
                "location_name": "Nathan",
                "location_address": "N/A"
            },
            {
                "location_name": "Yoyo",
                "location_address": "N/A"
            },
            {
                "location_name": "Mia",
                "location_address": "N/A"
            },
            {
                "location_name": "Joseph",
                "location_address": "N/A"
            },
            {
                "location_name": "Frederick",
                "location_address": "N/A"
            },
            {
                "location_name": "Emily",
                "location_address": "N/A"
            },
            {
                "location_name": "Bradley",
                "location_address": "N/A"
            },
            {
                "location_name": "Isabel ",
                "location_address": "N/A"
            },
            {
                "location_name": "Alexandra",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6e455568-e42f-9d6a-49d3-d3af4f57443d",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/prospectus_banner_1920_x_650_px_1.jpg",
        "about_us": "Choose from a huge range of fascinating courses and enjoy our world-class facilities. Join our supportive college community and benefit from our outstanding wider student experience."
    },
    {
        "id": "14ec1f1a804d1139ef7b44c7d4e02234",
        "university_name": "New College Durham",
        "location": "Framwellgate Moor Campus, Framwellgate, County Durham, DH1 5ES",
        "official_website": "https://www.newcollegedurham.ac.uk",
        "contact_email": "admissions@newdur.ac.uk",
        "contact_phone": "0191 375 4400",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Framwellgate Moor, Durham, DH1 5ES"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/2daba510-7fb3-dfee-deaa-b192ead4ac0e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_img.jpg",
        "about_us": "N/A"
    },
    {
        "id": "7e7d0595a4e29fd4e08074dcd4d8860a",
        "university_name": "Dyslexia Action",
        "location": "Centurion House, London Road, Staines-upon-Thames, TW18 4AX",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Centurion House, London Road, Staines-upon-Thames, TW18 4AX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/70100535-72e5-7de4-2c26-c241ab88307f",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/teacher_supporting_pupils_banner.jpg",
        "about_us": "N/A"
    },
    {
        "id": "564529000ae51b51abd72e37988b1804",
        "university_name": "East 15 Acting School",
        "location": "Hatfields, Rectory Lane, Loughton, IG10 3RY",
        "official_website": "https://www.east15.ac.uk/",
        "contact_email": "east15@essex.ac.uk",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Loughton Campus",
                "location_address": "Hatfields, Rectory Lane, Loughton, IG10 3RY"
            },
            {
                "location_name": "Southend Campus",
                "location_address": "Elmer Approach, Southend-on-Sea, SS1 1LW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/7dce1b37-38c8-b717-893b-cdeed13799b5",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/untitled_design_5.png",
        "about_us": "N/A"
    },
    {
        "id": "cc4ebcb38483716a367146e0d1390234",
        "university_name": "University of East Anglia UEA",
        "location": "Norwich Research Park, Norwich Research Park, Norwich, Norfolk, NR4 7TJ",
        "official_website": "http://www.uea.ac.uk?utm_medium=third_party_sites&utm_source=ucas&utm_campaign=UG24_AW_&utm_content=UCAS_institution_profile",
        "contact_email": "admissions@uea.ac.uk",
        "contact_phone": "01603 456161",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "University of East Anglia, Norwich, NR4 7TJ"
            },
            {
                "location_name": "Main Site",
                "location_address": "Norwich Research Park, Norwich, NR4 7TJ"
            },
            {
                "location_name": "University of East Anglia",
                "location_address": "Norwich Research Park, Norwich, NR4 7TJ"
            },
            {
                "location_name": "Evie",
                "location_address": "N/A"
            },
            {
                "location_name": "Embla",
                "location_address": "N/A"
            },
            {
                "location_name": "Alexandra",
                "location_address": "N/A"
            },
            {
                "location_name": "Daisy",
                "location_address": "N/A"
            },
            {
                "location_name": "Aim\u00e9e ",
                "location_address": "N/A"
            },
            {
                "location_name": "Beth",
                "location_address": "N/A"
            },
            {
                "location_name": "Francesca",
                "location_address": "N/A"
            },
            {
                "location_name": "Emma",
                "location_address": "N/A"
            },
            {
                "location_name": "Dhara",
                "location_address": "N/A"
            },
            {
                "location_name": "Eleanor",
                "location_address": "N/A"
            },
            {
                "location_name": "Charlotte",
                "location_address": "N/A"
            },
            {
                "location_name": "Ayesha",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/59590204-91b1-f9f8-de3f-924f0940515f",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/banner_image_.jpg",
        "about_us": "How can we help build your future? That is the question at the heart of everything we do at UEA. We understand you are unique, providing space and opportunities to develop your skills. We want our students to know they are a part of something bigger. To belong, to find your community and your cause. Innovation is our DNA and our beautiful campus, with 320 acres of open space, was designed with a vision: to create the ideal community environment. To build a place where work and life are perfectly balanced. To combine cutting-edge technology spaces for learning and a natural, picturesque habitat. To ensure that productivity and inspiration can thrive. Our students are passionate about making changes whether through research, medicine or discovering lost shipwrecks. We are ranked\u00a0UK Top 25 (Complete University Guide 2024) and UK Top 30 (The Times/Sunday Times 2024)\u00a0as well as World Top 300\u00a0(QS World University Rankings 2024). Come and find your place with us!"
    },
    {
        "id": "eb6bffd3643c592108c66be8a449c0af",
        "university_name": "EKC Group",
        "location": "New Dover Road, Canterbury, Kent, CT1 3AJ",
        "official_website": "https://www.ekcgroup.ac.uk/",
        "contact_email": "highered@eastkentcollege.ac.uk",
        "contact_phone": "01227 811111",
        "course_locations": [
            {
                "location_name": "Canterbury College",
                "location_address": "New Dover Road, Canterbury, CT1 3AJ"
            },
            {
                "location_name": "Ashford College",
                "location_address": "Elwick Road, Ashford, TN23 1NN"
            },
            {
                "location_name": "Folkestone College",
                "location_address": "Shorncliffe Road, Folkestone, CT20 2TZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ae6f4d85-e45e-1129-677c-739ee1734486",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_image_front_entrance_2.jpg",
        "about_us": "EKC Group offer a range of Higher Education courses, delivered at Canterbury, Ashford and Folkestone colleges. These courses will provide you with the skills, knowledge and qualifications to start a lifelong career in your chosen field."
    },
    {
        "id": "430f0e2cf57c5599fafa621500b63430",
        "university_name": "University of East London",
        "location": "Docklands Campus, University Way, London, E16 2RD",
        "official_website": "http://www.uel.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "020 8223 3333",
        "course_locations": [
            {
                "location_name": "Stratford Campus",
                "location_address": "Water Lane, Stratford, E15 4LZ"
            },
            {
                "location_name": "Main Site",
                "location_address": "University Way, London, E16 2RD"
            },
            {
                "location_name": "UD (UD Music Foundation)",
                "location_address": "Talent House, 3 Sugar House Lane, London, E15 2QS"
            },
            {
                "location_name": "Main Site",
                "location_address": "Docklands Campus, 4-6 University Way, Newham, E16 2RD"
            },
            {
                "location_name": "Ducere Global Business School",
                "location_address": "Ducere Global Business School,, Suite 5, 120 Newport Centre Drive,, Newport Beach,, CA 92660"
            },
            {
                "location_name": "Docklands Campus",
                "location_address": "University Way, London, E16 2RD"
            },
            {
                "location_name": "University Square Stratford",
                "location_address": "1 Salway Road, Newham, E15 1NF"
            },
            {
                "location_name": "Islington Music Workshop (IMW)",
                "location_address": "24 Seward Street, London, EC1V 3PA"
            },
            {
                "location_name": "Maria Montessori Institute",
                "location_address": "41 Riding House Street, London, W1W 7BE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6d89f913-12f6-c979-28f1-9721878fac41",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_0.png",
        "about_us": "125 Years of Pioneering Futures\u00a0\u00a0\u00a0Since 1898, the University of East London has been a leader in careers-first education, supporting the local community and beyond.\u00a0Today, we are continuing that mission to improve our communities and reduce inequalities by preparing our students for the careers of the future and making education accessible to all.\u00a0\u00a0We offer a wide range of courses across different subject areas including business, law, the arts, and social sciences. In addition to our academic programs, the university is actively involved in community outreach and engagement and works with local organisations and businesses to create mutually beneficial partnerships for the good of the community and the university."
    },
    {
        "id": "21e611296c5e250c13fa3ed3e97d4613",
        "university_name": "East Riding College",
        "location": "Flemingate Centre, \r\nArmstrong Way, Armstrong Way, Beverley, East Yorkshire, HU17 0GH",
        "official_website": "https://www.eastridingcollege.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "0345 120 0044",
        "course_locations": [
            {
                "location_name": "Beverley Campus",
                "location_address": "Flemingate Centre, Beverley, HU17 0GH"
            },
            {
                "location_name": "Bridlington",
                "location_address": "St Mary's Walk, Bridlington, YO16 7JW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/7837e9ef-950e-4257-50da-be07151bdc23",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/east_riding_college_flemingate_campus.jpg",
        "about_us": "N/A"
    },
    {
        "id": "d365ab139628dc53c140f673c82cf88d",
        "university_name": "East Sussex College",
        "location": "East Sussex College Head Office, Eastbourne Campus, Cross Levels Way, Eastbourne, East Sussex, BN21 2UF",
        "official_website": "https://www.escg.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "School of Information Risk Management",
                "location_address": "Chapel Road, Lodnon, IG1 2DR"
            },
            {
                "location_name": "Eastbourne",
                "location_address": "Cross Levels Way, Eastbourne, BN212UF"
            },
            {
                "location_name": "Hastings (Station Plaza)",
                "location_address": "Station Approach, Hastings, TN34 1BA"
            },
            {
                "location_name": "Hastings (Ore Valley)",
                "location_address": "Parker Road, Hastings, TN34 3TT"
            },
            {
                "location_name": "Lewes",
                "location_address": "Mountfield Road, Lewes, BN7 2XH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/9597b298-4100-24a4-7026-9d56260243a1",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/thinkstockphotos-486862953-1-black-and-white.jpg",
        "about_us": "East Sussex is an attractive place to work, live, study, and play; thanks to its vibrant, cosmopolitan and bohemian atmosphere. Many of our degree students are nominated for and win national awards for their work, and go on to work all around the world for international companies in London, New York, Los Angeles and Dubai. Our student to staff ratio,\u00a0 student support, affordable fees and fantastic surroundings, makes us a very attractive option to study degrees, and more recently, Higher Apprenticeships. Our university-style campuses are fantastic places to learn.We\u2019re lucky to have everything from 3G all-weather sports pitches to music recording studios and the latest recording technology. Our tutors are always available and happy to talk about the courses we offer.\u00a0Each year, more and more students are choosing to study with us because our specialist courses are helping them to get into the jobs they want and achieve life-long success."
    },
    {
        "id": "5c22ea2c4bd668ebd804f2c7fcdf786c",
        "university_name": "Edinburgh Napier University",
        "location": "Merchiston Campus10 Colinton Road, Edinburgh, EH10 5DT",
        "official_website": "http://www.napier.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "219 Colinton Road, Edinburgh, EH14 1DJ"
            },
            {
                "location_name": "West Lothian College",
                "location_address": "The Hub, Livingston, EH54 7EY"
            },
            {
                "location_name": "Sighthill",
                "location_address": "Sighthill Campus, Bankhead Avenue, Edinburgh, EH11 4DE"
            },
            {
                "location_name": "Merchiston Campus",
                "location_address": "10 Colinton Road, Edinburgh, EH10 5DT"
            },
            {
                "location_name": "Craiglockhart Campus",
                "location_address": "Craiglockhart Campus, 219 Colinton Road, Edinburgh, EH14 1DJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/dc3ccbfc-cc9a-a614-e70a-12f74560c026",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner3.jpg",
        "about_us": "N/A"
    },
    {
        "id": "35267595b7769efd07ec68dfb3aa7d2f",
        "university_name": "The University of Edinburgh",
        "location": "Old College, South Bridge, Edinburgh, EH8 9YL",
        "official_website": "https://www.ed.ac.uk/?utm_source=ucas&utm_medium=instprofile&utm_campaign=institutionprofiles&utm_term=&utm_content=websiteurl",
        "contact_email": "futurestudents@ed.ac.uk",
        "contact_phone": "+44 (0)131 650 1000",
        "course_locations": [
            {
                "location_name": "Central area campus",
                "location_address": "George Square, Edinburgh, EH8 9JZ"
            },
            {
                "location_name": "Main Site",
                "location_address": "The University of Edinburgh, Old College, South Bridge, Edinburgh, EH8 9YL"
            },
            {
                "location_name": "King's Buildings",
                "location_address": "West Mains Rd, Edinburgh, EH9 3JW"
            },
            {
                "location_name": "Western General Hospital",
                "location_address": "Crewe Road South, Edinburgh, EH4 2XU"
            },
            {
                "location_name": "Edinburgh College of Art",
                "location_address": "Lauriston Place, Edinburgh, EH3 9DF"
            },
            {
                "location_name": "Moray House School of Education and Sport",
                "location_address": "The University of Edinburgh, Old Moray House, Holyrood Road, Edinburgh, EH8 8AQ"
            },
            {
                "location_name": "Royal (Dick) School of Veterinary Studies",
                "location_address": "Easter Bush, Midlothian, Edinburgh, EH25 9RG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/114bb2e7-442a-d9fd-ee93-bdf998c6ca0e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_-_2025_v3.png",
        "about_us": "Edinburgh. Extraordinary futures await.We live in a complex, fast-changing world and we\u2019re honest about the significant challenges facing us all. As a leading global university, we know education will play a vital role solving those challenges and relish our shared responsibility to respond to them.World leading reputationThe University of Edinburgh is one of the world's top universities, consistently ranked in the world top 50* and placed 27th in the 2025 QS World University Rankings.Our entrepreneurial and cross-disciplinary culture attracts students and staff from across the globe, creating a unique Edinburgh experience. Whatever excites you, whatever your ambition, whatever makes you \u2018you\u2019, we know one thing; nothing ordinary comes from this extraordinary place.*Times Higher Education World RankingsBanner image\u00a0\u00a9Chris Close"
    },
    {
        "id": "872f3adc53476000a1bd315669469fb6",
        "university_name": "Escape Studios",
        "location": "6 Mitre Passage, North Greenwich, London, SE10 0ER",
        "official_website": "https://www.escapestudios.ac.uk/?utm_source=ucas&utm_medium=onlinelisting&utm_campaign=profiles_esc",
        "contact_email": "hello@escapestudios.ac.uk",
        "contact_phone": "0203 441 1303",
        "course_locations": [
            {
                "location_name": "Escape Studios",
                "location_address": "6 Mitre Passage, London, SE10 0ER"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/c615f773-2bcc-0f11-c675-03ba813f7f0e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/1920x700_three-students-sat-on-wall-with-skateboard-escape-studios_ucas-banner-image_2.jpg",
        "about_us": "Escape Studios is one of the UK's leading animation, games and VFX schools, supplying the industry with studio-ready talent. Our courses are designed and delivered with industry experts within a welcoming, close-knit and successful community.Our world-class industry partners include Industrial Light &amp; Magic, Framestore, The Mill, DNEG, Milk VFX, Creative Assembly, Cinesite, Blue Zoo and MPC. Through our partnership with Epic Games, we were the UK\u2019s first Unreal Authorized Training Center and are an Unreal Engine Academic Partner.Approved and endorsed internationally as a Rookies and Houdini certified school, we offer a range of undergraduate, postgraduate and short courses. Our Escapees have worked on award-winning TV series Stranger Things and Obi-Wan Kenobi, along with Oscar and BAFTA nominated films such as Avatar: The Way of Water, Black Panther: Wakanda Forever, The Batman, Elvis, Top Gun: Maverick and bestselling games Assassin\u2019s Creed, Uncharted 2 and Forza Horizon."
    },
    {
        "id": "a10cd047eed73fe23fbe98370adb5456",
        "university_name": "ESCP Business School",
        "location": "527 Finchley Road, London, London, NW3 7BG",
        "official_website": "https://escp.eu",
        "contact_email": "bachelorlondon@escp.eu",
        "contact_phone": "020 7443 8800",
        "course_locations": [
            {
                "location_name": "Main Site - London",
                "location_address": "ESCP Europe London Campus, 527 Finchley Road, London, NW3 7BG"
            },
            {
                "location_name": "Madrid Campus",
                "location_address": "ESCP Europe Madrid Campus, c/ Arroyofresno, 1, Madrid, 28035"
            },
            {
                "location_name": "Paris Campus",
                "location_address": "ESCP Europe Paris Campus / Champerret, 8, av. de la Porte de Champerret, Paris, 75017"
            },
            {
                "location_name": "Warsaw Campus",
                "location_address": "C/o Kozminski University - International Relations Office, 57/59 Jagiellonska St, Warsaw, 30-301"
            },
            {
                "location_name": "Berlin Campus",
                "location_address": "ESCP Europe Wirtschaftshochschule Berlin, Heubnerweg 8-10, Berlin, D-14059"
            },
            {
                "location_name": "Turin Campus",
                "location_address": "ESCP Europe Turin Campus, Student Headquarters, Corso Unione Sovietic\u00e0, 218 bis, Turin, 10134"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/24524819-28d1-8d52-a160-2ca47383f078",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/20220402_escp_038_0.jpg",
        "about_us": "Founded in 1819 by economists and entrepreneurs, ESCP is the world's first business school. For over 200 years, we have been training generations of responsible and purpose-driven leaders eager to leave their mark on business and society.With six campuses in Berlin, Madrid, London, Paris, Turin, and Warsaw, ESCP is the only pan-European business school.\u00a0Becoming part of ESCP is to adopt this vision and to learn how to make enlightened choices, guided by the universal values of diversity, multiculturalism and interdisciplinarity. Be ready to meet the great challenges of our time with optimism.\u00a0Graduates are prepared to make choices grounded in knowledge, science and creativity thanks to ESCP's unique pedagogical approach, which combines management, social and digital sciences in interdisciplinary programmes, a multicultural experience with required studies in several international cities, and partnerships with leading universities worldwide.It all starts here, with you."
    },
    {
        "id": "94a647439606a7f403ef6467dd507bbe",
        "university_name": "University of Essex",
        "location": "Wivenhoe Park, Colchester, Essex, CO4 3SQ",
        "official_website": "https://www.essex.ac.uk/",
        "contact_email": "admit@essex.ac.uk",
        "contact_phone": "01206 873666",
        "course_locations": [
            {
                "location_name": "Colchester Campus",
                "location_address": "University of Essex, Wivenhoe Park, Colchester, CO4 3SQ"
            },
            {
                "location_name": "East 15 Acting School, Loughton",
                "location_address": "Hatfields, Loughton, IG10 3RY"
            },
            {
                "location_name": "Southend Campus",
                "location_address": "10 Elmer Approach, Southend, SS1 1LW"
            },
            {
                "location_name": "Main Site",
                "location_address": "Wivenhoe Park, Colchester, CO4 3SQ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/91f1f141-d376-4883-0afa-65d325c06ed0",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas-header-banner-1920x420.jpg",
        "about_us": "Founded in 1964, we\u2019ve spent 60 years encouraging boldness, bravery and ensuring an Essex education is accessible to all. You\u2019ll feel this Essex Spirit across our campuses.We\u2019re proud of our students, many of whom are the first in their family to attend university, which is why we\u2019re committed to supporting them; from cost-of-living support including bursaries, to career guidance that lasts a lifetime.You\u2019ll join our 15,000 strong student-body made up of over 140 nationalities. There\u2019s a reason we\u2019re ranked 15th for international outlook (THE World University Rankings 2023).We\u2019re top 30 in the Guardian University Guide 2024, but it\u2019s not just our teaching that\u2019s recognised. 90.4% of our research impact is world-leading or internationally excellent (Grade Point Average, REF 2021), with 5 subjects ranked top 10 in the UK for research power.If you\u2019re ready to join a community with inclusivity at its core, and be taught by world-leading academics; we are Essex, who are you?"
    },
    {
        "id": "62999c069d3b0f49eb87db784faa79fd",
        "university_name": "European School of Economics",
        "location": "11-13 Mandeville Place, Marylebone, London, W1U 3AJ",
        "official_website": "https://www.ese.ac.uk",
        "contact_email": "recruitment@eselondon.ac.uk",
        "contact_phone": "+442037780303",
        "course_locations": [
            {
                "location_name": "ESE Florence",
                "location_address": "Borgo Santi Apostoli, 19, Florence, 50123"
            },
            {
                "location_name": "ESE Rome",
                "location_address": "Corso Vittorio Emmanuele II, 154, Rome, 00186"
            },
            {
                "location_name": "ESE Madrid",
                "location_address": "Calle Antonio Maura, 12, Madrid, 28014"
            },
            {
                "location_name": "ESE London",
                "location_address": "11-13 Mandeville Place, London, W1U 3AJ"
            },
            {
                "location_name": "ESE Milan",
                "location_address": "Via Paolo Lomazzo, 19, Milan, 20154"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ed93b6a5-8c30-4c95-b03c-1d6d07a691b9",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ese_banner.jpg",
        "about_us": "The European School of Economics is a private College of Higher Education without borders, multicultural and pragmatic,\u00a0offering Bachelor\u2019s Degree, Masters, MBA and Short\u00a0 Courses at its centres in London, Madrid, Rome, Milan, and Florence.\u00a0 Begin your degree course any centre with the option to move locations on a per-term basis. Study is in English language in all centres.ESE graduates are among the most competitive business professionals on the market, prepared for leadership roles in\u00a0International Business,\u00a0Marketing, Finance,\u00a0Communication and Management. The European School of Economics is a new concept in higher education. Combining academic excellence, internationality and pragmatism, ESE degree and certificate programmes regularly host students from over 80 countries.ESE was\u00a0voted Number One in\u00a0Student Experience 2021\u00a0in a survey including over top 4000 unis worldwide."
    },
    {
        "id": "2cc29e1f1dc03fc46452d1f9ce32ef39",
        "university_name": "Exeter College",
        "location": "Exeter College, Hele Road, Exeter, Devon, EX4 4JS",
        "official_website": "https://www.exe-coll.ac.uk",
        "contact_email": "adultadvice@exe-coll.ac.uk",
        "contact_phone": "01392 400170",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Exeter College, Hele Road, Exeter, EX4 4JS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/1f21f18b-b7d4-45b9-2889-9de0a43b9fb3",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/1921x421.jpg",
        "about_us": "Exeter College is an award-winning, world class college, which offers a successful Higher Education element.We believe in doing our degrees differently for you. For us, it's about you and your exceptional future.\u00a0We're proud to work in partnership with our well-respected partners; University of Plymouth, University of Exeter, Kingston University, Pearson and Plymouth Marjon University, allowing our students to study in Exeter and benefit from quality and a wide range of courses"
    },
    {
        "id": "005a5c9262ec5d4e559907475c10c00b",
        "university_name": "University of Exeter",
        "location": "Streatham Campus, Northcote House, The Queen's Drive, Exeter, Devon, EX4 4QJ",
        "official_website": "https://www.exeter.ac.uk/?utm_source=ucas&utm_medium=affiliate&utm_campaign=uoe-profile",
        "contact_email": "ug-ad@exeter.ac.uk",
        "contact_phone": "0300 555 6060 (UK Callers) +44(0)1392 723044 (International callers)",
        "course_locations": [
            {
                "location_name": "University of Exeter - Exeter campuses",
                "location_address": "Northcote House, The Queen's Drive, Exeter, Devon, EX4 4QJ"
            },
            {
                "location_name": "University of Exeter - Streatham Campus",
                "location_address": "Northcote House, The Queen's Drive, Exeter, Devon, EX4 4QJ"
            },
            {
                "location_name": "Online Study",
                "location_address": "Northcote House, The Queen's Drive, Exeter, Devon, EX4 4QJ"
            },
            {
                "location_name": "University of Exeter - Truro Campus",
                "location_address": "University of Exeter Medical School, Knowledge Spa, Truro, Cornwall, TR1 3HD"
            },
            {
                "location_name": "University of Exeter - St Luke's Campus",
                "location_address": "Heavitree Road, Exeter, Devon, EX1 2LU"
            },
            {
                "location_name": "University of Exeter - Penryn Campus, Cornwall",
                "location_address": "Penryn Campus, Penryn, Cornwall, TR10 9FE"
            },
            {
                "location_name": "Main Site",
                "location_address": "Northcote House, The Queen's Drive, Exeter, EX4 4QJ"
            },
            {
                "location_name": "Jersey International Centre of Advanced Studies",
                "location_address": "Highlands Campus, Highlands Lane, St. Saviour, JE1 1HL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/91fd2fa0-e5ec-efe7-eb46-db8f10a74059",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/22354_header_1920x640.jpg",
        "about_us": "At the University of Exeter, we offer a range of courses in the beautiful and historic surroundings of South West England.\u00a0A member of the UK\u2019s prestigious Russell Group of leading research universities, we're one of only a few Russell Group institutions to have been awarded triple gold by the Teaching Excellence Framework (TEF 2023). We are also home to some of the country\u2019s top researchers, with more than 99% of our research rated as being of international quality (REF 2021)*.\u00a0We welcome over 30,000 students from more than 150 countries to our four friendly campuses: Streatham and St Luke\u2019s in Exeter, Devon, and Penryn and Truro in Cornwall. Our locations offer environments that are inspiring, safe places where you will soon feel at home.\u202f\u202f\u00a0With superb links to thousands of international, national and regional businesses, we are in the top 10 universities most targeted by top UK employers ('The Graduate Market in 2024', High Fliers Research).*Research rated 4* + 3* + 2* in REF 2021\u00a0"
    },
    {
        "id": "1bf4052a0561a98fb106443c57319fee",
        "university_name": "Falmouth University",
        "location": "Woodlane, Falmouth, Cornwall, Cornwall, TR11 4RH",
        "official_website": "https://www.falmouth.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01326 211077",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Falmouth Campus, Woodlane, Falmouth, TR11 4RH"
            },
            {
                "location_name": "WaterBear, Brighton",
                "location_address": "Brighthelm Gardens, Hanover House, Lower Ground Floor, 118 Queens Road, Brighton, BN1 3XG"
            },
            {
                "location_name": "DBS Institute, Plymouth",
                "location_address": "6 Elizabeth Court, Higher Lane, Plymouth, PL1 2AN"
            },
            {
                "location_name": "WaterBear, Sheffield",
                "location_address": "Unit 4, Gatecrasher, 49 Eyre Lane, Sheffield, S1 4RB"
            },
            {
                "location_name": "Bodmin College",
                "location_address": "Digital Media Centre, Unit 16, Woods Browning Industrial Estate, Bodmin, PL31 1DQ"
            },
            {
                "location_name": "Penryn Campus",
                "location_address": "Penryn Campus, Treliever Road, Penryn, TR10 9EZ"
            },
            {
                "location_name": "Main Site",
                "location_address": "Woodlane, Falmouth, TR11 4RH"
            },
            {
                "location_name": "DBS Institute, Bristol",
                "location_address": "2 Mitchell Lane, Bristol, BS1 6BU"
            },
            {
                "location_name": "DBS Institute, Manchester",
                "location_address": "New Century Hall, Mayes Street, Manchester, M60 4ES"
            },
            {
                "location_name": "Fourth Monkey, London",
                "location_address": "The Monkey House, 97-101 Seven Sisters Road, London, N7 7QP"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/13fe6f4f-d7a5-2d5b-6a39-23922b3922e5",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/penryn_campus_aerial_shot_3.jpg",
        "about_us": "Virtual reality. Green energy. Culture-shifting content. Nothing in this world has ever been achieved without an injection of creativity.\u00a0\u00a0At Falmouth, our industry connections, professional-grade facilities, and real-world approach to teaching mean our students are making an impact from day one. We champion creativity as a force for innovation, broadening people\u2019s ideas of what imagination is, where it is needed and who it is for.\u00a0\u00a0We\u2019re proud to work with some of the finest minds in their fields, and our students get to work with them too. We empower our students to carve out successful, entrepreneurial careers - and the tools and skills to make a difference in industry.Learning in a safe, open environment with the untamed natural beauty of Cornwall (there are two beaches and a castle on our doorstep) student life at Falmouth really is unique.\u00a0"
    },
    {
        "id": "08044aa21e9fe35f3982f842f9309422",
        "university_name": "University Centre Farnborough",
        "location": "Boundary Road, Farnborough, Hampshire, GU14 6SB",
        "official_website": "https://www.ucfarnborough.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": " 01252 407028",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Boundary Road, Farnborough, GU14 6SB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/56ce8405-825c-7b4b-7f50-282d9a19945b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_image_1.jpg",
        "about_us": "University Centre Farnborough (UCF) offers a range of university-level qualifications in a different way to traditional universities. Based at Farnborough College of Technology, we offer classroom-based lectures, a small campus experience and plenty of personalised support.\u00a0Our degrees are supported by the University of Surrey, who validate our cost two-year fast track, foundation and honours degrees."
    },
    {
        "id": "246759322ab0653830f5f32d9175a635",
        "university_name": "Fashion Retail Academy",
        "location": "Electra House, 84 Moorgate, London, EC2M 6SE",
        "official_website": "https://www.fashionretailacademy.ac.uk/",
        "contact_email": "info@fra.ac.uk",
        "contact_phone": "0300 247 0039",
        "course_locations": [
            {
                "location_name": "Fashion Retail Academy",
                "location_address": "15 Gresse Street, London, W1T 1QL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/9a25a2cc-42f7-481f-b6a2-fa102a4ece88",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banners_1_1.jpg",
        "about_us": "The Fashion Retail Academy is a unique, industry-led college and university founded in collaboration with the fashion and retail industry. We offer a range of specialist two and three-year fashion degrees with unparalleled industry enrichment throughout the course. You\u2019ll graduate with the skills and contacts you need to start an exciting career in the fashion industry.\u00a0Our exceptional industry network allows us to offer our students a wide range of industry opportunities. We work with over 140 brands to deliver courses, industry enrichment and learning opportunities that allow our students to develop the skills needed by industry. Each undergraduate degree course includes a work placement (of up to three months) which we arrange for you, your own industry mentor, and projects and workshops with our brand partners throughout the course.\u00a0\u00a0"
    },
    {
        "id": "80e5aee603108971b5fda539bc0c968a",
        "university_name": "Furness College",
        "location": "Channelside, Barrow-in-Furness, Cumbria, LA14 2PJ",
        "official_website": "https://www.furness.ac.uk",
        "contact_email": "student.services@furness.ac.uk",
        "contact_phone": "01229 825017",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Channelside, Barrow-in-Furness, LA14 2PJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/e1e76de4-aabc-d9ce-a54f-1e9d0d2f800d",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/furness-college-channelside.jpg",
        "about_us": "N/A"
    },
    {
        "id": "eca97546b2045a1e2b2c02e5e116140d",
        "university_name": "Futureworks",
        "location": "Riverside, New Bailey Street, Manchester, Greater Manchester, M3 5FS",
        "official_website": "https://www.futureworks.ac.uk",
        "contact_email": "enquiries@futureworks.ac.uk",
        "contact_phone": "0161 2144600",
        "course_locations": [
            {
                "location_name": "Futureworks (Manchester)",
                "location_address": "New Bailey Street, Riverside, Manchester, M3 5FS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/1018280f-7a1a-b9c4-6cee-ba38de4b934c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/5544_fut_ucas_website_images_headerimage_lr.jpg",
        "about_us": "Futureworks is an independent higher education provider based in the centre of Manchester, which delivers a specialised program of degrees designed to launch careers in the creative industries. From games design and animation to music production, filmmaking and visual effects, our courses are designed to prepare students for a rewarding and successful career in the subject they\u2019re passionate about.Our tutors are not just highly experienced in teaching and supporting students, but are also active industry professionals. This means that students are taught by creatives at the forefront of developments in their respective industries, and who can give expert guidance and insights.For students looking to embark on a career in the creative industries, Futureworks provides globally recognised qualifications, with an impressive variety of studios and spaces furnished with industry-standard equipment and facilities."
    },
    {
        "id": "003ae2fa3d3f12646d351c7aa997445e",
        "university_name": "Gateshead College",
        "location": "Baltic Business Quarter, Quarryfield Road, Gateshead, Tyne and Wear, NE8 3BE",
        "official_website": "http://www.gateshead.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01914 900300",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Quarryfield Road, Gateshead, NE8 3BE"
            },
            {
                "location_name": "Skills Academy for Automotive, Engineering, Manufacturing",
                "location_address": "Kingsway South, Team Valley, Gateshead, NE11 0SH"
            },
            {
                "location_name": "Skills Academy for Construction",
                "location_address": "8th Avenue, Kingsway South, Team Valley, Gateshead, NE11 0JL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/2304d818-b786-2c9e-279f-d162df0205c8",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/baltic-campus-eve1920px.jpg",
        "about_us": "N/A"
    },
    {
        "id": "86efdf460368ffad5e933a43d5e0234c",
        "university_name": "Glasgow Caledonian University",
        "location": "Cowcaddens Road, Glasgow, G4 0BA",
        "official_website": "http://www.gcu.ac.uk/",
        "contact_email": "studentenquiries@gcu.ac.uk",
        "contact_phone": "0141 331 3000",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "City Campus, Cowcaddens Road, Glasgow, G4 0BA"
            },
            {
                "location_name": "Main Site",
                "location_address": "Cowcaddens Road, Glasgow, G4 0BA"
            },
            {
                "location_name": "GCU London",
                "location_address": "40 Fashion Street, Spitalfields, Tower Hamlets, London, E1 6PX"
            },
            {
                "location_name": "Glasgow Caledonian University",
                "location_address": "Cowcaddens Road, Glasgow, G4 0BA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5cffcbbb-1d1f-46ed-c25d-28119a85c6c2",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/gcu-campus-1.jpg",
        "about_us": "If you want your undergraduate degree to kick-start your career, you\u2019re in the right place. At Glasgow Caledonian University, we offer a modern learning experience and an excellent network of student support.The right university experience is about more than lectures, study halls, and industry placements. We know you have the creativity and curiosity to achieve your full potential and go above and beyond to become part of a bigger movement for change. We are here to support your ideas so that you can go on to contribute, create, lead and influence the way we work and live."
    },
    {
        "id": "f04f638b7bbc6e8cce33939f1ecec58e",
        "university_name": "City of Glasgow College",
        "location": "City Campus190 Cathedral Street, Glasgow, G4 0RF",
        "official_website": "http://www.cityofglasgowcollege.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "0141 375 5555",
        "course_locations": [
            {
                "location_name": "City Campus",
                "location_address": "190 Cathedral Street, Glasgow, G4 0RF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/dd2bb28c-7b0b-aba1-13d7-42e22eed8da2",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/cogc_jan17_mt_d5_79.jpg",
        "about_us": "N/A"
    },
    {
        "id": "b3a66d34d2496f76fba341faef538c60",
        "university_name": "Glasgow School of Art",
        "location": "167 Renfrew Street, Glasgow, Glasgow, G3 6RQ",
        "official_website": "http://www.gsa.ac.uk/",
        "contact_email": "recruitment@gsa.ac.uk",
        "contact_phone": "0141 353 4500",
        "course_locations": [
            {
                "location_name": "Garnethill Campus",
                "location_address": "167 Renfrew Street, Glasgow, G3 6RQ"
            },
            {
                "location_name": "GSA Highlands and Islands Creative Campus",
                "location_address": "Blairs Farm, Steading, Altyre Estate, Forres, IV36 2SH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a7656ba9-e521-e161-e3de-768ac819ffbb",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/viv_option_22.jpg",
        "about_us": "N/A"
    },
    {
        "id": "2c2dd76c4ac9e0613d9ac50c0b9f6bb6",
        "university_name": "Gloucestershire College",
        "location": "Cheltenham Campus, Princess Elizabeth Way, Princess Elizabeth Way, Cheltenham, Gloucestershire, GL51 7SJ",
        "official_website": "https://www.gloscol.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01452 563451",
        "course_locations": [
            {
                "location_name": "Gloucester",
                "location_address": "Llanthony Road, Gloucester, GL2 5JQ"
            },
            {
                "location_name": "Cheltenham",
                "location_address": "Princess Elizabeth Way, Cheltenham, GL51 7SJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/098e22b9-a762-848b-f3d5-d92f4faa000b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/gc_higher_edcation_-_cheltenham_campus_banner.jpg",
        "about_us": "N/A"
    },
    {
        "id": "5fbedff2be2018a773b2c016d1b3c91f",
        "university_name": "University of Gloucestershire",
        "location": "Park Campus, The Park, The Park, Cheltenham, Gloucestershire, GL50 2RH",
        "official_website": "http://www.glos.ac.uk/",
        "contact_email": "admissions@glos.ac.uk",
        "contact_phone": "03330 141414",
        "course_locations": [
            {
                "location_name": "New College Swindon",
                "location_address": "New College Drive, Swindon, SN3 1AH"
            },
            {
                "location_name": "Bristol North Somerset and South Gloucestershire Pod",
                "location_address": "Healthier Together\u2019 Bristol, North Somerset and South Gloucestershire, Bristol, BS1"
            },
            {
                "location_name": "Park Campus - Cheltenham",
                "location_address": "The Park, Cheltenham, GL50 2RH"
            },
            {
                "location_name": "Main Site",
                "location_address": "The Park, Cheltenham, GL50 2RH"
            },
            {
                "location_name": "Francis Close Hall - Cheltenham",
                "location_address": "Swindon Road, Cheltenham, GL50 4AZ"
            },
            {
                "location_name": "Institute of Technology (Swindon)",
                "location_address": "North Star Campus, North Star Avenue, Swindon, SN2 1DY"
            },
            {
                "location_name": "Swindon Pod",
                "location_address": "Great Western Hospitals NHS Foundation Trust, Swindon, SN3 6BB"
            },
            {
                "location_name": "Bath Pod",
                "location_address": "Royal United Hospitals Bath NHS Foundation Trust, Bath, BA1 3NG"
            },
            {
                "location_name": "Oxstalls Campus - Gloucester",
                "location_address": "Oxstalls Lane, Longlevens, Gloucester, GL2 9HW"
            },
            {
                "location_name": "Hardwick Campus - Cheltenham",
                "location_address": "St Paul's Road, Cheltenham, GL50 4BS"
            },
            {
                "location_name": "Yeovil College",
                "location_address": "Yeovil College University Centre, Mudford Road, Yeovil, BA21 4DR"
            },
            {
                "location_name": "City Campus - Gloucester",
                "location_address": "Kings Square, Gloucester, GL11RP"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/aa1a1601-6d0b-4513-dd8f-e4e481144d84",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_4.png",
        "about_us": "At UoG we support ambition in all its forms. We want our students to achieve all they can, gaining the confidence and drive to succeed.That\u2019s why we\u2019re the top comprehensive uni in the South West for graduate outcomes* and\u00a0ranked 2nd globally for employment &amp; careers support^.Our first campus opened in 1847 and is now host to high-tech labs and architecture studios. The Uni has three campuses across the beautiful spa town of Cheltenham and historic cathedral city of Gloucester. Each has its own green spaces and state-of-the-art facilities, including an international-standard Sports Arena, fully equipped Media centre and a Business School with trading rooms, moot court room and consumer behaviour lab.We're home to a diverse and vibrant community of over 10,000 students from 60+ countries - the place to learn, grow, and make lasting connections.*96% of graduates in work or further study within 15 months of graduating (Graduate Outcomes Survey 2023 &amp; based on 2020/21 leavers)^ISB 2021"
    },
    {
        "id": "6e1ed1eef369a03d6e78403cd6282c1d",
        "university_name": "Goldsmiths, University of London",
        "location": "8 Lewisham Way, New Cross, London, SE14 6NW",
        "official_website": "https://gold.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "0207 919 7171",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Goldsmiths, University of London, New Cross, London, SE14 6NW"
            },
            {
                "location_name": "Goldsmiths, University of London",
                "location_address": "New Cross, London, SE14 6NW"
            },
            {
                "location_name": "Irie! Dance Theatre",
                "location_address": "The Moonshot Centre, Angus street, London, SE14 6LU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a8aae742-488b-7a13-16a1-acd04ed91125",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/2020.01.28-aerial_ucas_1920x420_rhb.jpg",
        "about_us": "Our academic legacy stretches back to 1891, but we are always looking forward. Full of students and staff who are inventive, imaginative and original, Goldsmiths provides an environment where new ideas flourish. Goldsmiths, part of the University of London for over 100 years, has a world-class reputation for its degrees in the arts, humanities, social sciences, computing and law. Situated on a single-site campus in New Cross and part of South East London's vibrant culture of arts and music. Goldsmiths: a close-knit community, a rich academic heritage, a creative powerhouse, a thought-provoking place."
    },
    {
        "id": "48cd2e3386ea4bcd542ce456e0c31de0",
        "university_name": "Gower College Swansea",
        "location": "Tycoch Road,, Tycoch, Swansea, Swansea, SA2 9EB",
        "official_website": "https://www.gowercollegeswansea.ac.uk",
        "contact_email": "he@gcs.ac.uk",
        "contact_phone": "01792 284000",
        "course_locations": [
            {
                "location_name": "Tycoch campus",
                "location_address": "Tycoch Road, Swansea, SA2 9EB"
            },
            {
                "location_name": "Sketty Hall Business School",
                "location_address": "Sketty Hall, Sketty Lane, Sketty, Swansea, SA2 8QF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d36d615d-1182-b5eb-d6ec-52986674b6e4",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/front-of-tycoch-2500px.jpg",
        "about_us": "N/A"
    },
    {
        "id": "cdb85bcb38043d45fafa7710a5bdf7f7",
        "university_name": "University of Greenwich",
        "location": "Greenwich Campus, Old Royal Naval College, Old Royal Naval College, Park Row, Greenwich, London, SE10 9LS",
        "official_website": "http://www.gre.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "02083319000",
        "course_locations": [
            {
                "location_name": "University of Greenwich (Medway Campus)",
                "location_address": "Central Avenue, Chatham, ME4 4TB"
            },
            {
                "location_name": "Hadlow College (Part of North Kent College)",
                "location_address": "Hadlow, Tonbridge, TN11 0AL"
            },
            {
                "location_name": "Flourish Learning Trust",
                "location_address": "Macdonald Road, Walthamstow, London, E17 4AZ"
            },
            {
                "location_name": "Main Site",
                "location_address": "Old Royal Naval College, Park Row, Greenwich, SE10 9LS"
            },
            {
                "location_name": "Bird College",
                "location_address": "The Centre, Sidcup, DA15 7EB"
            },
            {
                "location_name": "University of Greenwich (Greenwich Campus)",
                "location_address": "Old Royal Naval College, King William Walk, London, SE10 9LS"
            },
            {
                "location_name": "Greenwich Maritime Campus",
                "location_address": "Old Royal Naval College, Park Row, Greenwich, SE10 9LS"
            },
            {
                "location_name": "University of Greenwich (Avery Hill Campus)",
                "location_address": "Avery Hill Campus, Avery Hill Road, London, SE9 2UG"
            },
            {
                "location_name": "London South East Colleges (Orpington Campus)",
                "location_address": "The Walnuts, London, BR6 0TE"
            },
            {
                "location_name": "Avery Hill Campus",
                "location_address": "Southwood Site, Avery Hill Road, Eltham, SE9 2UG"
            },
            {
                "location_name": "London South East Colleges (Bexley Campus)",
                "location_address": "Erith Campus, Walnut Tree Road, Erith, DA8 1RA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/2c82b225-5a2f-3032-abfa-dd476e8b04c9",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_web_banner_1920x880.png",
        "about_us": "The University of Greenwich is an ambitious community of students and academics with no limits. No limits on where you come from and what you can do next. We empower our students to explore new and exciting possibilities, unbound from conventional thinking. We\u2019ll help you achieve your personal and academic goals in a supportive environment where staff go the extra mile. Our welcoming campuses in London and Kent provide a platform for a global network of over 250,000 undergraduates, postgraduates, researchers, alumni and partners. The University of Greenwich is a place where minds meet, ideas spark, and new adventures begin."
    },
    {
        "id": "04c38b471fd762841c9fb9c8bce0d4d3",
        "university_name": "Guildhall School of Music &amp; Drama",
        "location": "Silk Street, City of London, EC2Y 8DT",
        "official_website": "https://www.gsmd.ac.uk/",
        "contact_email": "registry@gsmd.ac.uk",
        "contact_phone": "+44 (0)20 7628 2571",
        "course_locations": [
            {
                "location_name": "Guildhall School of Music &amp; Drama",
                "location_address": "Silk Street, City of London, EC2Y 8DT"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5e389d01-76b6-a732-e958-e23f24bd07d7",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/13_c_david_monteith-hodge_photographise_please_credit_guildhall-hide_and_seek-dmh_6597.jpg",
        "about_us": "Ranked as number one in Arts, Drama &amp; Music by the\u00a0Complete University Guide 2025, one of the top ten performing arts institutions in the world (QS World University Rankings 2024), as well as the number one higher education institution in the\u00a0Guardian University Guide\u00a0music league table, we deliver world-class professional training in partnership with distinguished artists, companies and ensembles.A global leader in creative and professional practice, we promote innovation, experiment and research, and are also one of the UK\u2019s leading providers of lifelong learning in the performing arts, offering inspiring training for children, young people, adult learners, and creative and business professionals."
    },
    {
        "id": "d665cfb16c129aeb3ae908f229348270",
        "university_name": "Halesowen College",
        "location": "Whittingham Road,, Halesowen, West Midlands, B63 3NA",
        "official_website": "https://www.halesowen.ac.uk",
        "contact_email": "admissions@halesowen.ac.uk",
        "contact_phone": "0121 602 7607",
        "course_locations": [
            {
                "location_name": "Whittingham Road",
                "location_address": "Whittingham Road, Halesowen, B63 3NA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/87fbf767-af3e-5229-6a11-a9b7ac33bfac",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/banner_0.jpg",
        "about_us": "We pride ourselves in being a modern and well-resourced college with an ongoing commitment to updating our facilities. We strive to deliver the best learning in an environment where you feel safe and are motivated to succeed."
    },
    {
        "id": "49456bfdf8f9af31c8c4fe2155060505",
        "university_name": "Harlow College",
        "location": "Velizy Avenue,, Harlow, Essex, CM20 3LH",
        "official_website": "https://www.harlow-college.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01289 868000",
        "course_locations": [
            {
                "location_name": "Stansted Airport College",
                "location_address": "Round Coppice Road, London Stansted Airport, Stansted, CM24 1AR"
            },
            {
                "location_name": "Harlow College",
                "location_address": "Velizy Avenue, Harlow, CM20 3EZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/c9a6da19-9076-45b7-a210-792469fcfe6a",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "5ecd84028a219c0e528c21cd5a524410",
        "university_name": "Harper Adams University",
        "location": "Edgmond, Newport, Shropshire, TF10 8NB",
        "official_website": "http://www.harper-adams.ac.uk/",
        "contact_email": "admissions@harper-adams.ac.uk",
        "contact_phone": "01952 820280",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Edgmond, Newport, TF10 8NB"
            },
            {
                "location_name": "Harper Adams University - Station Quarter",
                "location_address": "Station Quarter, Telford, TF3 4DU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a249ae49-673f-59c7-bf4f-c791ba842aa2",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas2.jpg",
        "about_us": "The world needs new talent. There are problems to solve and we are developing the skills and technology to tackle them. Here at Harper Adams we're taking on global challenges such as food security, sustainable technologies and natural resource management. Our task is to equip people to find creative, sustainable solutions to the economic and environmental challenges facing the world, both now and in the future.\u00a0We believe passionately in what we do at Harper Adams and our students share our common agenda. With 8 billion people to feed (a figure expected to rise to 9.7 billion by 2050) and a planet to protect, the stakes could scarcely be higher.\u00a0Harper Adams graduates are, right now, helping businesses and communities, the rural sector and the world to flourish. Find the degree that matters to you and you could be joining them.\u00a0"
    },
    {
        "id": "2f1b49bd5623fe5dcdfce89a93211af3",
        "university_name": "Harper and Keele Veterinary School",
        "location": "Newport, Newport, Shropshire, TF10 8NB",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "01952 815000",
        "course_locations": [
            {
                "location_name": "Harper and Keele Veterinary School",
                "location_address": "Harper Adams University, Newport, TF10 8NB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/788cb34b-9779-4ac2-b997-91e88f205dd4",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/hkvs_buildings.jpg",
        "about_us": "N/A"
    },
    {
        "id": "3fb80fcdd82302203c8e8f0265c971d6",
        "university_name": "Hartpury University",
        "location": "Hartpury University and Hartpury College, Hartpury House, Hartpury, Gloucestershire, GL19 3BE",
        "official_website": "https://www.hartpury.ac.uk",
        "contact_email": "admissions@hartpury.ac.uk",
        "contact_phone": "+44 (0) 1452 702333",
        "course_locations": [
            {
                "location_name": "Hartpury University",
                "location_address": "Hartpury House, Gloucester, GL19 3BE"
            },
            {
                "location_name": "Main Site",
                "location_address": "Hartpury House, Hartpury, Gloucester, GL19 3BE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/abf8e17f-d0aa-e094-b5cb-1b01d712f708",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/46974473832_65eb0ffd94_o_0.jpg",
        "about_us": "Accolades and credentialsOur undergraduate provision has been awarded TEF Gold in all aspects - Overall, Student Exeperience, and Student Outcomes. The National Student Survey, 2023 ranks us as the no.1 English Mainstream University for Academic Support. We're also in the Times and Sunday Times Good University Guide, 2024 Top 10 universities for teaching quality.Subjects and campusWe're one of the UK's leading specialist education providers in agriculture, animal, business, equine, sport and veterinary nursing, offering a world-class education to over 4,600 students from over 60 countries.\u00a0We\u2019ve invested \u00a350 million in our 360-hectare campus over recent years to provide an ideal environment in which to learn and progress.Employment opportunities97% of Hartpury graduates are in employment, further study or other purposeful activity (Graduate Outcomes, 2024).\u00a0We have 11 commercial businesses on-site and a 3,000-strong business network."
    },
    {
        "id": "50bffd1bd4e8bb8ecf61d816067a3206",
        "university_name": "Havant and South Downs College",
        "location": "College Road, Waterlooville, Hampshire, PO7 8AA",
        "official_website": "https://www.hsdc.ac.uk",
        "contact_email": "HE@hsdc.ac.uk",
        "contact_phone": "023 9387 9999",
        "course_locations": [
            {
                "location_name": "South Downs Campus",
                "location_address": "College Road, Waterlooville, PO7 8AA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3ed8539f-69c0-5015-f7d2-507d57f4f5a2",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas-banner-image-v1.jpg",
        "about_us": "We are proud to offer one of the largest Higher Education provisions within a Further Education setting locally.Studying at a higher level at college is a great way to further your studies whether you are returning to study, continuing after college or studying alongside work - and it is extremely cost-effective!Our specialist teaching staff all have experience in their fields and embed years of knowledge into all of their teaching. We offer a wide range of facilities and resources which will equip you to gain your qualification.Our courses have much smaller class sizes than traditional university courses, this means that the support you receive is exceptional, with detailed and timely feedback allowing you to improve and excel.From 2023, HSDC will be part of the brand new South Coast Institute of Technology, working as a collaboration between five Further Education colleges and two universities, backed by more than \u00a313 million in funding from the Department for Education."
    },
    {
        "id": "e77ad9ebce36f1581e71f4c0657aa209",
        "university_name": "New City College (incorporating Havering College)",
        "location": "Ardleigh Green Road, Hornchurch, Essex, RM11 2LL",
        "official_website": "https://www.ncclondon.ac.uk/course-type/higher-education/",
        "contact_email": "info@ncclondon.ac.uk",
        "contact_phone": "0330 135 9000",
        "course_locations": [
            {
                "location_name": "Ardleigh Green",
                "location_address": "Ardleigh Green Road, Hornchurch, RM11 2LL"
            },
            {
                "location_name": "Rainham",
                "location_address": "Rainham Campus Construction Centre, New Road, Rainham, RM13 8GP"
            },
            {
                "location_name": "Wingletye Lane",
                "location_address": "Wingletye Ln, Hornchurch, RM11 3TB"
            },
            {
                "location_name": "Poplar",
                "location_address": "112 Poplar High Street, London, E14 0AF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/9bca8f42-92c1-251c-c512-1f9da7815cde",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/hc_ardleigh_green_rgb_1920px.jpg",
        "about_us": "New City College (NCC) is a large further education (FE) college group with higher education (HE) provision located \u00a0across east and outer London at \u00a0Hackney, Ardleigh Green ( Havering), Rainham, and Redbridge campuses.\u00a0 \u00a0The College\u2019s educational vision centres on creating inspiring teaching and learning opportunities for both traditional and \u00a0non-traditional entrants to HE, while celebrating equality and diversity as its core values.\u00a0NCC offers a diverse range of HE qualifications from \u00a0including HNCs, HNDs, foundation degrees, degrees\u00a0to postgraduate courses."
    },
    {
        "id": "f9c5415685df2ace1029bb40448dd997",
        "university_name": "Haybridge Alliance School Centred Initial Teacher Training",
        "location": "Haybridge Alliance SCITT, Brake Lane, Hagley, Worcestershire, DY8 2XS",
        "official_website": "https://www.teachwithhaybridge.co.uk",
        "contact_email": "N/A",
        "contact_phone": "01562 881110   07535814330",
        "course_locations": [
            {
                "location_name": "Haybridge High School and Sixth Form",
                "location_address": "Brake Lane, Hagley, Worcestershire, DY8 2XS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/e9d03a65-5be5-411b-a7d5-0e33b7002b1d",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/img_1653.jpg",
        "about_us": "N/A"
    },
    {
        "id": "57be4f7c9f229be8292d187e472125d7",
        "university_name": "Harrow, Richmond &amp; Uxbridge College",
        "location": "Park Road,, Uxbridge, Middlesex, UB8 1NQ",
        "official_website": "https://www.uxbridge.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01895 853333",
        "course_locations": [
            {
                "location_name": "Uxbridge College Campus",
                "location_address": "Park Road, Uxbridge, UB8 1NQ"
            },
            {
                "location_name": "Richmond Upon Thames College Campus",
                "location_address": "Langhorn Drive, Twickenham, TW2 7SJ"
            },
            {
                "location_name": "Uxbridge College - Hayes Community Campus",
                "location_address": "College Way, Coldharbour Lane, Hayes, UB3 3BB"
            },
            {
                "location_name": "Harrow College Campus",
                "location_address": "Harrow-on-the-Hill campus, Lowlands Road, Harrow, HA1 3AQ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/25f59ca8-d9e1-4df0-16ce-31165e139a4b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_landscape_1920x420px_002.png",
        "about_us": "N/A"
    },
    {
        "id": "33e82a53d6ee9ed47a27051e41715e10",
        "university_name": "Health Sciences University (formerly AECC University College)",
        "location": "13-15 Parkwood Road, Bournemouth, Dorset, BH5 2DF",
        "official_website": "https://www.hsu.ac.uk/",
        "contact_email": "admissions@aecc.ac.uk",
        "contact_phone": "01202 436200",
        "course_locations": [
            {
                "location_name": "Borough High Street - London",
                "location_address": "275 Borough High Street, London, SE1 1JE"
            },
            {
                "location_name": "Health Sciences University",
                "location_address": "Parkwood Campus, Parkwood Road, Bournemouth, BH5 2DF"
            },
            {
                "location_name": "Main Site",
                "location_address": "Parkwood Campus, Parkwood Road, Bournemouth, BH5 2DF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d920c4e2-d428-8cbf-e0ff-2ec19b371514",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_header_-_campus_front.jpg",
        "about_us": "AECC University College becomes Health Sciences UniversityWe chose this name because we didn\u2019t want anyone to be in any doubt about who we are and what we stand for. We want to be the leading specialist health sciences university in the UK, and we want you to join us.\u00a0Our Bournemouth campus is situated moments away from some of the best beaches in the UK, while our London campus is just a walk from Waterloo Station. Established in 1965, we have been at the forefront of musculoskeletal health for over 55 years and offer a wide array of specialist health science courses, including:Chiropractic\u00a0Sport, clinical exercise and rehabilitation science\u00a0Psychology, sport and exercise psychology\u00a0Medical ultrasound and radiography\u00a0Physiotherapy\u00a0Speech and language therapyDieteticsOccupational therapyPodiatryWe are driven to deliver excellence in all these areas, to provide the next generation of health professionals, to upskill our current workforce, and to improve public health.\u00a0"
    },
    {
        "id": "db6fc2d1ee7890333837a8b9b6bca559",
        "university_name": "Heart of Worcestershire College",
        "location": "Redditch Campus, Peakman Street, Bromsgrove, Redditch, Worcestershire, B98 8DW",
        "official_website": "http://www.howcollege.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Worcester",
                "location_address": "Deansway, Worcester, WR1 2JF"
            },
            {
                "location_name": "Bromsgrove",
                "location_address": "Slideslow Drive, Bromsgrove, B60 1PQ"
            },
            {
                "location_name": "Redditch",
                "location_address": "Peakman Street, Redditch, B98 8DW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/48a4bc2c-a843-5364-3ec0-5b321ae1f9d1",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/rs3608_img_0240-lpr_1.jpg",
        "about_us": "Heart of Worcestershire College offers a vast range of vocational and\u202ftechnical courses in multiple specialist subject areas alongside a vast array of apprenticeships and higher education courses.\u202fFrom courses for school leavers to affordable higher education and professional qualifications, we pride ourselves in being able to deliver job related qualifications in high quality environments to enable our students to achieve a positive and bright future.\u00a0"
    },
    {
        "id": "bf0e2418d456a19dee08278b1f01377d",
        "university_name": "Hereford College of Arts",
        "location": "Folly Lane, Hereford, Herefordshire, HR1 1LT",
        "official_website": "https://www.hca.ac.uk",
        "contact_email": "registry@hca.ac.uk",
        "contact_phone": "01432-273359",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Folly Lane, Hereford, HR1 1LT"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ebb4f05e-5aa3-d6b2-180b-0bca7e46b6f9",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/44668043232_d0f59bf5f0_k.jpg",
        "about_us": "Here, you can find your place in an inspiring art school community; while our expert staff, studios &amp; workshops support your individual journey.HCA\u2019s intimate scale lends itself to natural collaborations and distinctive creative groups. It's the ideal setting for you to discover alternative ways of looking at things. Students also experience an excellent amount of contact time with tutors in a forum which is small enough for your voice to be heard.All students benefit from inductions to relevant workshops and studios. If your practice leads you down a new and inventive route; you are free to experiment using HCA\u2019s great range of facilities. So, if you\u2019re an animator who discovers you\u2019d like to experiment with embroidery \u2013 you can! Many of the staff at HCA are also active practitioners in their own right, meaning they can offer you guidance as mentors, specialists and peers.Whether you want to pursue art, media, design, popular music or performing arts, we have your future in mind."
    },
    {
        "id": "54876a868820f9f734dbd1a99da83561",
        "university_name": "Herefordshire, Ludlow &amp; North Shropshire College",
        "location": "Folly Lane, Hereford, HR1 1LS",
        "official_website": "https://www.hlnsc.ac.uk",
        "contact_email": "enquiries@hlnsc.ac.uk",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Hereford Campus",
                "location_address": "Folly Lane, Hereford, Herefordshire, HR1 1LS"
            },
            {
                "location_name": "Oswestry Campus",
                "location_address": "Shrewsbury Rd, Oswestry, SY11 4QB"
            },
            {
                "location_name": "Holme Lacy Campus",
                "location_address": "Holme Lacy, Hereford, HR2 6LL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/09715d18-2d6a-4e62-bf0e-2aae227ce0bf",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/hlns_shoot2305030570.jpg",
        "about_us": "N/A"
    },
    {
        "id": "87a6a8c17137d182c6cebf2ca8ffcee1",
        "university_name": "Heriot-Watt University",
        "location": "Edinburgh Campus, Edinburgh, EH14 4AS",
        "official_website": "https://www.hw.ac.uk/",
        "contact_email": "studywithus@hw.ac.uk",
        "contact_phone": "0131 449 5111",
        "course_locations": [
            {
                "location_name": "Scottish Borders Campus",
                "location_address": "Netherdale, Galashiels, TD1 3HF"
            },
            {
                "location_name": "Main Site",
                "location_address": "Riccarton, Edinburgh, EH14 4AS"
            },
            {
                "location_name": "Scottish Borders",
                "location_address": "Heriot-Watt University, Nether Road, Netherdale, Galashiels, TD1 3HE"
            },
            {
                "location_name": "Edinburgh Campus",
                "location_address": "Riccarton, Edinburgh, EH14 4AS"
            },
            {
                "location_name": "Orkney Campus",
                "location_address": "The Old Academy, Back Road, Stromness, KW16 3AW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5c658fd1-ce3f-ca66-401d-5f38b48c89af",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_image_new.jpg",
        "about_us": "Be Future Made With Heriot-Watt UniversityAt Heriot-Watt, we've been raising the bar and producing employable graduates since 1821. We are a university that specialises in Business, Science, Technology, Engineering, Mathematics and Fashion Textiles - subjects which make a real impact on the world and society.We collaborate with international business and industry to tackle the global challenges faced by the world we live in. That's one reason why Heriot-Watt undergraduates are highly employable and sought after by the best organisations worldwide.Study at our 380-acre parkland campus in Edinburgh (voted best city for student experience and 3rd safest city in the world in YouGov polls), and join a community of over 27,000 students and an alumni network of over 140,000, who stand shoulder to shoulder with our leaders in ideas and solutions."
    },
    {
        "id": "bd7fde711e560d291b791473cd396fb3",
        "university_name": "Hertford Regional College",
        "location": "London Road, Ware, Hertfordshire, SG12 9JF",
        "official_website": "https://www.hrc.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01992 411400",
        "course_locations": [
            {
                "location_name": "Broxbourne",
                "location_address": "Broxbourne Campus, High Road, Turnford, Broxbourne, EN10 6AE"
            },
            {
                "location_name": "Ware",
                "location_address": "London Road, Ware, SG12 9JF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/73efa26e-b411-7282-4a9b-7b0bc0dd85c6",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/herts_regional_college_bond_bryan.jpg",
        "about_us": "N/A"
    },
    {
        "id": "71ef3920b052e3bba54e82fabc2b4f9d",
        "university_name": "Holy Cross Sixth Form College and University Centre",
        "location": "Manchester Road,, Bury, Greater Manchester, BL9 9BB",
        "official_website": "https://www.holycross.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "0161 762 4500",
        "course_locations": [
            {
                "location_name": "Holy Cross University Centre",
                "location_address": "Manchester Road, Bury, BL9 9BB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/9ccfa755-f171-c4c9-9541-3f934dd1a158",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/hc0008_building_2_0.jpg",
        "about_us": "N/A"
    },
    {
        "id": "5089a2ba20bd53de17aa6024c940184e",
        "university_name": "Hopwood Hall College",
        "location": "Rochdale Road, Middleton, Middleton, Greater Manchester, M24 6XH",
        "official_website": "https://www.hopwood.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01706 345346",
        "course_locations": [
            {
                "location_name": "Rochdale Campus",
                "location_address": "St Mary's Gate, Rochdale, OL12 6RY"
            },
            {
                "location_name": "Middleton",
                "location_address": "Rochdale Road, Middleton, Manchester, M24 6XH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/32bce31b-56e6-5540-d5ca-3e5a354acbef",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/he_split_image.jpg",
        "about_us": "N/A"
    },
    {
        "id": "3cc85b3bb1acdcf44d25bbdb0b9d3961",
        "university_name": "University of Huddersfield",
        "location": "Queensgate,, Huddersfield, West Yorkshire, HD1 3DH",
        "official_website": "https://hud.ac.uk",
        "contact_email": "ask@hud.ac.uk",
        "contact_phone": "+44 (0)1484 472625",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Queensgate, Huddersfield, HD1 3DH"
            },
            {
                "location_name": "Main Site",
                "location_address": "Queensgate, Huddersfield, HD1 3DH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/c666f13c-3fb7-0b03-4f67-eeaeba301103",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/barbarahepworth_canal-resize.jpg",
        "about_us": "The University of Huddersfield is a TEF gold-rated, award-winning university where you'll benefit from inspirational teaching and learning, and top-class facilities. Huddersfield is located on one campus, at the centre of a vibrant town and surrounded by Yorkshire countryside. A friendly place to call home and, unlike some large cities, offers a relatively low cost of living. We've invested \u00a3156m in our campus and are building a National Health Innovation Campus and a new Faith building.\u00a0Triple proof of Teaching Excellence: our staff rank in the top three in England for the proportion who hold doctorates, who have higher degrees, and hold teaching qualifications (HESA 2022).\u00a0More than a degree as an undergrad, you'll study Global Professional Award and gain valuable qualities that we know employers are looking for.We're top 20 in the UK for placement years in industry placements are important so you have real-world experience on your CV (HESA 2020/21)."
    },
    {
        "id": "dfc1c9926de43685c8be4513f18cd85d",
        "university_name": "Hugh Baird College",
        "location": "Balliol Road, Bootle, Merseyside, L20 7EW",
        "official_website": "http://www.hughbaird.uk.com/",
        "contact_email": "N/A",
        "contact_phone": "01513 534400",
        "course_locations": [
            {
                "location_name": "University Centre",
                "location_address": "Stanley Road, Liverpool, Liverpool, L20 7EW"
            },
            {
                "location_name": "Bootle Campus",
                "location_address": "Balliol Road, Bootle, L20 7EW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/1231e6ce-2e1c-23c9-2f3a-2f028ad5c89b",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "77e0ecd01f2377959098992b997af994",
        "university_name": "Hull College",
        "location": "Queens Gardens,, Hull, East Yorkshire, HU1 3DG",
        "official_website": "http://www.hull-college.ac.uk/",
        "contact_email": "info@hull-college.ac.uk",
        "contact_phone": "01482 598749",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Queen's Gardens, Wilberforce Drive, Hull, HU1 3DG"
            },
            {
                "location_name": "Main Site",
                "location_address": "Queen's Gardens, Hull, HU1 3DG"
            },
            {
                "location_name": "Hull School of Art and Design",
                "location_address": "The Queen's Gardens Centre, Wilberforce Drive, Hull, HU1 3DG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/cbb9695b-0683-405d-3869-739ae7f0995e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/tower.jpg",
        "about_us": "Studying for a degree is a great pathway for your future and doing so at Hull College offers a real alternative to a traditional university.Whether you're a mature learner returning to education or a high-flying sixth form student; we can help! We take immense pride in offering a truly personalized university experience. You're not just a number to us; you're a valued individual, and we're committed to providing the support you need to succeed. "
    },
    {
        "id": "3997ecd7acc0994e55d4eaeeda8f0163",
        "university_name": "Hull York Medical School",
        "location": "The University Of York, Heslington, Heslington, York, North Yorkshire, YO10 5DD",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "University of Hull, Hull, HU6 7RX"
            },
            {
                "location_name": "Main Site, University of Hull",
                "location_address": "University of Hull, Hull, HU6 7RX"
            },
            {
                "location_name": "University of York or University of Hull",
                "location_address": "The University of York / The University of Hull, York / Hull, YO10 5DD"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6a15243b-60d5-96a6-1164-54056bc88ab4",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_hyms_tile.jpg",
        "about_us": "N/A"
    },
    {
        "id": "02ee2036e15edc5754db488ca134402a",
        "university_name": "University of Hull",
        "location": "Admissions Office, Cottingham Road, Cottingham Road, Hull, East Yorkshire, HU6 7RX",
        "official_website": "https://www.hull.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01482 346311",
        "course_locations": [
            {
                "location_name": "The University of Hull",
                "location_address": "Cottingham Road, Hull, HU6 7RX"
            },
            {
                "location_name": "Main Site",
                "location_address": "Admissions Office, Cottingham Road, Hull, HU6 7RX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ac1fb4b5-6103-57d1-d967-3ecb926af5ae",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_header_3.jpg",
        "about_us": "Change the world, or change your world? You\u2019re free to do both at Hull. We\u2019ve been teaching people to think differently since 1927.Hull\u2019s a unique university with a proudly progressive history \u2013 and state of the art facilities. All within a ten-minute walk across a safe campus. Expect academic excellence and the chance to play your part in tackling the big issues challenging humanity \u2013 many of which are banging at our very door.We\u2019re research leaders in clean energy, flood resilience, cancer and wound care, and the fight against modern slavery.Our research is helping build the largest wind farms in the world. It\u2019s also helping students build successful careers in growth sectors like A.I., flood risk management, social justice and renewable energy.We\u2019re proud to be part of this unique city. Together, we\u2019re enabling people, organisations and businesses to thrive here in Hull, regionally and around the world."
    },
    {
        "id": "0492cd6bb6e087d680825f12302a8ac9",
        "university_name": "ICON College of Technology and Management",
        "location": "Unit 21-22, 1-13 Adler Street, Tower Hamlets, Tower Hamlets, London, E1 1EG",
        "official_website": "https://www.iconcollege.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "020 7377 2800",
        "course_locations": [
            {
                "location_name": "ICON College of Technology and Management",
                "location_address": "Unit 21, 1-13 Adler Street, London, E1 1EG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/4215e8c6-25b1-63c3-46d9-167691b8bd09",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "5fac796291a198b2c1b5d81e99483a50",
        "university_name": "Institute of Advanced Legal Studies, School of Advanced Study, University of London",
        "location": "17 Russell Square, Camden, London, WC1B 5DR",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Institute of Advanced Legal Studies",
                "location_address": "17 Russell Square, Camden, WC1B 5DR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/9fd86a3e-60e3-fb86-7912-6e36c659f5df",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "3205ee1e5f4ec163f9a2454fce40a375",
        "university_name": "Institute of Cancer Research, University of London",
        "location": "Chester Beatty Laboratories, 237 Fulham Road, London, SW3 6JB",
        "official_website": "https://www.icr.ac.uk",
        "contact_email": "admissions@icr.ac.uk",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Institute of Cancer Research",
                "location_address": "The Registry, 15 Cotswold Road, Sutton, SM2 5NG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/44eed984-57e4-efdd-7f2c-4f25dc8fa865",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/icr_logo.jpg",
        "about_us": "Our competitive science PhD programme is designed for the next generation of world-leading scientists who want a career in cancer research. These students benefit from an above average tax-free stipend and exceptional research facilities.\u00a0Additionally, clinicians can undertake a PhD or MD(Res) degree. These students form a vital link in our translational research, bringing knowledge of clinical practice into the laboratory and taking the knowledge of laboratory research back into the clinic and using it to help cancer patients.The ICR also runs an MSc in Oncology for medically qualified candidates who intend to pursue a professional career in some aspect of clinical or medical oncology, either as a clinical academic or a clinician.\u00a0The course helps clinicians to develop their career by equipping them with a high-level understanding of the theory and practice of cancer science, of advanced cancer treatment, and the science of cancer research \u2013 all to the benefit of cancer patients."
    },
    {
        "id": "045f2a731230ec9f1886b71444610842",
        "university_name": "Institute of Commonwealth Studies, School of Advanced Study, University of London",
        "location": "2nd Floor, South Block, Senate House, Malet Street, Camden, London, WC1E 7HU",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Institute of Commonwealth Studies",
                "location_address": "28 Russell Square, Camden, WC1B 5DS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d04f617a-326c-9954-f168-0b9896beedeb",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "8841cdf79ede1c716a225c6ca699b7d5",
        "university_name": "Institute of Contemporary Music Performance",
        "location": "1B Dyne Road, London, NW6 7XG",
        "official_website": "http://icmp.ac.uk/",
        "contact_email": "enquiries@icmp.ac.uk",
        "contact_phone": "020 7328 0222",
        "course_locations": [
            {
                "location_name": "ICMP London",
                "location_address": "1B Dyne Road, London, NW6 7XG"
            },
            {
                "location_name": "ICMP Leeds",
                "location_address": "Switch at The Electric Press, 14 Great George Street, Leeds, LS1 3DW"
            },
            {
                "location_name": "ICMP Liverpool",
                "location_address": "38 Pall Mall, Liverpool, L3 6AL"
            },
            {
                "location_name": "The Institute of Contemporary Music Performance",
                "location_address": "1B Dyne Road, Kilburn, NW6 7XG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b829718a-edf3-5bd9-7d96-9dfc2c8e9d9d",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/studio.jpg",
        "about_us": "ICMP, \u00a0has been developing and delivering music education and training to students of contemporary music for over 35 years,\u00a0longer than any other UK school. \u00a0With two well-established campuses in London, we will be proudly opening a brand-new campus in Liverpool ready for students starting in September 2025.Our incredible campuses are packed full of the latest musical equipment, unrivalled student access to facilities for studying, recording and rehearsing (we're open 7 days a week, until 11pm on weekdays!) and a supportive tightknit creative community, ICMP is the obvious choice for aspiring music industry professionals.ICMP programmes are focused on preparing musicians, producers, performers and entrepreneurs for a successful and rewarding career in the creative industries. We're connected to major record labels, publishers, promoters and management companies across the country and the wider music industry.\u00a0"
    },
    {
        "id": "3b9927b9477adf366bf288fe97aa233d",
        "university_name": "Institute of Development Studies",
        "location": "Library Road, Brighton, BN1 9RE",
        "official_website": "https://www.ids.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Institute of Development Studies",
                "location_address": "Library Road, Brighton, BN1 9RE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5737f385-0456-ba7f-207c-e9b85780313b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ids_14.jpg",
        "about_us": "The Institute of Development Studies (IDS) delivers world-class research, learning and teaching that transforms the knowledge, action and leadership needed for more equitable and sustainable development globally.Through\u00a0equitable and sustainable partnerships, we work with governments, philanthropic foundations, non-governmental organisations, academics and civil society to transform approaches to progressive social, political and economic change in ways that ultimately make a difference to people\u2019s lives."
    },
    {
        "id": "637707b9c45c114eec30f3d824ab3252",
        "university_name": "Institute of English Studies, School of Advanced Study, University of London",
        "location": "Senate House, Camden, London, WC1E 7HU",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Institute of English Studies",
                "location_address": "Senate House, Camden, WC1H 7HU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/909e0405-4ffa-3950-a8ca-dc6acbb4b2cc",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "01ca4b43fafbb5fe80a07a7c5ec1dedc",
        "university_name": "Institute of Historical Research, School of Advanced Study, University of London",
        "location": "Senate House, Camden, London, WC1E 7HU",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Institute of Historical Research",
                "location_address": "Senate House, Camden, WC1H 7HU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3e0010c4-3df1-1745-27ae-ff9082c29a5c",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "752f02d6bc23bca624f3c9080e746c4e",
        "university_name": "Institute of Modern Languages Research, School of Advanced Study, University of London",
        "location": "Senate House, Camden, City of London, WC1E 7HU",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Institute of Modern Languages Research",
                "location_address": "Senate House, Malet Street, Camden, WC1E 7HU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/08f1a8cc-5eb5-e3c9-9d78-85e24f7da0af",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "067431c8263082e9578acd41a9b29904",
        "university_name": "International Business College Manchester",
        "location": "11 Portland Street, Manchester, M1 3HU",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "International Business College Manchester",
                "location_address": "11 Portland St, Manchester, M1 3HU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/54791c19-bf4e-458f-95c1-f0dbd589570f",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banners.jpg",
        "about_us": "N/A"
    },
    {
        "id": "eea0bc0fc73473f4dccfda246db387b7",
        "university_name": "International College of Musical Theatre (ICMT)",
        "location": "68 Wallis Road, London, E9 5LH",
        "official_website": "https://theicmt.com",
        "contact_email": "admissions@theicmt.com",
        "contact_phone": "02072533118",
        "course_locations": [
            {
                "location_name": "ICMT London",
                "location_address": "68 Wallis Road, London, E9 5LH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/16963c21-d1ff-477a-9244-b415d0a57ba8",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_provider_banner.png",
        "about_us": "The International College of Musical Theatre (ICMT) is a leading UK performing arts College renowned for its rigorous and professional performing arts training.We are dedicated to shaping the next generation of performers through our innovative and comprehensive musical theatre programs. Our courses are crafted and delivered by a faculty of active industry professionals, ensuring you receive the highest quality education. With a focus on practical, hands-on learning, you'll gain the technical, creative, and professional skills needed to thrive in the competitive world of performing arts.At the ICMT, we are committed to helping you reach your full potential in a supportive and positive atmosphere. Join us and become part of a community that values excellence, discipline, and creativity."
    },
    {
        "id": "40a218ec9467bb21d229f3cd620a6ddf",
        "university_name": "International Luxury Academy",
        "location": "15-19 Cavendish Place, London, W1G 0DD",
        "official_website": "https://www.internationalluxuryacademy.com/",
        "contact_email": "info@internationalluxuryacademy.com",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "ILA, International Luxury Academy",
                "location_address": "15-19 Cavendish Place, London, W1G 0DD"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a96bc33f-4040-4bd6-a5a4-bfab5715687d",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/1b_banner.jpg",
        "about_us": "N/A"
    },
    {
        "id": "3e57925028bce7400d1b484d78c2f984",
        "university_name": "Irish Baptist College",
        "location": "19 Hillsborough Road, Moira, BT67 0HG",
        "official_website": "https://www.irishbaptistcollege.org",
        "contact_email": "registrar@thebaptistcentre.org",
        "contact_phone": "028 9267 9267",
        "course_locations": [
            {
                "location_name": "Irish Baptist College",
                "location_address": "19 Hillsborough Road, Moira, BT67 0HG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/c1eb45fd-d7e1-c16b-fe0b-a933e60e9957",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "2fcd1b700750ec6e22828948229fc199",
        "university_name": "Islamic College for Advanced Studies",
        "location": "133 High Road, Willesden, Willesden, London, NW10 2SW",
        "official_website": "https://www.islamic-college.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "020 8451 9993",
        "course_locations": [
            {
                "location_name": "Islamic College for Advanced Studies",
                "location_address": "133 High Street, Willesden, Brent, NW10 2SW"
            },
            {
                "location_name": "Main Site",
                "location_address": "133 High Road, Willesden, London, NW10 2SW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/dd033310-a756-e331-77d0-1f6a37badae3",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "b3f8fed92a47e56731d1da127410723a",
        "university_name": "Istituto Marangoni London",
        "location": "30 Fashion Street, London, London, E1 6PX",
        "official_website": "http://www.istitutomarangoni.com/en/",
        "contact_email": "admissions.london@istitutomarangoni.com",
        "contact_phone": "+44 20 3608 2401",
        "course_locations": [
            {
                "location_name": "Istituto Marangoni London",
                "location_address": "30 Fashion Street, London, E1 6PX"
            },
            {
                "location_name": "Main Site",
                "location_address": "30 Fashion Street, London, E1 6PX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/23aec7b0-c716-21e8-4fd6-59e4bc8a0dea",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_25.jpg",
        "about_us": "Istituto Marangoni was founded in 1935 in Milan as 'Istituto Artistico dell\u2019Abbigliamento Marangoni\u2019, and has been a top educational choice for creatives in the worlds of fashion, art and design for over 85 years now.With four generations of students from 5 continents, it was the springboard for over 45,000 luxury professionals, including Domenico Dolce, Alessandro Sartori, Paula Cademartori, Gilda Ambrosio, Julie de Libran and Nicola Brognano.Istituto Marangoni currently welcomes about 5,000 students from 108 different countries every year in its schools in the world capitals of fashion, art and design, including Milano, Firenze, Paris, London, Dubai, Mumbai, Shanghai, Shenzhen and Miami.Istituto Marangoni is ranked among the 100 best universities in the world in its fields according to QS World University Ranking 2023."
    },
    {
        "id": "03504e8a1eb06eee9c0bb39d7cb9f617",
        "university_name": "JCA | London Fashion Academy",
        "location": "20 Hanover Square, Mayfair, London, W1S 1JY",
        "official_website": "https://www.jca.ac.uk",
        "contact_email": "info@jca.ac.uk",
        "contact_phone": "0207 157 9828",
        "course_locations": [
            {
                "location_name": "Boston Manor Campus",
                "location_address": "Boston Manor House, Boston Manor Road, Brentford, London, TW8 9JX"
            },
            {
                "location_name": "Mayfair Campus",
                "location_address": "20,, Hanover Square, Mayfair, London, W1S 1JY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/bb956d7d-b8d8-40af-988d-ef096ceb78c9",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/untitled_design-3.jpg",
        "about_us": "Professor Jimmy Choo OBE, invites future fashion design pioneers to his innovative new London Fashion Academy.Higher education can be a frustrating place for students that want to gain access to the industry as a fashion entrepreneur while learning their craft at university.The Academy has been set up as the fashion school of the future, to provide ambitious design students with all of the tools they need to become a brand innovator.Our practical design courses focus heavily on developing an entrepreneurial mindset, driving innovation and building relationships with key industry contacts as we aim to create the next generation of fashion pioneers.The world of tomorrow will be shaped by the pioneers of fashion design from JCA. Tackling and solving challenges of fashion in 21st century life.With education as your platform; build a legacy and claim your place in history as a fashion design innovator.What will you be known for?"
    },
    {
        "id": "213d34d328c0eda86d06fa4e4470681f",
        "university_name": "Keele University",
        "location": "Keele University, Keele, Newcastle-under-Lyme, Staffordshire, ST5 5BG",
        "official_website": "http://www.keele.ac.uk/",
        "contact_email": "enquiries@keele.ac.uk",
        "contact_phone": "+44 (0)1782 734010",
        "course_locations": [
            {
                "location_name": "Keele Campus",
                "location_address": "Keele University, Keele, ST5 5BG"
            },
            {
                "location_name": "Guy Hilton Research Centre, University Hospitals of North Midlands NHS Trust",
                "location_address": "Thornburrow Drive, Hartshill, Stoke on Trent, ST4 7QB"
            },
            {
                "location_name": "Main Site",
                "location_address": "Keele University, Keele, ST5 5BG"
            },
            {
                "location_name": "Main Site",
                "location_address": "Keele, ST5 5BG"
            },
            {
                "location_name": "University Hospitals of North Midlands NHS Trust",
                "location_address": "University Hospitals of North Midlands NHS Trust, Staffordshire, ST4 6QG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5e07e833-7099-0cde-fea9-55afd4644bb2",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/keele-students-outside-keele-hall-ucas-banner-1920x850.jpg",
        "about_us": "Keele University was established in 1949 by the former Vice-Chancellor of Oxford University. Founded to meet the needs of a changing world, Keele has always had a pioneering vision to be a different kind of university.\u00a0We excel in both teaching and research, with some of the most satisfied students in England, and research that is changing lives for the better at a regional, national and global level.\u00a0In collaboration with our global partners, we\u2019re tackling some of society\u2019s most urgent problems \u2013 including global health, food security and the climate crisis."
    },
    {
        "id": "d55e331046d4f307fdf101f880466bb8",
        "university_name": "Kent and Medway Medical School",
        "location": "Kent",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Canterbury",
                "location_address": "Kent and Medway Medical School, Canterbury, CT2 7NZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/99fa626a-979b-4fb9-8ab5-4bfa32ff3b6e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/cropped.jpg",
        "about_us": "N/A"
    },
    {
        "id": "8377d9e9da3aee2606e19204191861ef",
        "university_name": "University of Kent",
        "location": "Giles Lane, Canterbury, Kent, CT2 7NR",
        "official_website": "https://www.kent.ac.uk?utm_source=UCAS&utm_medium=paid_referrer&utm_campaign=ug_profiles_25&utm_id=ken046&utm_content=general-profile",
        "contact_email": "information@kent.ac.uk",
        "contact_phone": "01227 768896",
        "course_locations": [
            {
                "location_name": "Canterbury campus",
                "location_address": "University of Kent, Canterbury, CT2 7NZ"
            },
            {
                "location_name": "Paris School of Arts and Culture",
                "location_address": "Reid Hall, 4 Rue de Chevreuse, Paris, 75006"
            },
            {
                "location_name": "University of Kent at Medway",
                "location_address": "Medway Building, Chatham, ME4 4AG"
            },
            {
                "location_name": "Main Site",
                "location_address": "Recruitment and Admissions Office, The Registry,, Darwin Road, Canterbury, CT2 7NZ"
            },
            {
                "location_name": "Canterbury College (East Kent College)",
                "location_address": "New Dover Road, Canterbury, CT1 3AJ"
            },
            {
                "location_name": "Medway campus",
                "location_address": "University of Kent at Medway, The Medway Building, Chatham Maritime, Chatham, ME4 4AG"
            },
            {
                "location_name": "Main Site",
                "location_address": "Recruitment and Admissions Office, Registry, Canterbury, CT2 7NZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/aa22c216-8919-508e-5893-8167c8ab6c57",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/kent-autumn-shoot-109.jpg",
        "about_us": "Becoming a University of Kent student is an exciting step towards achieving your ambition. Make the most of our outstanding facilities and soak up all the experiences and opportunities we offer, as you study at a Top 40 University (The Times Good University Guide 2025). Whatever your goals and interests, we\u2019re sure you\u2019ll find your place at Kent.You\u2019ll join a vibrant, creative and inclusive university where we inspire each other to do outstanding things in the world. During your time here, you\u2019ll benefit from quality teaching, well-resourced libraries, extensive student support services and outstanding career guidance, plus hundreds of societies and excellent sports facilities.Find out where your ambition can take you.\u00a0"
    },
    {
        "id": "9dff00577cfacc4017d64ff613577051",
        "university_name": "King Stage",
        "location": "8 Greenwich Quay, London, SE8 3EY",
        "official_website": "https://www.king-stage.com",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Dreadnought building",
                "location_address": "30 Park Row Greenwich, London, SE10 9LS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3cb12f40-4417-4706-a5fb-b1db146ceaf9",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/king_stage.jpg",
        "about_us": "N/A"
    },
    {
        "id": "3e920dbf33f8e319ac7795165340ee15",
        "university_name": "King's College London, University of London",
        "location": "Strand, London, London, WC2R 2LS",
        "official_website": "http://www.kcl.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "02078 365454",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Strand, London, WC2R 2LS"
            },
            {
                "location_name": "Strand Campus",
                "location_address": "Strand, Westminster, London, WC2R 2LS"
            },
            {
                "location_name": "Main Site",
                "location_address": "Strand, Westminster, WC2R 2LS"
            },
            {
                "location_name": "St Thomas' Campus",
                "location_address": "Westminster Bridge Road, Southwark, London, SE1 7EH"
            },
            {
                "location_name": "Waterloo Campus",
                "location_address": "Stamford Street, Southwark, London, SE1 8WA"
            },
            {
                "location_name": "Denmark Hill Campus",
                "location_address": "Health Service and Population Research Department, Institute of Psychiatry, De Crespigny Park, Denmark Hill, Southwark, London, SE5 8AF"
            },
            {
                "location_name": "Guy's Campus",
                "location_address": "New Hunts House, Southwark, London, SE1 1UL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5fd4b1cc-6fb7-5a7c-0bf6-19f979c6cf36",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/kcl_guys_open_day_cropped_2.jpg",
        "about_us": "N/A"
    },
    {
        "id": "e68824cd3757edbcb8ea60960a5f7a46",
        "university_name": "Kingston Maurward College",
        "location": "University Centre Kingston Maurward, Dorchester, Dorset, DT2 8PY",
        "official_website": "https://www.kmc.ac.uk",
        "contact_email": "enquiries@kmc.ac.uk",
        "contact_phone": "01305-215000",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Dorchester, Dorchester, DT2 8PY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/58bbc794-c3ef-37b3-8008-75b5d382a072",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/image1_0.jpg",
        "about_us": "Sometimes the best opportunities are right on your doorstep and here at University Centre Kingston Maurward our degree-level courses are a prime example.Kingston Maurward is a specialist provider serving the needs of land-based and allied industries in Dorset and the South West.Our students will be inspired to achieve their potential and thrive in a challenging yet caring environment, enjoying exciting learning and high-quality facilities within a vibrant commercial rural estate.With various degree-level courses, some of which include new Higher Technical Qualifications (HTQs), and partnerships with universities including The Open University and Bournemouth University, we pride ourselves on helping those in land-based industries continue to develop their careers.With an established transport system and great road links across the county, we are easy to access with ample car-parking on site too."
    },
    {
        "id": "d8b88adfc623fc865de5a31452a67746",
        "university_name": "Kingston University",
        "location": "Finance Department, Kingston University, 2nd Floor Main Building, Penrhyn Road, Kingston upon Thames, KT1 2EE, London, KT2 6TN",
        "official_website": "http://www.kingston.ac.uk/",
        "contact_email": "UKenquiries@kingston.ac.uk",
        "contact_phone": "02035100106",
        "course_locations": [
            {
                "location_name": "Roehampton Vale",
                "location_address": "Roehampton Vale, Friars Avenue, Kingston upon Thames, SW15 3DW"
            },
            {
                "location_name": "Kingston College",
                "location_address": "Kingston Hall Road, Kingston upon Thames, KT1 2AQ"
            },
            {
                "location_name": "Reach Academy",
                "location_address": "53-55 High Street, Feltham, TW13 4AB"
            },
            {
                "location_name": "LETTA Trust",
                "location_address": "Aberfeldy Centre, Aberfeldy Street, Aberfeldy Village, London, E14 0NU"
            },
            {
                "location_name": "Flourish  Learning Trust",
                "location_address": "Macdonald Road, Waltham Forest, E17 4AZ"
            },
            {
                "location_name": "Richmond Upon Thames College",
                "location_address": "Egerton Road, Twickenham, TW2 7SJ"
            },
            {
                "location_name": "Penrhyn Road Campus",
                "location_address": "Penrhyn Road, Kingston upon Thames, KT1 2EE"
            },
            {
                "location_name": "Main Site",
                "location_address": "River House, 53-57 High Street, Kingston upon Thames, KT1 1LQ"
            },
            {
                "location_name": "Knights Park",
                "location_address": "Grange Road, Kingston upon Thames, KT1 2QJ"
            },
            {
                "location_name": "Royal School of Needlework",
                "location_address": "Apartment 12a, Hampton Court Palace, East Molesey, KT8 9AU"
            },
            {
                "location_name": "Brooklands College - Weybridge Campus",
                "location_address": "Heath Road, Weybridge, KT13 8TT"
            },
            {
                "location_name": "Morley College - North Kensington Campus (formerly known as Kensington and Chelsea College)",
                "location_address": "Wornington Road, London, W10 5QQ"
            },
            {
                "location_name": "Morley College - Chelsea Campus (formerly known as Kensington and Chelsea College)",
                "location_address": "Hortensia Road, London, SW10 0QS"
            },
            {
                "location_name": "West Thames College",
                "location_address": "London Road, Isleworth, London, TW7 4HS"
            },
            {
                "location_name": "Main Site",
                "location_address": "Yorkon Building, Kingston University, Kingston upon Thames, London, KT2 7LB"
            },
            {
                "location_name": "Kingston Hill",
                "location_address": "Kingston Hill, Kingston upon Thames, KT2 7LB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/4d6287f3-52f5-a2ce-f369-918c5e1e12bc",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/town_house_ucas_2.jpg",
        "about_us": "At Kingston University, we are passionate about enhancing our students' life chances by helping them make the most of their learning opportunity and equipping them with the skills and knowledge needed in the 21st Century's demanding workplace.Careers and employability are at the centre of our curriculum. Looking to our students' futures, many courses have been developed in consultation with industry-leading organisations and carry professional accreditation. Our staff maintain links to business and industry by staying active in the workplace and earning respect in the fields in which they practice.\u00a0The university is superbly placed for students to take advantage of London\u2019s many activities and attractions, as well as being close to one of the largest employment centres in the world. Students at Kingston will be able to benefit from award-winning teaching quality during their studies and go on to join a global network of alumni across different industries and sectors upon graduation."
    },
    {
        "id": "eef2b4a49847b9c3f379ddfb11ed4be4",
        "university_name": "KLC School of Design",
        "location": "West Dean: London, Dilke House, 1 Malet St, London, London, WC1E 7JN",
        "official_website": "https://www.westdean.ac.uk/klc-school-of-design",
        "contact_email": "study@westdean.ac.uk",
        "contact_phone": "+44 (0)20 7376 3377",
        "course_locations": [
            {
                "location_name": "Online Study",
                "location_address": "100% online with teaching staff based at:, Dilke House, 1 Malet Street, London, WC1E 7JN"
            },
            {
                "location_name": "West Dean: London",
                "location_address": "Dilke House, 1 Malet St, London, WC1E 7JN"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/eb187029-10de-02e3-4ac7-0a8120a2c82c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/web-9585-2.jpg",
        "about_us": "Founded in 1982 by Principal Jenny Gibbs OBE, KLC School of Design has been committed to delivering an industry-leading range of accredited and professional qualifications in Interior Design and Gardening. Our close industry connections, access to practising professionals and investment in emerging technologies offers our students cutting-edge teaching and career ready skills.KLC School of Design merged with West Dean College of Arts and Conservation on 31 July 2021. Combining our strengths, we are in a better position to meet the fast-changing needs of students, the industries we serve and the rapidly changing world beyond.Students will also be offered greater choice in their studies going forward, with a wider range of courses in complementary subjects. The focus will remain firmly on fostering creative excellence and innovation, providing the ideal setting for students to acquire and apply the skills needed for successful careers in the arts, conservation and design industries."
    },
    {
        "id": "8ccb7cffc4ab98df88f36e87cfe890f8",
        "university_name": "LAMDA (London Academy of Music &amp; Dramatic Art)",
        "location": "155 Talgarth Road, London, W14 9DA",
        "official_website": "https://www.lamda.ac.uk",
        "contact_email": "admissions@lamda.ac.uk",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "LAMDA",
                "location_address": "155 Talgarth Road, London, W14 9DA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/73bc7b26-5eaf-44d7-b1e2-48f109cf0768",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/lamda_cyrano_calex_brenner_12_8338-arw-dx5_s.jpg",
        "about_us": "LAMDA is the oldest drama school in the UK and a pioneering conservatoire offering industry-leading vocational training in\u00a0acting, directing, musical theatre, and production and technical arts.Committed to providing an innovative and inspiring environment for its students, LAMDA achieved more than 85% in the 2024 National Student Survey. LAMDA came number one in The Guardian's 2025 UK Universities league table for Drama and Dance and in 2024, The Hollywood Reporter ranked LAMDA as one of the top 10 drama schools in the world.Notable alumni include actors Benedict Cumberbatch, Leah Harvey, David Oyelowo and Brian Cox, directors Rebecca Frecknall, Tinuke Craig and Polly Findlay, and technical practitioners Natasha Chivers and Tingying Dong.\u00a0\u00a0"
    },
    {
        "id": "6f234b610a4abe154821b5af512bfb29",
        "university_name": "Lancaster University",
        "location": "Lancaster University, University House, Bailrigg, Lancashire, LA1 4YW",
        "official_website": "http://www.lancaster.ac.uk/",
        "contact_email": "visitus@lancater.ac.uk",
        "contact_phone": "0152465201",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Bailrigg, Lancaster, LA1 4YW"
            },
            {
                "location_name": "Main Site",
                "location_address": "Admissions, Bailrigg, Lancaster, LA1 4YW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/058545dd-9b74-4129-217d-dd59394240e8",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/sl_lancsuni_jun22_slv_1814-edit_hr_0.jpg",
        "about_us": "Located just three miles from Lancaster city centre in England's scenic North West, Lancaster University is situated on a spacious green campus. Our community is comprised of staff and students from over 100 countries, so whether you\u2019re local or crossing oceans to join us, you\u2019ll build lifelong connections that cross borders and make memories to last a lifetime.\u00a0\u00a0Lancaster is a Top 10 UK university (Complete University Guide 2025), consistently top 15 in the three main UK league tables and this year, was named one of the top 30 most targeted universities by the UK's leading graduate employers in the Graduate Market in 2024 report (High Fliers).\u00a0Our community of researchers transform lives and communities across the globe. The strength of our research was recognised by the UK Government\u2019s Research Excellence Framework which rated 91% of our research rated world-leading or internationally excellent in 2021.\u00a0\u00a0"
    },
    {
        "id": "f1778459b00b88d5ddd52c97d66895de",
        "university_name": "LCCM",
        "location": "241 Union Street, London, SE1 0LR",
        "official_website": "https://www.lccm.org.uk",
        "contact_email": "contact@lccm.org.uk",
        "contact_phone": "020 3535 1080",
        "course_locations": [
            {
                "location_name": "The Music Box",
                "location_address": "241 Union Street, London, SE1 0LR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b79f887e-0e6e-c107-1733-550f2a6c7a5a",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/untitled_design_21.png",
        "about_us": "N/A"
    },
    {
        "id": "c4bd5470f9e2ab967aadfb13e4a95ebf",
        "university_name": "Leeds Arts University",
        "location": "Blenheim Walk, Leeds, West Yorkshire, LS2 9AQ",
        "official_website": "https://www.leeds-art.ac.uk/",
        "contact_email": "marketing@leeds-art.ac.uk",
        "contact_phone": "0113 202 8000",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Blenheim Walk, Leeds, LS2 9AQ"
            },
            {
                "location_name": "Blenheim Walk",
                "location_address": "Blenheim Walk, Leeds, LS2 9AQ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/272be5f0-a5a7-a1d6-a873-73dffa53dbe5",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas-provider-page-banner_0.jpg",
        "about_us": "\"You\u2019ll be immersed in an inspiring atmosphere and will have opportunities to collaborate with others whose specialism lies outside of your own - from filmmakers to fashion designers, photographers to musicians, artists to designers; anything feels possible here.\u00a0There\u2019s a real-life feel to studying with us. You\u2019ll have countless opportunities to learn from, network with, and work for some of the best names in your chosen field, thanks to our contacts within industry and practising creatives. This adds a vital perspective to your studies, helps to build your CV and will enhance your prospects.\u00a0We work extremely hard to remain a leading centre for the arts, nurturing some of the most sought-after talent. Our diverse student body encompasses creatives from across the globe, from India to Iceland, Spain to Hong Kong, each bringing with them their own individual perspective, skills and potential.\"Professor Simone WonnacottVice-Chancellor"
    },
    {
        "id": "dec2b59b0f9e3f9e6910c7f543b43b17",
        "university_name": "Leeds Beckett University",
        "location": "City Campus, Leeds, West Yorkshire, LS1 3HE",
        "official_website": "https://www.leedsbeckett.ac.uk",
        "contact_email": "study@leedsbeckett.ac.uk",
        "contact_phone": "0113 812 0000",
        "course_locations": [
            {
                "location_name": "Headingley Campus",
                "location_address": "Beckett Park, Headingley, Leeds, LS6 3QS"
            },
            {
                "location_name": "City Campus",
                "location_address": "Calverley Street, Leeds, Leeds, LS1 3HE"
            },
            {
                "location_name": "City CampusC",
                "location_address": "City Centre Campus, Leeds, LS1 3HE"
            },
            {
                "location_name": "Main Site",
                "location_address": "City Campus, Leeds, LS1 3HE"
            },
            {
                "location_name": "Emily ",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/059b84a8-9224-10b5-6526-b2331e526138",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/leeds-city-scape-2.jpg",
        "about_us": "Giving you a future advantage in your career is at the heart of what we do at Leeds Beckett. While we get you career-ready, you'll get an amazing student experience with high-quality teaching in one of the largest student cities in the UK.\u00a0We have over 24,000 students in Leeds and 185,000 alumni worldwide.We have recently been\u00a0awarded a five-star rating by QS Stars, positioning us alongside elite higher education institutions globally.We offer a wide range of challenging and exciting courses that will help you develop vital transferable and professional skills. Our amazing tutors and industry links mean you\u2019ll get the support you need to build your future.Leeds is a diverse and vibrant city recently\u00a0named the best place to live in the North by The Sunday Times.\u00a0 Also ranked one of the top 10 UK cities most affordable for students by the Natwest Student Living Index in 2023, it\u2019s easy to see why so many students choose to study in Leeds year after year.\u00a0"
    },
    {
        "id": "0bc06c07e55313499bed10bf6377ef9f",
        "university_name": "University Centre Leeds, Leeds City College",
        "location": "University Centre Leeds, Park Lane, Leeds, West Yorkshire, LS3 1AA",
        "official_website": "https://ucleeds.ac.uk",
        "contact_email": "engage@ucleeds.ac.uk",
        "contact_phone": "01132354450",
        "course_locations": [
            {
                "location_name": "Mabgate",
                "location_address": "Mabgate, 74-76 Mabgate, Leeds, LS9 7EA"
            },
            {
                "location_name": "University Centre",
                "location_address": "University Centre, Park Lane, Leeds, LS3 1AA"
            },
            {
                "location_name": "Keighley College",
                "location_address": "Keighley College, Bradford Road, Keighley, BD21 4HQ"
            },
            {
                "location_name": "Quarry Hill Campus",
                "location_address": "Playhouse Square, Leeds, LS2 7UP"
            },
            {
                "location_name": "Park Lane Campus",
                "location_address": "Park Lane, Leeds, LS3 1AA"
            },
            {
                "location_name": "Temple Newsam Home Farm",
                "location_address": "Temple Newsam Road, Leeds, LS15 0BG"
            },
            {
                "location_name": "Harrogate College",
                "location_address": "Hornbeam Park Avenue, Harrogate, HG2 8QT"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/fe767449-8161-5162-905e-8baafdf54c23",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/49053899731_b09193b954_o.jpg",
        "about_us": "Whether you\u2019re looking to continue your studies or level up your career, University Centre Leeds will provide all the support you need.We offer a vast array of flexible two-year foundation degrees and one-year top-up courses, so you can turn your studies into a full masters degree. Students can explore creative arts, science, digital and more, while gaining a nationally recognised qualification that works around their life.With a strong focus on enhancing employability, the centre offers students first-class industry links, masterclasses and workshops, practical placement opportunities and facilities to experience learning in the real world. Students are also supported every step of the way through smaller class sizes and one-to-one feedback from industry expert tutors.This has seen UC Leeds achieve the highest student satisfaction ratings in Leeds for four years running (National Student Survey, 2024)."
    },
    {
        "id": "31922d3f478f508c8f287dd9ccc914b6",
        "university_name": "Leeds Conservatoire",
        "location": "3 Quarry Hill, Leeds, West Yorkshire, LS2 7PD",
        "official_website": "https://leedsconservatoire.ac.uk/?utm_source=ucas&utm_medium=referral&utm_campaign=ug_25",
        "contact_email": "hello@lcm.ac.uk",
        "contact_phone": "0113 222 3416",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "3 Quarry Hill, Leeds, LS2 7PD"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/74edaea7-4171-f316-b292-a982238f83d2",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_header_0.jpg",
        "about_us": "Leeds Conservatoire has been at the forefront of music and performing arts education for more than 50 years and offers contemporary specialist training to a community of around 1,500 undergraduate students. With a national and international reputation for creativity and innovation, it offers unrivalled collaboration opportunities regardless of discipline, whether that be classical, jazz, pop music, acting, music business, music production, or musical theatre.Students are taught in small groups or one-to-one sessions, working with creative practitioners and professionals in industry-standard facilities, practice rooms, and studios. Leeds Conservatoire sits in the heart of the Cultural Quarter. Surrounded by theatres, dance companies, and music venues, students can benefit from the city\u2019s thriving cultural scene."
    },
    {
        "id": "1298c62ad13f5848e2f1c42ef8724de2",
        "university_name": "Leeds Trinity University",
        "location": "Brownberrie Lane, Horsforth, Leeds, West Yorkshire, LS18 5HD",
        "official_website": "https://www.leedstrinity.ac.uk/",
        "contact_email": "hello@leedstrinity.ac.uk",
        "contact_phone": "01132 837100",
        "course_locations": [
            {
                "location_name": "Horsforth Campus",
                "location_address": "Brownberrie Lane, Leeds, LS18 5HD"
            },
            {
                "location_name": "Bradford College",
                "location_address": "Bradford College, Great Horton Road, Bradford, BD7 1AY"
            },
            {
                "location_name": "Leeds City Campus",
                "location_address": "1 Trevelyan Square, Leeds, LS1 6HP"
            },
            {
                "location_name": "Leeds Trinity University",
                "location_address": "Brownberrie Lane, Leeds, LS18 5HD"
            },
            {
                "location_name": "Asabea",
                "location_address": "N/A"
            },
            {
                "location_name": "Abbey",
                "location_address": "N/A"
            },
            {
                "location_name": "Amy",
                "location_address": "N/A"
            },
            {
                "location_name": "Belle ",
                "location_address": "N/A"
            },
            {
                "location_name": "Ellie",
                "location_address": "N/A"
            },
            {
                "location_name": "Azaan",
                "location_address": "N/A"
            },
            {
                "location_name": "Abbie ",
                "location_address": "N/A"
            },
            {
                "location_name": "Aneesah",
                "location_address": "N/A"
            },
            {
                "location_name": "Arefeen",
                "location_address": "N/A"
            },
            {
                "location_name": "Angel",
                "location_address": "N/A"
            },
            {
                "location_name": "Chloe",
                "location_address": "N/A"
            },
            {
                "location_name": "Bethany",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/10bb36d7-3ad8-4a90-efc1-d6ce93dc54b8",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/01182024jsp_ltu_03-0403_1_5.jpg",
        "about_us": "Leeds Trinity is known for producing highly employable graduates thanks to the professional work placements that are embedded within the majority of our standard undergraduate degrees.This has helped\u00a096% of our graduates to enter employment or further study within 15 months of graduating.\u00a0(Graduate Outcomes, Higher Education Statistics Agency (HESA) published 2024)\u200b.\u200b\u200bAt Leeds Trinity, you'll develop valuable employability and life skills, with placement and internship opportunities, or opportunities to go overseas, alongside a variety of clubs and societies to join.Leeds Trinity is proud of its high-quality teaching and facilities across both its campuses. The Main Campus offers teaching in a beautiful green space and easy access to the surrounding areas, while the new Leeds City Campus ensures that students can be close to the action in the heart of Leeds."
    },
    {
        "id": "837fe0e970fff8dc417d0f1c7cb7a76d",
        "university_name": "University of Leeds",
        "location": "Woodhouse Lane, Leeds, West Yorkshire, LS2 9JT",
        "official_website": "http://www.leeds.ac.uk/",
        "contact_email": "study@leeds.ac.uk",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "University of Leeds",
                "location_address": "Woodhouse Lane, Leeds, LS2 9JT"
            },
            {
                "location_name": "Main Site",
                "location_address": "Woodhouse Lane, Leeds, LS2 9JT"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b03b9d06-ac02-d7d8-dd09-5917f9efe4cb",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_.png",
        "about_us": "Choosing Leeds is about much more than selecting a university place \u2013 you\u2019ll enjoy a learning experience unlike any other, while being at the heart of one of the most vibrant and multicultural cities in the country.Established in 1904 and ranked among the top 100 universities in the UK, we\u2019re part of the prestigious Russell Group of universities, renowned globally for the outstanding quality of our research and teaching.Embrace the journey \u2013 study at the University of Leeds and gain invaluable skills that will help you grow personally and professionally and enable you to make your mark on your future.Think beyond, come to Leeds.\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0"
    },
    {
        "id": "f52afcff940348f95336b48ddf3a14db",
        "university_name": "Leicester College",
        "location": "Grafton Place, St John's Street, Leicester, Leicestershire, LE1 3WL",
        "official_website": "https://leicestercollege.ac.uk/higher-education/",
        "contact_email": "N/A",
        "contact_phone": "01162 242000",
        "course_locations": [
            {
                "location_name": "Freemen's Park Campus",
                "location_address": "Freemen's Park Campus, Welford Road, Leicester, LE2 7LW"
            },
            {
                "location_name": "St Margaret\u2019s Campus",
                "location_address": "St John Street, Leicester, LE1 3WL"
            },
            {
                "location_name": "Abbey Park Campus",
                "location_address": "Painter Street, Leicester, LE1 3WA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/082a9246-a880-1ff1-8f69-cbb52b1396be",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/2703.jpg",
        "about_us": "Higher education is an excellent way develop yourself and to increase your career opportunities. Here are just three reasons why you should study a university-level course at Leicester College:Affordable, high-quality learning:\u00a0Our fees are more affordable than many universities, but with the same great teaching and learning experience. You could also save money by living at home rather than staying in the expensive halls of residences or private accommodation at university.Exceptional standards of teaching:\u00a0Our teaching staff are experienced industry professionals with a genuine passion for their subjects \u2013 you can really benefit from their exceptional teaching and mentoring.Flexible study to suit you:\u00a0We offer courses on both a full and part-time basis, enabling you to choose a mode of study that suits your lifestyle."
    },
    {
        "id": "fe2ca066e3c015aae5f3445e3d598115",
        "university_name": "Lincoln College University Centre",
        "location": "Monks Road, Lincoln, Lincoln, Lincolnshire, LN2 5HQ",
        "official_website": "https://www.lincolncollege.ac.uk/university-centre",
        "contact_email": "he@lincolncollege.ac.uk",
        "contact_phone": "01522 876 398 ",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Monks Road, Lincoln, LN2 5QH"
            },
            {
                "location_name": "ASI Newark",
                "location_address": "ASI Newark, Great North Road, Newark on Trent, NG24 1BL"
            },
            {
                "location_name": "Newark College",
                "location_address": "Friary Road, Newark, NG24 1PB"
            },
            {
                "location_name": "Christ's Hospital Terrace",
                "location_address": "Christ's Hospital Terrace, Lincoln, LN2 1LY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d680310d-e072-4c8b-5312-dcdafdf6ef36",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/afternoon_ceremony_114_lr.jpg",
        "about_us": "Lincoln College University Centre\u00a0Career Degree programmes are business-led and career-focused. Many of our courses have been designed in conjunction with our business and industry partners, ensuring our students have the opportunity to leave us with a clear pathway to a successful career.A highly competitive job market across most sectors can make securing graduate roles challenging. That\u2019s why we\u2019ve developed Career Degrees, to give our students the opportunity to get ahead of the competition at interview and help them secure the career of their dreams.Our University Centre is home to our School of Musical Instrument Crafts (based at our Newark College campus) and Air and Space Institute (Newark).\u00a0"
    },
    {
        "id": "b332ac815dba2e1b2e89fdbe42994b2b",
        "university_name": "City of Liverpool College University Centre",
        "location": "The Learning Exchange,Roscoe Street, Liverpool, Merseyside, L1 9DW",
        "official_website": "http://www.liv-coll.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "0151-252-1515",
        "course_locations": [
            {
                "location_name": "The Learning Exchange, Roscoe Street",
                "location_address": "Roscoe Street, Liverpool, L1 9DW"
            },
            {
                "location_name": "Vauxhall Road Centre",
                "location_address": "Vauxhall Road, Liverpool, L3 6BN"
            },
            {
                "location_name": "Arts Centre",
                "location_address": "The Arts Centre, Myrtle Street, Liverpool, L7 7JA"
            },
            {
                "location_name": "Duke St",
                "location_address": "Duke Street, Liverpool, L1 5BG"
            },
            {
                "location_name": "Clarence St",
                "location_address": "Clarence Street, Liverpool, L3 5TP"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/bf0964d9-4289-6817-6ba1-24d7356686c9",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_4.jpg",
        "about_us": "N/A"
    },
    {
        "id": "65abb54b49be8380b4e8cd3c23627b1b",
        "university_name": "Liverpool Hope University",
        "location": "Hope Park Campus, Taggart Avenue, Liverpool, Merseyside, L16 9JD",
        "official_website": "http://www.hope.ac.uk",
        "contact_email": "courses@hope.ac.uk",
        "contact_phone": "01512 913000",
        "course_locations": [
            {
                "location_name": "Hope Park",
                "location_address": "Hope Park, Liverpool, L16 9JD"
            },
            {
                "location_name": "Main Site",
                "location_address": "Hope Park, Liverpool, L16 9JD"
            },
            {
                "location_name": "Carmel College",
                "location_address": "Prescot Road, St. Helens, WA10 3AG"
            },
            {
                "location_name": "Creative Campus",
                "location_address": "Shaw Street, Liverpool, L6 1HP"
            },
            {
                "location_name": "Bellmont College",
                "location_address": "1st Floor, 9 Lymington Avenue, Wood Green, London, N22 6EA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/78c33534-76bb-35d4-7324-dd7e69f3cd18",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_bulletin_1920x420_080819.jpg",
        "about_us": "At Liverpool Hope University, we have two beautiful main teaching campuses, a dedicated accommodation campus, and an outdoor education centre. It is within the beautiful landscaped grounds of these campuses that you will study, live and socialise, whilst enjoying all of the benefits of being in the vibrant city of Liverpool.Hope Park CampusLiverpool Hope\u2019s largest campus, Hope Park, is a friendly educational village with places to live, work, study, eat and relax. It is situated in a leafy suburb of Liverpool, just four miles from the city centre. With major investment over recent years, the campus now consists of traditional architecture alongside state-of-the-art, modern, purpose-built buildings.Creative CampusOur Creative Campus is close to Liverpool city centre and is the home of our School of Creative and Performing Arts subjects. The newly expanded site sees state-of-the-art technology and performance spaces sit alongside inspirational Grade-II listed buildings."
    },
    {
        "id": "cd26ecbb4d4e1869b938d709e7bee82d",
        "university_name": "Liverpool Institute for Performing Arts",
        "location": "Mount Street, Liverpool, Merseyside, L1 9HF",
        "official_website": "https://lipa.ac.uk/",
        "contact_email": "enquiries@lipa.ac.uk",
        "contact_phone": "01513 303000",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Mount Street, Liverpool, L1 9HF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/0097ca6a-685c-20f1-6823-815f66102863",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/2ube_xtra_lipa_banner_ps.jpg",
        "about_us": "The Liverpool Institute for Performing Arts (LIPA) is world renowned as a leading provider of university level creative and performing arts training with a portfolio of ambitious and popular courses.\u00a0LIPA\u2019s pioneering approach to learning is project based. This highly practical method is underpinned by collaboration, industry links and entrepreneurial training. It creates multi-skilled, innovative and empowered graduates able to follow their passions by forging a sustained career in the creative and performing arts."
    },
    {
        "id": "3f8e874038f61848017c55aa74c4a5a3",
        "university_name": "Liverpool John Moores University",
        "location": "Student Life Building, Copperas Hill, Liverpool, Merseyside, L3 5AJ",
        "official_website": "http://www.ljmu.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "0151 2312121",
        "course_locations": [
            {
                "location_name": "Liverpool John Moores University",
                "location_address": "Student Recruitment and Admissions, Exchange Station, Tithebarn Street, Liverpool, L2 2QP"
            },
            {
                "location_name": "City Campus",
                "location_address": "3 Byrom Street, Liverpool, L3 3AF"
            },
            {
                "location_name": "Everton Football College",
                "location_address": "Spellows Lane, Liverpool, L4 4DF"
            },
            {
                "location_name": "Mount Pleasant Campus",
                "location_address": "Student Life Building, 10 Copperas Hill, Liverpool, L3 5AH"
            },
            {
                "location_name": "Main Site",
                "location_address": "Student Life Building, Copperas Hill, Liverpool, L3 5AJ"
            },
            {
                "location_name": "Mount Pleasant Campus",
                "location_address": "Student Life Building, 10 Copperas Hill, Liverpool, L35AH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b4d790d4-6257-fa90-60eb-31b5fc917ad1",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_header_2025_option_1.5_0.jpg",
        "about_us": "Liverpool John Moores University (LJMU) is a modern, student-centred university with a heritage that stretches back to 1823. LJMU\u2019s teaching is informed by exceptional research and extensive industry links, producing graduates who are socially engaged, professional, confident and well-equipped to thrive in their chosen field. LJMU is committed to helping you to reach new heights and forge a fulfilling future to be proud of.All of this is on offer in the vibrant city of Liverpool, famed for its arts, culture and friendly people. Liverpool has been placed number 1 in the Student Crowd's 'Best Student Cities 2023' and number 7 in Time Out's 'Best Cities in the World 2024, and you can be part of this great city.With our wide range of available courses, our go abroad programmes and our Students\u2019 Union offering over 100 clubs and societies to join, from true crime to Taylor Swift, you will not be short of opportunities at LJMU."
    },
    {
        "id": "0e9276342704549e7f69f7fc663d44b2",
        "university_name": "Liverpool School of Tropical Medicine",
        "location": "Pembroke Place, Liverpool, L3 5QA",
        "official_website": "https://www.lstmed.ac.uk",
        "contact_email": "mylstm@lstmed.ac.uk",
        "contact_phone": "0151 702 9590",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Pembroke Place, Liverpool, L3 5QA"
            },
            {
                "location_name": "Liverpool School of Tropical Medicine",
                "location_address": "Liverpool School of Tropical Medicine, Pembroke Place, Liverpool, L3 5QA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ff27f6b2-9ff1-46da-3cb3-86442eac2b3d",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/facebook_banner.jpg",
        "about_us": "LSTM is a centre of excellence in research and postgraduate teaching in the field of tropical medicine. It is the oldest institution of its kind in the world and has been at the forefront of training leaders in global health since 1898. As we move towards our 125th anniversary in 2023, we will continue to focus on providing high quality scientific evidence and translating our scientific discovery into policies and practices. We do that by working collaboratively in over 70 countries to fulfil our mission of improving the health of the world\u2019s poorest people."
    },
    {
        "id": "c1590be3172f19888458b9b87b367b44",
        "university_name": "University of Liverpool",
        "location": "Brownlow Hill, Liverpool, Merseyside, L69 7ZX",
        "official_website": "http://www.liverpool.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "0151 794 2000",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "The Foundation Building, Brownlow Hill, Liverpool, L69 7ZX"
            },
            {
                "location_name": "City Centre Campus",
                "location_address": "University of Liverpool, Liverpool, L69 3BX"
            },
            {
                "location_name": "Main Site",
                "location_address": "The Foundation Building, 765 Brownlow Hill, Liverpool, L69 7ZX"
            },
            {
                "location_name": "Online",
                "location_address": "The Foundation Building, 765 Brownlow Hill, Liverpool, L69 7ZX"
            },
            {
                "location_name": "Leahurst Campus",
                "location_address": "Chester High Road, Neston, CH64 7TE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/9a2e99c3-c568-12ae-bd18-488bc579b30d",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/cmccoy_wynne-6624.jpg",
        "about_us": "Our story began in 1881 when we became one of the first civic universities - the original redbrick.Today, we\u2019re a globally recognised Russell Group university, associated with nine Nobel Prize winners. We're home to valued research and lively, original thinking.The city of Liverpool is a unique and exciting place to study and live in. It shapes the University through its dynamic spirit, global outlook, and pioneering opportunities.We\u2019re proud to have achieved Gold status in the Teaching Excellence Framework (TEF) 2023. Our offer excels in aspects such as course teaching, learning resources, and progression rates.Studying with us brings countless career benefits. The University's Careers and Employability Service, one of the most innovative and dynamic in the UK, will support you every step of the way. So, you can be confident that you are making a sound decision for your future career by choosing us.Start your origin story here.\u00a0"
    },
    {
        "id": "401697a5241829780b53e82214825107",
        "university_name": "LMA",
        "location": "Atrium B, Broadcast Centre, 10 East Bay Lane, London, E15 2GW",
        "official_website": "https://www.lma.ac.uk",
        "contact_email": "admissions@lma.ac.uk",
        "contact_phone": "07723 611 410",
        "course_locations": [
            {
                "location_name": "LMA - Liverpool",
                "location_address": "LMA  Metquarter, 35 Whitechapel, Liverpool, L1 6DA"
            },
            {
                "location_name": "LMA - London",
                "location_address": "Atrium B, Broadcast Centre, Here East, 10 East Bay Lane,  Queen Elizabeth Olympic Park, London, E15 2GW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/812e7c31-baac-40b7-a493-0e5bb540a3f5",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/lma_banner.jpg",
        "about_us": "LMA is one of the fastest growing creative institutions in the UK. Celebrating talent, diversity and inclusivity by breaking down barriers into higher education.\u00a0When you join LMA, you are surrounded by like-minded people, in an environment where hard work brings great reward. You are taught by industry professionals in state of the art facilities."
    },
    {
        "id": "90969d9a3acbfe3c2d67ac7bb646f9de",
        "university_name": "London Business School, University of London",
        "location": "Regent's Park, Westminster, NW1 4SA",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "London Business School",
                "location_address": "Regent's Park, Camden, NW1 4SA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/32d682dc-fcc9-b0ec-778a-fbc7924494a6",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/lbs_sussex_place.jpg",
        "about_us": "N/A"
    },
    {
        "id": "80f05ab2193c13fb5423f947af79ba3f",
        "university_name": "London College",
        "location": "680 Bath Road,Cranford, Hounslow, Greater London, TW5 9QX",
        "official_website": "https://www.lcuck.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "020 7243 4000",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "680, Bath Road, Hounslow, TW5 9QX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/f0896af7-91cb-41bc-9542-ce9fa43902dc",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "b8f0ed4d513eecb71f23fd95c124db56",
        "university_name": "London College of Business Studies",
        "location": "277 Cranbrook Road, Ilford, London, IG1 4TG",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Ilford",
                "location_address": "277 Cranbrook Road, Ilford, IG1 4TG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/0115c340-ad5e-48cb-a788-daa53f02d5b4",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "15589f2689d2a1326311b009638502cc",
        "university_name": "London College of Contemporary Arts",
        "location": "Sceptre Court, 40 Tower Hill, London, EC3N 4DX",
        "official_website": "https://www.lcca.org.uk",
        "contact_email": "info@lcca.org.uk",
        "contact_phone": "+44 (0)20 3435 4642",
        "course_locations": [
            {
                "location_name": "Sceptre Court",
                "location_address": "Sceptre Court, 40 Tower Hill, London, EC3N 4DX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d9ea977d-2d12-49ab-936d-d6bfdfac0b75",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/generic_0.png",
        "about_us": "N/A"
    },
    {
        "id": "40e223bd85532c2c8658ac543742e930",
        "university_name": "London Film Academy",
        "location": "The Old Church, 52a Walham Grove, 52a Walham Grove, London, SW6 1QR",
        "official_website": "https://londonfilmacademy.com",
        "contact_email": "N/A",
        "contact_phone": "0207 3867711",
        "course_locations": [
            {
                "location_name": "London Film Academy",
                "location_address": "London Film Academy, 52A Walham Grove, London, SW6 1QR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/436d2160-9350-49a4-9ccb-590501536c57",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/lfa_longest_ride_sarah_passos_-_12.jpg",
        "about_us": "N/A"
    },
    {
        "id": "2947021cf336a6e43759bb2830658d80",
        "university_name": "The London Film School",
        "location": "24 Shelton Street, Westminster, WC2H 9UB",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "London Film School",
                "location_address": "24 Shelton Street, London, Covent Garden, WC2H 9UB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/04dc593c-888e-2450-3be8-079a5c74d079",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "5cfd86a64427ed3c413c2aaa40f776f1",
        "university_name": "London Metropolitan University",
        "location": "166-220 Holloway Road, London, London, N7 8DB",
        "official_website": "http://www.londonmet.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "020 7133 4207",
        "course_locations": [
            {
                "location_name": "Holloway",
                "location_address": "166-220 Holloway Road, Islington, N7 8DB"
            },
            {
                "location_name": "Aldgate",
                "location_address": "Calcutta House, Old Castle Street, London, E1 7NT"
            },
            {
                "location_name": "Main Site",
                "location_address": "Admissions Office, 166-220 Holloway Road, City of London, N7 8DB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/36dc6feb-81b2-d5b7-eabf-33396c2683a2",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/4_organic-ucas-banners.jpg",
        "about_us": "Based in one of the world's most exciting capital cities, London Met is home to an inspiring community of learners, teachers and innovative thinkers.We pride ourselves on:our extraordinary communityour commitment to equity and inclusionour excellent teaching \u2013\u00a0we're in the top 10 UK universities for teaching quality in the The Times Good University Guide 2023our location in the very heart of Londonour civic mission to tackle the inequalities facing London, to improve people\u2019s lives and to deliver social justiceour commitment to environmental sustainability"
    },
    {
        "id": "e523f2de9c7f2faa36cd0e544669ca56",
        "university_name": "London School of Economics and Political Science, University of London",
        "location": "Houghton Street,, London, London, WC2A 2AE",
        "official_website": "http://www.lse.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "020 7955 7160",
        "course_locations": [
            {
                "location_name": "London School of Economics and Political Science",
                "location_address": "Houghton Street, London, WC2A 2AE"
            },
            {
                "location_name": "Main Site",
                "location_address": "Houghton Street, Westminster, WC2A 2AE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/25fe3db7-b8b4-4c71-029b-37a6ad3006fa",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/lse_campus_1920x420.png",
        "about_us": "N/A"
    },
    {
        "id": "8a452bd2c13b5f8b6ceaeb7c1afa4064",
        "university_name": "London School of Hygiene &amp; Tropical Medicine, University of London",
        "location": "Keppel Street, London, WC1E 7HT",
        "official_website": "https://www.lshtm.ac.uk/?utm_source=ucas&utm_medium=profile&utm_campaign=generic",
        "contact_email": "study@lsthm.ac.uk",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Keppel Street, Camden, WC1E 7HT"
            },
            {
                "location_name": "London School of Hygiene and Tropical Medicine",
                "location_address": "Keppel Street, London, WC1E 7HT"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b137fc85-578c-7409-25b5-2fc7afbb6969",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/banner_image.png",
        "about_us": "The London School of Hygiene &amp; Tropical Medicine (LSHTM) is renowned for its research, postgraduate studies and continuing education in public and global health.Our mission is to improve health and health equity in the UK and worldwide; working in partnership to achieve excellence in public and global health research, education and translation of knowledge into policy and practice."
    },
    {
        "id": "560b990cf62a099db7d74b70c7445c45",
        "university_name": "London School of Management Education",
        "location": "509-511 Cranbrook Road,, Gants Hill,, Essex, IG2 6EY",
        "official_website": "https://www.lsme.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "0208 5948 462",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Cambrian House, 509-511 Cranbrook Road, Gants Hill, Essex, IG2 6EY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6ac2c56a-1286-42e7-9a7f-b0eff167dcf7",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/lsme_banner_0.jpg",
        "about_us": "N/A"
    },
    {
        "id": "6a00632847eeb6df2f9f069cb502bbe7",
        "university_name": "London School of Theology",
        "location": "Green Lane, Northwood, Middlesex, HA6 2UW",
        "official_website": "https://lst.ac.uk",
        "contact_email": "enquiries@lst.ac.uk",
        "contact_phone": "+44 (0)1923 456 000",
        "course_locations": [
            {
                "location_name": "London School of Theology",
                "location_address": "Green Lane, Harrow, HA6 2UW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/73c1c444-ca32-21ce-24a1-a836d9fc4b6f",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/graduation-presentation.jpg",
        "about_us": "Since its founding in 1943, London School of Theology has been committed to offering the highest standard of theological education. We offer a thought-provoking and challenging range of courses that will enhance your walk with God while preparing you to engage with the world. We design programmes to give you a Biblical and theological foundation on which to build your life - rooted in Christ, centred in the gospel.At LST, we're focus on building up a community of Christians grounded in the gospel, but prepared to step out into the world to live for Christ. This is why our wide range of vocations is always taught alongside theology.Many of our students have gone on to have a significant impact in various contexts around the world, including in missions, worship ministry, counselling and psychotherapy, church leadership, teaching, charity work, academia - and much more!"
    },
    {
        "id": "22f18b1e3f47e1401617ad253f7a5b7f",
        "university_name": "London South Bank University",
        "location": "103 Borough Road, London, SE1 0AA",
        "official_website": "https://www.southbank-university.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "020 792 88989",
        "course_locations": [
            {
                "location_name": "LSBU at Havering and Whipps Cross",
                "location_address": "Central Enquiries Office, Borough Road, London, SE1 0AA"
            },
            {
                "location_name": "Main Site",
                "location_address": "103 Borough Road, Southwark, SE1 0AA"
            },
            {
                "location_name": "Southwark Campus",
                "location_address": "103 Borough Road, London, SE1 0AA"
            },
            {
                "location_name": "Croydon Campus",
                "location_address": "Electric House, 3 Wellesley Road, Croydon, London, CR0 2AG"
            },
            {
                "location_name": "Southwark College",
                "location_address": "Waterloo Centre, London, SE1 8LE"
            },
            {
                "location_name": "LSBU Main Site - Southwark Campus",
                "location_address": "103 Borough Road, London, SE1 0AA"
            },
            {
                "location_name": "London South Bank Technical College (LSBTC) Nine Elms",
                "location_address": "Belmore Street, Wandsworth Road, London, SW8 2JY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b8a3b6cb-f484-8bf8-3a8e-3d44c636cbd8",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/lsbu-1_banner.jpg",
        "about_us": "N/A"
    },
    {
        "id": "16d90a3232c514a5c2ca6f2d90bad505",
        "university_name": "London South East Colleges (Bromley College)",
        "location": "Rookery Lane, Bromley Common, Bromley, Greater London, BR2 8HE",
        "official_website": "http://www.lsec.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "020 3954 4000",
        "course_locations": [
            {
                "location_name": "Bromley College (Orpington Campus)",
                "location_address": "Ozone Building, High Street, Orpington, BR6 0TE"
            },
            {
                "location_name": "Main Site Bromley College",
                "location_address": "Rookery Lane, London, BR2 8HE"
            },
            {
                "location_name": "Bromley College (Holly Hill Campus)",
                "location_address": "Upper Holly Hill Road, Belvedere, DA17 6JG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/aba77858-fb84-4043-2106-c575658472fa",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucasbromley_campus_front_1920_x_420_002.png",
        "about_us": "N/A"
    },
    {
        "id": "2c1d17cf8ba3d1828687c1b89fea51f9",
        "university_name": "Loughborough College",
        "location": "Radmoor Road, Loughborough, Leicestershire, LE11 3BT",
        "official_website": "https://www.loucoll.ac.uk/undergraduate-study",
        "contact_email": "undergradadmissions@loucoll.ac.uk",
        "contact_phone": "01509 618110",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Loughborough College, Radmoor Road, Loughborough, LE11 3BT"
            },
            {
                "location_name": "Loughborough College",
                "location_address": "**Blended Courses Contact Days at College Only **, Radmoor Road, Loughborough, LE11 3BT"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/f838c19c-04dc-f1cb-6240-daa92d8d50e2",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/lc_banner1.jpg",
        "about_us": "N/A"
    },
    {
        "id": "5a82e17df01d9c3a32ac9cd67fd99097",
        "university_name": "Loughborough University",
        "location": "Epinal Way, Loughborough, Leicestershire, LE11 3TU",
        "official_website": "http://www.lboro.ac.uk/?utm_source=ucas-2025&utm_medium=subscription-site&utm_campaign=ucas-2025",
        "contact_email": "emailus@lboro.ac.uk",
        "contact_phone": "01509 222222",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Loughborough, LE11 3TU"
            },
            {
                "location_name": "Loughborough University",
                "location_address": "Epinal Way, Loughborough, LE11 3TU"
            },
            {
                "location_name": "Online",
                "location_address": "Loughborough University, Loughborough, LE11 3TU"
            },
            {
                "location_name": "Main Site",
                "location_address": "Ashby Road, Loughborough, LE11 3TU"
            },
            {
                "location_name": "Loughborough University London",
                "location_address": "3 Lesney Avenue, The Broadcast Centre, Here East, Queen Elizabeth Olympic Park, London, E15 2GZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b935bbb7-cac6-ee2a-2df0-8cf09d7016c2",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_header_2.jpg",
        "about_us": "Here at Loughborough, our students are our top priority, and we are committed to providing an unparalleled learning experience for each and every student that walks through our campus gates.We provide world-class facilities for every aspect of university life, from our award-winning four-storey library and STEMLab, to our unrivalled sports facilities which include an Olympic sized swimming pool and an elite athlete centre. We are continually investing in our state-of-the-art facilities in order to provide our students with the optimal environment to learn, grow and succeed.We are passionate about what we do, which is why we are one of England's top research-led universities and have been awarded several prestigious awards to celebrate our hard work in both research and teaching.\u00a0"
    },
    {
        "id": "6131713d7e82271551b5ae32dfea546b",
        "university_name": "Manchester Metropolitan University",
        "location": "Manchester Campus, All Saints, Manchester, Greater Manchester, M15 6BH",
        "official_website": "http://www.mmu.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "0161 247 2000",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Academic Division, Oxford Road, All Saints, Manchester, M15 6BH"
            },
            {
                "location_name": "Manchester College",
                "location_address": "Access Studies Unit City Centre Campus, Lower Hardman Street, Manchester, M3 3ER"
            },
            {
                "location_name": "Main Site",
                "location_address": "All Saints Building, All Saints, Manchester, M15 6BH"
            },
            {
                "location_name": "Manchester School of Architecture",
                "location_address": "Chatham Building, Cavendish Street, Manchester, M15 6BR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/bb9bf30f-81f9-01ac-51d3-40c9aa40e63e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/file_new2.jpg",
        "about_us": "Manchester Met is home to one of the UK\u2019s largest and most diverse student communities, with over 43,000 students representing over 100 countries. Located in the heart of Manchester, we have been proudly driving progress through excellent education and impactful research for 200 years. It\u2019s a caring environment, where students, staff, businesses and communities come together to innovate and share knowledge to help improve life for all.\u00a0As a Manchester Met student, you\u2019ll study in an inspiring global city, on a campus with world-class learning spaces, a lively Students\u2019 Union and fantastic social hubs.\u00a0It\u2019s a destination of opportunity, where you can be your true self.You\u2019ll be encouraged to tailor your education around your interests, with a huge range of modules and study options, including study abroad. With the support of our award-winning careers service, you\u2019ll gain hands-on industry experience and get career ready, life ready, and future ready."
    },
    {
        "id": "923b5a0b7d620b26f86fcfef7d3e12d8",
        "university_name": "University of Manchester",
        "location": "University of Manchester, B002 John Owens Building, Manchester, Greater Manchester, M13 9PL",
        "official_website": "https://www.manchester.ac.uk/",
        "contact_email": "study@manchester.ac.uk",
        "contact_phone": "0161-200 3397",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Oxford Road, Manchester, M13 9PL"
            },
            {
                "location_name": "Main Site",
                "location_address": "University Recruitment And Adm, Oxford Road, Manchester, M13 9PL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/01b03c44-38c1-56b2-1a0d-90720d6ce111",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/students_in_brunswick.jpg",
        "about_us": "Study at The University of Manchester and gain skills and connections to make your mark on the future. We\u2019re set apart by our commitment to social responsibility, innovative research, and outstanding teaching and learning facilities. We\u2019re part of the prestigious Russell Group of universities, ranked as the 28th best university in the world (QS World University Rankings, 2023), and the most targeted university by top graduate employers (High Fliers Research, 2022).We have a history of world firsts and brilliant discoveries, from splitting the atom to discovering graphene. Never content to live on past glories, we look to the future and have a passion for progress and pioneering change.Whether you\u2019re from a small town, joining us from overseas or used to life in a major city \u2013 you'll love Manchester, home to students from 170 countries.Here you'll get opportunities that will help you grow academically, personally and professionally to become responsible leaders of the future.\u00a0"
    },
    {
        "id": "4716305cd9c72e3da9eb9a3665992d1c",
        "university_name": "Medway School of Pharmacy",
        "location": "Anson Building, Central Avenue, Chatham Maritime, Gillingham, Kent, ME4 4TB",
        "official_website": "http://www.msp.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01634 202935",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Central Avenue, Chatham, ME4 4TB"
            },
            {
                "location_name": "Medway School of Pharmacy",
                "location_address": "Central Avenue, Chatham, ME4 4TB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/e53c5903-fe17-57ac-3fda-afa6dcedef61",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "952ac91d344c550e934e7748df3d11a2",
        "university_name": "MetFilm",
        "location": "Ealing Studios, Ealing Green, Ealing Green, London, W5 5EP",
        "official_website": "https://www.metfilmschool.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "020 8280 9119",
        "course_locations": [
            {
                "location_name": "Met Film School - London",
                "location_address": "Met Film School London, Ealing Studios, Ealing Green, London, W5 5EP"
            },
            {
                "location_name": "Met Film School - Berlin",
                "location_address": "Oberlandstra\u00dfe 26, Germany, Berlin, 12099"
            },
            {
                "location_name": "Met Film School - Leeds",
                "location_address": "Met Film School Leeds, Prime Studios, 94\u201396 Kirkstall Rd., Leeds, LS3 1HD"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/fa347416-1962-6431-17cd-4160c0c5e7d2",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner2.jpg",
        "about_us": "MetFilm School is a leading film, television, and online media school offering undergraduate, postgraduate, and short courses across various craft areas.The School has campuses in London within the famous Ealing Studios, in\u00a0Berlin\u00a0at Molecule Studios and the House of Music (from 2024), and in\u00a0Leeds at Prime Studios."
    },
    {
        "id": "51765d11fea8c2589738c84344a8391e",
        "university_name": "Middlesbrough College",
        "location": "Dock Street, Middlehaven, Middlesbrough, North Yorkshire, TS2 1AD",
        "official_website": "https://www.mbro.ac.uk",
        "contact_email": "courseinfo@mbro.ac.uk",
        "contact_phone": "01642 333333",
        "course_locations": [
            {
                "location_name": "Middlesbrough College",
                "location_address": "Dock Street, Middlesbrough, TS2 1AD"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/44c02f19-4de7-eaff-144f-9ac5dc9f3395",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/middlesbrough_college_university_level_students_1.png",
        "about_us": "Unviversity Centre at MiddlesbroughHere at Middlesbrough College we take great pride in delivering high-quality employer-focused University level qualifications that enable you to develop both professional skills and experience for industry.\u00a0 \ufeffYou will be taught in our University Centre Middlesbrough that offers you a practical alternative to University. You will be taught by experienced professionals and receive more teaching hours, meaning you\u2019ll receive more contact time with your tutor including regular 1:1 feedback on your work and progress. \ufeffThrough our partnership with The Open University we deliver affordable job-focused courses that are vocationally orientated and designed for employment, so you\u2019ll also gain the skills employers are looking for, a strong work ethic and the confidence to progress in the workplace.Study smart with our industry focused courses that will give you the skills employers need right now and stay local to save money.Are you ready to join us?"
    },
    {
        "id": "6194d5d7beda689fd093a87e1e1651bf",
        "university_name": "MidKent College (Inc Medway School of Arts)",
        "location": "Medway Road, Gillingham, Kent, ME7 1FN",
        "official_website": "https://www.midkent.ac.uk/medway-school-of-arts",
        "contact_email": "MSA@midkent.ac.uk",
        "contact_phone": "01634 384000",
        "course_locations": [
            {
                "location_name": "Medway School of Arts",
                "location_address": "The Historic Dockyard, Sail and Colour Loft, Church Lane, Chatham, ME4 4TE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/7cb2e0fa-1c45-8872-67a5-67e8c1bb4e53",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_2_0.png",
        "about_us": "N/A"
    },
    {
        "id": "5018a07e649d55a6b3e06a36b93c5318",
        "university_name": "Morley College London",
        "location": "61 Westminster Bridge Road, London, SE1 7HT",
        "official_website": "http://www.morleycollege.ac.uk/",
        "contact_email": "enquiries@morleycollege.ac.uk",
        "contact_phone": "020 7450 1889",
        "course_locations": [
            {
                "location_name": "Waterloo Centre",
                "location_address": "61 Westminster Bridge Road,, London, SE1 7HT"
            },
            {
                "location_name": "Chelsea Centre for the Creative Industries",
                "location_address": "Hortensia Road, London, SW10 0QS"
            },
            {
                "location_name": "North Kensington Centre for Skills",
                "location_address": "Wornington Road, London, W10 5QQ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a5ec7e73-dd46-4764-993e-9fa02c1119fa",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_-_banner_v8.jpg",
        "about_us": "N/A"
    },
    {
        "id": "698c2e2a5382c504e8047387002b1358",
        "university_name": "Moulton College",
        "location": "West Street, Moulton, Moulton, Northampton, Northamptonshire, NN3 7RR",
        "official_website": "http://www.moulton.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01604 491131",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "West Street, Moulton, Northampton, NN3 7RR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/15cba371-8d5f-9bc8-f3d0-365288219936",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/he_ucas_page_header_amended_moulton.jpg",
        "about_us": "Set in 1100 Acres of beautiful, rural Northamptonshire Countryside we are able to offer something truly unique from our degree courses.\u00a0Smaller class sizesOur commitment that all students are taught in small groups means you will get the time and resources you deserve, alongside specialist support to ensure you feel confident and excel in your studies.Our lecturers will work with you, often on a one-to-one basis to enable you to reach your full potential.Excellent employer linksWe have excellent links with employers and businesses across all of our subject areas, giving you the skills and opportunities for your future career, Our range of courses have been designed to give you valuable hand on experience alongside the academic theory to support your qualification.Dedicated facilitiesOur dedicated Higher Education Centre offers and inclusive, supportive environment where you will feel comfortable and valued as you progress through your degree.\u00a0"
    },
    {
        "id": "c6924766c0d064a863b524bd7973a724",
        "university_name": "Mountview Academy of Theatre Arts",
        "location": "Ralph Richardson Memorial St, Kingfisher Place, Clarendon Road, London, N22 6XF",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Mountview Academy of Theatre Arts",
                "location_address": "Clarendon Road, Haringey, N22 6XF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3a59e89f-338a-0706-d767-96b35c64c600",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "fa4fab2f6ca57a8c9929a0984c6838b3",
        "university_name": "University Centre Myerscough",
        "location": "St Michael's Road, Bilsborrow, Preston, Lancashire, PR3 0RY",
        "official_website": "https://www.ucmyerscough.ac.uk/",
        "contact_email": "he@myerscough.ac.uk",
        "contact_phone": "01995 642222",
        "course_locations": [
            {
                "location_name": "Preston Campus",
                "location_address": "Myerscough College, Bilsborrow, Preston, PR3 0RY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/7d269cca-03ad-d105-a630-8d2a0cf1c39c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/gen_bigger.jpg",
        "about_us": "University Centre Myerscough is one of the UK\u2019s largest land-based, engineering and sport institutions, offering a wide range of undergraduate and postgraduate degrees.It\u2019s a popular choice for students and is based just outside Preston with excellent student accommodation on offer at our beautiful, rural campus. Myerscough offers a range of university level degrees from Foundation Degree level right up to Masters level and many of the subjects have a strong research base.Subjects include Agriculture, Agricultural Engineering, Animal Studies, Arboriculture (Tree Management), Ecology and Conservation, Equine, Farriery, Floral Design, Horticulture, Landscape, Motorsports, Sports Studies (including Basketball, Cricket, Football, Golf, Rugby and Strength &amp; Conditioning) and Sportsturf (Greenkeeping &amp; Groundsmanship). Entry requirements range from 48 UCAS Points for a Foundation Degree up to 104 points for an Honours Degree, although please check each course for specific requirements. "
    },
    {
        "id": "11b588b16edc2d435bf58eb18a9538d1",
        "university_name": "National College of Hypnosis &amp; Psychotherapy",
        "location": "16 St Phillips Road, Burton-on-the-Wolds, Loughborough, LE12 5TS",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "National College of Hypnosis and Psychotherapy",
                "location_address": "16 St Phillips Road, Loughborough, LE12 5TS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3886d908-fcc6-94d1-a4b1-e6992063e944",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "5dae6c817474a18c1dde0abb5779fd94",
        "university_name": "National Film and Television School",
        "location": "Beaconsfield Studios, Station Road, Beaconsfield, Buckinghamshire, HP9 1LG",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "National Film and Television School",
                "location_address": "Beaconsfield Studios, Beaconsfield, HP9 1LG"
            },
            {
                "location_name": "Main Site",
                "location_address": "Beaconsfield Studios, Station Road, Beaconsfield, HP9 1LG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/e0e252b0-adc0-2d1b-5036-bd56e27d061c",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "3025a5a51b97755682d1075e377a00eb",
        "university_name": "Nazarene Theological College",
        "location": "Dene Road, Didsbury, Didsbury, Greater Manchester, M20 2GU",
        "official_website": "https://www.nazarene.ac.uk",
        "contact_email": "admissions@nazarene.ac.uk",
        "contact_phone": "01614453063",
        "course_locations": [
            {
                "location_name": "Nazarene Theological College",
                "location_address": "Dene Road, Manchester, M20 2GU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a30c9a10-27bc-e9f3-6597-370d5c8cf03d",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ntc2_0_1.jpg",
        "about_us": "We\u2019ve been based in Didsbury, south Manchester, for over 60 years. In that time, we\u2019ve nurtured a learning community with one aim: to prepare our students for innovative Christian ministry. The academic qualifications and personal experiences you take from your time here will, we hope, be just the start of a lifelong journey.Whether your interest is in\u00a0part time,\u00a0full time,\u00a0distance\u00a0or\u00a0flexible options\u00a0for\u00a0study, at vocational, certificate, graduate or Post Graduate level, we have\u00a0something for everyone.NTC has formal links with a wide range of international colleges and universities, and the exchange of students generates a vigorous global dimension to campus life: typically, over 30 nationalities are represented in the student body. The relationships formed during study at NTC are life-changing, and world-expanding.\u00a0"
    },
    {
        "id": "fdeb9988ffa9ac77a60cf95a7e071d8a",
        "university_name": "Nelson and Colne College Group",
        "location": "Scotland Road, Nelson, Lancashire, BB9 7YT",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "01282 440200",
        "course_locations": [
            {
                "location_name": "Accrington Campus",
                "location_address": "Broad Oak Road, Accrington, BB5 2AW"
            },
            {
                "location_name": "Nelson Campus",
                "location_address": "Scotland Road, Barrowford, Nelson, BB9 7YT"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3a1c51f4-7a05-4bc2-803d-6e5aef715bc0",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "86be655d86caa1017f5ec78bd5b86265",
        "university_name": "NESCOT",
        "location": "Reigate Road,Ewell, Ewell, Epsom, Surrey, KT17 3DS",
        "official_website": "https://www.nescot.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "020 8394 1731",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Reigate Road, Epsom, KT17 3DS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/cb222c5f-534a-af80-7ca7-5622a6426917",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/nescot_image.jpg",
        "about_us": "N/A"
    },
    {
        "id": "d987f13e7a7edd66c029287174e3bf93",
        "university_name": "New Model Institute for Technology and Engineering (NMITE)",
        "location": "Blackfriars St, Hereford, Herefordshire, HR4 9HS",
        "official_website": "https://www.nmite.ac.uk",
        "contact_email": "admissions@nmite.ac.uk",
        "contact_phone": "01432371111",
        "course_locations": [
            {
                "location_name": "NMITE Skylon",
                "location_address": "Canary Drive, Skylon Park, Hereford, HR2 6SR"
            },
            {
                "location_name": "NMITE Blackfriars",
                "location_address": "Blackfriars Street, Hereford, HR4 9HS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a524ae42-b4cb-4548-8f3d-4c6325754261",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/dsc03204.jpg",
        "about_us": "Are you looking to get a head start on becoming a work-ready, world-conscious engineer?\u00a0If you've been searching for an engineering or construction degree that can provide you with the education and hands-on employer experience you need to make your impact (and fast!) - you've come to the right place. We've woven sustainability, ethics and liberal arts thinking into all our degree modules. Why? Modern problems require modern solutions! We ensure you learn what needs to be done, in the context of why it needs to be done.\u00a0We are the New Model Institute for Technology and Engineering and our degrees are designed with you, and your future, in mind.\u00a0We understand\u00a0that what you can offer our world goes beyond academic assessment. We look at all aspects of your application to ensure that we really understand YOU. When it comes to employability, we're one step ahead of the competition as you build a portfolio of experience with a wide range of actual employers.\u00a0\u00a0"
    },
    {
        "id": "ce74f045904c223088123936913d53e6",
        "university_name": "New School of Psychotherapy and Counselling",
        "location": "The Existential Academy, 61-63 Fortune Green Road, London, NW6 1DR",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "The Existential Academy, 61-63 Fortune Green Road, London, NW6 1DR"
            },
            {
                "location_name": "New School of Psychotherapy and Counselling",
                "location_address": "The Existential Academy, 61-63 Fortune Green Road, London, NW6 1DR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/bbb76d07-bf1e-4d62-93ff-261cb072c1e8",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "92adeb277394e55f68dae2dbb06c3464",
        "university_name": "Newbold College",
        "location": "St Mark's Road, Binfield, Bracknell, RG42 4AN",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Newbold College",
                "location_address": "St Mark's Road, Bracknell, RG42 4AN"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/41110e8a-37fd-5bfa-98d5-bb769ba1a7cc",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "179b5d4c52ccc806585bb74fb0d2ea2b",
        "university_name": "Newcastle College University Centre",
        "location": "Rye Hill Campus,Scotswood Road, Scotswood Road, Newcastle upon Tyne, Tyne And Wear, NE4 7SA",
        "official_website": "http://www.ncgrp.co.uk",
        "contact_email": "N/A",
        "contact_phone": "0191 2004111",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Rye Hill Campus, Scotswood Road, Newcastle upon Tyne, NE4 7SA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6783d4af-c512-75e7-5089-7e25711e1d96",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/3262_-_ucas_website_banner_and_tile_images_-_banner_01.jpg",
        "about_us": "N/A"
    },
    {
        "id": "5a4fc021e8d2e3292c23617bed251e48",
        "university_name": "Newham College of Further Education",
        "location": "East Ham Campus, High Street South, London, E6 6ER",
        "official_website": "https://www.newham.ac.uk/higher-education",
        "contact_email": "info@newham.ac.uk",
        "contact_phone": "02082574000",
        "course_locations": [
            {
                "location_name": "Newham College of Further Education (East Ham Campus)",
                "location_address": "High Street South, London, E6 6ER"
            },
            {
                "location_name": "London City Institute of Technology",
                "location_address": "5 Hope St, Leamouth Peninsula, London, E14 0BZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3cc3eeb1-6a72-5560-4bc7-8518d5f9b32c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/he-banner-ucas.jpg",
        "about_us": "At Newham College, we align our programmes with industry needs, ensuring you gain in-demand skills. Our dynamic Higher Technical Qualifications (HTQs) offer essential training in key fields such as computing, engineering, and education. Developed in collaboration with employers, our courses lead to exciting careers in sectors like construction, digital technology, and healthcare, preparing you to excel in the competitive UK job market.Our students benefit from industry-focused programmes, excellent facilities, small class sizes, and personalised support. This comprehensive approach equips you to succeed, whatever your ambitions.With a focus on excellence and strong industry partnerships, Newham College empowers graduates to thrive in their chosen professions. Join us to shape your future with education that meets the needs of today\u2019s world."
    },
    {
        "id": "ed5254ffdf00b69108805e5c16b1a8b8",
        "university_name": "Norland",
        "location": "39-41 Upper Oldfield Park, Bath, Somerset, BA2 3LB",
        "official_website": "https://www.norland.ac.uk/?utm_source=UCAS&utm_medium=Provider+Page&utm_campaign=UCAS+Provider+Page",
        "contact_email": "admissions@norland.ac.uk",
        "contact_phone": "01225 904050",
        "course_locations": [
            {
                "location_name": "Norland (Main Site)",
                "location_address": "39-41 Upper Oldfield Park, Bath, BA2 3LB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/881106e7-273d-c860-bbaf-b4acb29e3446",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/gij-norland-2021-156.jpg",
        "about_us": "Based in Bath, Norland offers world-class degree-based training for students aspiring to be the very best professional early years practitioners.\u00a0Students pursue two distinct qualifications: a BA (Hons) in Early Childhood Education and Care alongside the practical Norland diploma.\u00a0Norland\u2019s comprehensive training includes a value-added curriculum that teaches students additional skills such as paediatric first aid, self-defence, cyber security, advanced driving skills and dedicated employment training. Norland\u2019s extensive training is what sets its graduates apart and ensures high demand for fully qualified Norlanders or Norland Nannies, as graduates are known.\u00a0Graduates have outstanding employment prospects upon leaving and throughout their careers. Norland has its own employment agency, providing 100% employment opportunities for fully qualified Norland Nannies to work with children and families.\u00a0"
    },
    {
        "id": "bb34b6163b04ab7f66922b1d22c90cf7",
        "university_name": "North Hertfordshire College",
        "location": "Cambridge Road, Hitchin, Hertfordshire, SG4 0JD",
        "official_website": "https://www.nhc.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01462 424242",
        "course_locations": [
            {
                "location_name": "Hitchin",
                "location_address": "Cambridge Road, Hitchin, SG4 0JD"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/08b3f282-b0c3-3716-c607-ce05f52a2fed",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/nhc-marketing-hitchin-actionshots-441.jpg",
        "about_us": "N/A"
    },
    {
        "id": "bf03c20cfefaece4d9e6fb3c2865cd9f",
        "university_name": "North Kent College",
        "location": "Oakfield Lane, Dartford, Kent, DA1 2JT",
        "official_website": "https://www.northkent.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01322 629400",
        "course_locations": [
            {
                "location_name": "Dartford Campus",
                "location_address": "Oakfield Lane, Dartford, DA1 2JT"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/aad4cda5-4800-87b1-f718-2f1b9ce7a6a7",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/he_header_banner_002.jpg",
        "about_us": "N/A"
    },
    {
        "id": "1138901e9ffb0b755a27ed26af7308da",
        "university_name": "North Warwickshire and South Leicestershire College",
        "location": "Hinckley Road,, Nuneaton, Warwickshire, CV11 6BH",
        "official_website": "https://www.nwslc.ac.uk",
        "contact_email": "HEenquiries@nwslc.ac.uk",
        "contact_phone": "2476243000",
        "course_locations": [
            {
                "location_name": "Nuneaton Campus",
                "location_address": "Hinckley Road, Nuneaton, CV11 6BH"
            },
            {
                "location_name": "MTI",
                "location_address": "MIRA Technology Park, Nuneaton, CV10 0UX"
            },
            {
                "location_name": "Wigston Campus",
                "location_address": "Blaby Road, South Wigston, LE18 4PH"
            },
            {
                "location_name": "Hinckley Campus",
                "location_address": "Lower Bond Street, Hinckley, LE10 1QU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ca9f4d85-b460-07bd-f9bb-215d59ef5561",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/hinckley_campus_main_image_2.jpg",
        "about_us": "Our dedication to our students to help them succeed is what distinguishes North Warwickshire and South Leicestershire College (NWSLC). \u00a0We support this through an inclusive learning environment that has earned a strong reputation for excellence in its teaching and support services. Our skilled and highly qualified lecturers are industry experts and are committed to helping students realise their full potential.Business, Art, Computing, Engineering, Social and Community Work, Games Development, Media, Graphic Design, Photography and Sport are just a few of the many courses that we offer here at NWSLC. All our courses focus on equipping students with the practical experience and skills to prepare students for success in their chosen careers.Your dream career is just an application away. Apply for one of our hands-on, practical courses taught by highly experienced tutors in their fields and with smaller group sizes you will get the one-to-one support you need to help you succeed. "
    },
    {
        "id": "b46f01e1a60dab6748f452da34b095f7",
        "university_name": "Northeastern University London",
        "location": "Devon House, 58 St Katharine's Way, London, E1W 1JP",
        "official_website": "https://www.nulondon.ac.uk",
        "contact_email": "info@nulondon.ac.uk",
        "contact_phone": "020 7637 4550",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Devon House, St Katharine's Way, London, E1W 1JP"
            },
            {
                "location_name": "Devon House, London",
                "location_address": "Devon House, St Katharine's Way, London, E1W 1JP"
            },
            {
                "location_name": "Vyom",
                "location_address": "N/A"
            },
            {
                "location_name": "Meriam",
                "location_address": "N/A"
            },
            {
                "location_name": "Brian",
                "location_address": "N/A"
            },
            {
                "location_name": "Libby",
                "location_address": "N/A"
            },
            {
                "location_name": "Lara",
                "location_address": "N/A"
            },
            {
                "location_name": "Eve",
                "location_address": "N/A"
            },
            {
                "location_name": "Bryan",
                "location_address": "N/A"
            },
            {
                "location_name": "Piranas",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/77bfcfb8-d2a3-a450-523b-a67a65a7d031",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/untitled_design_2.png",
        "about_us": "Northeastern University London (formerly New College of the Humanities) offers innovative undergraduate degrees that are designed to provide students with in-depth study of their subjects, while developing a range of practical transferable skills, gaining real-world experiences, and exploring a variety of complementary disciplines.Students enjoy the flexibility to create their own academic pathway from a wide range of elective courses, academic disciplines and themes, designed to contextualise their studies. Experiential opportunities, which are credit-bearing and co-designed with partners, offer the possibility of gaining real-world and international experience, while working towards a degree.The University\u2019s undergraduate programmes include courses in Business, Creative Writing, Computer Science, Data Science, Economics, English, History, Law, Philosophy, Politics &amp; IR and Psychology."
    },
    {
        "id": "243c74f56627bbc7080e82f8e252541f",
        "university_name": "Northern College of Acupuncture",
        "location": "61 Micklegate, York, YO1 6LJ",
        "official_website": "https://www.nca.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "York",
                "location_address": "61 Micklegate, York, YO1 6LJ"
            },
            {
                "location_name": "Main Site",
                "location_address": "61 Micklegate, York, YO1 6LJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ad1ad199-6b15-49e6-aa9d-a5dc53b582c6",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/nca_building_and_street_2.jpg",
        "about_us": "N/A"
    },
    {
        "id": "03e561175f4db89f4cd9c896520efdc6",
        "university_name": "The Northern School of Art",
        "location": "1 Church Street, Hartlepool, North Yorkshire, TS24 7DR",
        "official_website": "http://northernart.ac.uk",
        "contact_email": "studentrecruitment@northernart.ac.uk",
        "contact_phone": "01642 288888",
        "course_locations": [
            {
                "location_name": "The Northern School of Art",
                "location_address": "Church Square, Hartlepool, TS24 7EX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/590e6636-6b85-e54d-8a21-9bcf4e27eba3",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_gallery_long.jpg",
        "about_us": "The Northern School of Art is a leading provider of specialist creative art and design courses in the north and one of the best in the UK. We have been offering specialist arts &amp; design education and training since 1874.WE MAKE STUDENTS FEEL WELCOME, ACCEPTED, AND EMPOWERED AS CREATIVE INDIVIDUALS, HELPING THEM TO FIND THEIR FUTURE CAREER PATH AND PLACE IN SOCIETY.We are a creative community where we get to know students supporting them individually as creative professional in training. Our talented academic staff are practising artists and designers. As an art school, you will not be confined to your course specialism, you will be encouraged to work with specialists across the full range of disciplines."
    },
    {
        "id": "0b1808702f8e8c0080905c717d24573e",
        "university_name": "Northern School of Contemporary Dance",
        "location": "98 Chapeltown Road, Leeds, West Yorkshire, LS7 4BH",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Northern School of Contemporary Dance",
                "location_address": "98 Chapeltown Road, Leeds, LS7 4BH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/bc655e18-5840-8018-c4f2-c82958de8cfe",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "23b12a2fa3e95cb03d7296cad3b2e6f4",
        "university_name": "Northumbria University, Newcastle",
        "location": "Sutherland Building,College Street, Ellison Place, Newcastle upon Tyne, Tyne and Wear, NE1 8ST",
        "official_website": "http://www.unn.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "0191 243 7420",
        "course_locations": [
            {
                "location_name": "Northumbria University London Campus",
                "location_address": "110 Middlesex Street, London, E1 7HT"
            },
            {
                "location_name": "Coach Lane Campus",
                "location_address": "Coach Lane, Benton, Newcastle upon Tyne, NE7 7XA"
            },
            {
                "location_name": "Main Site",
                "location_address": "Sutherland Building, Newcastle upon Tyne, NE1 8ST"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/408b9be4-df53-0595-e1f4-8c3a701c0682",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/banner_1.jpg",
        "about_us": "N/A"
    },
    {
        "id": "67ac32bf1d910b3a10ab214fcf3c4e69",
        "university_name": "Norwich University of the Arts",
        "location": "Francis House, 3-7 Redwell Street, 3-7 Redwell Street, Norwich, Norfolk, NR2 4SN",
        "official_website": "http://www.norwichuni.ac.uk/",
        "contact_email": "info@norwichuni.ac.uk",
        "contact_phone": "01603 610561",
        "course_locations": [
            {
                "location_name": "Norwich University of the Arts",
                "location_address": "Francis House, 3-7 Redwell Street, Norwich, NR2 4SN"
            },
            {
                "location_name": "Main Site",
                "location_address": "Francis House, 3-7 Redwell Street, Norwich, NR2 4SN"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/bd68226f-3d1d-ba3e-abb0-4d6b5d099425",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/rs14920_nua_nua_year1fineartfeb2022hr_1_lpr_copy.jpg",
        "about_us": "Norwich University of the Arts is a forward-thinking specialist university offering a wide range of courses in design, media, psychology, performance, architecture, technology and business. We are the only specialist creative arts university to earn a triple Gold rating in the Teaching Excellence Framework for student experience, teaching, and outcomes.Our award-winning city campus is at the heart of Norwich's creative quarter. Our hands-on, real-world approach to teaching ensures that you will graduate with the confidence, critical thinking, and creativity needed to shape our world. You'll be taught by expert course teams that bring years of industry experience and connections.We believe that creativity is the driving force behind innovation, ideas and change. Our graduates have built the movie world of Harry Potter, designed for global brands like Google, brought virtual worlds to life for BAFTA-winning video games, and created future-facing architecture for a more sustainable world."
    },
    {
        "id": "1b3b204d309f0d0aece25f46f23896b5",
        "university_name": "Nottingham College",
        "location": "Adams Building, Stoney Street, The Lace Market, Stoney Street, Nottingham, Nottinghamshire, NG1 1NG",
        "official_website": "https://www.ncn.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "0115 9100100",
        "course_locations": [
            {
                "location_name": "City Hub",
                "location_address": "Nottingham College - City Hub, 111 Canal Street, Nottingham, NG1 7HB"
            },
            {
                "location_name": "Highfields",
                "location_address": "Nottingham College - Highfields, Jesse Boot Avenue, University Boulevard, Nottingham, NG7 2RU"
            },
            {
                "location_name": "City (Adams)",
                "location_address": "Nottingham College - The Adams Building, Stoney Street, The Lace Market, Nottingham, NG1 1NG"
            },
            {
                "location_name": "Mansfield (Expressions Performing Arts Academy)",
                "location_address": "3 Newgate Lane, Mansfield, NG18 2LB"
            },
            {
                "location_name": "Basford",
                "location_address": "Nottingham College - Basford, 664 Nuthall Road, Basford, Nottingham, NG8 6AQ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/319ba5b1-c95f-00a3-2ce3-8bbe070ec3f5",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_image_2mb.jpg",
        "about_us": "N/A"
    },
    {
        "id": "b98c936ecef4a03486c04e144facef74",
        "university_name": "NPTC Group",
        "location": "Neath Campus, Dwr-Y-Felin Road, Dwr-Y-Felin Road, Neath, West Glamorgan, SA10 7RF",
        "official_website": "https://www.nptcgroup.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Newtown College",
                "location_address": "Llanidloes Road, Newtown, Powys, Newtown, SY16 4HU"
            },
            {
                "location_name": "Llandarcy Academy of Sport",
                "location_address": "Llandarcy Park, Llandarcy, SA10 6JD"
            },
            {
                "location_name": "Afan College",
                "location_address": "Bertha Road, Margam, Port Talbot, SA13 2AL"
            },
            {
                "location_name": "Neath College",
                "location_address": "Dwr-y-Felin Road, Neath, SA10 7RF"
            },
            {
                "location_name": "Brecon Beacons College",
                "location_address": "Penlan, Brecon, LD3 9SR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d1045d7f-df89-34b6-e6a8-5bb9d9adf21e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_7.jpg",
        "about_us": "N/A"
    },
    {
        "id": "13c20d19874f1aa1007468180865ef13",
        "university_name": "Oak Hill Theological College",
        "location": "Chase Side, Southgate, London, N14 4PS",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Oak Hill College",
                "location_address": "Chase Side, London, N14 4PS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/4f7a0e47-7a01-7654-afb7-eeb3f8b96f9b",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "2fd9052aeb7e5602f6f7c32ca9635a5a",
        "university_name": "Oaklands College",
        "location": "St Albans Campus, Hatfield Road, St Albans, Hertfordshire, AL4 0JA",
        "official_website": "http://www.oaklands.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01727 737080",
        "course_locations": [
            {
                "location_name": "Welwyn Garden City Campus",
                "location_address": "The Campus, Welwyn Garden City, AL8 6AH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/8c7662bf-6ba4-3b6c-5bea-9515d1a1fc45",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "f3dca0910a385b6df50b6e2542545762",
        "university_name": "University Campus Oldham",
        "location": "University Way, Oldham, Greater Manchester, OL1 1BB",
        "official_website": "https://www.uco.oldham.ac.uk/",
        "contact_email": "info@uco.oldham.ac.uk",
        "contact_phone": "0161 344 8800",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "University Way, Oldham, OL1 1BB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d2e1adfe-7904-cd77-0cef-dafc40175a99",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_images_header.2.jpg",
        "about_us": "We partner with UK universities and industry leaders to deliver a range of courses with excellent teaching at their heart, at Higher National Certificate/Diploma, Foundation Degree, full Honours Degree, professional qualification and Postgraduate level.There\u2019s something really special about studying here. Our campus is a thriving, close-knit and supportive environment where smaller class sizes give you vital one-to-one and personalised support that you won\u2019t get elsewhere. What sets UCO apart is that you will get one of the best student experiences in the UK. In 2022 we were rated top across Greater Manchester for Overall Satisfaction in the National Student Satisfaction Survey. This ranks UCO higher than all other regional FE colleges \u2013 and also puts us 12.5 per cent above the HE sector average. UCO is Oldham\u2019s \u2018hidden gem\u2019 and our staff take great pride in their mission of developing the next generation of industry leaders, entrepreneurs, creatives, teachers and frontline heroes. "
    },
    {
        "id": "4e107a79e92dc35af405927d292f32c8",
        "university_name": "Open University",
        "location": "Walton Hall, Milton Keynes, Buckinghamshire, MK7 6AA",
        "official_website": "http://www.open.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01908 274066",
        "course_locations": [
            {
                "location_name": "Distance Learning",
                "location_address": "Student Recruitment Team, PO Box 197, Milton Keynes, MK7 6BJ"
            },
            {
                "location_name": "Main Site",
                "location_address": "Student Recruitment Team, PO Box 197, Milton Keynes, MK7 6BJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/7f559a95-6b5f-41eb-1fd9-54f3f34b21c3",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/open_university_banner_image1.jpg",
        "about_us": "Welcome to The Open UniversityWe bring university to you. We\u2019ve pioneered supported distance learning for over 50 years and are the world leaders in high-quality distance education.Our flexible approach to learning means you can study from home around your job, family and life. So you can gain a recognised qualification without putting your life on hold. Plus, you\u2019ll receive expert tutor support every step of the way and join our community of learners in the UK and 157 countries worldwide.The Open University was established by Royal Charter on 23 April 1969. We\u2019ve come a long way since then, constantly innovating our teaching and research at every step to make sure that our founding mission \u2013 enabling access to education for all \u2013 remains as relevant now as it did over 50 years ago."
    },
    {
        "id": "14fd9c1c741fb6d24c9fd2fae6ac0fbd",
        "university_name": "Orbital South Colleges University Centre",
        "location": "Gatton Point,London Road, Claremont Road, Redhill, Surrey, RH1 2JT",
        "official_website": "https://www.esc.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01737 772611",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "East Surrey College, London Road, Redhill, RH1 2JX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d66b3801-e0d8-c291-ab4c-7c7de644d37b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/esc_1920_px_v2.jpg",
        "about_us": "N/A"
    },
    {
        "id": "41c5ddfc7248aad65b3eaa601340c58d",
        "university_name": "Oxford Brookes University",
        "location": "Gipsy Lane Campus, Headington, Headington, Oxford, Oxfordshire, OX3 0BP",
        "official_website": "http://www.brookes.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "+441865 741111",
        "course_locations": [
            {
                "location_name": "Abingdon and Witney College",
                "location_address": "Wootton Road, Abingdon, OX14 1GG"
            },
            {
                "location_name": "Main Site",
                "location_address": "Gipsy Lane, Headington, Oxford, OX3 0BP"
            },
            {
                "location_name": "Solihull College",
                "location_address": "Blossomfield Road, Solihull, B91 1SB"
            },
            {
                "location_name": "Wiltshire College and University Centre",
                "location_address": "College Road, Trowbridge, BA14 0ES"
            },
            {
                "location_name": "New College Swindon",
                "location_address": "North Star Avenue, Swindon, SN2 1DY"
            },
            {
                "location_name": "Harcourt Hill Campus",
                "location_address": "Harcourt Hill Campus, Oxford, OX2 9AT"
            },
            {
                "location_name": "Marston Road Campus",
                "location_address": "Jack Straws Lane, Marston, Oxford, OX3 0FL"
            },
            {
                "location_name": "Main Site",
                "location_address": "Gipsy Lane, Oxford, OX3 0BP"
            },
            {
                "location_name": "Headington Campus",
                "location_address": "Gipsy Lane, Oxford, OX3 0BP"
            },
            {
                "location_name": "Activate Learning-City of Oxford College",
                "location_address": "Oxpens Road, Oxford, OX1 1SA"
            },
            {
                "location_name": "Swindon Campus",
                "location_address": "Delta 900, Welton Road, Westlea, Swindon, SN5 7XQ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d422b186-8335-d857-34a8-7ce618a55de0",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/obu_campus_1920x420_1.jpg",
        "about_us": "Oxford Brookes\u00a0is a leading modern British university, known for its strengths in teaching, innovative approaches to learning, and its focus on employability.\u00a0We are ranked 5th in the UK for teaching (Times Higher Education Young University Rankings 2023), and among the world elite in 21 individual subjects (QS, 2024).Oxford Brookes is rated first in Journalism and Children\u2019s Nursing\u00a0 (Guardian League Tables 2024).We are based in Oxford, a vibrant student city that has centred around education and learning for centuries. We have four campuses, each with a unique feel and community in Oxford and a campus in Swindon for studying Adult Nursing.\u00a0There are four main faculties at\u00a0Oxford Brookes:\u25cf Oxford Brookes Business School\u25cf Faculty of Health and Life Sciences\u25cf Faculty of Humanities and Social Sciences\u25cf Faculty of Technology, Design and Environment"
    },
    {
        "id": "41e7a0677b0a37a5f04a6e71687150e1",
        "university_name": "University of Oxford",
        "location": "Undergraduate Admissions Office, Wellington Square, University Of Oxford, Oxford, Oxfordshire, OX1 2JD",
        "official_website": "http://www.ox.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Lincoln",
                "location_address": "Turl Street, Oxford, OX1 3DR"
            },
            {
                "location_name": "Christ Church",
                "location_address": "Christ Church, Oxford, OX1 1DP"
            },
            {
                "location_name": "University",
                "location_address": "University, Oxford, OX1 4BH"
            },
            {
                "location_name": "Merton",
                "location_address": "Merton Street, Oxford, OX1 4JD"
            },
            {
                "location_name": "Mansfield",
                "location_address": "Mansfield Road, Oxford, OX1 3TF"
            },
            {
                "location_name": "Oriel",
                "location_address": "Oriel, Oxford, OX1 4EW"
            },
            {
                "location_name": "Harris Manchester (Mat)",
                "location_address": "Mansfield Road, Oxford, OX1 3TD"
            },
            {
                "location_name": "University of Oxford",
                "location_address": "University Offices, Wellington Square, Oxford, OX1 2JD"
            },
            {
                "location_name": "Keble",
                "location_address": "Keble, Oxford, OX1 3PG"
            },
            {
                "location_name": "Pembroke",
                "location_address": "Pembroke, Oxford, OX1 1DW"
            },
            {
                "location_name": "Wycliffe Hall (PPH)",
                "location_address": "Wycliffe Hall (PPH), Oxford, OX1 2JD"
            },
            {
                "location_name": "Lady Margaret Hall",
                "location_address": "Norham Gardens, Oxford, OX2 6QA"
            },
            {
                "location_name": "Queen's",
                "location_address": "The High Street, Oxford, OX1 4AW"
            },
            {
                "location_name": "Green Templeton College",
                "location_address": "Woodstock Road, Oxford, OX2 6HG"
            },
            {
                "location_name": "Somerville",
                "location_address": "Woodstock Road, Oxford, OX2 6HD"
            },
            {
                "location_name": "Open Application",
                "location_address": "Undergraduate Admissions Office, Wellington Square, Oxford, OX1 2JD"
            },
            {
                "location_name": "Magdalen",
                "location_address": "High Street, Oxford, OX1 4AU"
            },
            {
                "location_name": "Exeter",
                "location_address": "Exeter, Oxford, OX1 3DP"
            },
            {
                "location_name": "Trinity",
                "location_address": "Broad Street, Oxford, OX1 3BH"
            },
            {
                "location_name": "St Hugh's",
                "location_address": "St Margaret's Road, Oxford, OX2 6LE"
            },
            {
                "location_name": "St Edmund Hall",
                "location_address": "Queen's Lane, Oxford, OX1 4AR"
            },
            {
                "location_name": "St John's",
                "location_address": "St Giles, Oxford, OX1 3JP"
            },
            {
                "location_name": "Corpus Christi",
                "location_address": "Merton Street, Oxford, OX1 4JF"
            },
            {
                "location_name": "Worcester",
                "location_address": "Walton Street, Oxford, OX1 2HB"
            },
            {
                "location_name": "Regent's Park (PPH)",
                "location_address": "Pusey Street, Oxford, OX1 2LB"
            },
            {
                "location_name": "Brasenose",
                "location_address": "Brasenose, Oxford, OX1 4AJ"
            },
            {
                "location_name": "St Catherine's",
                "location_address": "Manor Road, Oxford, OX1 3UJ"
            },
            {
                "location_name": "Main Site",
                "location_address": "University Offices, Wellington Square, Oxford, OX1 2JD"
            },
            {
                "location_name": "Balliol",
                "location_address": "Balliol, Oxford, OX1 3BJ"
            },
            {
                "location_name": "St Peter's",
                "location_address": "New Inn Hall Street, Oxford, OX1 2DL"
            },
            {
                "location_name": "University of Oxford Department for Continuing Education",
                "location_address": "Department for Continuing Education, University of Oxford, Rewley House, 1 Wellington Square, Oxford, OX1 2JA"
            },
            {
                "location_name": "New College",
                "location_address": "Holywell Street, Oxford, OX1 3BN"
            },
            {
                "location_name": "St Anne's",
                "location_address": "Woodstock Road, Oxford, OX2 6HS"
            },
            {
                "location_name": "Main Site",
                "location_address": "Undergraduate Admissions Office, Wellington Square, Oxford, OX1 2JD"
            },
            {
                "location_name": "St Hilda's",
                "location_address": "Cowley Place, Oxford, OX4 1DY"
            },
            {
                "location_name": "Jesus",
                "location_address": "Turl Street, Oxford, OX1 3DW"
            },
            {
                "location_name": "Hertford",
                "location_address": "Catte Street, Oxford, OX1 3BW"
            },
            {
                "location_name": "Wadham",
                "location_address": "Parks Road, Oxford, OX1 3PN"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/351374e0-7548-01be-a996-dc560274708e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/open_day_banner.jpg",
        "about_us": "N/A"
    },
    {
        "id": "c90b8469c70156bfa1006416ae07af23",
        "university_name": "Peter Symonds' College",
        "location": "Owens Road,, Winchester, Hampshire, SO22 6RX",
        "official_website": "https://www.psc.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01962-857500",
        "course_locations": [
            {
                "location_name": "Peter Symonds College - Adult and Higher Education",
                "location_address": "Stoney Lane, Winchester, SO22 6DR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5986cf35-cbcf-2213-4cbd-47863a34fc58",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/img_2152fruit.jpg",
        "about_us": "N/A"
    },
    {
        "id": "6bb4260f9383ab00e8d967063cda9162",
        "university_name": "University Centre Peterborough",
        "location": "Park Cresent, Peterborough, Cambridgeshire, PE1 4DZ",
        "official_website": "https://www.ucp.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01733 214466",
        "course_locations": [
            {
                "location_name": "Stamford College",
                "location_address": "Drift Road, Stamford, PE9 1XA"
            },
            {
                "location_name": "University Centre Peterborough",
                "location_address": "Park Crescent, Peterborough, PE1 4DZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/7ca2e52f-06de-0b6c-65ac-b8f2c1717464",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucp_external_building_shots.jpg",
        "about_us": "Hello Future!\u00a0University Centre Peterborough (UCP) offers modern facilities, a supportive community, and a wide range of courses, all close to home for local students or in a welcoming city for those looking to move away from home. Currently our university in Peterborough has around 700 students that are enrolled in over 30 different degree-level programmes.\u00a0Located in the heart of Peterborough, a city known for its diverse culture, and friendly atmosphere, UCP offers more than just academics. It's a place where you can discover your passions, whether it's finally pursuing that lifelong dream of becoming an Engineer or brushing up your skills for a career change. You'll find your niche and a welcoming community at UCP, regardless of your age or background. UCP also has a Campus in Stamford, known as UCP at Stamford, where we run our Counselling, Education and Animal Studies programmes. "
    },
    {
        "id": "1a03877fac8c4764ac48808f9e5ab330",
        "university_name": "Petroc",
        "location": "Barnstaple Campus, Old Sticklepath Hill, Barnstaple, Barnstaple, Devon, EX31 2BQ",
        "official_website": "https://www.petroc.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01271 338074",
        "course_locations": [
            {
                "location_name": "Mid Devon Campus",
                "location_address": "Bolham Road, Tiverton, EX16 6SH"
            },
            {
                "location_name": "North Devon Campus",
                "location_address": "Old Sticklepath Hill, Barnstaple, EX31 2BQ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/fba7f8e7-770b-b3bc-b283-af5be021448f",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/petroc_banner.jpg",
        "about_us": "N/A"
    },
    {
        "id": "4b1a10efa6953da9f99fab744cc747bd",
        "university_name": "Plumpton College",
        "location": "Ditchling Road, Lewes, East Sussex, BN7 3AE",
        "official_website": "http://www.plumpton.ac.uk/",
        "contact_email": "enquiries@plumpton.ac.uk",
        "contact_phone": "01273 890 454",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Ditchling Road, Lewes, BN7 3AE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/be7280d5-2028-68e8-2c0a-27c0518e805e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/untitled_design.png",
        "about_us": "Discover our land &amp; environment degreesBe part of the next generation of industry leaders. Plumpton College delivers specialist higher education courses that prepare you for a career in the land-based sector. From viticulture to veterinary and animal science, our university degrees have been co-designed with academics and industry experts, equipping you with industry-relevant skills for a fulfilling career."
    },
    {
        "id": "af1b8eef7a9c98c2a24703ffa03858c0",
        "university_name": "City College Plymouth",
        "location": "Kings Road,Devonport, Plymouth, Devon, PL1 5QG",
        "official_website": "https://www.cityplym.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01752 305300",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Kings Road, Plymouth, PL1 5QG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/babaeca8-51e1-acab-ba7d-bde04f761c37",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/_dsc6333.jpg",
        "about_us": "N/A"
    },
    {
        "id": "a7d18cd0460d48ca3642eec39d7aa318",
        "university_name": "University of Portsmouth",
        "location": "University House, Winston Churchill Avenue, Winston Churchill Avenue, Portsmouth, Hampshire, PO1 2UP",
        "official_website": "http://www.port.ac.uk",
        "contact_email": "myport@port.ac.uk",
        "contact_phone": "023 9284 8484",
        "course_locations": [
            {
                "location_name": "University of Portsmouth London",
                "location_address": "Juniper House, Walthamstow, London, E17 9PH"
            },
            {
                "location_name": "Chichester College",
                "location_address": "Westgate Fields, Chichester, PO19 1SB"
            },
            {
                "location_name": "Main Site",
                "location_address": "University House, Winston Churchill Avenue, Portsmouth, PO1 2UP"
            },
            {
                "location_name": "Isle of Wight College",
                "location_address": "Medina Way, Newport, PO30 5TA"
            },
            {
                "location_name": "City of Portsmouth College",
                "location_address": "City of Portsmouth College, Tangier Road, Portsmouth, PO3 6PZ"
            },
            {
                "location_name": "Havant and South Downs College - South Downs Campus",
                "location_address": "College Road, Waterlooville, PO7 8AA"
            },
            {
                "location_name": "Chichester College Crawley Campus",
                "location_address": "College Road, Crawley, RH10 1NR"
            },
            {
                "location_name": "Isle of Wight College - CECAMM Centre",
                "location_address": "The Island Technology Park, Whippingham Road, East Cowes, PO32 6FY"
            },
            {
                "location_name": "South Hampshire College Group - Eastleigh Campus",
                "location_address": "Chestnut Ave, Eastleigh, SO50 5FS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/039b8331-636f-6c80-ba3f-47b93af8ccb5",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas-page-4.jpg",
        "about_us": "At Portsmouth, we give you the skills, knowledge and confidence you need to make your life anything you want it to be. As a Gold rated university in the Teaching Excellence Framework (TEF 2023), we\u2019re respected for the quality of our teaching, learning and student outcomes.\u00a0Here, you\u2019ll get career-ready. Our graduate employment rates are some of the UK\u2019s best, with 94 per cent in work or study (HESA Graduate Outcomes Survey 2021/22). Courses are designed to give you skills employers are looking for, often using simulated working environments and professional equipment. That's why we're ranked in the top 20 in England for academic support and learning resources (National Student Survey, 2023).With us, you\u2019ll become an independent learner \u2013 and we\u2019ll support you to grow and succeed. If you need help, we act. Everyone here can get outstanding support for everything from mental and physical health, through to money, housing or a part-time job.To apply through Clearing call\u00a002392 84 8000.\u00a0"
    },
    {
        "id": "29058922d8f200c5ed1d60db63f0e5d6",
        "university_name": "Queen Margaret University, Edinburgh",
        "location": "Queen Margaret Drive, Edinburgh, Musselburgh, Edinburgh, EH21 6UU",
        "official_website": "https://www.qmu.ac.uk/",
        "contact_email": "admissions@qmu.ac.uk",
        "contact_phone": "0131 474 0000",
        "course_locations": [
            {
                "location_name": "Newbattle Abbey College",
                "location_address": "Newbattle Road, Dalkeith, EH22 3LL"
            },
            {
                "location_name": "Queen Margaret University",
                "location_address": "Queen Margaret University, Edinburgh, EH21 6UU"
            },
            {
                "location_name": "Main Site",
                "location_address": "Queen Margaret University Drive, Musselburgh, Edinburgh, EH21 6UU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/403c3afa-827f-7f37-55c1-9c5d403335c7",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/qmu-campus-1920x420_002.jpg",
        "about_us": "Don\u2019t get lost in the crowd: study with QMU.We are distinctive in our person-centred and career-focused approach to education and teaching, and our commitment to shaping a better world.As a student with us, you are much more than a matriculation number, you can become your best self, and you really can go far. Renowned for immediately relevant research and innovation, we have widely recognised expertise in business, the creative industries, education, health and rehabilitation, and social sciences."
    },
    {
        "id": "0646709b32b5364374c87551db73759d",
        "university_name": "Queen Mary University of London",
        "location": "Mile End Rd, London, London, E1 4NS",
        "official_website": "https://www.qmul.ac.uk/undergraduate/?utm_medium=third_party_profile&utm_source=ucas&utm_campaign=qmul_ug_enq_2024",
        "contact_email": "N/A",
        "contact_phone": "0207 8823353",
        "course_locations": [
            {
                "location_name": "Mile End",
                "location_address": "Mile End Road, Tower Hamlets, E1 4NS"
            },
            {
                "location_name": "Lincoln's Inn Fields",
                "location_address": "67-69 Lincoln's Inn Fields, London, WC2A 3JB"
            },
            {
                "location_name": "Whitechapel Campus",
                "location_address": "Garrod Building, Turner Street, Tower Hamlets, E1 2AD"
            },
            {
                "location_name": "University of London Institute in Paris",
                "location_address": "9-11 rue Constantine, Paris, 75007"
            },
            {
                "location_name": "Main Site",
                "location_address": "Mile End Road, London, E1 4NS"
            },
            {
                "location_name": "Charterhouse Square",
                "location_address": "Charterhouse Square, Islington, EC1M 6BQ"
            },
            {
                "location_name": "Main Site",
                "location_address": "Admissions and Recruitment Office, Mile End Road, Tower Hamlets, London, E1 4NS"
            },
            {
                "location_name": "Whitechapel",
                "location_address": "Turner Street, London, E1 2AD"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/856d62c2-800c-07ea-e751-1bbae4c76f4b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/queens_building_panorama_1920x600.jpg",
        "about_us": "Queen Mary University of London is a Russell Group university with a difference \u2013 one that opens the doors of opportunity to anyone with the potential to succeed. A unique place of world-leading research and open inclusivity, we live and breathe our heritage and are embedded in the communities we serve.\u00a0\u00a0Ranked in the top 150 universities in the world and top 5 in London (QS 2024), our truly global community welcomes students and staff from over 170 nationalities to our five London and international campuses in China, Malta, Paris, and Singapore.\u00a0\u00a0We are the only campus-based Russell Group university in London, offering some of the most affordable student accommodation in the number one city in the world for students (QS 2024).\u00a0\u00a0"
    },
    {
        "id": "2607f183691598cc1308b4d71d2d58f1",
        "university_name": "Queen's University Belfast",
        "location": "Queen's University Belfast, Belfast, Co Antrim, BT7 1NN",
        "official_website": "http://www.qub.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "+44 (0)28 9024 5133",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "University Road, Belfast, BT7 1NN"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3e45ece0-e028-cc61-58ac-43f91dc2f3f0",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/adm20_ucas_q75_5.2.jpg",
        "about_us": "A prestigious Russell Group UK university,\u00a0Queen's University Belfast\u00a0is ranked in the top 200 universities in the world. (THE World University Rankings 2023). Queen's is at the heart of Belfast's culture, as well as its art and social scenes. We're proud to be part of a bustling, cosmopolitan city boasting a vibrant social life and rich cultural heritage."
    },
    {
        "id": "5b6246531656de903cb9ba341c75f385",
        "university_name": "Ravensbourne University London",
        "location": "6 Penrose Way,, London, London, SE10 0EW",
        "official_website": "http://www.ravensbourne.ac.uk/",
        "contact_email": "hello@rave.ac.uk",
        "contact_phone": "020 3040 3500",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Greenwich Peninsula, 6 Penrose Way, Greenwich, SE10 0EW"
            },
            {
                "location_name": "Main Site",
                "location_address": "6 Penrose Way, London, SE10 0EW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6f003a01-db03-39c5-2eff-eb8f18fda755",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/banner_ucas.jpg",
        "about_us": "Based in London\u2019s Design District, Ravensbourne University is renowned for its strong industry links and nurtures a close-knit global network of creators, makers, and innovators. Established in 1962, we have a strong educational heritage and foster enterprise and innovation within a forward-focused academic community.Ravensbourne is committed to empowering our students with the knowledge, skills and confidence needed to succeed in the digital and creative industries. We encourage collaboration across various disciplines, offering real-world experiences and embedding industry insights into our teaching.Our award-winning, sustainable campus accommodates less than 4,500 students, from over 100 nationalities, fostering a supportive and inclusive environment, ideal for creative collaborations. Our students benefit from exclusive industry partnerships, enabling them to create change, build networks and make connections."
    },
    {
        "id": "82e2dfd64df8326e2839034e9c93cb99",
        "university_name": "University of Reading",
        "location": "Whiteknights, Reading, Berkshire, RG6 6AH",
        "official_website": "https://www.reading.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "0118 987 5123",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Room 208, Pepper Lane, Reading, RG6 6AH"
            },
            {
                "location_name": "London Road Campus",
                "location_address": "London Road, Reading, RG1 5AQ"
            },
            {
                "location_name": "Henley Business School",
                "location_address": "Greenlands, Henley-on-Thames, Oxfordshire, Reading, RG9 3AU"
            },
            {
                "location_name": "University of Reading",
                "location_address": "Whiteknights House, PO Box 217, Reading, RG6 6UR"
            },
            {
                "location_name": "Main Site",
                "location_address": "Whiteknights House, PO Box 217, Reading, RG6 6AH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/c283b1b0-f407-0c6f-ca7d-c63b2604f5cc",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/university_of_reading_library_night_time.jpg",
        "about_us": "The University of Reading is a research-intensive campus university in south-east England. With more than 150 years of history, we are now recognised for our expertise in areas such as climate science, business, agriculture, and food science.We have a global presence, with two overseas campuses, the triple-accredited Henley Business School, and dozens of international partnerships.We offer a range of courses across different levels of study, including foundation courses, undergraduate degrees, master\u2019s programmes, PhD opportunities, apprenticeships, and academic English language and writing courses.You\u2019ll study on a beautiful, award-winning green campus in a thriving town and become part of a diverse and inclusive community of more than 25,000 students from around 160 countries.Join us and become part of a caring community of both students and staff that lives, learns, and thrives together. "
    },
    {
        "id": "a2009ae88e97362524bc26fd23c87c9d",
        "university_name": "Real Training",
        "location": "Canterbury Office, 70 Stour Street, Canterbury, Kent, CT1 2NZ",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Real Training",
                "location_address": "Canterbury Office, 70 Stour Street, Canterbury, CT1 2NZ"
            },
            {
                "location_name": "Main Site",
                "location_address": "Canterbury Office, 70 Stour Street, Canterbury, CT1 2NZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b3301895-279b-418a-9c3e-071db61e5b2d",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/194834mon022030.jpg",
        "about_us": "N/A"
    },
    {
        "id": "179df5c9691fec226d30060e2c6b3e3d",
        "university_name": "University Centre Reaseheath",
        "location": "Reaseheath College, Reaseheath, Cheshire, CW5 6DF",
        "official_website": "https://ucreaseheath.ac.uk/",
        "contact_email": "ucr@reaseheath.ac.uk",
        "contact_phone": "01270 613 284",
        "course_locations": [
            {
                "location_name": "University Centre Reaseheath",
                "location_address": "Reaseheath College, Reaseheath, Nantwich, CW5 6DF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ae8f02f9-4bc7-ece6-9b85-2b0a542e2f39",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/microsoftteams-image_81.png",
        "about_us": "University Centre Reaseheath (UCR) is a specialist land-based institution in Cheshire, set in 330 hectares of farms, lake, and parkland, providing industry leading higher education in course areas including Agriculture, Animal Science, Conservation, Equine, Food Science, and many more.\u00a0UCR\u2019s purpose is to inspire their students\u2019 purpose, while equipping their undergraduates with the skills to grow, progress and achieve more than they thought possible.\u00a0The expert staff at UCR are highly focused on developing the passions of their students and cultivating progress in the land-based sector. They deliver industry-focused courses which provide students with the transferable skills and knowledge that employers demand. \u00a0"
    },
    {
        "id": "0bdd3a2bfadb7364ee3e2388c39bfccc",
        "university_name": "Refugee Law Initiative, School of Advanced Study, University of London",
        "location": "Senate House, Camden, City of London, WC1E 7HU",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Senate House, Malet Street, Camden, WC1E 7HU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/4687847f-25fe-dc99-199a-75f0d02fc3d6",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "1e7e0ea28062d8bc1497ad35ea47e1a8",
        "university_name": "Regent's University London",
        "location": "Regents University London ,Inner Circle, Regents Park, London, NW1 4NS",
        "official_website": "http://www.regents.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "020-7487-7654",
        "course_locations": [
            {
                "location_name": "Regent's University London",
                "location_address": "Inner Circle, Regent's Park, London, Westminster, NW1 4NS"
            },
            {
                "location_name": "Regent's University London",
                "location_address": "Regents Park, London, NW1 4NS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d22aa302-9e66-e26f-98e6-07fe7cc0e1a0",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/dsc_57512.jpg",
        "about_us": "N/A"
    },
    {
        "id": "b747c5100200ea65eceba60e6bb82b84",
        "university_name": "Regents Theological College",
        "location": "West Malvern Road, Malvern, WR14 4AY",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Regents Theological College",
                "location_address": "West Malvern Road, Malvern, WR14 4AY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/60144100-c9f7-212d-24a6-7ea0f4cb5f85",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "89d284dfe02102e1f67ad543ab8ed18c",
        "university_name": "Robert Gordon University",
        "location": "Garthdee House, Garthdee Road, Garthdee Road, Aberdeen, Aberdeenshire, AB10 7QB",
        "official_website": "http://www.rgu.ac.uk/",
        "contact_email": "admissions@rgu.ac.uk",
        "contact_phone": "01224 262728",
        "course_locations": [
            {
                "location_name": "Main Site - Aberdeen",
                "location_address": "Garthdee House, Garthdee Road, Aberdeen, AB10 7QB"
            },
            {
                "location_name": "Garthdee Campus",
                "location_address": "Garthdee Road, Aberdeen, AB10 7QE"
            },
            {
                "location_name": "Main Site",
                "location_address": "Garthdee House, Garthdee Road, Aberdeen, AB10 7QB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5a3cac22-f05b-20b5-d281-c122d1490650",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_-_rgu_campus.jpg",
        "about_us": "Robert Gordon University (RGU) is an innovative, accessible, impactful and professionally-minded university. We provide industry led undergraduate and postgraduate courses that enables our graduates to thrive in their future careers. Situated on the banks of the River Dee, we\u2019ve invested in developing our campus to ensure our up-to-date facilities, equipment and work spaces enhance your learning experience and inspire innovation.With over 16,000 students from 150 countries, RGU is the place where curious minds from across the world come together to learn, connect and establish friendships for life. RGU comprises of 11 schools offering over 300 on-campus and online courses including engineering, architecture, computing, life sciences,the creative industries, health and social care, and business. These courses are developed through close collaboration with industry to ensure they are demand driven and tailored to future employment markets. "
    },
    {
        "id": "77506184c280007594f988d0e03e680b",
        "university_name": "Rose Bruford College",
        "location": "Lamorbey Park, Burnt Oak Lane, Sidcup, Kent, DA15 9DF",
        "official_website": "http://www.bruford.ac.uk/",
        "contact_email": "future@bruford.ac.uk",
        "contact_phone": "02083082600",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Lamorbey Park, Burnt Oak Lane, Bexley, DA15 9DF"
            },
            {
                "location_name": "Arthaus.berlin",
                "location_address": "The Eden Studios, Breite Str. 43, Berlin, 13187"
            },
            {
                "location_name": "Rose Bruford College",
                "location_address": "Lamorbey Park, Burnt Oak Lane, Sidcup, DA15 9DF"
            },
            {
                "location_name": "Main Site",
                "location_address": "Lamorbey Park, Sidcup, DA15 9DF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5bc57c84-3270-8e10-ee6d-d897f3bca8a5",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas-banner4-may2024_copy.jpg",
        "about_us": "Rose Bruford College welcomes students from around the world for BA and MA/MFA degree study in performance, design, management and technical arts. The College also offers short-term and intensive foundation courses.\u00a0 Students are encouraged to be independent thinkers ready to make an impact in TV, film, theatre or live events. Students benefit from our links with international institutions via student and staff exchanges, collaborative projects and research initiatives.The Sidcup Campus is in the beautiful parkland grounds of Lamorbey House, 23 minutes by train from Central London.The hall of residence is a 10 minute walk from the Sidcup Campus. There are 168 spacious en-suite bedrooms in flats with a shared kitchen and social area for 6 students. Laundry facilities, a rehearsal space and secure bicycle storage are available on-site."
    },
    {
        "id": "23231f1f591ec10b30cd444e10aafe43",
        "university_name": "Royal Academy of Dance",
        "location": "188 York Road, Battersea, London, SW11 3JZ",
        "official_website": "https://radprospectus.info/",
        "contact_email": "faculty@rad.org.uk",
        "contact_phone": "02073268086",
        "course_locations": [
            {
                "location_name": "Royal Academy of Dance",
                "location_address": "188 York Road, Wandsworth, SW11 3JZ"
            },
            {
                "location_name": "Main Site",
                "location_address": "188 York Road, London, SW11 3JZ"
            },
            {
                "location_name": "Main Site",
                "location_address": "188 York Road, Battersea, London, SW11 3JZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/94090b0b-2ad1-43d3-511a-dfbfc364256e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/230330_1221_cmyk.jpg_ucas.jpg",
        "about_us": "We are a specialist dance education provider with over 100 years of experience inspiring, cultivating and supporting dance teachers around the world. Our Faculty of Education was created in 1999 in recognition of our increasing commitment to higher education.We recognise the importance of having qualified, well-informed and inspirational dance teachers who can meet the challenges of dance teaching in the twenty-first century. Dance teachers are important role models, with the power to motivate and influence not only the next generation of dancers and dance teachers, but also the dance audiences of the future."
    },
    {
        "id": "daef8275de67fe4ef486658dea7893a2",
        "university_name": "Royal Academy of Dramatic Art",
        "location": "62-64 Gower Street, Camden, WC1E 6ED",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Royal Academy of Dramatic Art",
                "location_address": "62-64 Gower Street, Camden, WC1E 6ED"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ac1ac95e-ea30-2fe6-4140-325d09b9afd0",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "a4768f8244514b550fa75bc9e66af2a1",
        "university_name": "Royal Academy of Music",
        "location": "Marylebone Road, Marylebone, London, NW1 5HT",
        "official_website": "http://www.ram.ac.uk/",
        "contact_email": "admissions@ram.ac.uk",
        "contact_phone": "02078 737373",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Marylebone Road, London, NW1 5HT"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6650b619-250b-6137-af18-9682e03501dd",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/180404-mainbuildext-frances_marshall.jpg",
        "about_us": "N/A"
    },
    {
        "id": "5309ba073591ceddff2131c867a85b07",
        "university_name": "Royal Agricultural University",
        "location": "Stroud Road, Cirencester, Gloucestershire, GL7 6JS",
        "official_website": "https://www.rau.ac.uk",
        "contact_email": " admissions@rau.ac.uk",
        "contact_phone": "01285 652531",
        "course_locations": [
            {
                "location_name": "Capel Manor College, Enfield Campus",
                "location_address": "Enfield Campus, Bullsmoor Lane, Enfield, EN1 4RQ"
            },
            {
                "location_name": "Cultural Heritage Institute-RAU Swindon Centre",
                "location_address": "11 Carriage Works,, London Street, Railway Village, Swindon, SN1 5FB"
            },
            {
                "location_name": "Main Site",
                "location_address": "Stroud Road, Cirencester, GL7 6JS"
            },
            {
                "location_name": "Royal Agricultural University",
                "location_address": "Stroud Road, Cirencester, GL7 6JS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/0014c21f-944c-e481-c807-3e85323bfd4b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/banner_2.png",
        "about_us": "The Royal Agricultural University (RAU) has been developing leaders, entrepreneurs and innovators since 1845 and we are the UK\u2019s leading global agricultural University. Today, we have more than 1,100 students studying agriculture, business, environment, equine science, farm management, food, real estate and rural land management. We are based in Cirencester, Gloucestershire and pride ourself on our links with industry and all courses are designed to meet the demands of the employment market for land-based expertise, both in the UK and worldwide. Our vision is to become the leading specialist University providing a fresh perspective for the land agri-food and rural enterprise sectors."
    },
    {
        "id": "7a7a8c9678600e874cf5172af9e4ce17",
        "university_name": "Royal Birmingham Conservatoire",
        "location": "Birmingham City University, 200 Jennens Road, Birmingham, B4 7XR",
        "official_website": "https://www.bcu.ac.uk/conservatoire",
        "contact_email": "conservatoire@bcu.ac.uk",
        "contact_phone": "0121 331 5901",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "200 Jennens Road, Birmingham, B4 7XR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/e4015e94-c8df-4934-d9ed-c77e001e2c0c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/rbc_ucas-1920x420.jpg",
        "about_us": "Located in the great city of Birmingham and making use of the specialist facilities we have on offer, our provision across a range of undergraduate and postgraduate courses is designed to prepare you for the modern profession and all its challenges. We put employability and entrepreneurship centre stage, and provide a platform from which successful and sustainable careers can be launched."
    },
    {
        "id": "010c42b9552239f01fe6f60ce2d64d40",
        "university_name": "Royal Central School of Speech and Drama, University of London",
        "location": "Embassy Theatre, 64 Eton Avenue, Swiss Cottage, London, NW3 3HY",
        "official_website": "http://www.cssd.ac.uk/",
        "contact_email": "admissions@cssd.ac.uk",
        "contact_phone": "+44 (0)20 7722 8183",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Embassy Theatre, Eton Avenue, Swiss Cottage, London, NW3 3HY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/098ac3d1-c3ac-4548-c6be-3a127a5aacaf",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/student_hub_ucas_banner_.jpg",
        "about_us": "The Royal Central School of Speech and Drama is a small specialist institution of performing arts within the University of London. Central has a long history of educating and training leading performance practitioners, with extensive connections across the creative industries.\u00a0Central\u2019s courses are available at both undergraduate and postgraduate level and cover the entire breadth of creative and performing arts, from performance to production, from applied theatre to drama therapy, and from sound design to writing for film and television. \u00a0Our mission is to inspire, educate and train the performers, practitioners and change-makers of tomorrow. We encourage collaboration, experimentation and innovation to ensure graduates are forward-thinking and prepared to enter the industry as a specialist in their discipline. Our students are passionate, daring and committed. As a community, we aim to shape the future of theatre and performing arts\u00a0"
    },
    {
        "id": "9e970f7c01d7925ca961bf4047ed2f10",
        "university_name": "Royal College of Art",
        "location": "Kensington Gore, Kensington and Chelsea, London, SW7 2EU",
        "official_website": "https://www.rca.ac.uk/?utm_source=UCAS&utm_medium=Listing&utm_id=UCAS&utm_content=Profile",
        "contact_email": "recruitment@rca.ac.uk",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "London \u2013 Battersea",
                "location_address": "Howie Street, Battersea, Battersea, SW11 4AY"
            },
            {
                "location_name": "London \u2013 Kensington",
                "location_address": "Kensington Gore, Kensington and Chelsea, SW7 2EU"
            },
            {
                "location_name": "London \u2013 White City",
                "location_address": "Garden House, Dorando Close, London, W12 7FN"
            },
            {
                "location_name": "Main Site",
                "location_address": "Kensington Gore, Kensington and Chelsea, SW7 2EU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b6e2e7ed-3ad1-dd41-f9f4-bdef84fdc737",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/rca_6-18_927_banner.png",
        "about_us": "The Royal College of Art is the world\u2019s leading art and design university, having been ranked for ten consecutive years as the world\u2019s number 1 art &amp; design university in the QS World University subject rankings (2015-2024). Our postgraduate\u00a0programmes\u00a0enable you to tackle complex issues, push the boundaries of your practice, build your networks, and find your voice as a professional in a changing world.Committed to creating the next generation of artists, designers, researchers, creators, and innovators, the RCA\u2019s world-leading\u00a0academic and technical services staff\u00a0will guide and challenge you to push the boundaries of your practice.\u00a0"
    },
    {
        "id": "07fd8016eeee8b8ebbc0574be97b95b0",
        "university_name": "Royal College of Music",
        "location": "Prince Consort Road, Kensington and Chelsea, London, SW7 2BS",
        "official_website": "http://www.rcm.ac.uk/",
        "contact_email": "admissions@rcm.ac.uk",
        "contact_phone": "02075 914362",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Prince Consort Road, London, SW7 2BS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5f0445dd-688c-9fdb-54e7-0c67048d32e8",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/13.jpg",
        "about_us": "The RCM is one of the world\u2019s great conservatoires,\u00a0 bringing together gifted, elite musicians from all over the world to develop their international careers as performers, conductors and composers.\u00a0Our professors are musicians with worldwide reputations and extensive professional portfolios of inspirational work.Established in 1883, our iconic building is located in beautiful South Kensington. Advanced facilties and technologies include a new Digital Innovation Lab, numerous practice and performance spaces.Alumni include composers such as\u00a0Holst,\u00a0Vaughan Williams, Turnage\u00a0and\u00a0Britten; conductors such as\u00a0Leopold Stokowski,\u00a0Sir Colin Davis\u00a0and\u00a0Sir Roger Norrington; singers include Dame Joan Sutherland,\u00a0Sir Thomas Allen\u00a0and\u00a0Alfie Boe, instrumentalists like Sir\u00a0James Galway, Gervase de Peyer, and\u00a0Natalie Clein.A truly international College, RCM students study at undergraduate, masters or doctoral level. You will join a vibrant community of collaborative, artistically progressive musicians."
    },
    {
        "id": "942cc59fb28a195a4f203fa877c9cec3",
        "university_name": "Royal Conservatoire of Scotland",
        "location": "100 Renfrew Street, Glasgow, Lanarkshire, G2 3DB",
        "official_website": "https://www.rcs.ac.uk",
        "contact_email": "hello@rcs.ac.uk",
        "contact_phone": "0141 332 4101",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "100 Renfrew Street, Glasgow, G2 3DB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/22072435-fb1a-6e77-3143-e8a1a0577885",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/rcs_building.jpg",
        "about_us": "More than 1000 lecturers, tutors, artists, technicians, co-ordinators and support staff work at the Royal Conservatoire of Scotland across all of the many art forms. As a student here, you will be working with some of the finest educators and performing and production artists in the country.Many of our teaching staff combine teaching with professional work, with music teachers holding positions in the national orchestras, drama tutors directing professional companies and film lecturers working on new projects around the world. Within the past year our teaching staff have won accolades such as Music Tutor of the Year, A Gramophone Award, a BBC Music Magazine Award, Jazz Educator of the Year and a British Composer Award.Additionally, we also have strong relationships with leading professionals across the world who join us as Visiting Artists throughout the year. We invite subject specialists to join us so students benefit from their professional, industry experience."
    },
    {
        "id": "2b5f213b572869d3b555c4008d608693",
        "university_name": "Royal Drawing School",
        "location": "19-22 Charlotte Rd, London, EC2A 3SG",
        "official_website": "https://royaldrawingschool.org",
        "contact_email": "admin@royaldrawingschool.org",
        "contact_phone": "020 7613 8568",
        "course_locations": [
            {
                "location_name": "Royal Drawing School",
                "location_address": "19-22 Charlotte Road, London, EC2A 3SG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/fb1d3c04-984f-4e6c-b51a-af868d1ecb5d",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/untitled_design_25.png",
        "about_us": "The Royal Drawing School is\u00a0an independent, not-for-profit resource that aims to raise the standard and profile of drawing through teaching and practice. We are one of\u00a0only a few institutions in the world offering in-depth, quality tuition in drawing from observation.\u00a0Drawing is a primary language natural to all human beings, and fundamental to all aspects of learning and thinking. It is a crucial route to innovation across the creative disciplines and beyond, from fashion, fine art and animation to filmmaking, product design and engineering.\u00a0By offering tuition and resources to art students, artists, children and the public, we aim to address the permanent need for high-quality drawing teaching in the UK, filling a gap left by the steady erosion of observational drawing from the study of art in higher education and in schools.Founded in 2000 by HM King Charles III and artist Catherine Goodman as The Prince's Drawing School, we became the Royal Drawing School in 2014."
    },
    {
        "id": "125edb4b5ea2944eb6c83e4fe3e97bce",
        "university_name": "Royal Holloway, University of London",
        "location": "Egham Hill, Egham, Egham, Surrey, TW20 0EX",
        "official_website": "https://www.royalholloway.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01784 434455",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Royal Holloway, University Of London, Egham Hill, Egham, TW20 0EX"
            },
            {
                "location_name": "Central London Campus",
                "location_address": "11 Bedford Square, London, WC1B 3RF"
            },
            {
                "location_name": "Royal Holloway",
                "location_address": "Egham Hill, Egham, TW20 0EX"
            },
            {
                "location_name": "Main Site",
                "location_address": "Egham, TW20 0EX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5b13b3ae-6a43-53a5-7382-2c010763dd54",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/sunny_founders_square_0.jpg",
        "about_us": "Royal Holloway was founded by two social pioneers with the ambition of making education accessible for all. We are proud to have made a university education a reality for so many and continue to evolve to meet the needs of young people today. Our undergraduate degrees are taught by recognised, world-leading academic who take the time to get to know you and help you develop your passions, and discover new ones. Alongside your studies, you\u2019ll develop the whole your whole self, whether it\u2019s a year abroad or in industry, joining a club or society, volunteering in the community or becoming one of our student ambassadors. We\u2019ll help you focus on your future career with a package of new initiatives aimed at boosting your employability when you graduate.We are passionate about the value of education and put our students first. Our diverse, inclusive and global community inspires you to succeed and achieve your ambitions."
    },
    {
        "id": "525aafd9427af54ad119b9c2d6d2fffa",
        "university_name": "Royal Northern College of Music",
        "location": "124 Oxford Road, Manchester, Greater Manchester, M13 9RD",
        "official_website": "http://www.rncm.ac.uk/",
        "contact_email": "info@rncm.ac.uk",
        "contact_phone": "0161 907 5200",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "124 Oxford Road, Manchester, M13 9RD"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/dd590a4b-1cf1-e758-446c-9df0da541a84",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/building_a.jpg",
        "about_us": "Founded in 1973 through the merger of the Royal Manchester College of Music and Northern School of Music, the Royal Northern College of Music (RNCM) is continually recognised as one of the world\u2019s most forward-thinking conservatoires.Home to over 900 students from more than 60 countries, it is dedicated to providing an outstanding education that propels students into careers as inspiring and versatile musicians, fully-equipped for exciting futures both on and off stage."
    },
    {
        "id": "e36f7cfca893a1bcfa8ab33ac6f4c6a5",
        "university_name": "Royal Welsh College of Music and Drama",
        "location": "Castle Grounds, Cathays Park, Cardiff, CF10 3ER",
        "official_website": "https://www.rwcmd.ac.uk/",
        "contact_email": "admissions@rwcmd.ac.uk",
        "contact_phone": "029 2039 1361",
        "course_locations": [
            {
                "location_name": "Royal Welsh College of Music and  Drama",
                "location_address": "Castle Grounds,, Cathays Park,, Cardiff,, CF10 3ER"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/76a6ba43-a82f-b41b-3b39-c62553b2970d",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_2024.jpg",
        "about_us": "Who we areThe Royal Welsh College of Music &amp; Drama attracts the best creative talent from across the globe. As Wales\u2019 national conservatoire, we fire imagination and drive innovation, offering training to more than 800\u00a0actors, musicians, designers, technicians and arts managers from more than 40 countries.\u00a0Our students\u2019 extraordinary talent and potential is fused with exceptional teaching and unrivalled industry links, to bring dreams to life. A space for everyone, creative ambition and collaboration are central to our excellence.Excellence empoweredIn addition to a diverse programme of performances involving students of all disciplines, the College attracts leading international artists, providing opportunities for students to learn up-close from some of the world's most renowned performers.\u00a0With some of Wales\u2019 most prestigious venues we operate a dynamic arts centre, and our performance programme of world class professionals is integral to students\u2019 training."
    },
    {
        "id": "ac3c637aeb5a2a8dee5cc8f4432b5f2d",
        "university_name": "SAE Institute",
        "location": "Georgia House, 38 Pall Mall, Liverpool, L3 6AL",
        "official_website": "http://www.sae.edu/gbr/",
        "contact_email": "marketinguk@sae.edu",
        "contact_phone": "+44 (0)3330 112 315",
        "course_locations": [
            {
                "location_name": "SAE Liverpool",
                "location_address": "Georgia House, 38 Pall Mall, Liverpool, L3 6AL"
            },
            {
                "location_name": "SAE Glasgow",
                "location_address": "85-87 Portman Street, Kinning Park, Glasgow, G41 1EJ"
            },
            {
                "location_name": "SAE Leeds",
                "location_address": "The Electric Press, 14 Great George Street, Leeds, LS1 3DW"
            },
            {
                "location_name": "SAE Royal Leamington Spa",
                "location_address": "Spencer Street, Royal Leamington Spa, CV31 3NE"
            },
            {
                "location_name": "SAE London",
                "location_address": "SAE House, 297 Kingsland Road, Hackney, E8 4DD"
            },
            {
                "location_name": "Main Site",
                "location_address": "Georgia House, 38 Pall Mall, Liverpool, L3 6AL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/db037e28-991d-ece0-2edb-90d079e142f3",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/yt_header_uk_v3_1.jpg",
        "about_us": "Established in 1976, SAE Institute provides creative media programmes to more than 10,000 students at 40+ campuses globally. In the UK, SAE has campuses in London, Liverpool, Leamington Spa, and Glasgow, providing industry-focused 2-year creative media degrees validated by the University of Hertfordshire across eight subject areas \u2013 Audio, Content Creation &amp; Online Marketing, Film, Game Art Animation, Games Programming, Music Business, Visual Effects, and Web Development. SAE students benefit from hands-on access to industry-grade facilities, expert staff and a nurturing campus environment. Our global community of inspirational alumni are winners of Oscars, Grammys and BAFTAS."
    },
    {
        "id": "2a4233cfabe5a761322b8dc6d3b38da5",
        "university_name": "University of St Andrews",
        "location": "St Katharine's West, 16 The Scores, 16 The Scores, St Andrews, Fife, KY16 9AX",
        "official_website": "https://www.st-andrews.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01334-462255",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "16 St Katharine's West, St Andrews, KY16 9AX"
            },
            {
                "location_name": "Main Site",
                "location_address": "College Gate, St Andrews, KY16 9AJ"
            },
            {
                "location_name": "St Andrews yrs 1-3, England yrs 4-6",
                "location_address": "University Of St Andrews, St Katharine's West, St Andrews, KY16 9AX"
            },
            {
                "location_name": "St Andrews yrs 1-3, No Preference for England or Scotland, yrs 4 - 6",
                "location_address": "University Of St Andrews, St Katharine's West, St Andrews, KY16 9AX"
            },
            {
                "location_name": "Either",
                "location_address": "University of St Andrews, St Andrews, KY16 9AX"
            },
            {
                "location_name": "University of St Andrews",
                "location_address": "St Katharine's West, The Scores, St Andrews, KY16 9AX"
            },
            {
                "location_name": "St Andrews, yrs 1-3, Manchester, yrs 4-6",
                "location_address": "University Of St Andrews, St Katharine's West, St Andrews, KY16 9AX"
            },
            {
                "location_name": "St Andrews, yrs 1-3, Scotland, yrs 4 - 6",
                "location_address": "University Of St Andrews, St Katharine's West, St Andrews, KY16 9AX"
            },
            {
                "location_name": "St Andrews yrs 1-3, Edinburgh Medical School yrs 4-6",
                "location_address": "University Of St Andrews, St Katharine's West, St Andrews, KY16 9AX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/81fe3292-5b22-efca-853a-e32870a2b0fe",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/quad.jpg",
        "about_us": "N/A"
    },
    {
        "id": "9e91fae79ae206787c4055f69d2552f8",
        "university_name": "St George's, University of London",
        "location": "Cranmer Terrace, London, SW17 0RE",
        "official_website": "https://www.sgul.ac.uk/",
        "contact_email": "study@sgul.ac.uk",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "St George's, University Of London",
                "location_address": "Cranmer Terrace, London, SW17 0RE"
            },
            {
                "location_name": "Main Site",
                "location_address": "Cranmer Terrace, Wandsworth, SW17 0RE"
            },
            {
                "location_name": "St George's, University of London",
                "location_address": "Cranmer Terrace, Wandsworth, SW17 0RE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/9b3207d7-e4ec-a455-62b5-8ce00d1194c9",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/4l5a4381.png",
        "about_us": "N/A"
    },
    {
        "id": "fd90fc5c19217a03aa4aaff5f40c92b8",
        "university_name": "St Mary's University College, Belfast",
        "location": "191 Falls Road, Belfast, BT12 6FE",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "St Mary's University College, Belfast",
                "location_address": "191 Falls Road, Belfast, BT12 6FE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/e47a9792-bad2-4221-5a59-afb269c2a14e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/a_image_st_marys_university_college_belfast.png.jpg",
        "about_us": "N/A"
    },
    {
        "id": "891d66c579f6c3eb62b88755589fb25c",
        "university_name": "University Centre at Salford City College",
        "location": "Eccles Sixth Form Centre, Chatsworth Road, Eccles, Chatsworth Rd, Salford, Greater Manchester, M30 9FJ",
        "official_website": "https://www.salfordcc.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01616 315000",
        "course_locations": [
            {
                "location_name": "University Centre at Salford City College",
                "location_address": "Dakota Avenue, Salford, M50 2PU"
            },
            {
                "location_name": "Pendleton Sixth Form College",
                "location_address": "Dronfield Road, Salford, M6 7FR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/8432fe0e-239d-7f0d-5304-9ddc4a4b0c60",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/banner_pic.jpg",
        "about_us": "N/A"
    },
    {
        "id": "95a9c962a62069b4ad99ef3a7515c004",
        "university_name": "University of Salford",
        "location": "43 Crescent, Salford, Greater Manchester, M5 4WT",
        "official_website": "http://www.salford.ac.uk/",
        "contact_email": "enquiries@salford.ac.uk",
        "contact_phone": "0161 295 4545",
        "course_locations": [
            {
                "location_name": "Salford Business School",
                "location_address": "Lady Hale Building, Salford, M5 4WT"
            },
            {
                "location_name": "Main Site",
                "location_address": "The Crescent, Salford, M5 4WT"
            },
            {
                "location_name": "Sri Lanka Colombo School of Construction Technology (CSCT)",
                "location_address": "Colombo School of Construction Technology (CSCT), Sri Lanka, Columbo, CO 10100"
            },
            {
                "location_name": "Bury College",
                "location_address": "Woodbury Centre, Market Street, Bury, BL9 0BG"
            },
            {
                "location_name": "Peel Park Campus",
                "location_address": "The Crescent, Salford, M5 4WT"
            },
            {
                "location_name": "MediaCity Campus",
                "location_address": "University of Salford, MediaCity B4, Orange Tower, Salford Quays, M50 2HE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/07fe4a70-9239-e4cb-813c-ae2f7715336f",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_image_2.jpg",
        "about_us": "At the University of Salford, we have always placed employability at the heart of what we do. We can trace our history back to 1896 when we were founded to meet the needs of the thriving businesses at the heart of Britain\u2019s Industrial Revolution. Today, we work hand-in-hand with businesses and organisations from all sectors to develop courses that are up-to-date and relevant to current challenges. Our academic staff actively engage with industry to update their knowledge and inform their teaching, and we create opportunities for students to gain hands-on experience and develop the professional and personal skills that employers value."
    },
    {
        "id": "8d2d87070bdc7937e3f8c581724c21ff",
        "university_name": "SGS (South Gloucestershire and Stroud College)",
        "location": "University Centre WISE, New Road, Stoke Gifford, Bristol, South Gloucestershire, BS34 8LP",
        "official_website": "https://uni.sgscol.ac.uk/",
        "contact_email": "HEadmissions@sgscol.ac.uk",
        "contact_phone": "07766 402835",
        "course_locations": [
            {
                "location_name": "University Centre WISE (UCWISE)",
                "location_address": "New Road, Stoke Gifford, Bristol, BS34 8LP"
            },
            {
                "location_name": "Bristol School of Art",
                "location_address": "Queens Road, Clifton, Bristol, BS8 1PX"
            },
            {
                "location_name": "Bristol Zoo Gardens",
                "location_address": "Clifton Campus, Bristol Zoo Gardens, Clifton, Bristol, BS8 3HA"
            },
            {
                "location_name": "Bristol Institute of Performing Arts (BIPA)",
                "location_address": "WISE Campus, New Road,  Stoke Gifford, WISE Campus, Bristol, BS34 8LP"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/80031aa0-3cca-4261-8c26-a231f0e49a09",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_image.png",
        "about_us": "SGS (South Gloucestershire and Stroud College) is located in Bristol with excellent travel options to the rest of the UK. \u00a0We are committed to investing in your future career and all our courses have a focus on employability. You will be taught in small classes by industry specialists within a supportive environment. \u00a0SGS has some of the best amenities in the southwest, with excellent sporting, media, specialist make-up and theatre arts facilities, at our University Centre; and studios at Bristol School of Art. \u00a0We also work in partnership with Bristol Zoo, the Wild Place Project, the Royal West of England Academy (RWA) and The Angels Dance Academy (ADA). Our degrees are awarded and quality assured by the University of Gloucestershire and Pearson.We understand the importance of having safe, supportive and creative spaces. \u00a0SGS provides an all-inclusive and welcoming environment, where everyone can feel free to be themselves. At SGS we celebrate all our students and their achievements!"
    },
    {
        "id": "30d6f47e9e032452fd496e65a1a15661",
        "university_name": "Sheffield College",
        "location": "City Campus, Granville Road, Sheffield, South Yorkshire, S2 2RL",
        "official_website": "http://www.sheffcol.ac.uk",
        "contact_email": "admissions@sheffcol.ac.uk",
        "contact_phone": "0114 260 2600",
        "course_locations": [
            {
                "location_name": "Generic",
                "location_address": "The Sheffield College, Granville Road, Sheffield, S2 2RL"
            },
            {
                "location_name": "Hillsborough Campus",
                "location_address": "The Sheffield College, Livesey Street, Hillsborough, Sheffield, S6 2ET"
            },
            {
                "location_name": "City Campus",
                "location_address": "The Sheffield College, Granville Road, Sheffield, S2 2RL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/86bcfbc8-e8ab-6abd-5eb6-a3b4d0411301",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/3965_uc-sheffield-a-levels-taster-brochure-sm.jpg",
        "about_us": "At UC Sheffield, you can join an increasing number of students who are choosing to study university level qualifications in a wide range of career driven courses with us. All of our courses are validated by leading universities (including Sheffield Hallam University and The University of Hull) and are designed to give you the skills, knowledge and real-world experience you need for your dream career."
    },
    {
        "id": "37eb0236f939fedde74bef28d6a1ed57",
        "university_name": "Sheffield Hallam University",
        "location": "Admissions Office, Surrey Building, Surrey Building, Sheffield, South Yorkshire, S1 1WB",
        "official_website": "http://www.shu.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "0114 225 5555",
        "course_locations": [
            {
                "location_name": "Collegiate Crescent Campus",
                "location_address": "Collegiate Crescent, Sheffield, S10 2BP"
            },
            {
                "location_name": "City Campus",
                "location_address": "Howard Street, Sheffield, S1 1WB"
            },
            {
                "location_name": "Main Site",
                "location_address": "City Campus, Howard Street, Sheffield, S1 1WB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/af611462-ca90-dadf-f0e8-d4cc25129f91",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/high_resolution-owen_building_1.jpg",
        "about_us": "N/A"
    },
    {
        "id": "38ad4111f7f3e4e50345b1671599b173",
        "university_name": "University of Sheffield",
        "location": "Western Bank, Sheffield, South Yorkshire, S10 2TN",
        "official_website": "https://www.sheffield.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "+44 114 222 8030",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Western Bank, Sheffield, S10 2TN"
            },
            {
                "location_name": "Department of Music - Jessop Building",
                "location_address": "34 Leavygreave Road, Broomhall, Sheffield, S3 7RD"
            },
            {
                "location_name": "Management School",
                "location_address": "Conduit Road, Sheffield, S10 1FL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/dbd76d8b-f49a-679e-306f-eb59a8bdc52c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_firth_court_1_.jpg",
        "about_us": "Postgraduate Open Days and Events\u00a0Register your interest in our upcoming postgraduate online open day (November 27th) or one of our other postgraduate events via the website link at the top of the page, and we\u2019ll be in touch with all the details.University of the Year 2024 - voted by our students\u00a0Studying at the University of Sheffield means you\u2019ll get the future that follows a degree from a Russell Group University. You\u2019ll see how world-leading research enhances our teaching and changes lives \u2013 starting with your own. You\u2019ll also have an unforgettable experience in one of the best student cities in the UK. These are just a few reasons our students voted us the University of the Year (Whatuni Student Choice Awards, 2024).\u00a0\u00a0An international outlookWe're a global community with an international outlook. You'll learn alongside 30,000 students from over 150 countries, meaning your experience at Sheffield will be truly international."
    },
    {
        "id": "fc5f8e01c401b1d3f4a02f5660d68238",
        "university_name": "Shrewsbury Colleges Group",
        "location": "London Road, Shrewsbury, Shropshire, SY2 6PR",
        "official_website": "https://www.shrewsbury.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01743 342342",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "LONDON ROAD, Shrewsbury, SY2 6PR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a840508b-baa5-e0f3-e673-86c2e3a9b903",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/002_ucas_banner_scg_logo_0.png",
        "about_us": "N/A"
    },
    {
        "id": "b48608619b71eeedce5bb6db028a11d1",
        "university_name": "Coleg Sir Gar",
        "location": "Graig Campus Sandy Road,, Llanelli, Llanelli, Carmarthenshire, SA15 4DN",
        "official_website": "https://www.colegsirgar.ac.uk",
        "contact_email": "admissions@colegsirgar.ac.uk",
        "contact_phone": "01554 748 000",
        "course_locations": [
            {
                "location_name": "UWTSD Carmarthen",
                "location_address": "UWTSD Carmarthen Campus, Carmarthen, SA31 3EP"
            },
            {
                "location_name": "Graig Campus",
                "location_address": "Sandy Road, Pwll, Llanelli, SA15 4DN"
            },
            {
                "location_name": "Pibwrlwyd Campus",
                "location_address": "Pibwrlwyd, Carmarthen, SA31 2NH"
            },
            {
                "location_name": "Carmarthen School of Art",
                "location_address": "Jobs Well Road, Carmarthen, SA31 3HY"
            },
            {
                "location_name": "Main Site",
                "location_address": "Sandy Road, Llanelli, SA15 4DN"
            },
            {
                "location_name": "Ammanford Campus",
                "location_address": "Tirydail, Ammanford, SA18 3TA"
            },
            {
                "location_name": "Gelli Aur Campus",
                "location_address": "Sandy Road, Llanelli, SA15 4DN"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/2a479d97-8084-9f4b-647b-c185a877a8b5",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "99ddd3d6b863965563eb8715ea3bf008",
        "university_name": "SMB College Group",
        "location": "Brooksby,, Brooksby, Melton Mowbray, Leicestershire, LE14 2LJ",
        "official_website": "http://www.smbgroup.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01664 850850",
        "course_locations": [
            {
                "location_name": "Brooksby - Specialist Land-based Campus",
                "location_address": "Brooksby, Melton Mowbray, LE14 2LJ"
            },
            {
                "location_name": "Melton - Creative and Service Industries Campus",
                "location_address": "Asfordby Road, Melton Mowbray, LE13 0HJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/01d1372f-f231-83a4-a8dd-005b3d14b0d9",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/hall_front_edited.jpg",
        "about_us": "N/A"
    },
    {
        "id": "1a5d68bc0e4a70e700dfde76abb0bf03",
        "university_name": "SOAS University of London",
        "location": "Thornhaugh Street, Russell Square, Russell Square, London, WC1H 0XG",
        "official_website": "http://www.soas.ac.uk/",
        "contact_email": "study@soas.ac.uk",
        "contact_phone": "02076 372388",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Thornhaugh Street, Russell Square, Camden, WC1H 0XG"
            },
            {
                "location_name": "Main Site",
                "location_address": "Thornhaugh Street, London, WC1H 0XG"
            },
            {
                "location_name": "SOAS University of London",
                "location_address": "Thornhaugh Street, Russell Square, Camden, WC1H 0XG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/63840f6d-a848-3256-1729-a2f36734d1a7",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/arp230429_soas_hi-res_49_1.jpg",
        "about_us": "At SOAS, we use our expertise in Africa, Asia and the Middle East as a lens through which to interrogate and understand the major challenges of our time. With an explicit mandate of social justice, we bring the diversity of the world to our vibrant campus in central London.We are a truly global institution. We are ranked 2nd in the UK for international faculty (QS 2024), we offer a wide array of study abroad options to over 70 destinations worldwide, and we welcome students and staff to our London campus from over 140 countries.\u00a0Our staff and students engage in the robust debate and academic rigour required to produce future leaders capable of making an impact in a changing world. At SOAS, you will learn not only the professional skills required by your chosen field, but the soft skills required to be an engaged and global citizen.\u00a0\u00a0"
    },
    {
        "id": "9145b37536c0ba76ca877860afaa093f",
        "university_name": "Solihull College &amp; University Centre",
        "location": "Blossomfield Campus, Blossomfield Road, Solihull, West Midlands, B91 1SB",
        "official_website": "https://www.solihull.ac.uk",
        "contact_email": "HE.Admissions@solihull.ac.uk",
        "contact_phone": "0121 678 7000",
        "course_locations": [
            {
                "location_name": "Woodlands Campus",
                "location_address": "Auckland Drive, Smiths Wood, Solihull, B36 0LY"
            },
            {
                "location_name": "Main Site",
                "location_address": "Blossomfield Road, Solihull, B91 1SB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/26360c43-79b8-00b4-5d41-b8a46172e0c0",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/img_4755.jpg",
        "about_us": "At Solihull College &amp; University Centre you can study on courses offered in partnership with top universities including Oxford Brookes, Birmingham Newman and Coventry as well as the College\u2019s own provision.Programmes available include HNCs, HNDs, Foundation, Top-Up and Bachelor\u2019s Degrees developed with the help of industry across a variety of exciting subject areas!With extremely competitive fees compared to many universities, the College offers you the chance to stay local and save thousands on your degree. Many courses are offered full and part-time allowing you to fit your studies around work and family life."
    },
    {
        "id": "f4b27b5c449a5863217595f4a47fcb88",
        "university_name": "Sotheby's Institute of Art (London)",
        "location": "30 Bedford Square, Bloomsbury, WC1B 3EE",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Sotheby's Institute of Art, New York",
                "location_address": "30 Bedford Square, Bloomsbury, WC1B 3EE"
            },
            {
                "location_name": "Sotheby's Institute of Art, London",
                "location_address": "30 Bedford Square, Camden, WC1B 3EE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b532a77f-cb17-14ed-a488-43d74526c11a",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "f729c7e46cada435c70cbc812420dd40",
        "university_name": "South and City College Birmingham",
        "location": "Digbeth Campus, \r\nHigh Street Deritend\r\nDigbeth, High Street Deritend, Birmingham, West Midlands, B5 5SU",
        "official_website": "http://www.sccb.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "012169 46293",
        "course_locations": [
            {
                "location_name": "Bournville College",
                "location_address": "1 Longbridge Lane, Birmingham, B31 2TW"
            },
            {
                "location_name": "Digbeth Campus - Main Site",
                "location_address": "High Street Deritend, Digbeth, Birmingham, B5 5SU"
            },
            {
                "location_name": "Fusion Centre",
                "location_address": "South and City College Birmingham, 93-108 High Street, Deritend, Birmingham, B5 6DY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/0c59f8f5-8ad5-e954-4377-e6ae2eded4d9",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/dg_image.jpg",
        "about_us": "N/A"
    },
    {
        "id": "405a5f4257c027f3ded02fabccdb3bf7",
        "university_name": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
        "location": "Kingston Hall Rd, Kingston Upon Thames, Kingston Upon Thames, London, KT1 2AQ",
        "official_website": "https://stcg.ac.uk/",
        "contact_email": "Catherine.Trenchfield@stcg.ac.uk",
        "contact_phone": "020 826 83081",
        "course_locations": [
            {
                "location_name": "Kingston College",
                "location_address": "Kingston Hall Road, Kingston upon Thames, KT2 5AQ"
            },
            {
                "location_name": "South Thames College (Wandsworth)",
                "location_address": "Wandsworth High Street, London, SW18 2PP"
            },
            {
                "location_name": "Carshalton College",
                "location_address": "Nightingale Road, Carshalton, SM5 2EJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b44ad48d-517e-4b2d-8eb3-a1d6c1f25c06",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/stcg_banner_image.jpg",
        "about_us": "South Thames Colleges Group gives students greater choices across South West London.\u00a0Our undergraduate courses are a flexible, competitively priced and excellent value alternative to university. Study for one, two or three years with our BTEC Higher National Certificates (HNCs) and Higher National Diplomas (HNDs), Foundation Degrees (FdAs) and Honours Degrees in a diverse range of subjects.\u00a0"
    },
    {
        "id": "c0dbfdf810df86ae4779da567fcbfab8",
        "university_name": "Solent University (Southampton)",
        "location": "Southampton Solent University, East Park Terrace, Southampton, Hampshire, SO14 0YN",
        "official_website": "https://www.solent.ac.uk",
        "contact_email": "admissions@solent.ac.uk",
        "contact_phone": "023 8201 3000",
        "course_locations": [
            {
                "location_name": "Resonance",
                "location_address": "Resonance, Cable Plaza, Waterfront West, Brierley Hill, Dudley, DY5 1LW"
            },
            {
                "location_name": "Main Site",
                "location_address": "East Park Terrace, Southampton, SO14 0YN"
            },
            {
                "location_name": "Main Site",
                "location_address": "East Park Terrace, Southampton, SO14 0YN"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/f92b2692-af07-4b91-95a0-fc33c3f33bf6",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/solent_uni_-_ucas_image_0.jpg",
        "about_us": "At Solent, we offer the freedom to achieve. Through innovation, inspiration, and real-world opportunities. Through the latest tech and the latest theory, and practical chances to get hands-on with both. Through conversations with experts, with professionals, with mentors and peers. Through space to imagine and explore. Grit, heart and imagination \u2013 that\u2019s what our students bring. We give them \u2013 give you \u2013 the skills, the connections, and the opportunities you need. So whatever you want from your career, from life? Solent Uni is here to help you get it. Employers in every industry want more than pure theoretical knowledge \u2013 they want experience, practicality and proactive, innovative thinking. Which is why we offer just that. At every stage, on every course, you\u2019ll have every chance to develop your professional and career skills \u2013 alongside practical real-world experience."
    },
    {
        "id": "9bbae13633cc84974806a6df449f1a9d",
        "university_name": "University Centre Sparsholt",
        "location": "Sparsholt, Winchester, Hampshire, SO21 2NF",
        "official_website": "https://www.sparsholt.ac.uk/university-centre/",
        "contact_email": "degrees@sparsholt.ac.uk",
        "contact_phone": "01962 797269",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Sparsholt, Winchester, SO21 2NF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/e71cb3c4-5cd9-ca75-70d5-0c19aef08bf1",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_4_0.jpg",
        "about_us": "University Centre Sparsholt (UCS) offers a truly diverse and exciting range of courses which can take your higher education journey to the next level.We believe a qualification from UCS means more than just a piece of paper. It means practical, hands-on experience, research opportunities and industry connections to prepare you from day one for your future career in your chosen specialist field.Our lecturers have wide ranging industry experience and are as excited about their specialist subject as you are. The teaching team is highly qualified with extensive industry contacts, all of which they are keen to share with you both on campus in the specialist facilities and off-campus at industry sites.At UCS you won\u2019t be a number in a large class, our dedicated HE team take time to get to know their students on first name terms and make themselves available for queries and support when they are not teaching.\u00a0"
    },
    {
        "id": "6f119da7f573d86f2199a7427519a854",
        "university_name": "Spurgeon's College",
        "location": "South Norwood Hill, London, London, SE25 6DJ",
        "official_website": "http://spurgeons.ac.uk",
        "contact_email": "admissions@spurgeons.ac.uk",
        "contact_phone": "020 8653 0850",
        "course_locations": [
            {
                "location_name": "Spurgeon's College",
                "location_address": "South Norwood Hill, London, SE25 6DJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/dc96ef3c-b8c9-5d03-23f6-9e30bb19218a",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/main_building_small.jpg",
        "about_us": "N/A"
    },
    {
        "id": "970def8ed0df06fdd0827ef48b21b3f4",
        "university_name": "SRUC Scotland's Rural College",
        "location": "West Mains Road, Edinburgh, Midlothian, EH9 3JG",
        "official_website": "https://www.sruc.ac.uk",
        "contact_email": "study@sruc.ac.uk",
        "contact_phone": "0800 269453",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "SRUC Edinburgh, West Mains Road, Edinburgh, EH9 3JG"
            },
            {
                "location_name": "Barony",
                "location_address": "SRUC, Parkgate, Dumfries, DG1 3NE"
            },
            {
                "location_name": "Oatridge",
                "location_address": "SRUC, Ecclesmachan, Ecclesmachan, EH52 6NH"
            },
            {
                "location_name": "Glasgow",
                "location_address": "Queen's Park Nursery (SRUC), Queen's Park, 520 Langside Road, Glasgow, G42 8QR"
            },
            {
                "location_name": "Elmwood",
                "location_address": "SRUC, Carslogie Road, Cupar, KY15 4JB"
            },
            {
                "location_name": "Ayr",
                "location_address": "Niven Building, Auchincruive, Ayr, KA6 5HW"
            },
            {
                "location_name": "Edinburgh",
                "location_address": "King's Building, West Main Road, Edinburgh, EH9 3JG"
            },
            {
                "location_name": "Aberdeen",
                "location_address": "Craibstone Estate, Bucksburn, Aberdeen, AB21 9YA"
            },
            {
                "location_name": "Aberdeen Campus",
                "location_address": "SRUC Aberdeen, Craibstone Estate, Bucksburn, Aberdeen, AB21 9YA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ba944ca6-36af-188e-40a1-324e839230de",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/banner_2.jpg",
        "about_us": "Scotland\u2019s national provider of college and university level education in the land-based sector. Scotland\u2019s Rural College (SRUC) is a specialist institution offering college and university level courses. SRUC strives to help grow a natural economy fuelled by the responsible use of the world\u2019s natural resources: people, land, energy, water, animals and plants.We focus on solving the global challenges facing the planet, including climate change, biodiversity and access to nutritious food and clean water. By focusing on the sustainability of our natural economy, SRUC will strive to lead the way in delivering economic, social and environmental benefits for all, both locally across Scotland, the UK, and beyond."
    },
    {
        "id": "d3d6fd6025ff6c5bb9ce31c97e7ddfac",
        "university_name": "University Centre St Helens",
        "location": "St Helens Town Centre Campus, Water Street, St Helens, Merseyside, WA10 1PP",
        "official_website": "https://www.sthelens.ac.uk/ucsh",
        "contact_email": "ucshadmissions@sthelens.ac.uk",
        "contact_phone": "01744 733766",
        "course_locations": [
            {
                "location_name": "University Centre St Helens",
                "location_address": "Town Centre Campus, Water Street, St Helens, WA10 1PP"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/f827e91c-8675-9440-e390-6a4a0b5ece68",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucsh-graduates_0.jpg",
        "about_us": "N/A"
    },
    {
        "id": "dbe0584d2e95e613be713980c3a064bb",
        "university_name": "St Mary's University, Twickenham",
        "location": "Waldegrave Road, Strawberry Hill, Strawberry Hill, Twickenham, Middlesex, TW1 4SX",
        "official_website": "http://www.stmarys.ac.uk",
        "contact_email": "hello@stmarys.ac.uk",
        "contact_phone": "02082402394",
        "course_locations": [
            {
                "location_name": "St Mary's University, Twickenham",
                "location_address": "Waldegrave Road, Richmond upon Thames, TW1 4SX"
            },
            {
                "location_name": "Main Site",
                "location_address": "St Mary's University, Waldegrave Road, Twickenham, TW1 4SX"
            },
            {
                "location_name": "Wrexham University",
                "location_address": "Wrexham University, Mold Road, Wrexham, LL11 2AW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/1264b52a-16b1-e13e-31e1-246da0955bd1",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/aerial5.jpg",
        "about_us": "Find your place at St Mary\u2019s University \u2013 a London campus with plenty of green space, great graduate outcomes, and one-to-one support. Established in 1850, we combine our long and distinguished history with a very modern outlook.\u00a0Your development is at the centre of everything we do \u2013 we'll work with you to build your skills, abilities, experience, and confidence \u2013 so you're ready for your career and new challenges. Become part of a community \u2013 we have small class sizes so you get personal support, an award-winning support team, our student societies are inclusive and diverse, and we focus on making a positive impact on society. This is what makes St Mary\u2019s the place to be. Based in the safest London borough, Richmond-on-Thames, St Mary\u2019s is home to over 5000 students and is consistently in the top 10 for teaching quality and student experience (Times Good University Guide 2024). "
    },
    {
        "id": "6f524bdee357a43f142a4080d7b72f7c",
        "university_name": "University of Stirling",
        "location": "University of Stirling,, Stirling, Stirlingshire, FK9 4LA",
        "official_website": "https://www.stir.ac.uk/",
        "contact_email": "admissions@stir.ac.uk",
        "contact_phone": "01786 467044",
        "course_locations": [
            {
                "location_name": "Stirling Campus",
                "location_address": "University of Stirling, Stirling, FK9 4LA"
            },
            {
                "location_name": "Stirling",
                "location_address": "Grange Road, Stirling, FK9 4LA"
            },
            {
                "location_name": "Main Site",
                "location_address": "Grange Road, Stirling, FK9 4LA"
            },
            {
                "location_name": "Online",
                "location_address": "University of Stirling, Cottrell Building, Stirling, FK9 4LA"
            },
            {
                "location_name": "Main Site",
                "location_address": "Stirling, FK9 4LA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/20b2137b-2930-80ed-337f-27127a9f1d28",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/3677740935_8cbebec3a4_o_2.jpg",
        "about_us": "The University of Stirling is an international university committed to helping students Be the Difference in the world. Based in the heart of Scotland, Stirling is a place where ability \u2013 not background \u2013 is valued, and teaching, employability and facilities are all rated five-star (QS Stars University Ratings 2024).\u00a0That\u2019s just part of the reason Stirling has been shortlisted for University of the Year in the Times Higher Education (THE) Awards 2024, rated top 20 in the UK for postgraduate teaching (Postgraduate Taught Experience Survey 2022) and number one in the UK for campus environment (The International Student Barometer 2022).\u00a0With more than 170 undergraduate course combinations and over 90 postgraduate courses, including a portfolio of online courses, we equip our graduates with the knowledge, expertise and employability skills needed to succeed in the workplace.\u00a0\u00a0"
    },
    {
        "id": "eaa635e926948406d4432ce77958e680",
        "university_name": "Stranmillis University College",
        "location": "Stranmillis Road, Belfast, BT9 5DY",
        "official_website": "http://www.stran.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "028 9038 1271",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Stranmillis, Belfast, BT9 5DY"
            },
            {
                "location_name": "South West College (Omagh Campus)",
                "location_address": "2 Mountjoy Road, Omagh, BT79 7AH"
            },
            {
                "location_name": "Northern Regional College (Newtownabbey)",
                "location_address": "Northern Regional College, 400 Shore Road, Newtownabbey, BT37 9RS"
            },
            {
                "location_name": "Northern Regional College (Ballymena)",
                "location_address": "Trostan Avenue Building, Ballymena, BT43 7BN"
            },
            {
                "location_name": "Main Site",
                "location_address": "Stranmillis Road, Belfast, BT9 5DY"
            },
            {
                "location_name": "South Eastern Regional College (Lisburn Campus)",
                "location_address": "Victoria Avenue, Newtownards, BT23 7ED"
            },
            {
                "location_name": "Southern Regional College (Newry Campus)",
                "location_address": "Catherine Street, Newry, BT35 6JG"
            },
            {
                "location_name": "Belfast Metropolitan College (Gerald Moag Campus)",
                "location_address": "125-153 Millfield, Belfast, BT1 1HS"
            },
            {
                "location_name": "North West Regional College (Derry-Londonderry)",
                "location_address": "North West Regional College, Strand Road, Derry-Londonderry, BT48 7AL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/337ec3b8-fdde-1ce5-f91e-1e03e51657da",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/stran_banner.png",
        "about_us": "N/A"
    },
    {
        "id": "8b8e7f4ed14392fe8635132a27aa6c37",
        "university_name": "University of Strathclyde",
        "location": "McCance Building, 16 Richmond Street, 16 Richmond Street, Glasgow, Lanarkshire, G1 1XQ",
        "official_website": "https://www.strath.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "+44(0)1415524400",
        "course_locations": [
            {
                "location_name": "University of Strathclyde",
                "location_address": "McCance Building, 16 Richmond Street, Glasgow, G1 1XQ"
            },
            {
                "location_name": "Main Site",
                "location_address": "McCance Building, 16 Richmond Street, Glasgow, G1 1XQ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5cc768aa-774a-7fc8-97fb-686b2f068676",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/uos_1920x420_2.jpg",
        "about_us": "Founded in 1796 as 'a place of useful learning', we're a leading technological University with a vibrant multicultural community of around 30,000 students from more than 140 nations.We believe ideas can change the world and we\u2019re bringing people of all backgrounds together, making an impact on our students our city and our global community. Based in the heart of Glasgow, our campus has pretty much everything you need, and for anything else there is an entire city to choose from!\u00a0"
    },
    {
        "id": "ac3f8c89fe9112973ae1584cfc0eaf91",
        "university_name": "City of Sunderland College",
        "location": "Bede Campus,Durham Road, Durham Road, Sunderland, Tyne and Wear, SR3 4AH",
        "official_website": "https://www.sunderlandcollege.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "0191 511 6000",
        "course_locations": [
            {
                "location_name": "Bede Campus",
                "location_address": "Durham Road, Sunderland, SR3 4AH"
            },
            {
                "location_name": "City Campus",
                "location_address": "Park Lane, Tyne &amp; wear, Sunderland, SR1 3NX"
            },
            {
                "location_name": "Northumberland College",
                "location_address": "College Road, Ashington, Northumberland, NE63 9RG"
            },
            {
                "location_name": "Kirkley Hall",
                "location_address": "Kirkley Hall, Ponteland, NE20 0AQ"
            },
            {
                "location_name": "Hartlepool Sixth Form",
                "location_address": "Hartlepool Sixth Form, Blakelock Road, Hartlepool, TS25 5PF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/31260746-2f58-40c2-dd26-74a7d063b040",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "596f76002523d529c397e09e25436538",
        "university_name": "University of Sunderland",
        "location": "Edinburgh Building, Chester Road, Sunderland, Tyne and Wear, SR1 3SD",
        "official_website": "http://www.sunderland.ac.uk/",
        "contact_email": "student.helpline@sunderland.ac.uk",
        "contact_phone": "0191 515 3000",
        "course_locations": [
            {
                "location_name": "East Durham College",
                "location_address": "Willerby Grove, Peterlee, SR8 2RN"
            },
            {
                "location_name": "Northern Academy of Music Education",
                "location_address": "The Peacock, 287 High Street West, Sunderland, SR1 3ES"
            },
            {
                "location_name": "City Campus",
                "location_address": "Chester Road, Sunderland, SR1 3SD"
            },
            {
                "location_name": "Dance City",
                "location_address": "Temple Street, Newcastle Upon Tyne, NE1 4BR"
            },
            {
                "location_name": "Main Site",
                "location_address": "Edinburgh Building, City Campus, Chester Road, Sunderland, SR1 3SD"
            },
            {
                "location_name": "Sir Tom Cowie Campus",
                "location_address": "St Peter's, Sunderland, SR6 0DD"
            },
            {
                "location_name": "University of Sunderland in London",
                "location_address": "197 Marsh Wall, London, E14 9SG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5f968715-d4b4-afb5-e842-8a4a024feb0d",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/main_hero_image.jpg",
        "about_us": "We\u2019re proud\u2026 to be a top 50 UK university (Guardian University Guide, 2023), of our top-quality facilities, our inclusive culture, and our vibrant, ever-changing city. You\u2019ll experience the best of both worlds if you come to study at Sunderland, with our two campuses nestled within the heart of the city, but just a stone\u2019s throw away from the stunning coast and countryside of the North East.\u00a0\u00a0We might be a small city, but we\u2019re friendly too, and we\u2019ve got plenty to offer in the way of student life. No matter where you\u2019re coming from, you\u2019ll receive a warm welcome at Sunderland. Our students are at the forefront of everything we do and we\u2019re here to help you succeed. Your time at Sunderland will shape who you are and what you do for the rest of your life and our career-focused, professions-facing courses will ensure you have a head start."
    },
    {
        "id": "fcb6889e372d7f80045c139ba89b4ec7",
        "university_name": "University of Surrey",
        "location": "Stag Hill, Guildford, Guildford, Surrey, GU2 7XH",
        "official_website": "http://www.surrey.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01483 300800",
        "course_locations": [
            {
                "location_name": "Stag Hill",
                "location_address": "Stag Hill, Guildford, GU2 7XH"
            },
            {
                "location_name": "Kate Granger Building (Surrey Research Park)",
                "location_address": "30 Priestley Road, Guildford, GU2 7YH"
            },
            {
                "location_name": "Main Site",
                "location_address": "Guildford, GU2 7XH"
            },
            {
                "location_name": "Surrey Research Park",
                "location_address": "Surrey Research Centre, Technology Centre, 40 Occam Rd, Guildford, GU2 7YG"
            },
            {
                "location_name": "Grace",
                "location_address": "N/A"
            },
            {
                "location_name": "Hannah",
                "location_address": "N/A"
            },
            {
                "location_name": "Imane",
                "location_address": "N/A"
            },
            {
                "location_name": "Charlotte",
                "location_address": "N/A"
            },
            {
                "location_name": "Daniel",
                "location_address": "N/A"
            },
            {
                "location_name": "Beth",
                "location_address": "N/A"
            },
            {
                "location_name": "Ellen",
                "location_address": "N/A"
            },
            {
                "location_name": "Janelle",
                "location_address": "N/A"
            },
            {
                "location_name": "Cat",
                "location_address": "N/A"
            },
            {
                "location_name": "Marko",
                "location_address": "N/A"
            },
            {
                "location_name": "Debora",
                "location_address": "N/A"
            },
            {
                "location_name": "Mariama",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5ea13bf3-a5ff-0ec1-873b-f3842afb46a6",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_profile_web_banner.png",
        "about_us": "At Surrey, you can expect more than just a degree - you can expect the experience of a lifetime. As part of our global community of diverse people and ideas, you'll thrive in a welcoming atmosphere of our vibrant, beautiful green campus. Expect exceptional, innovative teaching, designed to empower and equip you with the skills and knowledge to stand out in a competitive world.We focus on your career from your first day; you can expect significant support to boost your employability. Surrey means success. The editor of The Times Good University Guide explains:\u201cIn an increasingly competitive graduate jobs market, a degree from the University of Surrey opens doors.\u201d Alastair McCall."
    },
    {
        "id": "efacfa1dfecf840228f35906423c5cd6",
        "university_name": "New College Swindon University Centre",
        "location": "New College Drive,, Swindon, Wiltshire, SN3 1AH",
        "official_website": "https://he.newcollege.ac.uk",
        "contact_email": "headmissions@newcollege.ac.uk",
        "contact_phone": "01793 491591",
        "course_locations": [
            {
                "location_name": "Queens Drive Campus",
                "location_address": "New College Drive, Swindon, SN3 1AH"
            },
            {
                "location_name": "Swindon and Wiltshire Institute of Technology",
                "location_address": "North Star Avenue, Swindon, SN2 1DY"
            },
            {
                "location_name": "North Star Campus",
                "location_address": "North Star Campus, North Star Avenue, Swindon, SN2 1DY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/16a7e353-421e-658b-688f-14b84d61c610",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_header_2024_2.png",
        "about_us": "New College Swindon University CentreYou don\u2019t need to travel far to study for a degree. We offer a local university experience right on your doorstep. Courses are offered from Level 4\u20136 which is each stage of a degree programme. This means you gain extra qualifications on your journey to a full degree. We have designated areas at both Queens Drive and the Institute of Technology at North Star. You will be impressed with the outstanding industry standard facilities on offer at both campuses and the very high standard of specialist teaching delivered by industry experts.The Swindon and Wiltshire Institute of Technology (SAWIoT)Based at our North Star campus, SAWIoT is a state-of-the-art training facility delivering high quality, higher level technical education and training across a range of STEM occupations and industries. Our sector specialisms include Business Management, Creative and Media Digital and Computer Science, Engineering, Health, Manufacturing and Lab Science professions."
    },
    {
        "id": "8ecec780fdeeb91745eece265b160c4d",
        "university_name": "Tameside College",
        "location": "Tameside College, Beaufort Road, Ashton-under-Lyne, Greater Manchester, OL6 6NX",
        "official_website": "http://www.tameside.ac.uk/",
        "contact_email": "HEAdmissions@tameside.ac.uk",
        "contact_phone": "0161 908 6600",
        "course_locations": [
            {
                "location_name": "Tameside College",
                "location_address": "Beaufort Road, Ashton-under-Lyne, OL6 6NX"
            },
            {
                "location_name": "Tameside One",
                "location_address": "Wellington Road, Ashton-under-Lyne, OL6 6BL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/4156d693-3fff-8615-614c-f2c1ad240b6c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/t_logo_horizontal-03_txtoutline.jpg",
        "about_us": "Tameside College will transform lives by offering first class education and training in order to improve employability and generate economic prosperity.Based in Ashton-under-Lyne, Greater Manchester, we are a further education college that provides the next steps from school to university and employment.\u00a0 We also offer a range of apprenticeships as well as adult and university level courses.We aim to support the individual aspirations of each learner by providing first class learning environments and resources.\u00a0 We are rated Ofsted Good and have invested significantly in our campus, to develop state-of-the-art facilities for our students."
    },
    {
        "id": "af29903b09077ef00344a180823d0750",
        "university_name": "The Engineering &amp; Design Institute London",
        "location": "11 Quebec Way, London, London, SE16 7LG",
        "official_website": "https://tedi-london.ac.uk/",
        "contact_email": "hello@tedi-london.ac.uk",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "TEDI-London Campus",
                "location_address": "TEDI - London Building, 11 Quebec Way, London, SE16 7LG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/bffe1f14-a9a5-4606-89fd-419c3f06e09e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/25936_youtube_banner.png",
        "about_us": "TEDI-London\u00a0is a new and innovative institution for future engineers, founded by three highly ranked global universities: Arizona State University, King\u2019s College London and UNSW Sydney.\u00a0We teach engineering differently; from day one students consider industry challenges and work on practical projects connected to global topics. This is to shape you to become the socially aware, creative problem-solver\u00a0that the engineering industry needs.If you think you\u2019ve got the makings of a great engineer, you can reach your potential at TEDI-London.\u00a0\u00a0"
    },
    {
        "id": "8300339573d375f2098c6cbed0b5b7ee",
        "university_name": "Teesside University",
        "location": "Borough Road, Middlesbrough, North Yorkshire, TS1 3BX",
        "official_website": "http://www.tees.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01642 218121",
        "course_locations": [
            {
                "location_name": "Darlington College",
                "location_address": "Central Park, Haughton Road, Darlington, DL1 1DR"
            },
            {
                "location_name": "Hartlepool College of Further Education",
                "location_address": "Stockton Street, Hartlepool, TS24 7NT"
            },
            {
                "location_name": "Stockton Riverside College",
                "location_address": "Harvard Avenue, Thornaby, Stockton-on-Tees, TS17 6FB"
            },
            {
                "location_name": "Teesside University Darlington",
                "location_address": "Vicarage Road, Darlington, DL1 1JW"
            },
            {
                "location_name": "Online",
                "location_address": "Teesside University, Middlesbrough, TS1 3BX"
            },
            {
                "location_name": "Teesside University Middlesbrough Campus",
                "location_address": "Borough Road, Middlesbrough, TS1 3BX"
            },
            {
                "location_name": "Redcar &amp; Cleveland College",
                "location_address": "Corporation Road, Redcar, TS10 1EZ"
            },
            {
                "location_name": "Teesside University London Campus",
                "location_address": "Here East, Queen Elizabeth Olympic Park, 14 East Bay Lane, London, E15 2GW"
            },
            {
                "location_name": "Bede Sixth Form College",
                "location_address": "Marsh House Avenue, Stockton-on-Tees, Billingham, TS23 3HB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b46d1424-48eb-18c7-b84c-ae0a0799dcfc",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_header_4.jpg",
        "about_us": "Set in the heart of Middlesbrough, with a vibrant and contemporary campus, Teesside University provides students with a friendly and inclusive environment with excellent state-of-the-art facilities.We have been awarded an overall rating of Gold in the Teaching Excellence Framework (TEF) 2023, including Gold for student experience and Gold for student outcomes (tees.ac.uk/source).We've invested more than \u00a3280m in our town-centre campus, giving us a leading edge in digital innovation, top-notch forensic facilities, and sport and health sciences buildings.Middlesbrough is a fantastic town, packed with all the essentials that a student could want, from everyday needs to something to help relax and unwind. The town is very well connected with short journeys to the many local beaches, the Yorkshire countryside and great cities in the region.We also have a campus in London."
    },
    {
        "id": "b6b49261dc4b5e6836d4a3f27aef1ae8",
        "university_name": "ThinkSpace Education",
        "location": "119 Third Avenue, Almodington, Chichester, PO20 7LB",
        "official_website": "https://thinkspaceeducation.com/",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "119 Third Avenue, Almodington, Chichester, PO20 7LB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6c26b7ba-0352-4d3d-999b-c41dae371cdc",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/tsebanner-739height.jpg",
        "about_us": "N/A"
    },
    {
        "id": "241f84f7884401674d0d9a354f71f066",
        "university_name": "Trafford &amp; Stockport College Group",
        "location": "Manchester Road, Timperley, Stockport, Altrincham, Greater Manchester, WA14 5PQ",
        "official_website": "http://www.trafford.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "0161 886 7070",
        "course_locations": [
            {
                "location_name": "Main Site (University Centre -Stockport College)",
                "location_address": "Wellington Road South, Stockport, SK1 3UQ"
            },
            {
                "location_name": "Trafford College, Stretford Campus",
                "location_address": "Stretford Campus, Talbot Road, Stretford, Manchester, M32 0XH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ad154893-2a54-7c4a-5e8a-2dfa04fea9f5",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/banner_3.png",
        "about_us": "N/A"
    },
    {
        "id": "b2bfe13a842202bf720084e2961f2d9f",
        "university_name": "Trinity Laban",
        "location": "King Charles Court, Old Royal Naval College, Greenwich, London, SE10 9JF",
        "official_website": "https://www.trinitylaban.ac.uk",
        "contact_email": "contact@trinitylaban.ac.uk",
        "contact_phone": "020 8305 4402",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Old Royal Naval College, King Charles Court, London, SE10 9JF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/09c81910-9316-5cef-5f55-970346633f92",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/colab_24_wk1_showcase_laban_slg_approved_8229_1.jpg",
        "about_us": "Trinity Laban is a world-leading conservatoire that redefines excellence. We create pioneers, focusing on the artistic freedom and transformation of our students. A community of global collaborators, we lead the arts across genres and beyond convention through innovative research, experimentation, and outreach. Against a backdrop of stunning architecture in the creative heart of South East London, Trinity Laban is where change happens."
    },
    {
        "id": "1f0a4155b6cc197c820ee7dbea08a6c7",
        "university_name": "University Centre Truro and Penwith",
        "location": "College Road, Truro, Cornwall, TR1 3XX",
        "official_website": "https://www.truro-penwith.ac.uk/study-with-us/university-centre/",
        "contact_email": "heenquiry@truro-penwith.ac.uk",
        "contact_phone": "01872 305746",
        "course_locations": [
            {
                "location_name": "Truro Campus",
                "location_address": "Truro College, College Road, Truro, TR1 3XX"
            },
            {
                "location_name": "Bodmin",
                "location_address": "Ottery Building, Callywith College, Old Callywith Road, Bodmin, PL31 2GT"
            },
            {
                "location_name": "Penwith Campus",
                "location_address": "St Clare Street, Penzance, TR18 2SA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/cfe1b520-9486-7aaf-3c1b-a4a87f073021",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/zennor_and_mount.jpg",
        "about_us": "The University Centre Truro &amp; Penwith is spread across two modern campuses in Cornwall, offering over 30 study programmes across 11 subject areas ranging from level four to postgraduate.There is a great range of University level courses and subjects to choose from, and all the courses on offer are approved by the University of Plymouth, University of Greenwich and Pearson.The University Centre\u2019s two campuses are located in Truro and Penzance. There is also an additional arts centre at the Penzance School of Art. The Truro campus is home to purpose-built facilities spread across 13 different buildings, while Penwith boasts enviable views and modern facilities in a relaxed, coastal environment after \u00a330 million of transformative investment."
    },
    {
        "id": "d94731ad2acf943a5dde755081a42519",
        "university_name": "Two Mile Ash ITTP (partnered with Chiltern Training Group)",
        "location": "c/o Fairfields Primary School, \r\nApollo Avenue, \r\nFairfields, The High Street, Milton Keynes, Buckinghamshire, MK11 4BA",
        "official_website": "https://www.mkitt.co.uk",
        "contact_email": "N/A",
        "contact_phone": "01908 533284",
        "course_locations": [
            {
                "location_name": "Main Site TMA ITTP",
                "location_address": "Apollo Avenue, Fairfields, Milton Keynes, MK11 4BA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/117bff52-14a6-4829-bd74-1a7c8d1a110b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/dsc0145.jpg",
        "about_us": "N/A"
    },
    {
        "id": "4f4a1630072dbcb391a38c223ef697d2",
        "university_name": "Tyne Coast College",
        "location": "St Georges Avenue, South Shields, Tyne and Wear, NE34 6ET",
        "official_website": "https://www.tynecoast.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01914273500",
        "course_locations": [
            {
                "location_name": "South Tyneside College",
                "location_address": "St Georges Avenue, Westoe, South Shields, NE34 6ET"
            },
            {
                "location_name": "Tyne Metropolitan College",
                "location_address": "Battle Hill Drive, Wallsend, Wallsend, NE28 9NL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/8df995e9-9656-947d-6471-af29d7bf99f1",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/tcc_logo_stacked_rgb.png",
        "about_us": "Tyne Coast College offers diverse and innovative programmes of study to create opportunities for all to succeed, professionally and personally.Working with employers, partners and other stakeholders, our offer is designed to meet the skills needs of the region and strongly support economic growth and prosperity. We have adopted a responsive curriculum, aligned to the LSIP priorities to ensure we tackle existing and future skills gaps in priority sectors.Incorporating the former South Tyneside and Tyne Metropolitan colleges, our key brands also include South Shields Marine School, recognised as one of the world\u2019s foremost maritime training centres and winner of the prestigious Queens Anniversary Prize for excellence in Further and Higher Education.\u00a0"
    },
    {
        "id": "9bb319996d4657981343e33fbed694ab",
        "university_name": "UCEN Manchester",
        "location": "City Campus Manchester, 60 Great Ducie Street, Manchester, Manchester, M3 1PT",
        "official_website": "https://www.ucenmanchester.ac.uk/contact/locations/?utm_source=ucas&utm_medium=referral&utm_campaign=open_days",
        "contact_email": "enquiries@ucenmanchester.ac.uk",
        "contact_phone": "0161 674 7777",
        "course_locations": [
            {
                "location_name": "Openshaw Campus",
                "location_address": "Ashton Old Road, Openshaw, Manchester, M11 2WH"
            },
            {
                "location_name": "City Campus Manchester",
                "location_address": "60 Great Ducie Street, Manchester, M3 1PT"
            },
            {
                "location_name": "Shena Simon Campus",
                "location_address": "Whitworth Street, Manchester, M1 3HB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/babb7b8c-c195-5902-75e5-48cee86dfadc",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_header_banner.jpg",
        "about_us": "We\u2019re Manchester born and bred and for more than three decades we have delivered degree-level courses.When we first started we were part of The Manchester College, but in 2018 we took a leap to become UCEN Manchester and create something new to provide something different: the quality degree-level education of a university with the personal support you\u2019d find in a college.Our offer is about combining the best of academic and career-based learning through smaller class sizes to ensure you\u2019re never lost in the crowd. You won\u2019t just learn the theory through writing essays, but spend time learning the hands-on, real-world skills you need to thrive in your career, having important, industry-relevant conversations with your tutors and peers, and learning in a setting that suits you.No matter your background, whether that\u2019s straight from college, returning as a parent, changing career or upskilling in your role, ensuring you\u2019re ready for a future career in industry is our number one aim."
    },
    {
        "id": "eb1ac1146e0c0955c0ef8c52c3bf3169",
        "university_name": "UCFB",
        "location": "UCFB College of Football Business Limited, 14th Floor, 111 Piccadilly, Manchester, Greater Manchester, M1 2HY",
        "official_website": "https://www.ucfb.ac.uk/",
        "contact_email": "admissions@ucfb.com",
        "contact_phone": "+443330607157",
        "course_locations": [
            {
                "location_name": "UCFB Manchester",
                "location_address": "Piccadilly Place, Manchester Campus, Manchester, M1 3BN"
            },
            {
                "location_name": "Main Site",
                "location_address": "University Campus of Football Business,, Arch View House, First Way, Wembley, Middlesex, HA9 0JD"
            },
            {
                "location_name": "UCFB Wembley",
                "location_address": "Wembley Stadium, PO Box 1966, Wembley, London, HA9 0WS"
            },
            {
                "location_name": "Blended",
                "location_address": "UCFB Manchester Campus or UCFB Wembley, Manchester or London, M11 3FF"
            },
            {
                "location_name": "Distance",
                "location_address": "UCFB Manchester Campus or UCFB Wembley Campus, Manchester or London, M11 3FF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/9bebda25-7230-c5e9-3f66-a2196a92a011",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/wembley-etihad-ucas-4.png",
        "about_us": "N/A"
    },
    {
        "id": "89cf6155e1bb41b4a9d3141373e84dd8",
        "university_name": "UCL (University College London)",
        "location": "Gower Street, London, London, London, WC1E 6BT",
        "official_website": "http://www.ucl.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "02076 792000",
        "course_locations": [
            {
                "location_name": "London, Bloomsbury",
                "location_address": "Gower Street, Camden, WC1E 6BT"
            },
            {
                "location_name": "London, Stratford (UCL East)",
                "location_address": "UCL East, Marshgate 1, 7 Sidings Street, Stratford, London, E20 2AE"
            },
            {
                "location_name": "Main Site",
                "location_address": "Gower Street, London, WC1E 6BT"
            },
            {
                "location_name": "Main Site",
                "location_address": "Admissions, Gower Street, London, WC1E 6BT"
            },
            {
                "location_name": "Archway Campus",
                "location_address": "Royal Free &amp; University College Medical School, Whittington Hospital, Islington, N19 5NF"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/bd0c70d8-cc38-309f-cecb-34f0c195b137",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/portico_1920x420px.jpg",
        "about_us": "N/A"
    },
    {
        "id": "ded50e01ab5f09c65a95ce19d4042960",
        "university_name": "Ulster University",
        "location": "University House, Cromore Road, Cromore Road, Coleraine, County Londonderry, BT52 1SA",
        "official_website": "https://www.ulster.ac.uk/home",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "E-Learning",
                "location_address": "Cromore Road, Coleraine, BT52 1SA"
            },
            {
                "location_name": "Main Site",
                "location_address": "Cromore Road, Coleraine, BT52 1SA"
            },
            {
                "location_name": "Jordanstown",
                "location_address": "Shore Road, Newtownabbey, BT37 0QB"
            },
            {
                "location_name": "South West College",
                "location_address": "Circular Road, Dungannon, BT71 6BQ"
            },
            {
                "location_name": "Derry~Londonderry",
                "location_address": "Northland Road, Derry~Londonderry, BT48 7JL"
            },
            {
                "location_name": "Coleraine",
                "location_address": "University House, Cromore Road, Coleraine, BT52 1SA"
            },
            {
                "location_name": "Belfast Metropolitan College",
                "location_address": "College Square East, Belfast, BT1 6DJ"
            },
            {
                "location_name": "QA Higher Education London",
                "location_address": "St James House, 10 Rosebery Avenue, London, EC1R 4TF"
            },
            {
                "location_name": "Belfast",
                "location_address": "York Street, Belfast, BT15 1ED"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/b0c96a45-5ad1-7f8b-1e71-073221059886",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/uu_banner_0.jpg",
        "about_us": "N/A"
    },
    {
        "id": "d6b0662a82ca4c6472db3a2cb6d68a45",
        "university_name": "Universal Centre of Sport",
        "location": "25 Bankside, Stationfields, Oxford, OX5 1JE",
        "official_website": "https://www.ucsport.org",
        "contact_email": "info@ucsport.org",
        "contact_phone": "01753 362248",
        "course_locations": [
            {
                "location_name": "UCS Dorking Campus",
                "location_address": "Meadowbank, Mill Lane, Dorking, RH4 1DX"
            },
            {
                "location_name": "UCS Lydney Campus",
                "location_address": "Dean Academy, Church Road, Lydney, GL15 5DZ"
            },
            {
                "location_name": "UCS Eastleigh Campus",
                "location_address": "Silverlake Stadium, Stoneham Lane, Eastleigh, SO50 9HT"
            },
            {
                "location_name": "UCS PPP Campus",
                "location_address": "2-4 The Square, Bury, BL9 9HR"
            },
            {
                "location_name": "UCS Manchester Campus",
                "location_address": "2-4 The Square, Bury, BL9 9HR"
            },
            {
                "location_name": "UCS Long Eaton Campus",
                "location_address": "Grange Park, Station Road, Long Eaton, NG10 2EF"
            },
            {
                "location_name": "UCS Hull Campus",
                "location_address": "The MKM Stadium, West Park, Hull, HU3 6HU"
            },
            {
                "location_name": "UCS Maidstone Campus",
                "location_address": "Gallagher Stadium, James Whatman Way, Maidstone, ME14 1LQ"
            },
            {
                "location_name": "UCS Northampton Campus",
                "location_address": "NTFC Education Centre, St Crispins Campus, St Crispins Drive, Northampton, NN5 4BZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/2f918b81-7af4-4be8-ab28-1876c3e1475b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_3.png",
        "about_us": "Selecting the next path in a learning journey can be confusing for any student. Our dedicated professional team will help you to make an informed decision that best supports your career aspirations.\u00a0UCS is an inclusive centre guided by the traditions of industry professionals, that places key emphasis on areas around intellectual curiosity, provision to others, and a comprehensive perspective on education. A UCS education grounded in sporting excellence, promotes career preparation and provides opportunities for personal and professional growth in sport and it's associated areas. A student-focused learning community that values equality and diversity, and focuses on the education of the whole person.\u00a0Here at UCS, we guide the intellectual, ethical, and physical development of each of our students.\u00a0"
    },
    {
        "id": "5665e22505eda449db228416f1a52306",
        "university_name": "University Academy 92 (UA92)",
        "location": "Brian Statham Way, Trafford, Old Trafford, Greater Manchester, M16 0PU",
        "official_website": "https://ua92.ac.uk?utm_source=ucas&utm_medium=referral&utm_campaign=ug-prospect_uk_2024_pre-app_ua92&utm_term=&utm_content=",
        "contact_email": "hello@UA92.ac.uk",
        "contact_phone": "0161 507 1992",
        "course_locations": [
            {
                "location_name": "UA92 Business School",
                "location_address": "Baskerville House, Browncross Street, Manchester, M3 5FS"
            },
            {
                "location_name": "UA92",
                "location_address": "UA92 Campus, Talbot Road, Manchester, M16 0PU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/4ddd9e94-ea3e-42b7-a987-41a29d802ee0",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/dji_0054.jpg",
        "about_us": "University Academy 92 (UA92) is a higher education institution in Manchester. We have a campus in Old Trafford and a Business School in the city-centre. We are deliberately different to traditional universities, with fixed morning or afternoon timetables and no end of year exams. Our industry-inspired courses, in Business, Media, Sport and Digital, place character and personal development firmly at the core of the learning experience.UA92 was established as a collaboration between Lancaster University and the Class of \u201892 - including Gary Neville, Paul Scholes, Ryan Giggs, Nicky Butt, and Phil Neville. Graduates also benefit from a degree awarded by Lancaster University, who are highly regarded by employers and perform well in University league tables."
    },
    {
        "id": "d165b60021b9ae742acdd619fe59a383",
        "university_name": "University Centre Calderdale College",
        "location": "Francis Street, Halifax, West Yorkshire, HX1 3UZ",
        "official_website": "http://www.calderdale.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01422 399316",
        "course_locations": [
            {
                "location_name": "Calderdale College Main Site",
                "location_address": "Francis Street, Halifax, HX1 3UZ"
            },
            {
                "location_name": "Dean Clough Studio",
                "location_address": "Room E107, E Mill, Dean Clough, Halifax, HX3 5AX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/9fed7298-2fc2-82c1-d371-c3e9ed316094",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/re-sized_uc_clearing_artwork.png",
        "about_us": "N/A"
    },
    {
        "id": "b1d969d99e8115f96756706f0f5c4f73",
        "university_name": "University Centre Grimsby",
        "location": "Nuns Corner,, Grimsby, Lincolnshire, DN34 5BQ",
        "official_website": "https://tecpartnership.com/about-tec-partnership",
        "contact_email": "headmissions@grimsby.ac.uk ",
        "contact_phone": "01472 311222",
        "course_locations": [
            {
                "location_name": "University Centre Grimsby",
                "location_address": "Grimsby Institute of Further and Higher Education, Nuns Corner Campus, Grimsby, DN34 5BQ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/33779918-25ec-9f8a-caf2-39cc55d53363",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/newucasucg2.jpg",
        "about_us": "About The University Centre GrimsbyIf you are looking to find something interesting, exciting and rewarding to do with your future career then The University Centre Grimsby at Higher Education is exactly the right place to get the training you need and employers look out for, leading to amazing career opportunities, the prospect of better wages and increased job and personal satisfaction.At the University Centre Grimsby we are committed to providing high class education in world class surroundings.\u00a0 The facilities inside the building at Nuns Corner include state of the art lecture theatres and seminar rooms, TV studio, Clinical skills rooms to simulate hospital wards and computer suites \u2013 all fantastic opportunities to train for future careers in realistic settings.\u00a0As a small University we are able to offer great personal support for students in all aspects of their study and university life."
    },
    {
        "id": "d1d24a193e3c5edd4ef9e1810c821117",
        "university_name": "University Centre Rotherham",
        "location": "University Centre Rotherham, Doncaster Gate, Rotherham, South Yorkshire, S65 1DA",
        "official_website": "https://ucr.rotherham.ac.uk",
        "contact_email": "ucr@rotherham.ac.uk",
        "contact_phone": "01709 722806",
        "course_locations": [
            {
                "location_name": "Rotherham College - Town Centre Campus",
                "location_address": "Eastwood Lane, Rotherham, S65 1EG"
            },
            {
                "location_name": "Dearne Valley College",
                "location_address": "Manvers Park, Wath-Upon-Dearne, Rotherham, S63 7EW"
            },
            {
                "location_name": "University Centre Rotherham (UCR)",
                "location_address": "Doncaster Gate, Rotherham, S65 1DA"
            },
            {
                "location_name": "North Notts College - Worksop Campus",
                "location_address": "Carlton Road, Worksop, S81 7HP"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/554e082a-7bc8-bc1e-daad-1f62fdbbac8e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucr_ucas_header.jpg",
        "about_us": "We\u2019re a modern, state-of-the-art campus in the heart of Rotherham, dedicated to providing outstanding outcomes for all of our students.We opened our doors to students in 2018, providing access to\u00a0Higher Education\u00a0in a wide range of subjects to driven people looking to make a difference.Our student\u2019s success\u00a0is at the centre of everything we do, we invest all of our efforts, expertise and excellent facilities into making that happen.Our degree courses (Level 4-6) allow our students to develop sought-after skills in\u00a0Engineering,\u00a0Caring Professions,\u00a0Business,\u00a0Computing,\u00a0Performance,\u00a0Media,\u00a0Arts and Graphic Design,\u00a0Education\u00a0and much more.Our degree and higher-level qualifications are accredited by top universities and awarding bodies including Sheffield Hallam University, the University of Huddersfield and University of Hull."
    },
    {
        "id": "49e48f1190cffb57b29c17dcfa21a772",
        "university_name": "University Centre South Devon \u2013 South Devon College",
        "location": "Long Road, Paignton, Devon, TQ4 7EJ",
        "official_website": "https://www.ucsd.ac.uk",
        "contact_email": "university@southdevon.ac.uk",
        "contact_phone": "08000 213181",
        "course_locations": [
            {
                "location_name": "UCSD",
                "location_address": "Long Road, Paignton, TQ4 7EJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3c63c31c-9a57-cf90-dd89-5e64021e2163",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucsd_ucas_banner_0.jpg",
        "about_us": "University Centre South Devon is a Higher Education Institution that is part of South Devon College based in Paignton, Devon.We pride ourselves on offering small and supportive classes focusing on being flexible around the needs of our students..Our campus and facilities have been designed to ensure that you can study and develop at your own pace. They provide a comfortable environment to suit your learning style, with all the necessary resources available to you.You will have access to the following across our campuses:1,200 computers25,000 booksCampus-wide wireless networkA comprehensive catalogue of e-books, journals, newspapers and electronic resourcesA virtual learning portalA student email addressOur Paignton campus also boasts the new and innovative \u00a317 million Hi Tech &amp; Digital Centre provides a visionary facility for higher education, towards ever-expanding hi tech, manufacturing, digital and creative sectors across Torbay, South Devon and wider regions.\u00a0"
    },
    {
        "id": "1a246a9cd5aa37ba0e38be7a50c7882f",
        "university_name": "University Centre South Essex",
        "location": "Luker Road, Southend-On-Sea, Southend-on-Sea, Essex, SS1 1ND",
        "official_website": "https://www.southessex.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01702 220441",
        "course_locations": [
            {
                "location_name": "University Centre Basildon",
                "location_address": "College Way, Basildon, SS14 1GP"
            },
            {
                "location_name": "Main Site",
                "location_address": "Luker Road, Southend, SS1 1ND"
            },
            {
                "location_name": "University Centre Southend",
                "location_address": "Luker Road, Southend-on-Sea, SS1 1ND"
            },
            {
                "location_name": "University Centre Thurrock",
                "location_address": "High Street, Grays, RM17 6TF"
            },
            {
                "location_name": "Thurrock Campus",
                "location_address": "High Street, Grays, RM17 6TF"
            },
            {
                "location_name": "University Centre Purfleet",
                "location_address": "High House Production Park, Vellacott Close, Purfleet, RM19 1RJ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/7e3d3c04-9c48-b469-8737-cc0c92d0f3a2",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/forum_image_1920x420px_v2.jpg",
        "about_us": "N/A"
    },
    {
        "id": "9d42fb69fc5886c97b4ebdea231d1901",
        "university_name": "University Centre at the Heart of Yorkshire Education Group (inc Wakefield College &amp; Selby College)",
        "location": "Margaret Street, Wakefield, West Yorkshire, WF1 2DH",
        "official_website": "https://www.wakcoll.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01924 789789",
        "course_locations": [
            {
                "location_name": "University Centre: Wakefield College",
                "location_address": "University Centre Wakefield,, Margaret Street,, Wakefield,, WF1 2DH"
            },
            {
                "location_name": "University Centre: Selby College",
                "location_address": "Abbot's Rd, Thunderhead Ridge, Selby, YO8 8AT"
            },
            {
                "location_name": "Wakefield College",
                "location_address": "Margaret Street, Wakefield, WF1 2DH"
            },
            {
                "location_name": "Castleford College",
                "location_address": "Thunderhead Ridge, Castleford, WF10 4UA"
            },
            {
                "location_name": "Wakefield City Campus",
                "location_address": "Margaret Street, Wakefield, WF1 2DH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/38a79022-d75e-c57c-592d-c2153229f082",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/img_he_004_1.jpg",
        "about_us": "N/A"
    },
    {
        "id": "ae9ea151cc032fc7cef45f12decdd3ed",
        "university_name": "University College of Estate Management",
        "location": "Horizons, 60 Queens Road, Caversham, Reading, Berkshire, RG1 4BS",
        "official_website": "https://www.ucem.ac.uk/",
        "contact_email": "enquiries@ucem.ac.uk",
        "contact_phone": "+441189219646",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Horizons, 60 Queen's Road, Reading, RG1 4BS"
            },
            {
                "location_name": "Online learning",
                "location_address": "University College of Estate Management, Horizons, Reading, RG1 4BS"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3c2fd5ff-127e-5a43-724a-8f30fddadd9c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/dsc_9905_crop.jpg",
        "about_us": "UCEM is the leading provider of supported online education for the built environment, with over 100 years\u2019 experience of providing the highest quality learning opportunities. We have more than 4,000 students globally benefiting from our qualifications taught by tutors with extensive industry experience.\u00a0We provide flexible and accessible postgraduate and undergraduate degree and apprenticeship programmes that fit your life and career goals at every stage.Our suite of modular, flexible courses are designed to work for each and every individual at each and every stage of their career. Whether you are starting out, or levelling up, fitting learning around life, or committing time to move to the next stage, we can offer a path that suits, and will be there to support you every step of the way.\u00a0Our courses put sustainability, prosperity and the wellbeing of communities at the heart, and equip our graduates to build better, and build with people in mind.\u00a0"
    },
    {
        "id": "79cad1b2bfb02080ddbd414419aa0014",
        "university_name": "UCO School of Osteopathy (formerly University College of Osteopathy)",
        "location": "275 Borough High Street, London, London, SE1 1JE",
        "official_website": "http://www.uco.ac.uk/",
        "contact_email": "admissions@uco.ac.uk",
        "contact_phone": "020 7089 5316",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "275 Borough High Street, London, SE1 1JE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/addf7cfa-5a1a-83af-70eb-eda47a1ff1c3",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/uco_banner.jpg",
        "about_us": "University College of Osteopathy has joined Health Sciences University (formerly AECC University College), and as of 1 August 2024 is now the UCO School of Osteopathy within Health Sciences University (HSU).\u00a0As one of our students you will be part of an institution that is committed to delivering educational excellence, to providing the next generation of health professionals for our health services, to upskilling our current workforce, to advancing our world through research, and to improving public health.\u00a0By choosing to study with us, you\u2019ll benefit from the specialist teaching expertise and exceptional clinical opportunities that have established the UCO as a leading provider of osteopathic education for over 100 years. UCO graduates make up 40% of UK osteopaths and develop careers in private practice, the NHS, professional sports, research and education.\u00a0\u00a0For 2024 entry please use institution code B87.For 2025 please use HSU institution code A65.\u00a0"
    },
    {
        "id": "86762ad7f2d49b358abfa915777c4756",
        "university_name": "University of Essex Online",
        "location": "Wivenhoe Park, Colchester, Colchester, CO4 3SQ",
        "official_website": "https://online.essex.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "West One, 114 Wellington Street, Leeds, LS1 1BA"
            },
            {
                "location_name": "Online Learning",
                "location_address": "West One, 114 Wellington Street, Leeds, LS1 1BA"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ba7a50f1-c89f-fa2f-7cc7-373c58f8a613",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/essex_ucas_background_1_0.png",
        "about_us": "The University of Essex Online provides 100% online, part-time Masters degrees and short courses in business, computing, education, health, law, criminology and psychology. Our students benefit from a flexible approach to learning while receiving the same dedicated support they expect from a top 25 UK University (Guardian, 2025). We\u2019re one of the longest-running providers of wholly online degrees in the UK and we\u2019ve put over a decade of expertise to good use in designing our online courses. Whether you're an experienced professional looking to advance your career, or a recent graduate who wants to boost their CV, online study is a cost-effective, convenient and flexible way to get ahead."
    },
    {
        "id": "9a3db67c9a5196f69e9fa6532ebe63c7",
        "university_name": "University of London Institute in Paris",
        "location": "9-11 rue de Constantine, Paris, Paris, 75007",
        "official_website": "https://london.ac.uk/institute-in-paris",
        "contact_email": "contact@ulip.lon.ac.uk",
        "contact_phone": "33 1 44 11 73 83",
        "course_locations": [
            {
                "location_name": "Paris Campus",
                "location_address": "University Of London Institute In Paris, 9-11 Rue De Constantine, Paris, 75007"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/0a4baa82-4cf3-1323-a562-076992e50aa3",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas-banner-4.jpg",
        "about_us": "N/A"
    },
    {
        "id": "f99618b40b29ed8f1cc37eaf598c92d8",
        "university_name": "University of Staffordshire",
        "location": "College Road, Stoke On Trent, Staffordshire, ST4 2DE",
        "official_website": "http://www.staffs.ac.uk/",
        "contact_email": "enquiries@staffs.ac.uk",
        "contact_phone": "01782 294000",
        "course_locations": [
            {
                "location_name": "South Staffordshire College",
                "location_address": "South Staffordshire College, Croft Street, Tamworth, B79 8AE"
            },
            {
                "location_name": "Westminster Kingsway College - Regent's Park Centre",
                "location_address": "Longford Street, London, NW1 3HB"
            },
            {
                "location_name": "University of Staffordshire (Stoke-on-Trent Campus)",
                "location_address": "College Road, Stoke-on-Trent, ST4 2DE"
            },
            {
                "location_name": "University of Staffordshire London",
                "location_address": "Broadcast Centre, Here East, 1 East Bay Lane, Queen Elizabeth Olympic Park, London, E15 2GW"
            },
            {
                "location_name": "CECOS College London",
                "location_address": "St Georges Chambers, 23 South Mall, Edmonton Green, London, N9 0TS"
            },
            {
                "location_name": "Burton and South Derbyshire College",
                "location_address": "Lichfield Street, Burton upon Trent, DE14 3RL"
            },
            {
                "location_name": "University of Staffordshire (Centre for Health Innovation)",
                "location_address": "Blackheath Lane, Stafford, ST18 0YG"
            },
            {
                "location_name": "Shrewsbury College of Arts &amp; Technology",
                "location_address": "Shrewsbury College Of Arts &amp; Technology, London Road, Shrewsbury, SY2 6PR"
            },
            {
                "location_name": "Sixth Form College, Stoke on Trent",
                "location_address": "Sixth Form College, Stoke On Trent, Leek Road, Stoke-on-Trent, ST4 2HN"
            },
            {
                "location_name": "Stoke-on-Trent College",
                "location_address": "Stoke-on-Trent College, Moorland Road, Stoke-on-Trent, ST4 2DG"
            },
            {
                "location_name": "Staffordshire University (Stoke Campus)",
                "location_address": "College Road, Stoke-on-Trent, ST4 2DE"
            },
            {
                "location_name": "Westminster Kingsway College (Capital City College Group)",
                "location_address": "Westminster Kingsway College, 22 Peter Street, Soho, London, W1F 0HS"
            },
            {
                "location_name": "Main Site",
                "location_address": "College Road, Stoke on Trent, ST4 2DE"
            },
            {
                "location_name": "CECOS College Birmingham",
                "location_address": "84 BARFORD STREET, BIRMINGHAM, B5 6AH"
            },
            {
                "location_name": "Wilkes Academy",
                "location_address": "Wilkes Academy Limited, Unit 1 Westmead Industrial Estate, Westmead Drive, Swindon, SN5 7YT"
            },
            {
                "location_name": "The College of Haringey, Enfield and North East London (Capital City College Group)",
                "location_address": "High Road, London, N15 4RU"
            },
            {
                "location_name": "Stafford College",
                "location_address": "Stafford College, Earl Street, Stafford, ST16 2QR"
            },
            {
                "location_name": "Ashton Sixth Form College",
                "location_address": "Darnton Road, Ashton-under-Lyne, OL6 9RL"
            },
            {
                "location_name": "Gemma",
                "location_address": "N/A"
            },
            {
                "location_name": "Lyra",
                "location_address": "N/A"
            },
            {
                "location_name": "Jack",
                "location_address": "N/A"
            },
            {
                "location_name": "Jerusha",
                "location_address": "N/A"
            },
            {
                "location_name": "Binita",
                "location_address": "N/A"
            },
            {
                "location_name": "Kirsty",
                "location_address": "N/A"
            },
            {
                "location_name": "Modie",
                "location_address": "N/A"
            },
            {
                "location_name": "Christine",
                "location_address": "N/A"
            },
            {
                "location_name": "Georgia",
                "location_address": "N/A"
            },
            {
                "location_name": "Emilia",
                "location_address": "N/A"
            },
            {
                "location_name": "Arron",
                "location_address": "N/A"
            },
            {
                "location_name": "Bunni",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/38236ddf-b8b2-e1fc-ec2f-6dda0a630eb0",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner.png",
        "about_us": "For over 100 years we\u2019ve been at the cutting-edge of industries, driving change and shaping what comes next. Today, we\u2019re a leading modern university built around the changing needs of work, life and everything in between \u2013 a revolutionary modern university built around you. With campuses in Stoke-on-Trent, Stafford, and London, we offer a wide range of dynamic, career-focused courses designed to take you beyond theory and into real-world practice. Here, you\u2019ll be immersed in exciting, hands-on experiences in cutting-edge simulation spaces, using real-world tools and techniques to bring your learning to life. With top industry placements and guaranteed opportunities for work experience, either alongside or integrated with your course, we\u2019ll give you the skills and opportunities you need to succeed.Here, you\u2019ll be part of a vibrant, supportive community where you can be you, surrounded by amazing people where amazing things happen. Here is the place of possibility. Here is your place. "
    },
    {
        "id": "39645c473558663bf6d4f01b55232919",
        "university_name": "University of the Arts London",
        "location": "272 High Holborn, London, London, WC1V 7EY",
        "official_website": "https://www.arts.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "020 7514  6000x6197",
        "course_locations": [
            {
                "location_name": "UAL School of Pre-Degree Studies",
                "location_address": "40 Lime Grove, London, W12 8EA"
            },
            {
                "location_name": "London College of Fashion",
                "location_address": "20 John Prince's Street, London, W1G 0BJ"
            },
            {
                "location_name": "Central Saint Martins",
                "location_address": "1 Granary Square, London, N1C 4AA"
            },
            {
                "location_name": "Chelsea College of Arts",
                "location_address": "16 John Islip Street, London, SW1P 4JU"
            },
            {
                "location_name": "Creative Computing Institute, University of the Arts London",
                "location_address": "Peckham Road, London, SE5 8UF"
            },
            {
                "location_name": "London College of Communication",
                "location_address": "Elephant &amp; Castle, London, SE1 6SB"
            },
            {
                "location_name": "Camberwell College of Arts",
                "location_address": "Peckham Road, London, SE5 8UF"
            },
            {
                "location_name": "Wimbledon College of Arts",
                "location_address": "Merton Hall Road, London, SW19 3QA"
            },
            {
                "location_name": "Jess",
                "location_address": "N/A"
            },
            {
                "location_name": "Daisy",
                "location_address": "N/A"
            },
            {
                "location_name": "Anna",
                "location_address": "N/A"
            },
            {
                "location_name": "Benjamin",
                "location_address": "N/A"
            },
            {
                "location_name": "Emily",
                "location_address": "N/A"
            },
            {
                "location_name": "Alejandra",
                "location_address": "N/A"
            },
            {
                "location_name": "Dex",
                "location_address": "N/A"
            },
            {
                "location_name": "Maisie",
                "location_address": "N/A"
            },
            {
                "location_name": "Jena\u00e9",
                "location_address": "N/A"
            },
            {
                "location_name": "Daria",
                "location_address": "N/A"
            },
            {
                "location_name": "Adrianna",
                "location_address": "N/A"
            },
            {
                "location_name": "Jacqueline",
                "location_address": "N/A"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/9928464e-bb25-4e0f-be6d-1373b4d28306",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/banner_final_1.jpg",
        "about_us": "University of the Arts London (UAL) offers an extensive range of courses in art, design, fashion, creative computing, communication and performing arts.UAL is ranked second in the world for Art and Design in the 2023 QS World University Rankings\u00ae.The University is made up of 6 renowned Colleges - Camberwell College of Arts, Central Saint Martins, Chelsea College of Arts, London College of Communication, London College of Fashion and Wimbledon College of Arts - and the UAL Creative Computing Institute.\u00a0Each College has a long established, global reputation for producing outstanding work. The UAL Creative Computing Institute operates at the intersection of art, design and computation.Whichever College or Institute you choose, you'll find a unique culture, philosophy and focus.\u00a0Come and join the creators and innovators of tomorrow."
    },
    {
        "id": "ac771b8035264bd1c7be7e0cc346dcdc",
        "university_name": "University of Westminster, London",
        "location": "University of Westminster, 309 Regent Street, London, W1B 2HW",
        "official_website": "http://www.westminster.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "+44 (0)20 7911 5000 EXT 65511",
        "course_locations": [
            {
                "location_name": "Regent Street",
                "location_address": "309 Regent Street, Westminster, W1B 2HW"
            },
            {
                "location_name": "Main Site",
                "location_address": "115 New Cavendish Street, Fitzrovia, London, W1W 6UW"
            },
            {
                "location_name": "Harrow",
                "location_address": "Watford Road, Northwick Park, Harrow, HA1 3TP"
            },
            {
                "location_name": "School of Law",
                "location_address": "4-12 Little Titchfield Street, Westminster, W1W 7BY"
            },
            {
                "location_name": "115 New Cavendish Street",
                "location_address": "115 New Cavendish Street, Westminster, W1W 6UW"
            },
            {
                "location_name": "Marylebone",
                "location_address": "35 Marylebone Road, Camden, NW1 5LS"
            },
            {
                "location_name": "Main Site",
                "location_address": "32-38 Wells Street, London, W1T 3UW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/0ad2bf41-bb38-0b1b-ec75-acf0845530a7",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/westminsterstudents6.jpeg",
        "about_us": "\u00a0A Global University with London Energy\u00a0Be yourself at a university where individuality is encouraged, diversity is celebrated and anything is possible.\u00a0With three out of four campuses located in the centre of London, you will be given the opportunity to gain practical skills and professional expertise for your future career, while being part of a supportive and vibrant community. Get hands-on with our specialist teaching facilities in a learning environment that focuses on support and employability.\u00a0Since 1838, we have been helping students from all backgrounds to achieve their potential. Our close relationships with employers and leading businesses will help you build a lasting global network and boost your career prospects. And, as a student in one of the world\u2019s best student cities, you can enjoy an endless choice of social and cultural activities from theatres and music to bars, restaurants and sport.\u00a0"
    },
    {
        "id": "39e76151776c55ea65b0e135d0f9f576",
        "university_name": "University of Wales Trinity Saint David",
        "location": "SA1 Waterfront Campus, Swansea, Swansea, SA1 8EW",
        "official_website": "http://www.uwtsd.ac.uk/",
        "contact_email": "admissions@uwtsd.ac.uk",
        "contact_phone": "0300 500 5054",
        "course_locations": [
            {
                "location_name": "Dynevor, Swansea",
                "location_address": "De-La-Beche Street, Swansea, SA1 3EU"
            },
            {
                "location_name": "Swansea Business Campus",
                "location_address": "Hight Street, Swansea, SA1 1NE"
            },
            {
                "location_name": "Lampeter Campus",
                "location_address": "College Drive, Lampeter, SA48 7ED"
            },
            {
                "location_name": "Cardiff Learning Centre",
                "location_address": "42 The Parade, Roath, Cardiff, CF24 3AD"
            },
            {
                "location_name": "SA1 Waterfront Campus, Swansea",
                "location_address": "UWTSD, Kings Road, Swansea, SA1 8AL"
            },
            {
                "location_name": "Cardiff (Caerdydd)",
                "location_address": "University of Wales Trinity Saint David, Haywood House North, Dumfries Place, Cardiff, CF10 3GA"
            },
            {
                "location_name": "Welsh International Academy of Voice",
                "location_address": "42 The Parade, Roath, Cardiff, CF24 3AD"
            },
            {
                "location_name": "Online",
                "location_address": "UWTSD, College Road, Carmarthen, SA31 3EP"
            },
            {
                "location_name": "Alexandra Road, Swansea",
                "location_address": "Alexandra Road, Swansea, SA1 5DU"
            },
            {
                "location_name": "Carmarthen Campus",
                "location_address": "College Road, Carmarthen, SA31 3EP"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/ff1a14b9-1214-76dc-340b-3822d9ea6c72",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/j-banner1.jpg",
        "about_us": "We are an employment-focused university, dedicated to ensuring our students from diverse backgrounds develop the academic knowledge and practical skills to thrive.We offer innovative teaching, state-of-the-art equipment and superb support across a wide range of learning locations and courses.We care about our students. Central to our vision is the promotion and embedding of a dual-sector educational system which educates learners of all ages and backgrounds.Known for our friendliness, UWTSD ranked 1st in the UK for Course and Lecturers in the Whatuni Student Choice Awards 2020.\u00a0"
    },
    {
        "id": "78c3eb476e56e280a0b30cd1537c26b0",
        "university_name": "Walsall College",
        "location": "Walsall College, Littleton Street West, Littleton Street West, Walsall, West Midlands, WS2 8ES",
        "official_website": "https://www.walsallcollege.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01922 657022",
        "course_locations": [
            {
                "location_name": "Walsall College",
                "location_address": "Littleton Street West, Walsall, WS2 8ES"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/49afad53-c84d-f79e-196d-73636eb2451b",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_banner_image_-_walsall_college.jpg",
        "about_us": "N/A"
    },
    {
        "id": "48d78b72b3059da8e9eba54aafcc4934",
        "university_name": "Warburg Institute, School of Advanced Study, University of London",
        "location": "Woburn Square, Camden, London, WC1H 0AB",
        "official_website": "https://false",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Warburg Inst, School of Advanced Study, Uni of London",
                "location_address": "Woburn Square, Camden, Wc1H 0AB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a3e358dc-4506-9d1c-670a-dcd37004f48f",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "0f8fbeb694e9b6c7eb21e648babe5019",
        "university_name": "University of Warwick",
        "location": "University of Warwick, Coventry, West Midlands, CV4 7AL",
        "official_website": "http://www.warwick.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "02476 523523",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Academic Office, University Road, Coventry, CV4 7AL"
            },
            {
                "location_name": "The Shard",
                "location_address": "32 London Bridge St, London, SE1 9SG"
            },
            {
                "location_name": "Main Site",
                "location_address": "Coventry, CV4 7AL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/e59d51a1-d4b5-314b-a01d-e013b9b51a80",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_wide_1_resize.jpg",
        "about_us": "Warwick is a world-leading campus university, a member of the Russell Group, and ranked in the top ten in\u00a0The Times and Sunday Times Good University Guide 2024 and The Guardian University Guide 2024.We are the fifth most targeted university by the UK\u2019s top 100 employers (High Fliers\u2019 Graduate Market 2023), and were named\u00a0University of the Year for Teaching Excellence by The Times and Sunday Times Good University Guide 2022."
    },
    {
        "id": "f0d1e965605668708028ba7822bd1c16",
        "university_name": "Warwickshire College and University Centre",
        "location": "Warwick New Road,, Leamington Spa, Leamington Spa, Warwickshire, CV32 5JE",
        "official_website": "https://www.wcuc.ac.uk",
        "contact_email": "he@wcuc.ac.uk",
        "contact_phone": "0300 456 0049",
        "course_locations": [
            {
                "location_name": "Pershore College",
                "location_address": "Avonbank, Pershore, WR10 3JP"
            },
            {
                "location_name": "Rugby College",
                "location_address": "Technology Drive, Rugby, CV21 1AR"
            },
            {
                "location_name": "Royal Leamington Spa College",
                "location_address": "Royal Leamington Spa College, Warwick New Road, Leamington Spa, CV32 5JE"
            },
            {
                "location_name": "Moreton Morrell College",
                "location_address": "Moreton Morrell, Warwick, CV35 9BL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/5d4edcb5-499b-015d-96f6-1d45b26ef33e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/w691_ec_ucas_webite_banner_1920x420_sept24.jpg",
        "about_us": "WCUC is a group of colleges that, combined, make up one of the largest further and higher education colleges in the UK.We deliver specialist degrees with a strong technical focus designed to support our graduates to achieve their potential. We work hard to meet the needs of our undergraduates and choosing our University Centre means you\u2019ll be part of an exciting and dynamic community of students.We understand the need for you to stand out as a graduate when entering the job market and that is why our industry experts are involved in our degree courses. We also know that you will flourish studying in our safe, friendly campuses alongside our supportive staff who will make you feel right at home and allow you to focus fully on your personal and professional development.\u00a0Developments with investment into WCUC include the WCG Career Launchpad platform which is now available to all our undergraduates providing advice and support to enhance employability throughout your studies."
    },
    {
        "id": "1e79bf8518efb5b8ecdd4884e437fd6f",
        "university_name": "Weald and Downland Living Museum",
        "location": "Town Lane, Singleton, Chichester, PO18 0EU",
        "official_website": "https://www.wealddown.co.uk",
        "contact_email": "mscprogrammes@wealddown.co.uk",
        "contact_phone": "01243 811044",
        "course_locations": [
            {
                "location_name": "Weald and Downland Living Museum",
                "location_address": "Weald and Downland Living Museum, Town Lane, Singleton, Chichester, PO18 0EU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/3a9aee2e-ba87-479a-af72-bafa95f387a1",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/weald_downland_living_museum_landscape_view_0.jpg",
        "about_us": "N/A"
    },
    {
        "id": "03df05bd86f0dcbc48e14b69de65a199",
        "university_name": "University Centre West Anglia",
        "location": "Tennyson Avenue,, Tennyson Avenue, King's Lynn, Norfolk, PE30 2QW",
        "official_website": "https://www.cwa.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01553 761144 X2286",
        "course_locations": [
            {
                "location_name": "Cambridge campus",
                "location_address": "Landbeach Road, Milton, CB24 6DB"
            },
            {
                "location_name": "King's Lynn campus",
                "location_address": "Tennyson Avenue, King's Lynn, PE30 2QW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/63b4cf1e-2e47-11c9-5b10-c292ef8bc986",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/17pt_cwa_lynn_2305_74.jpg",
        "about_us": "N/A"
    },
    {
        "id": "a230661e42d0fec174400a5112e14529",
        "university_name": "West Dean College",
        "location": "West Dean, Chichester, West Sussex, PO18 0QZ",
        "official_website": "https://www.westdean.ac.uk",
        "contact_email": "study@westdean.ac.uk",
        "contact_phone": "01243 818300",
        "course_locations": [
            {
                "location_name": "West Dean:London",
                "location_address": "Dilke House, 1 Malet Street, Bloomsbury, London, WC1E 7JN"
            },
            {
                "location_name": "West Dean College",
                "location_address": "West Dean, Nr Chichester, West Sussex, Chichester, PO18 0QZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/54468f81-39ed-c1a1-47d1-e51a00ffb4cb",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/college_exterior_banner_1.jpg",
        "about_us": "N/A"
    },
    {
        "id": "f14d3995a9d1360163c747eca2c316a8",
        "university_name": "West Herts College",
        "location": "Hempstead Road,, Hempstead Road, Watford, Hertfordshire, WD17 3EZ",
        "official_website": "https://www.westherts.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01923-812000",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Watford Campus, Hempstead Road, Watford, WD17 3EZ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/a73de6e5-cd9f-eb2b-bd7c-b8bf1c1d0274",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/west_herts-07910-066.jpg",
        "about_us": "N/A"
    },
    {
        "id": "f3ef3d95e1dde7fa2adab76a434f4b4c",
        "university_name": "University of West London",
        "location": "St Mary's Road, Ealing, London, UK, W5 5RF",
        "official_website": "http://www.uwl.ac.uk",
        "contact_email": "courses@uwl.ac.uk",
        "contact_phone": "0800 036 8888",
        "course_locations": [
            {
                "location_name": "The Creative Academy",
                "location_address": "73a Stoke Poges Lane,, Off Portsmouth Court,, Berkshire, SL1 3NY"
            },
            {
                "location_name": "Drama Studio London",
                "location_address": "1 Grange Road, London, W5 5QN"
            },
            {
                "location_name": "Berkshire Institute for Health - UWL - Reading",
                "location_address": "Tenth Floor, Fountain House, 2 Queens Walk, Reading, RG1 7QF"
            },
            {
                "location_name": "West London",
                "location_address": "Paragon, Boston Manor Road, Brentford, TW8 9GA"
            },
            {
                "location_name": "Main site - West London",
                "location_address": "St Mary's Road, Ealing, London, W5 5RF"
            },
            {
                "location_name": "Ruskin College",
                "location_address": "Ruskin Hall, Dunstan Road, Old Headington, OX3 9BZ"
            },
            {
                "location_name": "Nexus Institute of Creative Arts (Nexus ICA)",
                "location_address": "Astoria House, 71 Albany Road, Coventry, CV5 6JR"
            },
            {
                "location_name": "Main Site",
                "location_address": "St Mary's Road, Ealing, W5 5RF"
            },
            {
                "location_name": "MPAA",
                "location_address": "154 High Street, Bushey, WD23 3DH"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/4b00d284-8af2-9520-230e-8e14993d398d",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/uwl_55571712119_7.jpg",
        "about_us": "Taking you beyond the classroom into the world of work\u00a0Our courses and facilities are designed to help you develop\u202fthe qualities and expertise that your future employer will look for.\u202fWe offer career-focused courses with excellent teaching in a campus designed for a great student experience.\u00a01st in London*, 3rd in England* and 4th in the UK* in the National Student Survey (NSS) 2023.\u00a0Best modern** London university (non-specialist)\u202fin the 2025 Complete University Guide***.\u00a032nd in the UK\u202fin\u202fThe Guardian University Guide 2024.\u00a0Top 5 for Student Experience\u202fand Teaching Quality\u202fin The Times and Sunday Times Good University Guide 2024.\u00a080%\u202fof our research is rated as world-leading (4*) or internationally excellent\u202f(3*) in the latest Government Research Excellence Framework (REF) assessment.\u00a0"
    },
    {
        "id": "e10609e9917f90e1319f4b6a2d6d2fe2",
        "university_name": "University of the West of Scotland",
        "location": "Paisley Campus, Paisley, PA1 2BE",
        "official_website": "https://www.uws.ac.uk/home/",
        "contact_email": "ask@uws.ac.uk",
        "contact_phone": "0141 849 4101",
        "course_locations": [
            {
                "location_name": "Paisley Campus",
                "location_address": "High Street, Paisley, PA1 2BE"
            },
            {
                "location_name": "Dumfries Campus",
                "location_address": "Dudgeon House, Dumfries, DG1 4ZN"
            },
            {
                "location_name": "New College Lanarkshire",
                "location_address": "Town Centre, Cumbernauld, G67 1HU"
            },
            {
                "location_name": "Dumfries and Galloway College",
                "location_address": "Bankend Road, Dumfries, DG1 4FD"
            },
            {
                "location_name": "Lanarkshire Campus",
                "location_address": "UWS, Eco Campus, Stephenson Place, Hamilton International Technology Park, Hamilton, G72 0LH"
            },
            {
                "location_name": "Ayr Campus",
                "location_address": "University Avenue, Ayr, KA8 0SX"
            },
            {
                "location_name": "Ayrshire College",
                "location_address": "Dam Park, Ayr, KA8 0EU"
            },
            {
                "location_name": "South Lanarkshire College",
                "location_address": "College Way, East Kilbride, G75 0NE"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d064e8a8-954c-2d57-1aec-e099af852d0a",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/pic_1._jpg.jpg",
        "about_us": "With exciting undergraduate degrees across a range of subject areas, the innovative learning environments and industry-accredited programmes at UWS will ensure your hard work adds up to a successful and fulfilling career.\u00a0Why not visit us at our upcoming open days to experience what UWS is really like? Register now at www.uws.ac.uk/opendays\u00a0AUTUMN OPEN DAYSPaisley Campus - Sat 26 Oct, 11am - 2.30pmSUBJECT SHOWCASESNursing Showcase \u2013 DumfriesMon 18 Nov, 4.30 \u2013 6pmNursing &amp; Health and Social Care Showcase \u2013 PaisleyTue 19 Nov, 4.45 \u2013 6.30pmNursing &amp; Sport Showcase \u2013 AyrWed 20 Nov, 4.45 \u2013 6.30pmNursing, Midwifery, Paramedic, Science &amp; Sport Showcase \u2013 LanarkshireThu 21 Nov, 4.45 \u2013 6.30pmEducation Showcase \u2013 AyrMon 25 Nov, 4.45 \u2013 6.30pmCreative Industries Showcase \u2013 AyrMon 25 Nov, 4.45 \u2013 6.30pmBusiness Showcase \u2013 PaisleyTue 26 Nov, 4.45 \u2013 6.30pmSocial Sciences Showcase \u2013 PaisleyTue 26 Nov, 4.45 \u2013 6.30pmComputing, Engineering &amp; Physical Sciences Showcase \u2013 PaisleyWed 27 Nov, 4.45 \u2013 6.30pm"
    },
    {
        "id": "4828155205fc3ccc5b1b6399dd8c5ae1",
        "university_name": "West Suffolk College",
        "location": "73 Western Way, Bury St Edmunds, Suffolk, IP33 3TB",
        "official_website": "https://www.universitystudies.wsc.ac.uk",
        "contact_email": "universitystudiesadmissions@easterneducationgroup.ac.uk",
        "contact_phone": "01284 412026",
        "course_locations": [
            {
                "location_name": "University Studies and Professional Development Centre",
                "location_address": "73 Western Way, Bury St Edmunds, IP33 3SP"
            },
            {
                "location_name": "West Suffolk College Sixth Form Campus",
                "location_address": "Out Risbygate, Bury St Edmunds, IP33 3RL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/d157880f-65c1-4a1e-808a-1579a4bea548",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/pa111526.jpg",
        "about_us": "University Studies at West Suffolk College is proud to offer degree, HNC, HND and Access to Higher Education courses in Bury St Edmunds.Our degree programmes are accredited by the University of East Anglia, and our HNDs and HNCs by Pearson, giving you access to a prestigious degree on your doorstep, right here in Suffolk.The success of our students means more to us than anything else. Our courses are designed with the input of local employers and our links with local industry mean that once you\u2019ve graduated, you\u2019ll be in the best possible position to start a meaningful career in your chosen field.Couple that with our wraparound support, intimate learning environment, inclusive culture and supported routes into degree learning with our Access to Higher Education, HNC and HND programmes, and you\u2019ll discover how becoming a part of University Studies will help you find, follow and fulfil your aspirations."
    },
    {
        "id": "e7ad5d0aba4e5d09cd2c48a8d7826776",
        "university_name": "Capital City College Group",
        "location": "Capital City College Group, 211 Gray's Inn Road, London, WC1X 8RA",
        "official_website": "https://www.capitalccg.ac.uk",
        "contact_email": "courseinfo@capitalccg.ac.uk",
        "contact_phone": "02078325000",
        "course_locations": [
            {
                "location_name": "Tottenham Centre",
                "location_address": "High Road, Tottenham, London, N15 4RU"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/f8ee2f6a-6aed-e79f-6d6b-3f30f02150b4",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/candi_zara-adu-minta_business_20211438_apr-2021.jpg",
        "about_us": "Capital City College Group (CCCG) is the largest further education provider in London, and the third largest in the UK, with three colleges \u2013\u00a0City and Islington College\u00a0(CANDI),\u00a0The College of Haringey, Enfield and North East London\u00a0(CONEL) and\u00a0Westminster Kingsway College\u00a0(WestKing) \u2013 and a training arm delivering apprenticeships and courses for employers,\u00a0Capital City College Training\u00a0(CCCT). CCCG also helped found Visionnaires, a social enterprise for entrepreneurs, and 01 Founders, a unique coding school."
    },
    {
        "id": "0319e15e72cc980b21d05972d55bc30f",
        "university_name": "United Colleges Group",
        "location": "Paddington Green Campus, 25 Paddington Green, London, London, City of, W2 1NB",
        "official_website": "http://www.cwc.ac.uk/",
        "contact_email": "marketing@cwc.ac.uk",
        "contact_phone": "02077238826",
        "course_locations": [
            {
                "location_name": "Islington Music Workshop",
                "location_address": "24 Seward Street, London, EC1V 3PA"
            },
            {
                "location_name": "Willesden Campus",
                "location_address": "Willesden Campus, London, NW10 2XD"
            },
            {
                "location_name": "Paddington Green Campus",
                "location_address": "Paddington Green Campus, 25 Paddington Green, Westminster, W2 1NB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/164a2e50-0e93-4141-07ee-22b6198c578f",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/cwc_stairs.jpg",
        "about_us": "United Colleges Group comprises of City of Westminster College and College of North West London. UCG is one of the largest Further Education Institutions in London serving the communities of central and northwest London for over 130 years across five dedicated college campuses."
    },
    {
        "id": "893cc9308e870de1c3bd115058ee8710",
        "university_name": "University Centre Weston",
        "location": "Knightstone Campus, Weston-Super-Mare, Somerset, BS23 2AL",
        "official_website": "http://www.ucw.ac.uk/",
        "contact_email": "enquiries@ucw.ac.uk",
        "contact_phone": "01934 411411",
        "course_locations": [
            {
                "location_name": "Loxton Campus",
                "location_address": "Loxton Road, Weston super Mare, BS23 4QU"
            },
            {
                "location_name": "Main Site",
                "location_address": "Knightstone Road, Weston-super-Mare, BS23 2AL"
            },
            {
                "location_name": "South West Skills Campus",
                "location_address": "393 Locking Road, Weston-super-Mare, BS22 8NL"
            },
            {
                "location_name": "Weston College",
                "location_address": "Knightstone Road, Weston super Mare, BS23 2AL"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/7c3e72d6-73d6-f65d-8d1c-e4c704c29a04",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/university_centre_weston_ucas_1.jpg",
        "about_us": "N/A"
    },
    {
        "id": "8fcb7bee4aeec2dd13377aba08bc2b01",
        "university_name": "Weymouth and Kingston Maurward College",
        "location": "Cranford Avenue, Weymouth, Dorset, DT4 7LQ",
        "official_website": "https://www.wkmc.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01305 761100",
        "course_locations": [
            {
                "location_name": "Main Site (Weymouth College)",
                "location_address": "Cranford Avenue, Weymouth, DT4 7LQ"
            },
            {
                "location_name": "Kingston Maurward Campus",
                "location_address": "Kingston Maurward College, Dorchester, Dorchester, DT2 8PY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/f845254e-e619-067f-1500-3dd7e04a6087",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/main_entrance_ucas_crop_2_0.jpg",
        "about_us": "N/A"
    },
    {
        "id": "6c1996eeaf3e7eb0e34075dc2ddd8f20",
        "university_name": "Wigan and Leigh College",
        "location": "Parson's Walk Centre,, Parson's Walk, Wigan, Greater Manchester, WN1 1RR",
        "official_website": "https://www.wigan-leigh.ac.uk/",
        "contact_email": "N/A",
        "contact_phone": "01942 761606",
        "course_locations": [
            {
                "location_name": "Leigh",
                "location_address": "Sale Way, Leigh Sports Village, Leigh, WN7 4JY"
            },
            {
                "location_name": "Wigan",
                "location_address": "Parsons Walk, Wigan, WN1 1RR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/090716a0-1170-7040-4575-d499c997b6ca",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/_mg_15344b_resized.jpg",
        "about_us": "N/A"
    },
    {
        "id": "446438c348608d4fae09d1b01718e7d0",
        "university_name": "Wiltshire College and University Centre",
        "location": "College Road,, Trowbridge, Wiltshire, BA14 0ES",
        "official_website": "https://wiltshire.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01225 530150 ",
        "course_locations": [
            {
                "location_name": "Salisbury",
                "location_address": "Southampton Road, Salisbury, SP1 2LW"
            },
            {
                "location_name": "Trowbridge",
                "location_address": "College Road, Trowbridge, BA14 0ES"
            },
            {
                "location_name": "Chippenham",
                "location_address": "Cocklebury Road, Chippenham, SN15 3QD"
            },
            {
                "location_name": "Lackham",
                "location_address": "Lacock, Chippenham, SN15 2NY"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/37ab70cb-6256-6b57-d1ac-5aaf16b3fca4",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/untitled_design_13.png",
        "about_us": "We Are Wiltshire's University Centre\u00a0Based across four campuses and a specialist centre, we offer a broad selection of university-level courses in a diverse variety of fields. From our friendly staff, to our indsutry-standard facilities, there are more reasons than ever to choose Wiltshire College &amp; University Centre.\u00a0"
    },
    {
        "id": "c4e2bfc4773dec3df2a414426f23c4fb",
        "university_name": "University of Winchester",
        "location": "Sparkford Road, Winchester, Hampshire, SO22 4NR",
        "official_website": "https://www.winchester.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01962 841515",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Sparkford Road, Winchester, SO22 4NR"
            },
            {
                "location_name": "Main Site",
                "location_address": "Sparkford Road, Winchester, Hampshire, SO22 4NR"
            },
            {
                "location_name": "West Downs",
                "location_address": "Romsey Road, Winchester, SO22 5HT"
            },
            {
                "location_name": "Distance Learning",
                "location_address": "Sparkford Road, Winchester, Hampshire, SO22 4NR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/34ba7b64-9643-c8e7-1709-02bcf2e15551",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/18728_4.jpg",
        "about_us": "We are a highly renowned educational institution with a 180-year history of academic excellence. Our community is driven by curiosity, love of subject, and a commitment to transformative education. Located in the cathedral city of Winchester, we\u2019re just one hour away from London and surrounded by the beautiful South Downs National Park."
    },
    {
        "id": "c23755ef8d90c2e22f6ac3e87d096071",
        "university_name": "Wirral Metropolitan College",
        "location": "Conway Park Campus, Europa Boulevard, Europa Boulevard, Birkenhead, Merseyside, CH41 4NT",
        "official_website": "https://www.wmc.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01515 517777",
        "course_locations": [
            {
                "location_name": "Hamilton Campus",
                "location_address": "Argyle Street, Birkenhead, CH41 1AD"
            },
            {
                "location_name": "Twelve Quays Campus",
                "location_address": "Shore Road, Birkenhead, CH41 1AG"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/4940a04d-62a0-adf5-fe81-b67dd755cecc",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/conway_park_campus.jpg",
        "about_us": "N/A"
    },
    {
        "id": "0bdb213e45345899d67e1f7258f72efa",
        "university_name": "University of Wolverhampton",
        "location": "Wulfruna Street, Camp Street, Wolverhampton, West Midlands, WV1 1LY",
        "official_website": "https://www.wlv.ac.uk/?utm_source=UCAS+Profile+Page&utm_medium=UCAS+Profile+-+Visit+our+website+Button&utm_campaign=UG+Campaign+2023&utm_id=UCAS",
        "contact_email": "enquiries@wlv.ac.uk",
        "contact_phone": "01902 321000",
        "course_locations": [
            {
                "location_name": "South Staffordshire College (Lichfield Campus)",
                "location_address": "South Staffordshire College (Lichfield Campus), The Friary, Lichfield, WS13 6QG"
            },
            {
                "location_name": "City Campus",
                "location_address": "Wulfruna Street, Wolverhampton, WV1 1SB"
            },
            {
                "location_name": "Telford Campus",
                "location_address": "Telford Innovation Centre, Shifnal Road, Priorslee, Telford, TF2 9NN"
            },
            {
                "location_name": "Sister Dora Building, Walsall",
                "location_address": "Gorway Road, Walsall, WS1 3BD"
            },
            {
                "location_name": "South Staffordshire College (Tamworth Campus)",
                "location_address": "Croft Street, Tamworth, B79 8AE"
            },
            {
                "location_name": "City of Wolverhampton College",
                "location_address": "Paget Road, Wolverhampton, WV6 0DU"
            },
            {
                "location_name": "South Staffordshire College - Rodbaston Campus",
                "location_address": "Rodbaston, Penkridge, Stafford, ST19 5PH"
            },
            {
                "location_name": "Cheshire College South and West (Crewe Campus)",
                "location_address": "Danes Bank Avenue, Crewe, Cheshire, CW2 8AB"
            },
            {
                "location_name": "University of Wolverhampton",
                "location_address": "Admissions Unit, Camp Street, Wolverhampton, WV1 1AD"
            },
            {
                "location_name": "Mary Seacole Building, Wolverhampton",
                "location_address": "Molineux Street, Wolverhampton, WV1 1SB"
            },
            {
                "location_name": "Main Site",
                "location_address": "Wulfruna Street, Wolverhampton, WV1 1LY"
            },
            {
                "location_name": "Sandwell College",
                "location_address": "Central Campus, 1 Spon Lane, West Bromwich, B70 6AW"
            },
            {
                "location_name": "South Staffordshire College (Cannock Campus)",
                "location_address": "Cannock Campus - The Green, The Green, Cannock, WS11 1UE"
            },
            {
                "location_name": "Walsall Campus",
                "location_address": "Gorway Rd, Walsall, WS1 3BD"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/0a5af9c9-f05a-bf52-fce5-c89917335f67",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/35171645612_6b0d6c3777_k.jpg",
        "about_us": "The University of Wolverhampton puts students first, encouraging them to \u201cBe who you want to be.\u201d Three main campuses in Wolverhampton, Walsall and Telford offer a wide range of courses, many of which are accredited by professional bodies. Part-time, full-time, flexible and distance learning options are available for some courses, along with options to study abroad or spend a year in industry."
    },
    {
        "id": "719a10668d82c734f57998a536c8baf4",
        "university_name": "Wrexham University",
        "location": "Mold Road, Mold Road, Wrexham, Wrexham, LL11 2AW",
        "official_website": "https://www.wrexham.ac.uk/",
        "contact_email": "enquiries@wrexham.ac.uk",
        "contact_phone": "01978 293439",
        "course_locations": [
            {
                "location_name": "Northop",
                "location_address": "Northop Campus, Holywell Road, Northop, CH7 6AA"
            },
            {
                "location_name": "Wrexham (Main Campus)",
                "location_address": "Wrexham Campus, Mold Road, Wrexham, LL11 2AW"
            },
            {
                "location_name": "St Asaph",
                "location_address": "University Centre St Asaph, Ffordd William Morgan, St Asaph, LL17 0JD"
            },
            {
                "location_name": "Wrexham",
                "location_address": "Wrexham Campus, Mold Road, Wrexham, LL11 2AW"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/fdb6a3ab-185d-286d-e06f-f97135cade7f",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/ucas_header-01-01.jpg",
        "about_us": "Wrexham University is a vibrant, friendly and inclusive learning community. We are dedicated to inspiring you and we place your success at the heart of everything we do.We are an accessible, innovative, supportive, and ambitious institution with a host of courses to suit you.Accessible \u2013 We have been ranked 1st in England and Wales for social inclusion for the sixth year running in The Times and Sunday Times Good University Guide, 2024.Innovative - \u00a0We invest in our students and produce ground-breaking research. Notably, we\u00a0polished prototype mirrors for a \u00a3900m project on the world\u2019s largest telescope.Supportive \u2013 Highly valuing student feedback, we ranked 2nd\u00a0among Welsh Universities for Student Voice in the National Student Survey, 2024, and 1st\u00a0of out Welsh Universities for Student Satisfaction in the Complete University Guide, 2025.Ambitious - Our \u00a380m investment into our campuses reflects our ambition to provide the best facilities and learning environment for our students."
    },
    {
        "id": "a8b46d0048719f21c2c766c5a9928395",
        "university_name": "Xenophon College London",
        "location": "85 Tottenham Court Road,, London, W1T 4TQ",
        "official_website": "https://xcl.ac.uk/applications",
        "contact_email": "N/A",
        "contact_phone": "N/A",
        "course_locations": [
            {
                "location_name": "Tottenham Court Road",
                "location_address": "85 Tottenham Court Road, London, W1T 4TQ"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/4175f17f-f8b6-4e9b-a174-5ce3c7dcc66d",
        "background_image": "",
        "about_us": "N/A"
    },
    {
        "id": "6e3947e8374523d30125c7db5d1f9dfd",
        "university_name": "Yeovil College University Centre",
        "location": "Mudford Road, Yeovil, Somerset, BA21 4DR",
        "official_website": "https://www.yeovil.ac.uk",
        "contact_email": "N/A",
        "contact_phone": "01935 423921",
        "course_locations": [
            {
                "location_name": "Yeovil College University Centre",
                "location_address": "Mudford Road, Yeovil, BA21 4DR"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/f4e4d70f-8b2a-4e4b-f333-94d35212b13e",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/img_6813edit_2.jpg",
        "about_us": "N/A"
    },
    {
        "id": "177ee440e6c3cde8cd17b67d8c27ca2e",
        "university_name": "York College University Centre",
        "location": "Sim Balk Lane,, York, North Yorkshire, YO23 2BB",
        "official_website": "https://www.yorkcollege.ac.uk",
        "contact_email": "info@yorkcollege.ac.uk",
        "contact_phone": "01904 770397",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Sim Balk Lane, York, YO23 2BB"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6045fd2c-9f53-7a88-5e42-0412b370b361",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/2020_03_04_yc_d2_s2_195.jpg",
        "about_us": "York College University Centre is a vibrant community with high-quality courses and a strong tradition in higher education. Our broad range of degree and higher-level courses offer a mixture of full and part-time programmes that are designed with your future employability in mind.Undergraduate qualifications are courses that are an academic step-up from A Levels or equivalent Level 3 courses. Our undergraduate qualifications include foundation degrees, bachelor\u2019s degrees, BA top-ups, Higher National Certificates and Diplomas, higher apprenticeships, and higher professional qualifications.We appreciate that not everyone follows the same path and encourage applicants from all walks of life, including those who are perhaps returning to education or are looking for career advancement or change. Our higher education provision provides the support, flexibility, contact time and teaching required to help students of all types succeed."
    },
    {
        "id": "e62337a7165239dd2aa289386aed4c83",
        "university_name": "York St John University",
        "location": "Lord Mayor's Walk, York, North Yorkshire, YO31 7EX",
        "official_website": "https://www.yorksj.ac.uk",
        "contact_email": "admissions@yorksj.ac.uk",
        "contact_phone": "01904 624624",
        "course_locations": [
            {
                "location_name": "YSJU London Campus",
                "location_address": "6th Floor, Export Building, 1 Clove Crescent, London, E14 2BA"
            },
            {
                "location_name": "York St John University",
                "location_address": "Lord Mayor's Walk, York, YO31 7EX"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/6d0cfb92-5817-1f26-10d0-52e6e2c1123c",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/campus-4_1.jpg",
        "about_us": "At York St John University we will support you throughout your entire university journey. From advice during Clearing, through to career support after graduation, our staff are available to help you. Join us to experience teaching that will challenge and inspire you to shape your future.Our campus brings modern, eco-conscious buildings together with Victorian architecture and beautiful green spaces. We are based in the centre of York, a historic city full of friendly people, independent businesses and exciting experiences.Studying at York St John University means becoming part of an inclusive, supportive and passionate community.We look forward to seeing you soon."
    },
    {
        "id": "dc2efb0d855bd0d1a651e37a00fd7711",
        "university_name": "University of York",
        "location": "Heslington, York, YO10 5DD",
        "official_website": "http://www.york.ac.uk/",
        "contact_email": "ug-admissions@york.ac.uk",
        "contact_phone": "+44 (0)1904 324000",
        "course_locations": [
            {
                "location_name": "Main Site",
                "location_address": "Heslington, York, YO10 5DD"
            },
            {
                "location_name": "Main Site",
                "location_address": "Heslington, York, YO10 5DD"
            }
        ],
        "logo_url": "https://d1l6hqpjksdq9d.cloudfront.net/Prod/f40210c2-c564-5bfc-ee3b-98ced83963b8",
        "background_image": "https://www.ucas.com/sites/default/files/provider-images/banners/university_of_york.jpg",
        "about_us": "The University of York is an acclaimed member of the Russell Group, tackling global issues through its teaching, research and commitment to improving local and international communities. Nurturing independent minds and a strong collective spirit, all for the public good, York empowers people from all backgrounds to flourish in a rapidly changing world.\u00a0Our student body is made up of over 22,000 students from over 150 countries, and its University of Sanctuary status reflects this culture of belonging. They provide a safe space for refugees, asylum seekers and others forced to migrate, and offer continued support to everyone who joins their diverse and inclusive community.\u00a0York challenges students to dream big and think critically. They work in partnership with institutions across the world, pioneering solutions to our most pressing global challenges. The University builds these global connections and research accolades into its teaching and student opportunities."
    }
]
export default university_details_updated;