import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../userProvider/AuthProvider';

function PrivateRoute({ role }) {
  const { userRole, currentUser } = useAuth();

  console.log("PrivateRoute currentUser:", currentUser);
  console.log("from auth:", userRole);
  console.log("from app:", role);

  // If the user is not logged in, redirect to login
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // If the user is logged in but doesn't have the correct role, redirect to 404
  if (userRole !== role) {
    return <Navigate to="/blank" />;
  }

  // If user is authenticated and has the correct role, render the requested route
  return <Outlet />;
}

export default PrivateRoute;
