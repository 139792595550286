// src/components/MapComponent.js
import React, { useEffect, useState } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRestaurants } from '../../../redux/slices/restaurantsSlice';
import { useInView } from 'react-intersection-observer';
import MapWithMarkers from './MapWithMarkers';

const MapComponent = () => {
  const dispatch = useDispatch();
  const restaurants = useSelector((state) => state.restaurants.list); // Get the list from Redux
  const [userLocation, setUserLocation] = useState(null); // State for storing user's current location
  const { ref, inView } = useInView({ triggerOnce: true });

  // Load Google Maps API only once
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
// console.log(restaurants)
  // Dispatch the fetchRestaurants action when the component mounts
  useEffect(() => {
    dispatch(fetchRestaurants());
  }, [dispatch]);

  // Fetch user's current location using Geolocation API
  useEffect(() => {
    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            setUserLocation(location); // Set the user's location
          },
          (error) => {
            console.error('Error getting user location:', error.message);
          },
          { timeout: 10000, enableHighAccuracy: true }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };

    getUserLocation();
  }, []);

  if (!isLoaded) return <div>Loading Google Maps...</div>;

  return (
    <div ref={ref}>
      {inView && (
        <MapWithMarkers
          restaurants={restaurants}
          userLocation={userLocation} // Pass user location to the map component
        />
      )}
    </div>
  );
};

export default MapComponent;
