import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../../firebase'; // Adjust the import based on your project structure
import { useAuth } from '../../../auth/userProvider/AuthProvider';

const VerificationLogs = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchLogs = async () => {
      if (!currentUser) {
        console.error('User is not logged in');
        setLoading(false);
        return;
      }

      try {
        // Create a query to fetch documents where organizerId equals currentUser.uid
        const logsCollection = collection(firestore, 'verificationLogs');
        const logsQuery = query(logsCollection, where('organizerId', '==', currentUser.uid));
        const logsSnapshot = await getDocs(logsQuery);
        const logsList = logsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setLogs(logsList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching logs:', error);
        setLoading(false);
      }
    };

    fetchLogs();
  }, [currentUser]);

  if (loading) {
    return <p>Loading logs...</p>;
  }

  return (
<div className="container mx-auto px-4 py-4 text-gray-200">
  <h2 className="text-2xl font-semibold text-white mb-4">Verification Logs</h2>
  <div className="overflow-x-auto">
    <table className="min-w-full bg-gray-800 rounded-lg shadow-lg">
      <thead>
        <tr>
          <th className="text-left py-2 px-4 bg-gray-700 text-gray-300">Customer Name</th>
          <th className="text-left py-2 px-4 bg-gray-700 text-gray-300">Email</th>
          <th className="text-left py-2 px-4 bg-gray-700 text-gray-300">Amount</th>
          <th className="text-left py-2 px-4 bg-gray-700 text-gray-300">Currency</th>
          <th className="text-left py-2 px-4 bg-gray-700 text-gray-300">Status</th>
          <th className="text-left py-2 px-4 bg-gray-700 text-gray-300">Verification Status</th>
          <th className="text-left py-2 px-4 bg-gray-700 text-gray-300">Verification Time</th>
        </tr>
      </thead>
      <tbody>
        {logs.map(log => (
          <tr key={log.id} className="border-b border-gray-700">
            <td className="py-2 px-4 text-gray-200">{log.customer_name}</td>
            <td className="py-2 px-4 text-gray-200">{log.customer_email}</td>
            <td className="py-2 px-4 text-gray-200">£{log.totalAmountPaid}</td>
            <td className="py-2 px-4 text-gray-200">{log.currency.toUpperCase()}</td>
            <td className={`py-2 px-4 ${log.status === 'paid' ? 'text-green-500' : 'text-red-500'}`}>{log.status}</td>
            <td className={`py-2 px-4 ${log.verified ? 'text-green-500' : 'text-red-500'}`}>
              {log.verified ? 'Verified' : 'Not Verified'}
            </td>
            <td className="py-2 px-4 text-gray-200">
              {log.verificationTime ? new Date(log.verificationTime.seconds * 1000).toLocaleString() : 'N/A'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

  );
};

export default VerificationLogs;
