import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { auth, googleProvider, firestore } from '../../firebase';
import { getDoc, doc, onSnapshot } from "firebase/firestore";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [userData, setUserData] = useState(null); // New state variable for storing user data

  const signUpWithEmailAndPassword = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  const signInWithEmailAndPassword = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = () => {
    return auth.signInWithPopup(googleProvider);
  };

  const logout = () => {
    return auth.signOut();
  };

  const resetPassword = (email) => {
    return auth.sendPasswordResetEmail(email);
  };

  const getUserRole = useCallback(async (uid, retryCount = 3, delay = 500) => {
    for (let attempt = 0; attempt < retryCount; attempt++) {
      try {
        const userDoc = await getDoc(doc(firestore, "users", uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log("User data retrieved successfully:", userData);
          return userData.role;
        } else {
          console.log("User document not found on attempt", attempt + 1);
        }
      } catch (error) {
        console.error("Error fetching user role on attempt", attempt + 1, ":", error);
      }
      // Wait before retrying
      await new Promise(resolve => setTimeout(resolve, delay));
    }
    console.log("Failed to retrieve user document after", retryCount, "attempts");
    return null;
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
            setCurrentUser(user);
            setLoading(true);

            const userDocRef = doc(firestore, "users", user.uid);
            const unsubscribeUserDoc = onSnapshot(userDocRef, (doc) => {
                if (doc.exists()) {
                    const userData = doc.data();
                    setUserData(userData);
                    setUserRole(userData.role);
                } else {
                    console.warn("No role found for user, setting userRole and userData to null");
                    setUserRole(null);
                    setUserData(null);
                }
                setLoading(false);
            });

            return () => unsubscribeUserDoc();
        } else {
            setCurrentUser(null);
            setUserRole(null);
            setUserData(null);
            setLoading(false);
        }
    });

    return unsubscribe;
}, []);


  const value = {
    currentUser,
    userRole,
    userData, // Export userData
    signUpWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithGoogle,
    logout,
    resetPassword,
    getUserRole,
    setUserData
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
