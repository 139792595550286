import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateDoc, doc, addDoc, collection } from "firebase/firestore";
import { firestore } from "../../../firebase";

const Loader = () => (
  <div className="flex justify-center items-center">
    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

const CollegeUpload = () => {
  const [collegeData, setCollegeData] = useState({
    collegeName: "",
    location: "",
    description: "",
    established: "",
    ranking: "",
    rating: "",
    collegeImage: null,
    totalStudents: "",
    scholarships: "",
    collegeId: "",
    examsAccepted: "",
    cardImage: null,
    coursesAndFees: [],
  });
  const [loading, setLoading] = useState(false);
  const [collegeImageURL, setCollegeImageURL] = useState(null);
  const [collegeCardImageURL, setCollegeCardImageURL] = useState(null);

  useEffect(() => {
    addRow();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    try {
      const docRef = await addDoc(collection(firestore, "universities"), {
        ...collegeData,
        collegeImage: collegeImageURL,
        cardImage: collegeCardImageURL,
      });
      const collegeId = docRef.id;
      await updateDoc(doc(firestore, "universities", collegeId), {
        collegeId: collegeId,
      });
      setCollegeData({
        collegeName: "",
        location: "",
        description: "",
        established: "",
        ranking: "",
        rating: "",
        collegeImage: null,
        totalStudents: "",
        scholarships: "",
        cardImage: null,
        collegeId: "",
        examsAccepted: "",
        coursesAndFees: [{ course: "", totalCourses: 0, tuitionFees: "", durationAndEligibility: "", examAccepted: "" }],
      });
      setCollegeImageURL(null);
      setCollegeCardImageURL(null);
      setLoading(false);
      toast.success("College data uploaded successfully!");
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.error("Error uploading college data");
      setLoading(false);
    }
  };

  const handleFileChange = async (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const storage = getStorage();
      const storageRef = ref(
        storage,
        `colleges/${collegeData.collegeId}/${collegeData.collegeName}/${type}/${file.name}`
      );
      try {
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        if (type === 'collegeImage') {
          setCollegeImageURL(downloadURL);
        } else {
          setCollegeCardImageURL(downloadURL);
        }
      } catch (error) {
        console.error("Error uploading image: ", error);
      }
    }
  };

  const addRow = () => {
    setCollegeData((prevData) => ({
      ...prevData,
      coursesAndFees: [...prevData.coursesAndFees, { course: "", totalCourses: 0, tuitionFees: "", durationAndEligibility: "", examAccepted: "" }],
    }));
  };

  const handleEdit = (index, field, value) => {
    setCollegeData((prevData) => {
      const updatedCoursesAndFees = [...prevData.coursesAndFees];
      updatedCoursesAndFees[index][field] = value;
      return { ...prevData, coursesAndFees: updatedCoursesAndFees };
    });
  };

  const handleDelete = (index) => {
    setCollegeData((prevData) => {
      const updatedCoursesAndFees = [...prevData.coursesAndFees];
      updatedCoursesAndFees.splice(index, 1);
      return { ...prevData, coursesAndFees: updatedCoursesAndFees };
    });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold mb-8">Upload University Details</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <form onSubmit={handleFormSubmit} className="space-y-4">
              <input
                type="text"
                placeholder="University Name"
                value={collegeData.collegeName}
                onChange={(e) => setCollegeData({ ...collegeData, collegeName: e.target.value })}
                className="w-full p-2 bg-gray-800 rounded"
              />
              <input
                type="text"
                placeholder="Location"
                value={collegeData.location}
                onChange={(e) => setCollegeData({ ...collegeData, location: e.target.value })}
                className="w-full p-2 bg-gray-800 rounded"
              />
              <textarea
                placeholder="Description"
                value={collegeData.description}
                onChange={(e) => setCollegeData({ ...collegeData, description: e.target.value })}
                className="w-full p-2 bg-gray-800 rounded"
                rows="4"
              ></textarea>
              <input
                type="text"
                placeholder="Year Established"
                value={collegeData.established}
                onChange={(e) => setCollegeData({ ...collegeData, established: e.target.value })}
                className="w-full p-2 bg-gray-800 rounded"
              />
              <input
                type="text"
                placeholder="Ranking"
                value={collegeData.ranking}
                onChange={(e) => setCollegeData({ ...collegeData, ranking: e.target.value })}
                className="w-full p-2 bg-gray-800 rounded"
              />
              <input
                type="text"
                placeholder="Accepted Exams"
                value={collegeData.examsAccepted}
                onChange={(e) => setCollegeData({ ...collegeData, examsAccepted: e.target.value })}
                className="w-full p-2 bg-gray-800 rounded"
              />
              <input
                type="text"
                placeholder="Total Students"
                value={collegeData.totalStudents}
                onChange={(e) => setCollegeData({ ...collegeData, totalStudents: e.target.value })}
                className="w-full p-2 bg-gray-800 rounded"
              />
              <input
                type="text"
                placeholder="Scholarships"
                value={collegeData.scholarships}
                onChange={(e) => setCollegeData({ ...collegeData, scholarships: e.target.value })}
                className="w-full p-2 bg-gray-800 rounded"
              />
              <input
                type="text"
                placeholder="Rating"
                value={collegeData.rating}
                onChange={(e) => setCollegeData({ ...collegeData, rating: e.target.value })}
                className="w-full p-2 bg-gray-800 rounded"
              />
              <button
                type="submit"
                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                disabled={loading}
              >
                {loading ? <Loader /> : "Submit"}
              </button>
            </form>
          </div>
          <div className="space-y-6">
            <div className="border-2 border-dashed border-gray-600 rounded-lg p-4">
              <label className="block text-sm font-medium mb-2">Cover Image</label>
              <input
                type="file"
                onChange={(e) => handleFileChange(e, 'collegeImage')}
                className="w-full text-sm text-gray-400
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-blue-50 file:text-blue-700
                hover:file:bg-blue-100"
              />
              {collegeImageURL && (
                <img src={collegeImageURL} alt="College" className="mt-4 max-w-full h-auto" />
              )}
            </div>
            <div className="border-2 border-dashed border-gray-600 rounded-lg p-4">
              <label className="block text-sm font-medium mb-2">Card Image</label>
              <input
                type="file"
                onChange={(e) => handleFileChange(e, 'cardImage')}
                className="w-full text-sm text-gray-400
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-blue-50 file:text-blue-700
                hover:file:bg-blue-100"
              />
              {collegeCardImageURL && (
                <img src={collegeCardImageURL} alt="Card" className="mt-4 max-w-full h-auto" />
              )}
            </div>
          </div>
        </div>
        <div className="mt-8">
          <h2 className="text-2xl font-bold mb-4">Courses and Fees</h2>
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-400">
              <thead className="text-xs uppercase bg-gray-700">
                <tr>
                  <th scope="col" className="px-6 py-3">Course</th>
                  <th scope="col" className="px-6 py-3">Total Courses</th>
                  <th scope="col" className="px-6 py-3">1st Year Tuition Fees</th>
                  <th scope="col" className="px-6 py-3">Duration & Eligibility</th>
                  <th scope="col" className="px-6 py-3">Exam Accepted</th>
                  <th scope="col" className="px-6 py-3">Action</th>
                </tr>
              </thead>
              <tbody>
                {collegeData.coursesAndFees.map((course, index) => (
                  <tr key={index} className="border-b bg-gray-800 border-gray-700">
                    <td className="px-6 py-4">
                      <input
                        type="text"
                        value={course.course}
                        onChange={(e) => handleEdit(index, "course", e.target.value)}
                        className="bg-gray-700 border border-gray-600 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      />
                    </td>
                    <td className="px-6 py-4">
                      <input
                        type="number"
                        value={course.totalCourses}
                        onChange={(e) => handleEdit(index, "totalCourses", parseInt(e.target.value))}
                        className="bg-gray-700 border border-gray-600 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      />
                    </td>
                    <td className="px-6 py-4">
                      <input
                        type="text"
                        value={course.tuitionFees}
                        onChange={(e) => handleEdit(index, "tuitionFees", e.target.value)}
                        className="bg-gray-700 border border-gray-600 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      />
                    </td>
                    <td className="px-6 py-4">
                      <input
                        type="text"
                        value={course.durationAndEligibility}
                        onChange={(e) => handleEdit(index, "durationAndEligibility", e.target.value)}
                        className="bg-gray-700 border border-gray-600 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      />
                    </td>
                    <td className="px-6 py-4">
                      <input
                        type="text"
                        value={course.examAccepted}
                        onChange={(e) => handleEdit(index, "examAccepted", e.target.value)}
                        className="bg-gray-700 border border-gray-600 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      />
                    </td>
                    <td className="px-6 py-4">
                      <button onClick={() => handleDelete(index)} className="font-medium text-red-500 hover:underline">Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button onClick={addRow} className="mt-4 bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            Add Row
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CollegeUpload;
