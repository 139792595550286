import React, { useState, useEffect } from 'react';
import { updated_acc } from './updated_acc';
import room from '../../../assets/img/room.jpg';
import { useAuth } from '../../../auth/userProvider/AuthProvider';
import {toast} from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
const HotelBooking = () => {
  const [exportData, setExportData] = useState([]);
  const [filterCity, setFilterCity] = useState(''); // Default to all cities
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueCities, setUniqueCities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState(300);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const itemsPerPage = 8;

  useEffect(() => {
    // Initialize export data and unique cities
    const loadData = () => {
      try {
        if (!updated_acc) {
          console.error('Data is null or undefined');
          return;
        }

        // Filter out entries with missing values
        const filteredExportSheet = updated_acc.filter(obj =>
          Object.values(obj || {}).every(value => value !== null)
        );
        setExportData(filteredExportSheet);

        // Get unique cities
        const cities = [...new Set(filteredExportSheet.map(item => item.city.trim()))];
        setUniqueCities(cities);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    // Apply filters to data
    const applyFilters = () => {
      let filtered = exportData || [];

      // City filter
      if (filterCity) {
        filtered = filtered.filter(item => item.city.toLowerCase().trim() === filterCity.toLowerCase().trim());
      }

      // Search term filter
      if (searchTerm) {
        filtered = filtered.filter(item =>
          Object.values(item).some(val =>
            val && val.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      }

      // Price range filter
      if (minPrice) {
        filtered = filtered.filter(item => parseInt(item.details[item.details.length - 1].match(/\d+/)) >= parseInt(minPrice));
      }
      if (maxPrice) {
        filtered = filtered.filter(item => parseInt(item.details[item.details.length - 1].match(/\d+/)) <= parseInt(maxPrice));
      }

      setFilteredData(filtered);
    };

    applyFilters();
  }, [exportData, filterCity, searchTerm, minPrice, maxPrice]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
  };

  const handleBuyClick = (roomLink) => {
    window.open(roomLink, '_blank');
  };
  const handleViewDetails = () => {
    if (currentUser) {
      toast(
        <span>
          Please contact <a href="mailto:info@worldlynk.co.uk" style={{ color: 'blue' }}>info@worldlynk.co.uk</a> for more assistance.
        </span>
      );
    } else {
      //console.log("login");
      navigate('/login');
    }
  };
  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      {/* Hero Section with Banner Image */}
      <div className="relative bg-gray-800 py-20 bg-cover bg-center" style={{ backgroundImage: `url(${room})` }}>
        <div className="relative z-10 container mx-auto px-4">
          <h1 className="text-4xl sm:text-5xl font-bold text-white mb-4">Find Your Ideal Accommodation</h1>
          <p className="text-xl text-gray-300">Discover comfortable stays tailored to your needs.</p>
        </div>
        <div className="absolute inset-0 bg-black opacity-30"></div>
      </div>

      {/* Filters and Results */}
      <div className="container mx-auto py-8 px-3">
        <div className="bg-gray-800 rounded-lg p-6 mb-8 shadow-md">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <input
              type="text"
              placeholder="Search..."
              onChange={e => setSearchTerm(e.target.value)}
              className="bg-gray-700 text-gray-100 p-3 rounded-md w-full border border-gray-600"
            />
            <select
              onChange={e => setFilterCity(e.target.value)}
              className="bg-gray-700 text-gray-100 p-3 rounded-md w-full border border-gray-600"
            >
              <option value="">All Cities</option>
              {uniqueCities.map((city, index) => (
                <option key={index} value={city}>{city}</option>
              ))}
            </select>
            <div className="flex flex-col">
              <label htmlFor="maxPrice" className="text-gray-300 mb-2">
                Max Price: £{maxPrice}
              </label>
              <input
                type="range"
                id="maxPrice"
                min={80}
                max={300}
                step={10}
                value={maxPrice}
                onChange={e => setMaxPrice(e.target.value)}
                className="w-full h-2 bg-gray-600 rounded-lg appearance-none cursor-pointer accent-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Hotel Cards */}
        {filteredData.length === 0 ? (
          <div className="text-center text-gray-400 mt-8">
            <p>No rooms available matching your criteria.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {currentItems.map((item, index) => (
              <div key={index} className="bg-gray-800 rounded-lg overflow-hidden shadow-lg transition-all transform hover:scale-105">
                <img
                  src={item.image_url}
                  alt="Accommodation"
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h2 className="text-xl font-semibold mb-2">{item.room_name}</h2>
                  <p className="text-gray-400 mb-2">City: {item.city}</p>
                  <p className="text-gray-400 mb-2">Location: {item.location}</p>
                  <p className="text-gray-400 mb-4">Details: {item.details.join(', ')}</p>
                  <button
                    className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
                    onClick={handleViewDetails}                  >
                    View Details
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Pagination */}
        <div className="flex justify-center items-center space-x-4 mt-8">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="bg-gray-700 text-gray-300 py-2 px-4 rounded-md disabled:opacity-50 hover:bg-gray-600"
          >
            Previous
          </button>
          <span className="text-gray-400">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="bg-gray-700 text-gray-300 py-2 px-4 rounded-md disabled:opacity-50 hover:bg-gray-600"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default HotelBooking;
