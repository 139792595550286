import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import EventList from "./EventList"; // Assuming you have a component to list events
import './filterEvents.css'; // Ensure this file contains the necessary styles

const EventsExplore = () => {
  const { eventCategory } = useParams();
  const [value, setValue] = useState("All Events");
  const [searchTerm, setSearchTerm] = useState("");
  const tabsRef = useRef(null); // Reference to the tabs container

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const scrollLeft = () => {
    tabsRef.current.scrollBy({ left: -200, behavior: "smooth" });
  };

  const scrollRight = () => {
    tabsRef.current.scrollBy({ left: 200, behavior: "smooth" });
  };

  return (
    <div className="container min-h-screen">
      <div className="w-full mb-4">
        <h1 className="text-3xl font-bold text-white">Explore Events</h1>
      </div>

      <div className="flex flex-col md:flex-row gap-4 mb-4">
        <input
          type="text"
          placeholder="Search Events"
          value={searchTerm}
          onChange={handleSearch}
          className="w-full md:w-1/2 p-2 border border-gray-400 rounded bg-gray-900 text-white placeholder-gray-500"
        />

        <div className="relative w-full md:w-1/2">
          <select
            value={value}
            onChange={handleChange}
            className="w-full p-2 border border-gray-400 rounded bg-gray-900 text-white"
          >
            <option value="All Events">All Events</option>
            <option value="Academic Events">Academic Events</option>
            <option value="Cultural Events">Cultural Events</option>
            <option value="Social Events">Social Events</option>
            <option value="Sports and Recreation">Sports and Recreation</option>
            <option value="Professional Development">Professional Development</option>
            <option value="Health and Wellness">Health and Wellness</option>
            <option value="Volunteer and Community Service">Volunteer and Community Service</option>
            <option value="Orientation and Information Sessions">Orientation and Information Sessions</option>
            <option value="Arts and Entertainment">Arts and Entertainment</option>
            <option value="Technology and Innovation">Technology and Innovation</option>
          </select>
        </div>
      </div>

      <div className="relative mb-4">
        {/* Left Navigation Button */}
        <button
          onClick={scrollLeft}
          className="absolute left-0 top-0 bottom-0 w-10 bg-gray-900 bg-opacity-50 hover:bg-opacity-75 text-white z-10 flex justify-center items-center"
        >
          &#9664;
        </button>

        {/* Tabs Container */}
        <div
          ref={tabsRef}
          className="flex space-x-4 overflow-x-auto scrollbar-hide mx-12" /* Adjusted padding for buttons */
        >
          {[
            "All Events",
            "Academic Events",
            "Cultural Events",
            "Social Events",
            "Sports and Recreation",
            "Professional Development",
            "Health and Wellness",
            "Volunteer and Community Service",
            "Orientation and Information Sessions",
            "Arts and Entertainment",
            "Technology and Innovation",
          ].map((category) => (
            <li
              key={category}
              className={`list-none flex-shrink-0 px-4 py-2 cursor-pointer whitespace-nowrap ${
                value === category ? "text-white border-b-2 border-white" : "text-gray-500"
              }`}
              onClick={() => setValue(category)}
            >
              {category}
            </li>
          ))}
        </div>

        {/* Right Navigation Button */}
        <button
          onClick={scrollRight}
          className="absolute right-0 top-0 bottom-0 w-10 bg-gray-900 bg-opacity-50 hover:bg-opacity-75 text-white z-10 flex justify-center items-center"
        >
          &#9654;
        </button>
      </div>

      <div>
        <EventList searchTerm={searchTerm} eventType={value} />
      </div>
    </div>
  );
};

export default EventsExplore;
