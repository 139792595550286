import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import insight from '../../../assets/img/insight.png';
import enhance from '../../../assets/img/enhance.png';
import amp from '../../../assets/img/amp.png';
import AboutUs from '../sub-components/About';
import './NewHome.css';
import video1 from '../../../assets/img/video1.mp4';
import video2 from '../../../assets/img/video2.mp4';
import video3 from '../../../assets/img/video3.mp4';
import video4 from '../../../assets/img/video4.mp4';
import video5 from '../../../assets/img/video5.mp4';
import video6 from '../../../assets/img/video6.mp4';
import club from '../../../assets/img/club.jpg';
import rest from '../../../assets/img/rest.png';
import uni from '../../../assets/img/univer.jpg';
import job from '../../../assets/img/job.avif';
import acc from '../../../assets/img/acc.jpg';
import BrandLogos from './BrandLogos';
import { FaUniversity, FaUtensils, FaBed, FaCalendarAlt, FaChalkboardTeacher, FaBriefcase } from 'react-icons/fa'; // Icons for sticky section
const NewHome = () => {
    
    const navigate = useNavigate();
    const typewriterRef = useRef(null);
    const [currentPhrase, setCurrentPhrase] = useState('');
    const [currentSlide, setCurrentSlide] = useState(0);
    const carouselRef = useRef(null);

    const carouselImages = useMemo(() => [
        { src: club, alt: "Club" },
        { src: rest, alt: "Restaurant" },
        { src: acc, alt: "Accommodation" },
        { src: uni, alt: "University" },
        { src: job, alt: "Job" }
    ], []);

    const handleNavigation = useCallback((path) => {
        navigate(path);
    }, [navigate]);

    useEffect(() => {
        const phrases = ["students", "brands", "entrepreneurs"];
        let phraseIndex = 0;
        let charIndex = 0;
        let isDeleting = false;
        let typingSpeed = 150;

        const typePhrase = () => {
            const currentFullPhrase = phrases[phraseIndex];

            if (!isDeleting && charIndex <= currentFullPhrase.length) {
                setCurrentPhrase(currentFullPhrase.substring(0, charIndex));
                charIndex++;
                typingSpeed = 150;
            } else if (isDeleting && charIndex >= 0) {
                setCurrentPhrase(currentFullPhrase.substring(0, charIndex));
                charIndex--;
                typingSpeed = 100;
            } else {
                isDeleting = !isDeleting;
                if (!isDeleting) {
                    phraseIndex = (phraseIndex + 1) % phrases.length;
                }
                typingSpeed = isDeleting ? 100 : 1000;
            }

            setTimeout(typePhrase, typingSpeed);
        };

        typePhrase();

        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => {
                const nextSlide = (prevSlide + 1) % carouselImages.length;
                gsap.to(carouselRef.current.children[prevSlide], { opacity: 0, duration: 1 });
                gsap.to(carouselRef.current.children[nextSlide], { opacity: 1, duration: 1 });
                return nextSlide;
            });
        }, 5000);

        const handleSmoothScroll = (e) => {
            e.preventDefault();
            document.querySelector(e.target.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        };

        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', handleSmoothScroll);
        });

        return () => {
            clearInterval(interval);
            document.querySelectorAll('a[href^="#"]').forEach(anchor => {
                anchor.removeEventListener('click', handleSmoothScroll);
            });
        };
    }, [carouselImages]);

    const renderCarouselImages = useCallback(() => (
        carouselImages.map((image, index) => (
            <img
                key={index}
                src={image.src}
                alt={image.alt}
                loading="lazy"
                decoding="async"
                className={`absolute inset-0 w-full h-full object-cover ${
                    index === currentSlide ? 'opacity-100' : 'opacity-0'
                }`}
            />
        ))
    ), [carouselImages, currentSlide]);

    const renderPartnerCards = useCallback(() => (
        [{ title: "Amplified Reach", description: "Expand your audience and increase brand visibility across our diverse network.", image: amp },
         { title: "Enhanced Engagement", description: "Connect with your target audience through tailored, interactive experiences.", image: enhance },
         { title: "Insightful Analytics", description: "Gain valuable data-driven insights to optimize your marketing strategies.", image: insight }]
        .map((item, index) => (
            <div key={index} className="flex flex-col items-center bg-gray-700 rounded-lg p-6 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
                <div className="custom-bg-color p-4 rounded-full mb-4">
                    <img src={item.image} alt={item.title} className="w-10 h-10" />
                </div>
                <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
                <p className="text-center text-sm">{item.description}</p>
            </div>
        ))
    ), []);

    return (
        <div className='bg-gradient-to-b from-gray-900 to-gray-500 text-white min-h-screen'>
            <section className='mt-0 relative h-screen'>
                <div className="absolute inset-0 overflow-hidden" ref={carouselRef}>
                    {renderCarouselImages()}
                    <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                </div>
                <div className="container mx-auto flex flex-col justify-center items-center h-full relative z-5 px-4 pt-16 text-center">
                    <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold mb-6 leading-tight">
                        The ultimate ecosystem for{' '}
                        <span className="custom-text-color" ref={typewriterRef}>{currentPhrase}</span> <br />in the UK
                    </h1>
                    <p className="text-lg sm:text-xl md:text-2xl mb-8">
                        WorldLynk is your comprehensive solution for a seamless experience in the UK.
                    </p>
                    <div className="flex flex-wrap justify-center">
                        <button onClick={() => handleNavigation('/login')} className="custom-bg-color hover:bg-orange-600 text-black py-3 px-6 rounded-full mr-4 mb-2 md:mb-0 transition duration-300 ease-in-out transform hover:scale-105 text-lg w-32">
                            Join Now
                        </button>
                        <button onClick={() => handleNavigation('/login')} className="custom-text-color border border-orange-500 hover:bg-white hover:text-black py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 text-lg w-32">
                            Sign in
                        </button>
                    </div>
                </div>
            </section>

            <AboutUs />
  

            <section className='py-10 bg-gray-800 text-white overflow-hidden'>
                <div className="container mx-auto px-4">
                    <h2 className='text-3xl font-bold text-center mb-10'>Experience Different Worlds With WorldLynk</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        <div className="space-y-4">
                            <video src={video1} autoPlay loop muted playsInline className="w-full h-auto rounded-lg lazyload"></video>
                            <img src="https://images.pexels.com/photos/1083822/pexels-photo-1083822.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" alt="placeholder" className="w-full h-auto rounded-lg lazyload" />
                            <video src={video2} autoPlay loop muted playsInline className="w-full h-auto rounded-lg lazyload"></video>
                        </div>
                        <div className="space-y-4">
                            <img src="https://images.pexels.com/photos/3629537/pexels-photo-3629537.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="placeholder" className="w-full h-auto rounded-lg lazyload" />
                            <video src={video3} autoPlay loop muted playsInline className="w-full h-auto rounded-lg lazyload"></video>
                            <img src="https://images.pexels.com/photos/3673762/pexels-photo-3673762.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="placeholder" className="w-full h-auto rounded-lg lazyload" />
                            <video src={video4} autoPlay loop muted playsInline className="w-full h-auto rounded-lg lazyload"></video>
                        </div>
                        <div className="space-y-4">
                            <video src={video5} autoPlay loop muted playsInline className="w-full h-auto rounded-lg lazyload"></video>
                            <img src="https://images.pexels.com/photos/4230630/pexels-photo-4230630.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="placeholder" className="w-full h-auto rounded-lg lazyload" />
                            <video src={video6} autoPlay loop muted playsInline className="w-full h-auto rounded-lg lazyload"></video>
                        </div>
                        <div className="space-y-4">
                            <img src="https://images.pexels.com/photos/2248516/pexels-photo-2248516.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="placeholder" className="w-full h-auto rounded-lg lazyload" />
                            <video src={video4} autoPlay loop muted playsInline className="w-full h-auto rounded-lg lazyload"></video>
                            <img src="https://images.pexels.com/photos/2899100/pexels-photo-2899100.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="placeholder" className="w-full h-auto rounded-lg lazyload" />
                            <video src={video1} autoPlay loop muted playsInline className="w-full h-auto rounded-lg lazyload"></video>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-10 bg-gray-800 text-white overflow-hidden'>
                <div className="container mx-auto px-4">
                    <h2 className='text-3xl font-bold text-center mb-10'>Our Brand Partners</h2>
                    <BrandLogos />
                </div>
            </section>

            <section className='py-10 bg-gray-800 text-white'>
                <div className="container mx-auto px-4">
                    <div className="text-center mb-10">
                        <h2 className='text-3xl font-bold'>Partner <span className="custom-text-color">With Us</span></h2>
                        <p className='mt-4'>Embark on a journey of mutual growth and success by aligning your brand with WorldLynk. Our partnership offers unparalleled advantages:</p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {renderPartnerCards()}
                    </div>
                    <div className="text-center mt-10">
                        <button onClick={() => handleNavigation('/business')} className="custom-bg-color hover:bg-orange-600 text-black py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105">
                            Contact Us
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default NewHome;
