import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FaHeart,
  FaComment,
  FaShare,
  FaBookmark,
  FaYelp,
  FaPlus,
  FaBell,
  FaVolumeMute,
  FaVolumeUp,
  FaChartLine
} from 'react-icons/fa';
import { firestore } from '../../firebase';
import { collection, query, orderBy, getDocs, startAfter, limit } from 'firebase/firestore';
import globe1 from '../../assets/img/globe2.png';

const RelivHome = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Track if more videos can be fetched
  const [muteStates, setMuteStates] = useState({}); // Track mute state per video
  const [showMuteIcon, setShowMuteIcon] = useState(false);
  const [showMore, setShowMore] = useState({});
  const [lastVisible, setLastVisible] = useState(null); // For pagination
  const videoRefs = useRef([]);
  const containerRef = useRef(null);
  const navigate = useNavigate();

  // Fetch posts with pagination
  const fetchPosts = useCallback(async () => {
    if (loading || !hasMore) return; // Prevent additional fetch if already loading or no more posts
    setLoading(true);

    try {
      const postsRef = collection(firestore, 'reliv-posts');
      const q = lastVisible
        ? query(postsRef, orderBy('createdAt', 'desc'), startAfter(lastVisible), limit(10))
        : query(postsRef, orderBy('createdAt', 'desc'), limit(10));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const newPosts = querySnapshot.docs.map((doc) => doc.data());
        setVideos((prevVideos) => [...prevVideos, ...newPosts]);
        console.log('Fetched new videos:', newPosts);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]); // Save last document for pagination
      } else {
        setHasMore(false); // No more posts available
        console.log('No more videos to fetch');
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, lastVisible]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  // Debounced scroll handler to prevent frequent calls
  const handleScroll = useCallback(() => {
    if (
      containerRef.current &&
      containerRef.current.scrollTop + containerRef.current.clientHeight >= containerRef.current.scrollHeight - 100
    ) {
      console.log('Reached bottom of the page, fetching more videos');
      fetchPosts();
    }
  }, [fetchPosts]);

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  // Video intersection observer for play/pause
  const handleIntersection = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        const index = Number(entry.target.getAttribute('data-index'));
        const video = videoRefs.current[index];
        if (video) {
          if (entry.isIntersecting) {
            if (video.paused) {
              video.play().catch((error) => console.warn('Error trying to play video:', error));
            }
            setCurrentVideoIndex(index);
            console.log('Video at index', index, 'is now playing');
          } else {
            video.pause();
            console.log('Video at index', index, 'is now paused');
          }
        } else {
          console.warn('Video ref at index', index, 'is not available');
        }
      });
    },
    []
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });
    videoRefs.current.forEach((video, index) => {
      if (video) {
        observer.observe(video);
        console.log('Observing video at index', index);
      } else {
        console.warn('Video ref at index', index, 'is not available');
      }
    });
    return () => videoRefs.current.forEach((video, index) => video && observer.unobserve(video));
  }, [videos, handleIntersection]);

  // Toggle mute for a specific video
  const toggleMute = (index) => {
    setMuteStates((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
    videoRefs.current[index].muted = !muteStates[index];
    setShowMuteIcon(true);
    setTimeout(() => setShowMuteIcon(false), 1000);
    console.log('Toggled mute state for video at index', index);
  };

  // Toggle show more/less for video titles
  const toggleShowMore = (index) => {
    setShowMore((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
    console.log('Toggled show more/less for video at index', index);
  };

  // IconButton component
  const IconButton = ({ Icon, label, count }) => (
    <div className="flex flex-col items-center">
      <button className="text-white p-2 rounded-full bg-gray-800 bg-opacity-50 hover:bg-opacity-75 transition-all duration-200">
        <Icon size={24} />
      </button>
      {count !== undefined && <span className="text-white text-xs mt-1">{count}</span>}
      <span className="text-white text-xs mt-1">{label}</span>
    </div>
  );

  return (
    <div className="bg-gray-900 min-h-screen flex flex-col">
      {/* Top Navigation with Tabs */}
      <div className="flex justify-between items-center px-4 py-3 bg-gray-900">
        <div className="flex items-center space-x-2">
          <h1 className="text-white text-2xl font-bold">Reliv</h1>
          <FaYelp className="text-white text-2xl" />
        </div>

        {/* Hide category section on mobile */}
        <div className="hidden md:flex space-x-4">
          {['For You', 'Friends', 'Global'].map((category, idx) => (
            <button key={idx} className="text-white bg-gray-800 px-4 py-2 rounded-full focus:outline-none">
              {category}
            </button>
          ))}
        </div>

        {/* Actions section */}
        <div className="flex items-center space-x-4">
          <FaPlus
            className="text-white text-xl cursor-pointer"
            onClick={() => navigate('/user-dashboard/reliv/create')}
          />
          <FaBell className="text-white text-xl cursor-pointer relative">
            <span className="absolute top-0 right-0 bg-red-500 text-xs rounded-full w-4 h-4 flex items-center justify-center">
              2
            </span>
          </FaBell>
          <img
            src={globe1}
            alt="WorldLynk Small Icon"
            className="w-8 h-8 cursor-pointer"
            onClick={() => navigate('/user-dashboard/reliv/myworlds')}
          />
        </div>
      </div>

      {/* Left side section hidden on mobile */}
      <div className="flex-1 flex relative">
        <div className="hidden md:block absolute left-6 top-20 space-y-4">
          {['Relive', 'Live'].map((option, idx) => (
            <div key={idx} className="flex items-center space-x-3">
              <div
                className={`w-2 h-8 rounded-l-full ${option === 'Relive' ? 'bg-orange-500' : 'bg-gray-800'}`}
              ></div>
              <span
                className={`text-white uppercase text-xl font-bold ${
                  option === 'Relive' ? 'text-orange-500' : 'text-gray-300'
                }`}
              >
                {option}
              </span>
            </div>
          ))}
        </div>

        {/* Video section with lazy loading */}
        <div ref={containerRef} className="flex justify-center items-center w-full">
          <div className="relative w-full max-w-[360px] h-[740px] overflow-y-scroll snap-y snap-mandatory scrollbar-hide bg-black">
            {videos.map((video, index) => (
              <div key={index} className="relative h-[640px] snap-start">
                <video
                  ref={(el) => (videoRefs.current[index] = el)}
                  data-index={index}
                  src={video.videoUrl}
                  className="w-full h-full object-cover"
                  loop
                  playsInline
                  muted={muteStates[index]} // Use per-video mute state
                  onError={() => console.error('Video not supported or unable to load')}
                  onClick={() => toggleMute(index)}
                />

                {showMuteIcon && (
                  <div className="absolute inset-0 flex justify-center items-center">
                    {muteStates[index] ? (
                      <FaVolumeMute size={40} className="text-white" />
                    ) : (
                      <FaVolumeUp size={40} className="text-white" />
                    )}
                  </div>
                )}

                {/* Title and Show More/Show Less Toggle */}
                <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black to-transparent">
                  <p className="text-white text-sm mb-1">
                    {showMore[index] ? video.title : `${video.title.substring(0, 50)}${video.title.length > 50 ? '...' : ''}`}
                    {video.title.length > 50 && (
                      <span onClick={() => toggleShowMore(index)} className="text-blue-400 cursor-pointer ml-1 text-xs">
                        {showMore[index] ? 'Show less' : 'Show more'}
                      </span>
                    )}
                  </p>
                  <p className="text-white text-xs font-semibold">@{video.userName}</p>
                </div>

                <div className="absolute right-4 bottom-20 flex flex-col space-y-6">
                  <div onClick={() => navigate(`/user-dashboard/reliv/book/${video.experienceId}`)}>
                    <IconButton Icon={FaYelp} label="Reliv" />
                  </div>
                  <div onClick={() => navigate('/user-dashboard/reliv/analysis')}>
                    <IconButton Icon={FaChartLine} label="Analytics" />
                  </div>
                  <IconButton Icon={FaShare} label="Share" />
                  <IconButton Icon={FaBookmark} label="Save" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelivHome;