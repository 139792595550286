import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { firestore } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';

const Itinerary = () => {
  const { experienceId } = useParams();
  const navigate = useNavigate();
  const [itineraryData, setItineraryData] = useState([]);

  useEffect(() => {
    const fetchItineraryData = async () => {
      try {
        const docRef = doc(firestore, 'reliv-experiences', experienceId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const experiences = data.experiences || [];
          setItineraryData(experiences);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching itinerary data:', error);
      }
    };

    fetchItineraryData();
    window.scrollTo(0, 0);
  }, [experienceId]);

  return (
    <div className="dark text-white min-h-screen flex flex-col items-center py-8 px-4 relative">
      <button
        onClick={() => window.history.back()}
        className="absolute top-4 left-4 flex items-center space-x-2 text-white text-lg font-semibold"
      >
        <FaArrowLeft />
      </button>
      <h1 className="text-3xl font-bold mb-10">Create A Relive Experience</h1>

      {/* Itinerary Section */}
      <div className="flex justify-between w-full max-w-3xl space-x-8">
        {/* Left Column: Time and Events */}
        <div className="flex flex-col space-y-10">
          <h2 className="text-xl font-semibold mb-4">Itinerary</h2>
          {itineraryData.map((item, index) => (
            <div key={index} className="flex items-center space-x-8">
              <div className="text-lg font-semibold">{item.time || 'No Time Provided'}</div>
              <div className="w-1 h-full border-l-2 border-dashed border-gray-500 mx-2"></div>
              <div className="flex flex-col">
                <div className="text-lg font-medium">{item.name || 'No Name Provided'}</div>
                {item.notes && item.notes.length > 0 && (
  <div className="text-sm font-medium flex">
    <p>note:</p> {/* Add margin to create space */}
    <ul className="flex-1"> {/* Flex-1 makes the list take remaining space */}
      {item.notes.map((note, noteIndex) => (
        <li key={noteIndex}>• {note}</li>
      ))}
    </ul>
  </div>
)}

              </div>
            </div>
          ))}
        </div>

        {/* Right Column: Clickable Images */}
        <div className="flex flex-col space-y-6">
          {itineraryData.map((item, index) => (
            <div
              key={index}
              className="relative group cursor-pointer"
              style={{ width: '150px', height: '150px' }} // Adjust the size of the image container
            >
              <img
                src={item.picture || 'https://via.placeholder.com/300x200'}
                alt={item.name || 'Experience Image'}
                className="rounded-lg shadow-lg object-cover w-full h-full"
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <span className="text-white font-semibold">CLICK TO VIEW</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Go to Itinerary Button */}
      <button
        onClick={() => navigate(`/user-dashboard/reliv/create/post/${experienceId}`)}
        className="bg-green-500 px-4 py-2 rounded text-black font-bold mt-8"
      >
        CREATE A RELIV POST
      </button>
    </div>
  );
};

export default Itinerary;
