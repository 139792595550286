import angelDen from '../../assets/vid/AngelsDen.mp4';
import Video1 from '../../assets/img/video1.mp4';
import Video2 from '../../assets/img/video2.mp4';
import Video3 from '../../assets/img/video3.mp4';
import Video4 from '../../assets/img/video4.mp4';


export const videos = [
    { title: 'Asking Students about their experience', user: 'WorldLynk', views: 12000, likes: 1200, comments: 450, src: Video1 },
    { title: 'Students about the problems they face in UK', user: 'WorldLynk', views: 8900, likes: 980, comments: 320, src: Video2 },
    { title: 'WorldLynk is an Experience!', user: 'WorldLynk', views: 5600, likes: 470, comments: 150, src: Video3 },
    { title: 'What is WorldLynk?', user: 'WorldLynk', views: 11000, likes: 1300, comments: 480, src: Video4 },
    { title: 'Angels Den', user: 'AngelsDen', views: 3036, likes: 670, comments: 210, src: angelDen },
  ];

 export const scheduleData = {
    'Thu 06': {
      title: 'Basics Of JavaScript',
      time: 'March 24 at 4:00 PM',
      meetingWith: 'Bhubnesh Maharana',
      imageUrl: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=150&h=150&fit=crop&crop=faces',
    },
    'Fri 07': {
      title: 'Advanced React Workshop',
      time: 'March 25 at 2:00 PM',
      meetingWith: 'Alex Johnson',
      imageUrl: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=150&h=150&fit=crop&crop=faces',
    },
    'Sat 08': {
      title: 'Node.js Backend Masterclass',
      time: 'March 26 at 5:00 PM',
      meetingWith: 'Sarah Lee',
      imageUrl: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?w=150&h=150&fit=crop&crop=faces',
    },
  };

  export const friendsImages = [
    "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=150&h=150&fit=crop&crop=faces",
    "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=150&h=150&fit=crop&crop=faces",
    "https://images.unsplash.com/photo-1580489944761-15a19d654956?w=150&h=150&fit=crop&crop=faces",
    "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=150&h=150&fit=crop&crop=faces",
    "https://images.unsplash.com/photo-1517841905240-472988babdf9?w=150&h=150&fit=crop&crop=faces",
  ];